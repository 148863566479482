import { Button } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import { MdOutlineDelete } from "react-icons/md";

import { DeleteDraftDialog } from "../draft/DeleteDraftDialog";

type Props = {
  draftUuid: string;
  queryKey: string | string[];
};

export const DeleteButton: React.FC<Props> = ({ draftUuid, queryKey }) => {
  const theme = useTheme();

  const { isMobile } = useDeviceType();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Button
        icon={<MdOutlineDelete />}
        css={{
          backgroundColor: isMobile ? "transparent" : theme.colors.dangerBackground,
          padding: isMobile ? "0.25rem" : "0.375rem",
          color: theme.colors.danger,
          "&:hover": {
            backgroundColor: isMobile ? "transparent" : theme.colors.danger,
            color: isMobile ? theme.colors.danger : "white",
          },
        }}
        onClick={(e) => setIsDialogOpen(true)}
      />
      {isDialogOpen && (
        <DeleteDraftDialog onClose={() => setIsDialogOpen(false)} draftUuid={draftUuid} queryKey={queryKey} />
      )}
    </>
  );
};
