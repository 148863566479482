import { Serializers } from "@legacy-megarax/serializers";

export interface BasicAddress {
  locality: string;
  street: string;
  postalCode: string;
  country: string;
}

export const addressSerializer = Serializers.object<BasicAddress>({
  locality: Serializers.string,
  street: Serializers.string,
  postalCode: Serializers.string,
  country: Serializers.string,
});
