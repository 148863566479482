import { Chip } from "@dash/form";
import { useTheme } from "@emotion/react";

import { ShoperStatus } from "@megaron/loyalty-orders-contracts";

export const ShoperStatusChip = ({ status, isLabeled }: { status: ShoperStatus; isLabeled?: boolean }) => {
  const label = isLabeled ? <span css={{ fontWeight: "bold" }}>Shoper</span> : null;
  const theme = useTheme();

  return {
    unplaced: <Chip variant="secondary">{label} Formularz</Chip>,
    inProgress: <Chip variant="secondary">{label} W realizacji</Chip>,
    pending: <Chip variant="secondary">{label} Przetwarzanie</Chip>,
    shipped: <Chip variant="primary">{label} Wysłano</Chip>,
    cancelled: (
      <Chip variant="primary" color={theme.colors.danger}>
        {label} Anulowane
      </Chip>
    ),
  }[status];
};
