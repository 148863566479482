import { FileDrop, TextField } from "@dash/form";
import { AddressSearchField } from "@dash/google-map";
import { useToast } from "@dash/toast";
import { useTheme } from "@emotion/react";
import Decimal from "decimal.js";
import Papa from "papaparse";
import { useState } from "react";

import { CustomerDoc } from "@megaron/docs-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Email, isValidEmail, PhoneNumber } from "@megaron/serializers";
import { Uuid } from "@megaron/uuid";

import { ImportedCustomersDialog } from "../importedCustomers/ImportedCustomersDialog";
import { RegionField } from "../RegionField";
import { SectionHeader } from "./AddCustomerPage";
import { ExistingCustomerError } from "./ExistingCustomerError";

type ImportedCustomerData = {
  phoneNumber: PhoneNumber;
  firstName: string | null;
  lastName: string | null;
  email: Email | null;
  region: string | null;
};

export type ImportedCustomer = ImportedCustomerData & { error?: string; uuid: string };

type Props = {
  isLoading?: boolean;
  nearCustomers: CustomerDoc[] | undefined;
  getAccount: (phoneNumber: PhoneNumber) => Promise<
    | {
        uuid: Uuid;
        isNew: boolean;
      }
    | undefined
  >;
} & (
  | {
      isFirm: true;
      customerData: {
        acronym: string;
        street: string;
        postalCode: string;
        city: string;
        country: string;
        placeId: string | null;
        region: string | null;
        latitude: Decimal | null;
        longitude: Decimal | null;
      };
      handlers: {
        onAcronymChange: (value: string) => void;
        onStreetChange: (value: string) => void;
        onPostalCodeChange: (value: string) => void;
        onCityChange: (value: string) => void;
        onCountryChange: (value: string) => void;
        onPlaceIdChange: (value: string | null) => void;
        onRegionChange: (value: string | null) => void;
        onLatitudeChange: (value: Decimal | null) => void;
        onLongitudeChange: (value: Decimal | null) => void;
      };
    }
  | {
      isFirm: false;
      customerData: { phoneNumber: PhoneNumber; region: string | null };
      handlers: { onPhoneNumberChange: (value: PhoneNumber) => void; onRegionChange: (value: string | null) => void };
    }
);

export const CustomerDetails: React.FC<Props> = ({
  isFirm,
  customerData,
  handlers,
  isLoading,
  getAccount,
  nearCustomers,
}) => {
  const theme = useTheme();

  const [importedCustomers, setImportedCustomers] = useState<ImportedCustomer[]>([]);
  const [isImportedCustomersDialogOpen, setIsImportedCustomersDialogOpen] = useState(false);

  const [addressInputValue, setAddressInputValue] = useState("");

  const addCustomerMut = useClientManager("crm").saveCustomer().useMutation();

  const toast = useToast();

  const handleInputChange = (value: PhoneNumber) => {
    if (isFirm) {
      return;
    }

    const filteredValue = value.replace(/[^0-9+]/g, "") as PhoneNumber;
    handlers.onPhoneNumberChange(filteredValue);
  };

  const addCustomerFromList = async (customer: ImportedCustomerData) => {
    const account = await getAccount(customer.phoneNumber);

    if (!account) {
      return;
    }

    const isEmailValid = customer.email ? isValidEmail(customer.email) : true;

    if (!isEmailValid) {
      setImportedCustomers((prev) => [...prev, { ...customer, error: "Niepoprawny email", uuid: account.uuid }]);
      return;
    }

    try {
      await addCustomerMut.mutateAsync({
        uuid: account.uuid,
        region: customer.region,
        email: customer.email,
        firstName: customer.firstName ?? undefined,
        lastName: customer.lastName ?? undefined,
        phoneNumber: customer.phoneNumber,
      });

      setImportedCustomers((prev) => [...prev, { ...customer, error: undefined, uuid: account.uuid }]);
    } catch (e) {
      setImportedCustomers((prev) => [...prev, { ...customer, error: e as string, uuid: account.uuid }]);
    }
  };

  function getPropertyCaseInsensitive(obj: any, key: string): any {
    const variations = [key, key.toLowerCase(), key.toUpperCase(), key.replace(/^\w/, (c) => c.toUpperCase())];
    for (const variant of variations) {
      if (Object.prototype.hasOwnProperty.call(obj, variant)) {
        return obj[variant];
      }
    }
    return null;
  }

  const handleFileChange = async (data: string | null, error: string | null) => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error("Error occurred while handling file change:", error);
      toast.error("Error", "Failed to handle file change");
      return;
    }

    if (!data) {
      // eslint-disable-next-line no-console
      console.error("No data received while handling file change");
      return;
    }

    const file = new Blob([data], { type: "text/csv" });
    const reader = new FileReader();

    reader.onload = () => {
      const text = reader.result as string;
      Papa.parse(text, {
        header: true,
        complete: async (results) => {
          const parsedData: ImportedCustomerData[] = results.data.map((item: any) => ({
            phoneNumber:
              parsePhoneNumber(getPropertyCaseInsensitive(item, "telefon")) ??
              parsePhoneNumber(getPropertyCaseInsensitive(item, "numer telefonu")),
            firstName: getPropertyCaseInsensitive(item, "imie") ?? getPropertyCaseInsensitive(item, "imię"),
            lastName: getPropertyCaseInsensitive(item, "nazwisko"),
            email:
              getPropertyCaseInsensitive(item, "email") ||
              getPropertyCaseInsensitive(item, "mail") ||
              getPropertyCaseInsensitive(item, "adres email") ||
              null,
            region: getPropertyCaseInsensitive(item, "region"),
          }));

          await Promise.all(parsedData.map((customer) => addCustomerFromList(customer)));

          setIsImportedCustomersDialogOpen(true);
        },
      });
    };

    reader.readAsText(file);
  };

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>
      <SectionHeader header="Dane klienta" />
      {isFirm ? (
        <>
          <TextField label="Akronim" value={customerData.acronym} onChange={handlers.onAcronymChange} />
          <RegionField region={customerData.region} onRegionChange={handlers.onRegionChange} />
          <hr
            css={{
              border: "none",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              width: "100%",
              height: 0,
              margin: 0,
              "&:last-child": {
                display: "none",
              },
            }}
          />
          <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
            <AddressSearchField
              value={addressInputValue}
              onValueChange={setAddressInputValue}
              onChange={(v) => {
                handlers.onStreetChange(v.street);
                handlers.onPostalCodeChange(v.postalCode);
                handlers.onCityChange(v.city);
                handlers.onCountryChange(v.country);
                handlers.onPlaceIdChange(v.googlePlaceId);
                handlers.onLatitudeChange(v.latitude);
                handlers.onLongitudeChange(v.longitude);
              }}
              predictionTypes={["geocode", "establishment"]}
            />
            <TextField label="Ulica" value={customerData.street} onChange={handlers.onStreetChange} />
            <div css={{ display: "flex", gap: "0.625rem" }}>
              <TextField label="Kod pocztowy" value={customerData.postalCode} onChange={handlers.onPostalCodeChange} />
              <TextField label="Miejscowość" value={customerData.city} onChange={handlers.onCityChange} />
            </div>
            <TextField label="Państwo" value={customerData.country} onChange={handlers.onCountryChange} />
          </div>
          {nearCustomers && nearCustomers.length > 0 && (
            <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
              {nearCustomers.map((customer) => (
                <ExistingCustomerError
                  customer={customer}
                  label="Istnieje klient o pobliskim adresie"
                  color={theme.colors.warning}
                />
              ))}
            </div>
          )}
        </>
      ) : (
        <>
          <TextField
            label="Numer telefonu"
            value={customerData.phoneNumber}
            onChange={(v) => handleInputChange(v as PhoneNumber)}
          />
          <RegionField region={customerData.region} onRegionChange={handlers.onRegionChange} />
          <FileDrop
            label="Lista klientów"
            onFileChange={handleFileChange}
            allowedExtensions={["csv"]}
            error={null}
            isLoading={isLoading}
          />
          {isImportedCustomersDialogOpen && (
            <ImportedCustomersDialog
              onClose={() => {
                setImportedCustomers([]);
                setIsImportedCustomersDialogOpen(false);
              }}
              importedCustomers={importedCustomers}
            />
          )}
        </>
      )}
    </div>
  );
};

const parsePhoneNumber = (phoneNumber: string): PhoneNumber => {
  const parsedPhoneNumber = phoneNumber.replace(/ /g, "").replace(/-/g, "");

  return `+48${parsedPhoneNumber.slice(parsedPhoneNumber.length - 9, parsedPhoneNumber.length)}` as PhoneNumber;
};
