import { PriceListDetail } from "@legacy-megarax/crm-contracts";
import { BreadcrumbMarkers } from "@legacy-megarax/ui-components";
import { Container, Tab, Tabs } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

import { getPriceListDetailsUrl, getPriceListHomeUrl } from "../routes";
import { PriceListCurrentContainer } from "./Current";
import { TradeItemDictionary } from "./PriceListDetailsContainer";
import { PriceListName } from "./PriceListName";
import { ProposalsContainer } from "./Proposals";

export type PriceListTab = "aktualne" | "propozycje";

interface Props {
  priceListDetail: PriceListDetail;
  tradeItems: TradeItemDictionary | null;
  switchTab: (uuid: string) => (tab: PriceListTab) => void;
  permissions: {
    viewProposals: boolean;
  };
  reload: () => void;
  renamePriceList: (name: string) => Promise<void>;
}

const tabToRouteMap = {
  current: "aktualne",
  proposals: "propozycje",
};

const routeToTabMap = {
  aktualne: "current",
  propozycje: "proposals",
};

export const PriceListDetailsView: React.FC<Props> = ({
  priceListDetail,
  switchTab,
  permissions,
  tradeItems,
  renamePriceList,
  reload,
}) => {
  const classes = useStyles();
  const params = useParams<{ uuid: string; tab: PriceListTab }>();
  const navigate = useNavigate();

  const uuid = params.uuid || "";

  const handleChange = (e: React.ChangeEvent<unknown>, value: "current" | "proposals") => {
    reload();
    switchTab(uuid)(tabToRouteMap[value] as PriceListTab);
  };

  useEffect(() => {
    if (!uuid) {
      navigate(getPriceListHomeUrl());
      return;
    }

    if (!["aktualne", "propozycje"].includes(params.tab ?? "")) {
      navigate(`${getPriceListDetailsUrl(uuid)}/aktualne`);
    }
  }, [navigate, params.tab, uuid]);

  return (
    <Container maxWidth="md" disableGutters className={classes.container}>
      <BreadcrumbMarkers
        breadcrumbs={[
          { title: "Cenniki", id: "priceLists", to: "/crm/pos/cenniki" },
          { title: `Cennik ${priceListDetail.name}`, id: "priceList" },
        ]}
      />
      <PriceListName name={priceListDetail.name} renamePriceList={renamePriceList} />
      {permissions.viewProposals && (
        <Tabs
          className={classes.tabs}
          value={routeToTabMap[params.tab || "aktualne"] ?? "current"}
          onChange={handleChange}
          centered
          indicatorColor="primary"
        >
          <Tab label="Aktualny cennik" value="current" />
          <Tab label="Propozycje zmian" value="proposals" />
        </Tabs>
      )}
      {params.tab === "aktualne" && (
        <PriceListCurrentContainer uuid={uuid} priceListDetail={priceListDetail} tradeItems={tradeItems} />
      )}
      {params.tab === "propozycje" && permissions.viewProposals && (
        <ProposalsContainer uuid={uuid} currency={priceListDetail.currency} tradeItems={tradeItems} />
      )}
    </Container>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {},
    title: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    tabs: {
      marginBottom: theme.spacing(4),
    },
  };
});
