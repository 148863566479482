import { useDeviceType } from "@dash/mq";
import { Page, PageHeader } from "@dash/page";
import { QuerySkeleton } from "@dash/skeleton";
import { useQueryClient } from "react-query";

import { useClientManager } from "@megaron/react-clients";

import { DownloadProfitTargetsButton } from "./DownloadProfitTargetsButton";
import { ImportProfitTargetsButton } from "./ImportProfitTargetsButton";
import { ProfitTargetsTable } from "./ProfitTargetsTable";

export const ProfitTargetsPage = () => {
  const { isMobile } = useDeviceType();

  const regionTargetQuery = useClientManager("crm").regionTargetQuery().useQuery({});

  const queryClient = useQueryClient();

  return (
    <Page
      css={{
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <PageHeader
        actions={
          <ActionButtons
            css={{ display: isMobile ? "none" : "flex" }}
            onImportSuccess={() => queryClient.invalidateQueries(regionTargetQuery.key)}
          />
        }
      >
        Plan marży
      </PageHeader>

      <ActionButtons
        css={{ display: isMobile ? "flex" : "none", marginBottom: "1rem" }}
        onImportSuccess={() => queryClient.invalidateQueries(regionTargetQuery.key)}
      />

      <QuerySkeleton query={regionTargetQuery}>
        {(regionTargets) => (
          <ProfitTargetsTable regionTargets={regionTargets} isLoading={regionTargetQuery.isFetching} />
        )}
      </QuerySkeleton>
    </Page>
  );
};

const ActionButtons = ({ onImportSuccess, className }: { onImportSuccess: () => void; className?: string }) => {
  return (
    <div css={{ display: "flex", gap: "0.5rem" }} className={className}>
      <DownloadProfitTargetsButton />

      <ImportProfitTargetsButton onImportSuccess={onImportSuccess} />
    </div>
  );
};
