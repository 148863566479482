import { QuerySkeleton } from "@dash/skeleton";
import { Navigate, useParams } from "react-router-dom-v5-compat";

import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

export const VisitRedirect = () => {
  const { uuid }: { uuid?: Uuid } = useParams();

  const visitQuery = useClientManager("legacyMegarax")
    .visit()
    .useQuery({ uuid: uuid as Uuid }, { enabled: !!uuid });

  if (!uuid) {
    return <Navigate to="/crm/customers" />;
  }

  return (
    <QuerySkeleton query={visitQuery}>
      {(visit) => (
        <Navigate
          to={`/crm/customers/id/${visit.customerUuid}?tab=historia`}
          replace
          state={{ scrollTo: `interaction-${uuid}` }}
        />
      )}
    </QuerySkeleton>
  );
};
