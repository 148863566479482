import { MSerializers } from "@legacy-megarax/serializers";

export type ChangeStartInput = {
  startTime: Date | null;
  startOdometer: number | null;
};

export const changeStartInputSerializer = MSerializers.object<ChangeStartInput>({
  startTime: MSerializers.datetime.nullable(),
  startOdometer: MSerializers.integer.nullable(),
});

export type ChangeEndInput = {
  endTime: Date | null;
  endOdometer: number | null;
};

export const changeEndInputSerializer = MSerializers.object<ChangeEndInput>({
  endTime: MSerializers.datetime.nullable(),
  endOdometer: MSerializers.integer.nullable(),
});

export type ChangeBreakInput = {
  startTime: Date;
  startOdometer: number | null;
  endTime: Date | null;
  endOdometer: number | null;
};

export const changeBreakInputSerializer = MSerializers.object<ChangeBreakInput>({
  endOdometer: MSerializers.integer.nullable(),
  endTime: MSerializers.datetime.nullable(),
  startOdometer: MSerializers.integer.nullable(),
  startTime: MSerializers.datetime,
});

export type ChangeEventInput = {
  title: string;
  description: string;
  startTime: Date;
  endTime: Date;
};

export const changeEventInputSerialzier = MSerializers.object<ChangeEventInput>({
  endTime: MSerializers.datetime,
  startTime: MSerializers.datetime,
  title: MSerializers.string,
  description: MSerializers.string,
});
