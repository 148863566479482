import { toDateString } from "@legacy-megarax/common";
import makeStyles from "@mui/styles/makeStyles";
import fileDownload from "js-file-download";
import React from "react";

import { useClientManager } from "@megaron/react-clients";

import { reportToCsv } from "./reportToCsv";
import { DateRange, WorkTimeReportDialog } from "./WorkTimeReportDialog";

interface Props {
  userId: number | undefined;
  onClose: () => void;
}

export const WorkTimeReportDialogContainer: React.FunctionComponent<Props> = ({ userId, onClose }) => {
  const [dateRange, setDateRange] = React.useState<DateRange>([null, null]);
  // const workDayProvider = useResourceProviderV2(workDayResource);
  const isDateRangeSelected = dateRange[0] !== null && dateRange[1] !== null;

  const query = useClientManager("crm")
    .workTimeReportQuery()
    .useQuery(
      {
        legacyUserId: userId,
        since: toDateString(dateRange[0] ?? new Date()),
        until: toDateString(dateRange[1] ?? new Date()),
      },
      {
        enabled: isDateRangeSelected,
      },
    );

  const report = query.data;

  const download = () => {
    if (!report) return;
    const csv = reportToCsv(report);
    fileDownload(csv, `zestawienie_czasu_pracy.csv`);
  };

  return (
    <WorkTimeReportDialog
      onClose={onClose}
      dateRange={dateRange}
      onDateRangeChange={setDateRange}
      report={isDateRangeSelected ? report : null}
      onDownload={download}
    />
  );
};

const useStyles = makeStyles((theme) => ({}));
