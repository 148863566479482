import { useTheme } from "@emotion/react";
import { useContext, useEffect } from "react";
import { MdOutlinePerson } from "react-icons/md";

import { IamAuthContext } from "@megaron/auth-react";
import { BudgetDoc } from "@megaron/invoices-contracts";

export type BudgetTileProps = {
  budget: BudgetDoc;
  action?: (arg: any) => void;
  hover?: boolean;
  onAuthorizationChange?: (isAuthorized: boolean) => void;
  className?: string;
};

export const BudgetTile: React.FC<BudgetTileProps> = (props) => {
  const theme = useTheme();
  const auth = useContext(IamAuthContext);

  const userGroups = auth.iamUser?.userType === "megarax" ? auth.iamUser.groups.map((group) => group.id) : [];

  const isAuthorized = userGroups.some((group) => props.budget.managerGroupIds.includes(group));

  useEffect(() => {
    if (props.onAuthorizationChange) {
      props.onAuthorizationChange(isAuthorized);
    }
  }, [isAuthorized, props.onAuthorizationChange]);

  return (
    <div
      css={{
        cursor: props.hover ? "pointer" : "normal",
        borderRadius: theme.smallBorderRadius,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "white",
        border: `1px solid ${theme.colors.border}`,
        padding: "8px 12px",
        fontSize: "14px",
        gap: "10px",
        textAlign: "left",
        color: "black",
        width: "100%",
        ":hover": {
          border: props.hover ? `1px solid ${theme.colors.primary}` : `1px solid ${theme.colors.border}`,
        },
      }}
      className={props.className}
      onClick={props.action}
    >
      <div css={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
        <div css={{ display: "flex", gap: "8px", alignItems: "center", flexWrap: "wrap" }}>
          <span css={{ color: theme.colors.primary, fontWeight: 700 }}>{props.budget.name}</span>
          <span css={{ fontWeight: 400 }}>{props.budget.budgetNumber}</span>
        </div>
        {isAuthorized && (
          <span
            css={{ fontWeight: 400, color: theme.colors.primary, display: "flex", alignItems: "center", gap: "8px" }}
          >
            Twój budżet <MdOutlinePerson />
          </span>
        )}
      </div>

      {props.budget.description && <span css={{ width: "100%" }}>{props.budget.description}</span>}
    </div>
  );
};
