import { Tile, TileStripe } from "@dash/tile";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom-v5-compat";
import tinycolor from "tinycolor2";

import { Uuid } from "@megaron/uuid";

type Props = {
  title: string;
  invoiceIssuer: string;
  invoiceNumber: string;
  status: "open" | "approved" | "closed";
  threadUuid: Uuid;
  className?: string;
};

export const SimilarThreadTile: React.FC<Props> = ({
  title,
  invoiceIssuer,
  invoiceNumber,
  status,
  threadUuid,
  className,
}) => {
  const theme = useTheme();

  const color =
    status === "open" ? theme.colors.primaryLight : status === "approved" ? theme.colors.success : theme.colors.danger;

  return (
    <Link
      to={`/faktury/watki/${threadUuid}`}
      css={{
        ":hover": {
          textDecoration: "none",
          h4: {
            textDecoration: "underline",
          },
        },
      }}
      className={className}
    >
      <Tile
        stripe={<TileStripe color={color}></TileStripe>}
        css={{
          borderRadius: theme.smallBorderRadius,
          border: `1px solid ${tinycolor(color).setAlpha(0.1)}`,
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            width: "100%",
          }}
        >
          <span
            css={{
              fontSize: "16px",
              color: color,
            }}
          >
            {title}
          </span>
          <span css={{ fontSize: "14px", color: theme.colors.secondaryText }}>{invoiceIssuer}</span>
          <div css={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <span css={{ fontSize: "12px" }}>{invoiceNumber}</span>
          </div>
        </div>
      </Tile>
    </Link>
  );
};
