import { useDialogRoute } from "@dash/dialog";
import { Button } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { HeaderBar, Page } from "@dash/page";
import { QuerySkeleton } from "@dash/skeleton";
import { MdOutlineDelete } from "react-icons/md";
import { useParams, useSearchParams } from "react-router-dom-v5-compat";

import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { DeleteDraftDialog } from "./DeleteDraftDialog";
import { OrderDraftPageContent } from "./OrderDraftPageContent";

type Props = {
  isNew?: boolean;
};

export const OrderDraftPage: React.FC<Props> = ({ isNew }) => {
  const { orderId } = useParams<{ orderId: string }>();

  const { isMobile } = useDeviceType();

  const [searchParams] = useSearchParams();
  const initialCustomerUuid = searchParams.get("initialCustomerUuid");

  const initialCustomerQuery = useClientManager("crm")
    .getCustomer()
    .useQuery(
      { customerId: initialCustomerUuid ? Uuid(initialCustomerUuid) : ("" as Uuid) },
      { enabled: !!initialCustomerUuid && isNew },
    );

  const orderDraftQuery = useClientManager("crm")
    .myDraftQuery()
    .useQuery({ uuid: orderId as Uuid }, { enabled: !isNew });

  const deleteDraftDialog = useDialogRoute("/delete", ({ onClose }) => {
    if (!orderId) {
      return null;
    }

    return <DeleteDraftDialog onClose={onClose} draftUuid={orderId} />;
  });

  return (
    <>
      {isNew ? (
        <HeaderBar header={`Nowa wersja robocza`} />
      ) : (
        <QuerySkeleton
          query={orderDraftQuery}
          height="58px"
          errorScreen={(error) => {
            if (error === "DraftNotFound") {
              return "Nie znaleziono wersji roboczej.";
            }

            return "Wystąpił błąd.";
          }}
        >
          {(order) => (
            <HeaderBar
              header={`Wersja robocza - ${order.sellToName ?? ""}`}
              actions={
                <Button
                  onClick={deleteDraftDialog.open}
                  css={{ background: "none" }}
                  icon={<MdOutlineDelete size={24} />}
                />
              }
            />
          )}
        </QuerySkeleton>
      )}

      <Page
        css={{
          display: "flex",
          marginBottom: "2rem",
          marginTop: "83px",
          padding: isMobile ? "0 1rem" : "0 2rem",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {isNew ? (
          initialCustomerUuid ? (
            <QuerySkeleton query={initialCustomerQuery} height="160px">
              {(customer) => <OrderDraftPageContent isNew={true} initialCustomer={customer} />}
            </QuerySkeleton>
          ) : (
            <OrderDraftPageContent isNew={!!isNew} />
          )
        ) : (
          <QuerySkeleton
            query={orderDraftQuery}
            height="160px"
            errorScreen={(error) => {
              if (error === "DraftNotFound") {
                return "Nie znaleziono wersji roboczej.";
              }

              return "Wystąpił błąd.";
            }}
          >
            {(order) => <OrderDraftPageContent isNew={!!isNew} orderDraft={order} queryKey={orderDraftQuery.key} />}
          </QuerySkeleton>
        )}
      </Page>

      {deleteDraftDialog.element}
    </>
  );
};
