import { PubsubTopic } from "@megaron/pubsub-contracts";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { budgetChangedTopic } from "./budget";
import { invoiceSerializer } from "./invoice";
import { commentMentionSerializer } from "./threadHistory";

export const threadChangedTopic = PubsubTopic({
  name: "invoices-thread-changed",
  serializer: Serializers.object({
    threadUuid: uuidSerializer,
    version: Serializers.integer,
  }),
});

export type ThreadChangedMessage = SerializerValue<typeof threadChangedTopic.serializer>;

export const threadParticipantInvitedTopic = PubsubTopic({
  name: "invoices-thread-participant-invited",
  serializer: Serializers.object({
    threadUuid: uuidSerializer,
    invitedUser: Serializers.string,
    invitedBy: Serializers.string,
    threadTitle: Serializers.string,
    invoice: invoiceSerializer,
    invitedAt: Serializers.datetime,
  }),
});

export type ThreadParticipantInvitedMessage = SerializerValue<typeof threadParticipantInvitedTopic.serializer>;

export const invoicesTopics = [budgetChangedTopic, threadParticipantInvitedTopic, threadChangedTopic];
