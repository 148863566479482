import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { invoiceSerializer } from "./invoice";
import { commentMentionSerializer, threadHistoryEntrySerializer } from "./threadHistory";

export const threadStatuses = ["open", "closed", "approved"] as const;
export type ThreadStatus = (typeof threadStatuses)[number];

export const threadTags = ["kartaSłużbowa", "środkiWłasne"] as const;
export type ThreadTag = (typeof threadTags)[number];

const validitySignatureSerializer = Serializers.object({
  uuid: uuidSerializer,
  amount: Serializers.decimal,
  signedBy: Serializers.string,
  subject: Serializers.string,
  signedAt: Serializers.datetime,
  isSuggestion: Serializers.boolean,
});

export type ValiditySignature = SerializerValue<typeof validitySignatureSerializer>;

const budgetSignatureSerializer = Serializers.object({
  uuid: uuidSerializer,
  amount: Serializers.decimal,
  budgetUuid: uuidSerializer,
  signedBy: Serializers.string,
  subject: Serializers.string,
  signedAt: Serializers.datetime,
  isSuggestion: Serializers.boolean,
});

export type BudgetSignature = SerializerValue<typeof budgetSignatureSerializer>;

export const participantSerializer = Serializers.object({
  isRead: Serializers.boolean,
  user: Serializers.string,
  invitedAt: Serializers.datetime,
});

export type Participant = SerializerValue<typeof participantSerializer>;

export const attachmentDtoSerializer = Serializers.object({
  fileName: Serializers.string,
  fileType: Serializers.string,
  url: Serializers.string,
  uuid: uuidSerializer,
  attachedBy: Serializers.string,
  attachedAt: Serializers.datetime,
});

export type AttachmentDto = SerializerValue<typeof attachmentDtoSerializer>;

const bcInvoiceSerializer = Serializers.object({
  addedAt: Serializers.datetime,
  id: Serializers.string.optional(),
  number: Serializers.string.optional(),
});

export type BcInvoice = SerializerValue<typeof bcInvoiceSerializer>;

export const threadDtoSerializer = Serializers.object({
  uuid: uuidSerializer,
  version: Serializers.integer,
  invoice: invoiceSerializer,
  status: Serializers.stringOneOf(...threadStatuses),
  budgetSignatures: budgetSignatureSerializer.array(),
  title: Serializers.string,
  referenceNumber: Serializers.string,
  validitySignatures: validitySignatureSerializer.array(),
  history: threadHistoryEntrySerializer.array(),
  attachments: attachmentDtoSerializer.array(),
  participants: participantSerializer.array(),
  isReceived: Serializers.boolean,
  accountingStatus: Serializers.boolean,
  statusLastChangedAt: Serializers.datetime,
  decreeNumber: Serializers.string.nullable(),
  signedBudgetRelative: Serializers.decimal,
  signedValidityRelative: Serializers.decimal,
  approvedAt: Serializers.datetime.nullable(),
  bcInvoice: bcInvoiceSerializer.nullable(),
});

export type ThreadDto = SerializerValue<typeof threadDtoSerializer>;

export const attachmentUploadSerializer = Serializers.object({
  uuid: uuidSerializer,
  fileB64: Serializers.string,
  fileName: Serializers.string,
  fileType: Serializers.string,
});

export type AttachmentUpload = SerializerValue<typeof attachmentUploadSerializer>;

const newThreadInputSerializer = Serializers.object({
  uuid: uuidSerializer,
  title: Serializers.string,
  invoice: invoiceSerializer,
  attachments: attachmentUploadSerializer.array(),
});

export type NewThreadInput = SerializerValue<typeof newThreadInputSerializer>;

export const threadActions = {
  threadQuery: HttpAction({
    path: "/threads/uuid/:uuid",
    method: "get",
    valueSerializer: threadDtoSerializer,
    errorSerializer: Serializers.stringOneOf("ThreadNotFound"),
    paramsSerializer: Serializers.object({ uuid: uuidSerializer }),
    requiresAuth: true,
  }),

  startNewThread: HttpAction({
    path: "/threads",
    method: "post",
    bodySerializer: Serializers.first(
      newThreadInputSerializer,
      Serializers.object({
        draftUuid: uuidSerializer,
      }),
    ),
    errorSerializer: Serializers.stringOneOf("DraftNotFound", "DraftInvoiceIncomplete"),
    requiresAuth: true,
  }),

  inviteParticipant: HttpAction({
    path: "/threads/uuid/:threadUuid/participants",
    method: "post",
    bodySerializer: Serializers.object({ user: Serializers.string }),
    errorSerializer: Serializers.stringOneOf("ThreadNotFound", "AlreadyInvited"),
    paramsSerializer: Serializers.object({ threadUuid: uuidSerializer }),
    requiresAuth: true,
  }),

  updateInvoice: HttpAction({
    path: "/threads/uuid/:threadUuid/invoice",
    method: "put",
    bodySerializer: Serializers.object({ invoice: invoiceSerializer }),
    errorSerializer: Serializers.stringOneOf("ThreadNotFound", "ThreadNotOpen", "ThreadContainsSignatures"),
    paramsSerializer: Serializers.object({ threadUuid: uuidSerializer }),
    requiresAuth: true,
  }),

  renameThread: HttpAction({
    path: "/threads/uuid/:threadUuid/rename",
    method: "post",
    bodySerializer: Serializers.object({ title: Serializers.string }),
    errorSerializer: Serializers.stringOneOf("ThreadNotFound", "ThreadNotOpen"),
    paramsSerializer: Serializers.object({ threadUuid: uuidSerializer }),
    requiresAuth: true,
  }),

  attachFiles: HttpAction({
    path: "/threads/uuid/:threadUuid/attachments",
    method: "post",
    bodySerializer: Serializers.object({ attachments: attachmentUploadSerializer.array() }),
    errorSerializer: Serializers.stringOneOf("ThreadNotFound", "ThreadClosed", "AlreadyAttached"),
    paramsSerializer: Serializers.object({ threadUuid: uuidSerializer }),
    requiresAuth: true,
  }),

  removeAttachment: HttpAction({
    path: "/threads/uuid/:threadUuid/attachments/:attachmentUuid",
    method: "delete",
    errorSerializer: Serializers.stringOneOf("ThreadNotFound", "AttachmentNotFound", "ThreadNotOpen"),
    paramsSerializer: Serializers.object({ threadUuid: uuidSerializer, attachmentUuid: uuidSerializer }),
    requiresAuth: true,
  }),

  approveThread: HttpAction({
    path: "/threads/uuid/:threadUuid/approve",
    method: "post",
    errorSerializer: Serializers.stringOneOf("ThreadNotFound", "ThreadNotOpen", "InvoiceNotReceived"),
    paramsSerializer: Serializers.object({ threadUuid: uuidSerializer }),
    requiresAuth: true,
  }),

  revokeApproval: HttpAction({
    path: "/threads/uuid/:threadUuid/revokeApproval",
    method: "post",
    errorSerializer: Serializers.stringOneOf("ThreadNotFound", "ThreadNotApproved"),
    paramsSerializer: Serializers.object({ threadUuid: uuidSerializer }),
    requiresAuth: true,
  }),

  closeThread: HttpAction({
    path: "/threads/uuid/:threadUuid/close",
    method: "post",
    errorSerializer: Serializers.stringOneOf("ThreadNotFound", "ThreadNotOpen"),
    paramsSerializer: Serializers.object({ threadUuid: uuidSerializer }),
    bodySerializer: Serializers.object({ reason: Serializers.string }),
    requiresAuth: true,
  }),

  reopenThread: HttpAction({
    path: "/threads/uuid/:threadUuid/reopen",
    method: "post",
    errorSerializer: Serializers.stringOneOf("ThreadNotFound", "ThreadNotClosed"),
    paramsSerializer: Serializers.object({ threadUuid: uuidSerializer }),
    requiresAuth: true,
  }),

  setReceptionStatus: HttpAction({
    path: "/threads/uuid/:threadUuid/receptionStatus",
    method: "put",
    bodySerializer: Serializers.object({ status: Serializers.boolean }),
    errorSerializer: Serializers.stringOneOf("ThreadNotFound", "ThreadNotOpen"),
    paramsSerializer: Serializers.object({ threadUuid: uuidSerializer }),
    requiresAuth: true,
  }),

  signValidity: HttpAction({
    path: "/threads/uuid/:threadUuid/signValidity",
    method: "put",
    bodySerializer: Serializers.object({
      uuid: uuidSerializer,
      subject: Serializers.string,
      amount: Serializers.decimal,
      isSuggestion: Serializers.boolean,
    }),
    errorSerializer: Serializers.stringOneOf("ThreadNotFound", "ThreadNotOpen"),
    paramsSerializer: Serializers.object({ threadUuid: uuidSerializer }),
    requiresAuth: true,
  }),

  signBudget: HttpAction({
    path: "/threads/uuid/:threadUuid/signBudget",
    method: "put",
    bodySerializer: Serializers.object({
      uuid: uuidSerializer,
      subject: Serializers.string,
      amount: Serializers.decimal,
      budgetUuid: uuidSerializer,
      isSuggestion: Serializers.boolean,
    }),
    errorSerializer: Serializers.stringOneOf(
      "ThreadNotFound",
      "ThreadNotOpen",
      "UnauthorizedToSignThisBudget",
      "BudgetNotFound",
    ),
    paramsSerializer: Serializers.object({ threadUuid: uuidSerializer }),
    requiresAuth: true,
  }),

  removeValiditySignature: HttpAction({
    path: "/threads/uuid/:threadUuid/validitySignatures/:signatureUuid",
    method: "delete",
    errorSerializer: Serializers.stringOneOf("ThreadNotFound", "SignatureNotFound", "ThreadNotOpen"),
    paramsSerializer: Serializers.object({ threadUuid: uuidSerializer, signatureUuid: uuidSerializer }),
    requiresAuth: true,
  }),

  removeBudgetSignature: HttpAction({
    path: "/threads/uuid/:threadUuid/budgetSignatures/:signatureUuid",
    method: "delete",
    errorSerializer: Serializers.stringOneOf(
      "ThreadNotFound",
      "SignatureNotFound",
      "ThreadNotOpen",
      "UnauthorizedToSignThisBudget",
    ),
    paramsSerializer: Serializers.object({ threadUuid: uuidSerializer, signatureUuid: uuidSerializer }),
    requiresAuth: true,
  }),

  postComment: HttpAction({
    path: "/threads/uuid/:threadUuid/comments",
    method: "post",
    bodySerializer: Serializers.object({
      commentUuid: uuidSerializer,
      message: Serializers.string,
      mentions: commentMentionSerializer.array(),
      hashtags: Serializers.string.array(),
    }),
    errorSerializer: Serializers.stringOneOf("ThreadNotFound", "AlreadyPosted"),
    paramsSerializer: Serializers.object({ threadUuid: uuidSerializer }),
    requiresAuth: true,
  }),

  setDecreeNumber: HttpAction({
    path: "/threads/uuid/:threadUuid/setDecreeNumber",
    method: "post",
    bodySerializer: Serializers.object({
      decreeNumber: Serializers.string.nullable(),
    }),
    errorSerializer: Serializers.stringOneOf("ThreadNotFound", "ThreadNotApproved"),
    paramsSerializer: Serializers.object({ threadUuid: uuidSerializer }),
    requiresAuth: true,
  }),

  setIsRead: HttpAction({
    path: "/threads/uuid/:threadUuid/setIsRead",
    method: "post",
    bodySerializer: Serializers.object({
      isRead: Serializers.boolean,
    }),
    errorSerializer: Serializers.stringOneOf("ThreadNotFound", "NotAParticipant"),
    paramsSerializer: Serializers.object({ threadUuid: uuidSerializer }),
    requiresAuth: true,
  }),

  addInvoiceToBc: HttpAction({
    path: "/threads/uuid/:threadUuid/addInvoiceToBc",
    method: "post",
    errorSerializer: Serializers.stringOneOf("ThreadNotFound", "ThreadNotApproved", "BcVendorNotFound"),
    paramsSerializer: Serializers.object({ threadUuid: uuidSerializer }),
    valueSerializer: Serializers.object({ bcInvoiceNumber: Serializers.string }),
    bodySerializer: Serializers.object({
      vendorNumber: Serializers.string,
      vatContractorContactNumber: Serializers.string.nullable(),
    }),
    requiresAuth: true,
  }),
};
