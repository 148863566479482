import { Uuid, UuidResourceParam, uuidSerializer } from "@legacy-megarax/common";
import { BaseResource, ResourceActionV2 } from "@legacy-megarax/rest-resource";
import { MSerializers } from "@legacy-megarax/serializers";

import list from "./list";
import { regionSimpleSerializer } from "./regionSimple";

export interface NameRegionInput {
  name: string;
}

export interface AppendMacroregionInput {
  macroregionUuid: Uuid;
}

const nameRegionInputSerialized = MSerializers.object<NameRegionInput>({
  name: MSerializers.string,
});

const appendMacroregionInputSerialized = MSerializers.object<AppendMacroregionInput>({
  macroregionUuid: uuidSerializer,
});

export const regionResource = BaseResource(["crm", "regions"], {
  byUuid: UuidResourceParam("uuid", "uuid", {
    retrieve: ResourceActionV2({
      name: "retrieve",
      method: "get",
      path: "",
      responseValueSerializer: regionSimpleSerializer.nullable(),
    }),
    nameRegion: ResourceActionV2({
      name: "nameRegion",
      method: "post",
      path: "nameRegion",
      requestBodySerializer: nameRegionInputSerialized,
    }),
    appendMacroregion: ResourceActionV2({
      name: "appendMacroregion",
      method: "put",
      path: "appendMacroregion",
      requestBodySerializer: appendMacroregionInputSerialized,
      responseErrorSerializer: MSerializers.identity<"RegionNotFound">(),
    }),
  }),
  list: list,
});
