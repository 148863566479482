import {
  BaseResource,
  IdResourceParam,
  paginatedListSerializer,
  ResourceAction,
  ResourceActionV2,
} from "@legacy-megarax/rest-resource";
import { Serializers } from "@legacy-megarax/serializers";

export interface UserListFilters {
  limit?: number;
  offset?: number;
  searchText?: string;
  /**
   * @deprecated
   */
  search?: string;
  includeDeleted?: boolean;
}
export interface UserSimple {
  id: number;
  email: string;
  isDeleted: boolean;
}
export interface UserDto {
  id: number;
  email: string;

  groups: {
    uuid: string;
    name: string;
  }[];

  isDeleted: boolean;
}
export interface UserProfileDto {
  user: {
    id: number;
    email: string;
  };
  firstName?: string;
  lastName?: string;
  pictureUrl?: string | null;
}
export interface UserProfileListFilters {
  limit?: number;
  offset?: number;
  searchText?: string;
}

export interface GroupReference {
  uuid: string;
}

export interface UpdateUserInput {
  email: string;
  groups: GroupReference[];
}

export interface NewUserInput {
  email: string;
  groups: GroupReference[];
}

const list = ResourceAction({
  name: "list",
  method: "get",
  path: "",
  querySerializer: Serializers.object<UserListFilters>({
    searchText: Serializers.string.optional(),
    search: Serializers.string.optional(),
    limit: Serializers.integer.optional(),
    offset: Serializers.integer.optional(),
    includeDeleted: Serializers.boolean.optional(),
  }),
  responseSerializer: paginatedListSerializer(
    Serializers.object<UserSimple>({
      id: Serializers.integer,
      email: Serializers.string,
      isDeleted: Serializers.boolean,
    }),
  ),
});

export const userResource = BaseResource(["iam", "users"], {
  current: ResourceAction({
    name: "currentUser",
    method: "get",
    path: "current",
    responseSerializer: Serializers.object({
      id: Serializers.integer,
      email: Serializers.string,
    }),
  }),
  list,
  byId: IdResourceParam("userId", {
    retrieve: ResourceAction({
      name: "retrieve",
      method: "get",
      path: "",
      responseSerializer: Serializers.object<UserDto>({
        email: Serializers.string,
        id: Serializers.integer,
        groups: Serializers.array(
          Serializers.object({
            name: Serializers.string,
            uuid: Serializers.uuid(),
          }),
        ),
        isDeleted: Serializers.boolean,
      }),
    }),
  }),
});

export const userProfileDtoSerializer = Serializers.object<UserProfileDto>({
  user: Serializers.object({
    id: Serializers.integer,
    email: Serializers.string,
  }),
  firstName: Serializers.string.optional(),
  lastName: Serializers.string.optional(),
  pictureUrl: Serializers.optional(Serializers.string.nullable()),
});

const userProfileListAction = ResourceAction({
  name: "all",
  method: "get",
  path: "",
  responseSerializer: paginatedListSerializer(userProfileDtoSerializer),
  querySerializer: Serializers.object<UserProfileListFilters>({
    limit: Serializers.integer.optional(),
    offset: Serializers.integer.optional(),
    searchText: Serializers.string.optional(),
  }),
});

export const profileResource = BaseResource(["iam", "profiles"], {
  byId: IdResourceParam("userId", {
    retrieve: ResourceAction({
      name: "retrieve",
      method: "get",
      path: "",
      responseSerializer: userProfileDtoSerializer,
    }),
  }),
  list: userProfileListAction,
});
