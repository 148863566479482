import { useVisibleTableColumns } from "@dash/table";

import { tableHeadingsList } from "./tableHeadingsListConstants";

const localStorageVisibleTableColumnKey = "invoicesVisibleTableColumns";

const defaultVisibleColumns = tableHeadingsList.filter((heading) => heading.isDefault).map((heading) => heading.label);

const defaultVisibleTableColumns = {
  mine: defaultVisibleColumns,
  all: defaultVisibleColumns,
};

export type VisibleInvoicesTableColumnsObject = {
  mine: { added: string[]; removed: string[] };
  all: { added: string[]; removed: string[] };
};

type VisibleInvoicesTableColumnsKey = keyof VisibleInvoicesTableColumnsObject;

export type VisibleInvoicesTableColumnsConfigObject = {
  mine: string[];
  all: string[];
};

export const useTableVisibleColumns = () => {
  return useVisibleTableColumns<VisibleInvoicesTableColumnsKey>({
    configKeys: ["mine", "all"],
    defaultColumns: defaultVisibleTableColumns,
    localStorageKey: localStorageVisibleTableColumnKey,
  });
};
