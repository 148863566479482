import { Button, DecimalField, IntegerFieldWithButtons } from "@dash/form";
import { useTheme } from "@emotion/react";
import { PricedItemDto } from "@legacy-megarax/crm-contracts";
import { DenominationDefinitions, formatCurrency, numberToDenominatedQuantity } from "@legacy-megarax/react-utils";
import Decimal from "decimal.js";
import { useCallback, useMemo, useState } from "react";

import { AddDraftLineInput, Currency, Gtin, Item, OrderDraftLineDto } from "@megaron/crm-contracts";
import { ItemDoc } from "@megaron/docs-contracts";
import { newUuid } from "@megaron/uuid";

type ItemPackSizes = "pallet" | "layer" | "box";

type QuantityOptions = {
  newPalletQuantity?: number;
  newLayerQuantity?: number;
  newBoxQuantity?: number;
  newUnitQuantity?: number;
};

type Props = {
  item: ItemDoc | Item | undefined;
  line?: OrderDraftLineDto;
  onSave: (line: AddDraftLineInput) => void;
  onDelete?: () => void;
  currency: Currency;
  pricedItem?: PricedItemDto;
  baseDiscount?: Decimal;
};

export const ItemDialogContent: React.FC<Props> = ({
  item,
  onSave,
  line,
  onDelete,
  currency,
  pricedItem,
  baseDiscount,
}) => {
  const theme = useTheme();

  const getRecalculatedQuantity = useCallback(
    (quantity: number) => {
      const packSizes = {
        box: item?.box,
        layer: item?.layer,
        pallet: item?.pallet,
      };

      const defs: DenominationDefinitions<ItemPackSizes & "item"> = {
        item: 1,
        ...Object.entries(packSizes).reduce((acc, [key, value]) => {
          if (value) {
            acc[key as ItemPackSizes] = value;
          }

          return acc;
        }, {} as Record<ItemPackSizes, number>),
      };

      const recountedQuantities = numberToDenominatedQuantity(defs)(quantity);

      return {
        pallet: "pallet" in recountedQuantities ? (recountedQuantities.pallet as number) : 0,
        layer: "layer" in recountedQuantities ? (recountedQuantities.layer as number) : 0,
        box: "box" in recountedQuantities ? (recountedQuantities.box as number) : 0,
        item: "item" in recountedQuantities ? (recountedQuantities.item as number) : 0,
      };
    },
    [item?.box, item?.layer, item?.pallet],
  );

  const {
    box: initialBoxQuantity,
    layer: initialLayerQuantity,
    pallet: initialPalletQuantity,
    item: initialUnitQuantity,
  } = getRecalculatedQuantity(line?.quantity || 0);

  const [palletQuantity, setPalletQuantity] = useState(initialPalletQuantity);
  const [layerQuantity, setLayerQuantity] = useState(initialLayerQuantity);
  const [boxQuantity, setBoxQuantity] = useState(initialBoxQuantity);
  const [unitQuantity, setUnitQuantity] = useState(initialUnitQuantity);

  const getQuantity = useCallback(
    ({
      newPalletQuantity = palletQuantity,
      newLayerQuantity = layerQuantity,
      newBoxQuantity = boxQuantity,
      newUnitQuantity = unitQuantity,
    }: QuantityOptions = {}) => {
      return (
        newPalletQuantity * (item?.pallet || 0) +
        newLayerQuantity * (item?.layer || 0) +
        newBoxQuantity * (item?.box || 0) +
        newUnitQuantity
      );
    },
    [boxQuantity, item?.pallet, item?.layer, item?.box, layerQuantity, palletQuantity, unitQuantity],
  );

  const quantity = useMemo(() => getQuantity(), [getQuantity]);

  const isRemovePalletDisabled = quantity < (item?.pallet || 0);
  const isRemoveLayerDisabled = quantity < (item?.layer || 0);
  const isRemoveBoxDisabled = quantity < (item?.box || 0);
  const isRemoveUnitDisabled = quantity === 0;

  const [lineDiscount, setLineDiscount] = useState(
    line?.lineDiscountRate.gt(0) ? Number(line.lineDiscountRate.mul(100).toFixed(2)).toString() : "",
  );
  const [lineDiscountError, setLineDiscountError] = useState<string | null>(null);

  const recountQuantity = (quantityChanges: QuantityOptions = {}) => {
    const newQuantity = getQuantity(quantityChanges);

    const {
      pallet: newPalletQuantity,
      layer: newLayerQuantity,
      box: newBoxQuantity,
      item: newUnitQuantity,
    } = getRecalculatedQuantity(newQuantity);

    setPalletQuantity(newPalletQuantity);
    setLayerQuantity(newLayerQuantity);
    setBoxQuantity(newBoxQuantity);
    setUnitQuantity(newUnitQuantity);
  };

  const handleQuantityInputChange = (isFocused: boolean) => {
    if (!isFocused) {
      recountQuantity();
    }
  };

  const [isOneZloty, setIsOneZloty] = useState(line?.promoPrice?.toNumber() === 1);
  const [isRW, setIsRW] = useState(line?.promoPrice?.toNumber() === 0);

  const areQuantityFieldsDisabled = !item;

  const getPromoPrice = () => (isOneZloty ? new Decimal(1) : isRW ? new Decimal(0) : null);

  const handleSave = () => {
    if (!item) {
      return;
    }

    if (baseDiscount?.plus(new Decimal(lineDiscount || 0).div(100)).gt(1)) {
      setLineDiscountError("Suma rabatów nie może przekroczyć 100%");
      return;
    }

    onSave({
      lineUuid: line?.uuid || newUuid(),
      item: { gtin: item.gtin as Gtin },
      promoPrice: getPromoPrice(),
      quantity: getQuantity(),
      lineDiscountRate: new Decimal(lineDiscount || 0).div(100),
    });
  };

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
        {item?.pallet && (
          <IntegerFieldWithButtons
            value={palletQuantity}
            onInputChange={(value) => {
              setPalletQuantity(value);
            }}
            onInputFocusChange={handleQuantityInputChange}
            onIncrement={() => {
              recountQuantity({ newPalletQuantity: palletQuantity + 1 });
            }}
            onDecrement={() => {
              recountQuantity({ newPalletQuantity: palletQuantity - 1 });
            }}
            label={`Palety [${item.pallet} szt. na palecie]`}
            isDisabled={areQuantityFieldsDisabled}
            isRemoveButtonDisabled={isRemovePalletDisabled}
          />
        )}
        {item?.layer && (
          <IntegerFieldWithButtons
            value={layerQuantity}
            onInputChange={(value) => {
              setLayerQuantity(value);
            }}
            onInputFocusChange={handleQuantityInputChange}
            onIncrement={() => {
              recountQuantity({ newLayerQuantity: layerQuantity + 1 });
            }}
            onDecrement={() => {
              recountQuantity({ newLayerQuantity: layerQuantity - 1 });
            }}
            label={`Warstwy [${item.layer} szt. na warstwie]`}
            isDisabled={areQuantityFieldsDisabled}
            isRemoveButtonDisabled={isRemoveLayerDisabled}
          />
        )}
        {item?.box && (
          <IntegerFieldWithButtons
            value={boxQuantity}
            onInputChange={(value) => {
              setBoxQuantity(value);
            }}
            onInputFocusChange={handleQuantityInputChange}
            onIncrement={() => {
              recountQuantity({ newBoxQuantity: boxQuantity + 1 });
            }}
            onDecrement={() => {
              recountQuantity({ newBoxQuantity: boxQuantity - 1 });
            }}
            label={`Kartony [${item.box} szt. w kartonie]`}
            isDisabled={areQuantityFieldsDisabled}
            isRemoveButtonDisabled={isRemoveBoxDisabled}
          />
        )}
        <IntegerFieldWithButtons
          value={unitQuantity}
          onInputChange={(value) => {
            setUnitQuantity(value);
          }}
          onInputFocusChange={handleQuantityInputChange}
          onIncrement={() => {
            recountQuantity({ newUnitQuantity: unitQuantity + 1 });
          }}
          onDecrement={() => {
            recountQuantity({ newUnitQuantity: unitQuantity - 1 });
          }}
          label="Sztuki"
          isDisabled={areQuantityFieldsDisabled}
          isRemoveButtonDisabled={isRemoveUnitDisabled}
        />
      </div>
      <span css={{ fontWeight: 700, color: theme.colors.primary, fontSize: "0.875rem" }}>
        Sztuk razem: {getQuantity().toFixed()}
      </span>

      <div css={{ display: "flex", gap: "0.5rem" }}>
        <Button
          variant={isOneZloty ? "primary" : "outline"}
          size="small"
          onClick={() => {
            setIsOneZloty(!isOneZloty);
            setIsRW(false);
            setLineDiscount("");
          }}
        >
          za złotówkę
        </Button>
        <Button
          variant={isRW ? "primary" : "outline"}
          size="small"
          onClick={() => {
            setIsRW(!isRW);
            setIsOneZloty(false);
            setLineDiscount("");
          }}
        >
          RW
        </Button>
      </div>

      <DecimalField
        value={lineDiscount}
        onChange={(value) => {
          if (
            new Decimal(value || 0)
              .div(100)
              .plus(baseDiscount || new Decimal(0))
              .gt(1)
          ) {
            setLineDiscountError("Suma rabatów nie może przekroczyć 100%");
            return;
          }

          setLineDiscountError(null);

          setLineDiscount(value);
        }}
        label="Dodatkowy rabat [%]"
        isDisabled={isRW || isOneZloty}
        error={lineDiscountError}
      />

      <span css={{ fontWeight: 700, color: theme.colors.primary, fontSize: "1rem" }}>
        {getTotalPriceText({
          netPricePerUnit: pricedItem?.basePrice ?? new Decimal(0),
          promoPrice: getPromoPrice(),
          quantity: getQuantity(),
          currency,
          lineDiscount: new Decimal(lineDiscount || 0).div(100),
          baseDiscount,
        })}
      </span>
      <div css={{ display: "flex", justifyContent: "space-between", gap: "0.625rem" }}>
        {onDelete && (
          <Button onClick={onDelete} color="danger">
            Usuń
          </Button>
        )}
        <Button onClick={handleSave} css={{ marginLeft: "auto" }}>
          Zapisz
        </Button>
      </div>
    </div>
  );
};

const getTotalPriceText = ({
  netPricePerUnit,
  promoPrice,
  quantity,
  currency,
  lineDiscount,
  baseDiscount,
}: {
  netPricePerUnit: Decimal;
  promoPrice: Decimal | null;
  quantity: number;
  currency: Currency;
  lineDiscount: Decimal;
  baseDiscount?: Decimal;
}) => {
  const multiplier = new Decimal(1).sub(lineDiscount.plus(baseDiscount || new Decimal(0)));

  const unitPrice = promoPrice ?? netPricePerUnit.mul(multiplier);
  const total = new Decimal(unitPrice.toFixed(2)).mul(quantity);

  return `Wartość netto: ${formatCurrency(total, currency)} (${formatCurrency(unitPrice, currency)}/szt.)`;
};
