import { BasicAddress } from "@legacy-megarax/common";
import _ from "lodash";

export interface Address extends BasicAddress {
  name: string;
}

export type Google = typeof google;

export const formatAddress = (address: Address) => {
  const secondPartArr = [address.postalCode, address.locality];
  const isSecondPartPresent = secondPartArr.filter((x) => x).length > 0;
  const secondPart = isSecondPartPresent ? secondPartArr.join(" ") : "";
  const thirdPartArr = [address.country];
  const isThirdPartPresent = thirdPartArr.filter((x) => x).length > 0;
  const thirdPart = isThirdPartPresent ? thirdPartArr.join(" ") : "";
  return [address.street, address.name, secondPart, thirdPart].filter((x) => x !== "").join(", ");
};
