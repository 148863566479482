import { priceListResource, TradeItemDto, tradeItemResource } from "@legacy-megarax/crm-contracts";
import { useResourceProviderV2 } from "@legacy-megarax/react-client";
import { useAsyncLoad } from "@legacy-megarax/react-utils";
import { commonErrorsMap, LoadingBar, useSnackbarErrorHandler } from "@legacy-megarax/ui-components";
import { LinearProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

import { getPriceListHomeUrl } from "../routes";
import { PriceListDetailsView } from "./PriceListDetailsView";

export interface TradeItemDictionary {
  [key: string]: TradeItemDto;
}

const errorsWithViews = ["PermissionDenied", "PriceListNotFound"];

export const PriceListDetailsContainer: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const uuid = params["uuid"] || "";

  const switchTab = (uuid: string) => (tab: "aktualne" | "propozycje") =>
    navigate(`${getPriceListHomeUrl()}/${uuid}/${tab}`, { replace: true });

  const priceListProvider = useResourceProviderV2(priceListResource);
  const tradeItemProvider = useResourceProviderV2(tradeItemResource);
  const priceListDetailsProvider = priceListProvider.byUuid(uuid);

  const handleError = useSnackbarErrorHandler({ ...commonErrorsMap });

  const permissions = {
    viewProposals: true,
    viewTradeItems: true,
  };

  const {
    value: priceListDetailQuery,
    loading: priceListDetailLoading,
    reload,
  } = useAsyncLoad(
    () =>
      priceListDetailsProvider.retrieve().mapError((error) => {
        if (errorsWithViews.includes(error)) return error;
        return handleError(error);
      }),
    [],
  );

  const { value: tradeItemsQuery, loading: tradeItemsLoading } = useAsyncLoad(() => {
    if (!permissions.viewTradeItems) return Promise.resolve(null);
    return tradeItemProvider
      .list({ limit: 100000 })
      .mapError(handleError)
      .map((result) =>
        result.items.reduce<TradeItemDictionary>((dictionary, item) => {
          dictionary[item.gtin] = item;
          return dictionary;
        }, {}),
      );
  }, []);

  const renamePriceList = (name: string) =>
    priceListDetailsProvider.rename(undefined, { name }).mapError(handleError).then(reload);

  if (priceListDetailQuery === undefined || tradeItemsQuery === undefined) return <LinearProgress />;

  if (tradeItemsQuery?.isFailure) return null;
  if (priceListDetailQuery.isFailure) {
    if (priceListDetailQuery.error === "PriceListNotFound") return <span>Nie znaleziono</span>;
    if (priceListDetailQuery.error === "PermissionDenied") return <span>Brak uprawnień</span>;
    return null;
  }

  return (
    <>
      <LoadingBar loading={tradeItemsLoading || priceListDetailLoading} />
      <PriceListDetailsView
        reload={reload}
        priceListDetail={priceListDetailQuery.value}
        permissions={permissions}
        renamePriceList={renamePriceList}
        tradeItems={tradeItemsQuery ? tradeItemsQuery.value : null}
        switchTab={switchTab}
      />
    </>
  );
};
