import { Dialog } from "@dash/dialog";
import { Button } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { useTheme } from "@emotion/react";
import { MdOutlineWarning } from "react-icons/md";

type Props = { onContinue: () => void; onClose: () => void };

export const UnsavedChangesAlert: React.FC<Props> = ({ onContinue, onClose }) => {
  const theme = useTheme();

  const { isMobile } = useDeviceType();

  return (
    <Dialog
      hideHeader
      placement={isMobile ? "top" : "center"}
      css={{ minHeight: 0, maxWidth: isMobile ? "calc(100% - 2rem)" : "400px" }}
      onClose={onClose}
    >
      <div
        css={{
          color: theme.colors.primary,
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
        }}
      >
        <div css={{ display: "flex", alignItems: "center", gap: "0.75rem", fontSize: "0.875rem" }}>
          <MdOutlineWarning size={18} css={{ flexShrink: 0 }} />
          Czy na pewno chcesz opuścić stronę? Niezapisane zmiany zostaną utracone.
        </div>
        <div css={{ display: "flex", justifyContent: "space-between" }}>
          <Button variant="outline" size="small" onClick={onClose}>
            Wróć
          </Button>
          <Button color="danger" size="small" onClick={onContinue}>
            Kontynuuj
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
