import { Checkbox } from "@dash/form";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import { MdCalendarToday } from "react-icons/md";

import { parseTimeUnitToString } from "./TimeRangeButton";
import { TimeRangeDialog } from "./TimeRangeDialog";
import { TimeRangeSelectProps } from "./TimeRangeSelect";

type Props = Omit<TimeRangeSelectProps, "onClose">;

export const TimeRangeField: React.FC<Props> = ({
  activeOptionName,
  endDate,
  onDateChange,
  startDate,
  timeUnit,
  unitCount,
}) => {
  const theme = useTheme();

  const [isTimeRangeSelectOpen, setIsTimeRangeSelectOpen] = useState(false);

  const [isDefaultTimeRange, setIsDefaultTimeRange] = useState(activeOptionName === "default");

  const getLabel = () => {
    if (activeOptionName === "default") {
      return "Domyślny";
    }

    if (activeOptionName) {
      return activeOptionName;
    }

    if (unitCount && timeUnit) {
      return `${unitCount} ${parseTimeUnitToString(timeUnit)}`;
    }

    if (startDate && endDate) {
      return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
    }

    return "Brak";
  };

  return (
    <div
      css={{
        position: "relative",
        display: "flex",
      }}
    >
      <div css={{ display: "flex", flexDirection: "column", width: "100%", gap: "0.5rem" }}>
        <label
          css={{
            color: theme.colors.primary,
            fontSize: "0.875rem",
            fontFamily: theme.displayFontFamily,
          }}
        >
          Zakres czasu
        </label>
        <Checkbox
          label="Domyślny"
          value={isDefaultTimeRange}
          onChange={() => {
            setIsDefaultTimeRange(!isDefaultTimeRange);
            onDateChange(null, null, null, null, "default");
          }}
        />
        <button
          css={{
            border: "none",
            display: "flex",
            alignItems: "center",
            gap: "0.75rem",
            width: "100%",
            padding: "0.5rem 0.75rem",
            fontSize: "1rem",
            borderRadius: "6px",
            backgroundColor: theme.colors.primaryLight,
            opacity: isDefaultTimeRange ? 0.5 : 1,
          }}
          disabled={isDefaultTimeRange}
          onClick={() => setIsTimeRangeSelectOpen(true)}
        >
          <MdCalendarToday size={16} />
          {getLabel()}
        </button>
      </div>
      {isTimeRangeSelectOpen && (
        <TimeRangeDialog
          activeOptionName={activeOptionName}
          endDate={endDate}
          onClose={() => setIsTimeRangeSelectOpen(false)}
          onDateChange={onDateChange}
          startDate={startDate}
          timeUnit={timeUnit}
          unitCount={unitCount}
        />
      )}
    </div>
  );
};
