import { DateString, dateStringSerializer, Uuid, uuidSerializer } from "@legacy-megarax/common";
import { Serializers } from "@legacy-megarax/serializers";
import Decimal from "decimal.js";

import { currencies, Currency } from "../common";
import { OrderStatus, orderStatuses } from "./orderStatus";

export type OrderSimpleDto = {
  uuid: Uuid;
  referenceNumber: string;
  sellToCustomerUuid: Uuid | null;
  billToCustomerUuid: Uuid | null;
  currency: Currency;
  orderDate: DateString;
  netTotal: Decimal;
  updatedAt: Date;
  placedAt: Date;
  status: OrderStatus;
};

export const orderSimpleDtoSerializer = Serializers.object<OrderSimpleDto>({
  uuid: uuidSerializer,
  referenceNumber: Serializers.string,
  sellToCustomerUuid: uuidSerializer.nullable(),
  billToCustomerUuid: uuidSerializer.nullable(),
  currency: Serializers.stringOneOf(...currencies),
  netTotal: Serializers.decimal,
  orderDate: dateStringSerializer,
  updatedAt: Serializers.datetime,
  placedAt: Serializers.datetime,
  status: Serializers.stringOneOf(...orderStatuses),
});
