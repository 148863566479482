import { useIndependentAsyncLoad } from "@legacy-megarax/react-client";
import { Search as SearchIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
import React, { useEffect, useState } from "react";

type Option<T extends string | number> = {
  value: T;
  label: string;
};

export type GetOptions<T extends string | number> = (searchString: string) => Promise<Option<T>[]>;

type Props<T extends string | number> = {
  label: string;
  value: T[];
  onChange: (newValue: T[]) => void;
  getOptions: GetOptions<T>;
  className?: string;
  style?: {
    opaque?: boolean;
  };
};

export const SearchMultiSelectChip = <T extends string | number>({
  getOptions,
  label,
  value,
  onChange,
  className,
  style,
}: Props<T>): React.ReactElement<any> => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState<string>("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [options, setOptions] = useState<Option<T>[]>();
  const onSearchChange = _.debounce((e) => setSearchText(e.target.value), 200);
  const [selectedBuffer, setSelectedBuffer] = useState<T[]>([]);
  const [allLabels, setAllLabels] = useState<{ [key in T]?: string }>({});
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { loading } = useIndependentAsyncLoad(
    () => getOptions(searchText),
    (options) => {
      const newLabels = _(options)
        .keyBy((o) => o.value)
        .mapValues((o) => o.label)
        .value();
      setAllLabels({ ...newLabels, ...allLabels });
      setOptions(options);
    },
    [searchText],
  );

  const open = () => {
    setSearchText("");
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
    onChange(selectedBuffer);
  };

  const onSelect = (selected: T, check: boolean) => {
    if (check) {
      setSelectedBuffer([...selectedBuffer, selected]);
    } else setSelectedBuffer(selectedBuffer.filter((item) => item !== selected));
  };

  const isSelected = value.length > 0;
  const chipLabel = isSelected
    ? value.length === 1
      ? `${label}: ${allLabels[value[0]] ?? "?"}`
      : `${label} (${value.length})`
    : label;

  const onDelete = () => {
    onChange([]);
  };

  useEffect(() => {
    setSelectedBuffer(value);
  }, [JSON.stringify(value)]);

  return (
    <>
      <Chip
        onClick={open}
        label={chipLabel}
        clickable
        color={isSelected ? "primary" : "default"}
        onDelete={isSelected ? onDelete : undefined}
        className={[className, style?.opaque && !isSelected ? classes.opaque : ""].join(" ")}
      ></Chip>
      <Dialog fullScreen={isMobile} open={isOpen} onClose={close} className={classes.dialog}>
        <Paper className={classes.searchbar} square={true} elevation={1}>
          <InputBase
            autoFocus
            fullWidth
            defaultValue={searchText}
            onChange={onSearchChange}
            placeholder="Szukaj..."
            startAdornment={<SearchIcon style={{ marginRight: "1rem" }} />}
          />
        </Paper>
        <List className={classes.list}>
          {options?.map((option) => {
            const isChecked = selectedBuffer?.some((v) => v === option.value);

            return (
              <ListItem
                key={option.value}
                dense
                button
                className={classes.listItem}
                onClick={() => onSelect(option.value, !isChecked)}
              >
                <ListItemIcon>
                  <Checkbox edge="start" checked={isChecked} tabIndex={-1} disableRipple />
                </ListItemIcon>
                <ListItemText>{option.label}</ListItemText>
              </ListItem>
            );
          })}
        </List>
        <Button color="primary" variant="contained" onClick={close} className={classes.closeButton}>
          Gotowe
        </Button>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  listItem: {},
  opaque: {
    backgroundColor: "#ededed",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  list: {
    overflow: "auto",
    minHeight: "400px",
    flex: 1,
  },
  dialog: {
    display: "flex",
    flexDirection: "column",
  },
  searchbar: {
    padding: "0.5rem 1rem",
    boxSizing: "border-box",
    margin: theme.spacing(1),
    borderRadius: "4px",
    zIndex: 1,
  },
  closeButton: {
    margin: theme.spacing(1),
  },
}));
