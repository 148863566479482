import { Button } from "@dash/form";
import { Spinner } from "@dash/spinner";
import { stringify } from "csv/browser/esm/sync";
import fileDownload from "js-file-download";
import { useState } from "react";
import { MdOutlineSimCardDownload } from "react-icons/md";

import { PostSaleDiscount } from "@megaron/crm-contracts";
import { useServiceClient } from "@megaron/react-clients";

export const DownloadPostSaleDiscountsButton = () => {
  const crm = useServiceClient("crm");

  const [isFetching, setIsFetching] = useState(false);

  const fetchPostSaleDiscounts = async () => {
    const postSaleDiscounts = await crm.postSaleDiscountsQuery({});

    return postSaleDiscounts.value || [];
  };

  const handleDownload = async () => {
    setIsFetching(true);

    const postSaleDiscounts = await fetchPostSaleDiscounts();
    setIsFetching(false);

    downloadCsv(postSaleDiscounts);
  };

  return (
    <Button
      variant="outline"
      icon={
        isFetching ? (
          <div css={{ width: "20px" }}>
            <Spinner size="16px" />
          </div>
        ) : (
          <MdOutlineSimCardDownload size={20} />
        )
      }
      onClick={handleDownload}
    >
      Pobierz
    </Button>
  );
};

const mapDiscountsToCsvEntry = (discount: PostSaleDiscount) => ({
  "Nazwa klienta": discount.customerName,
  "Rabat posprzedażowy": discount.discount?.toFixed(2),
});

const downloadCsv = (discounts: PostSaleDiscount[]) => {
  const entries = discounts.slice(0, 10000).map(mapDiscountsToCsvEntry);

  const columns = ["Nazwa klienta", "Rabat posprzedażowy"];

  const csv = stringify(entries, {
    header: true,
    columns: columns,
  });

  fileDownload(csv, `rabaty_posprzedazowe.csv`);
};
