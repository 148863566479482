/* eslint-disable @typescript-eslint/no-empty-function */
import { Currency, Gtin, priceListResource, TradeItemDto, validateGtin } from "@legacy-megarax/crm-contracts";
import { useProfilesDictionary } from "@legacy-megarax/iam-webapp";
import { useResourceProviderV2 } from "@legacy-megarax/react-client";
import { useAsyncLoad } from "@legacy-megarax/react-utils";
import {
  commonErrorsMap,
  FeedbackSnackbarContext,
  InvalidGtinDialog,
  useSnackbarErrorHandler,
} from "@legacy-megarax/ui-components";
import React, { useContext } from "react";

import { Uuid } from "@megaron/uuid";

import { ProposeDeletionInput } from "./ProposeDeletion/proposeDeletionForm";
import { ProposeEditInput } from "./ProposeEdit/proposeEditForm";
import { TradeItemDetailsView } from "./TradeItemDetailsView";

interface ContainerProps {
  closeDialog: () => void;
  getTradeItemDetails: (gtin: Gtin) => TradeItemDto | undefined;
  currency: Currency;
  gtin: string;
  uuid: Uuid;
}

export const TradeItemDetailsContainer: React.FC<ContainerProps> = ({
  closeDialog,
  getTradeItemDetails,
  currency,
  gtin,
  uuid,
}) => {
  const isGtinValid = validateGtin(gtin || "");

  const tradeItemDetails = getTradeItemDetails(gtin as Gtin);
  const snackbarContext = useContext(FeedbackSnackbarContext);

  const priceListDetailsProvider = useResourceProviderV2(priceListResource).byUuid(uuid || ("" as Uuid));

  const { value: item } = useAsyncLoad(() => getItemDetails(gtin as Gtin), [gtin]);

  const profiles = useProfilesDictionary(
    item?.value?.allChanges.flatMap((change) => [change.approvedBy, change.proposedBy]),
  );
  const handleErrors = useSnackbarErrorHandler({
    ...commonErrorsMap,
    DateInThePast: "Data wprowadzenia zmiany musi być w przyszłości.",
    PriceListDoesNotExist: "Nie znaleziono cennika.",
    NegativePrice: "Cena nie może być ujemna.",
  });

  if (isGtinValid.isFailure) return <InvalidGtinDialog gtin={gtin as Gtin} />;

  const getItemDetails = (gtin: Gtin) => priceListDetailsProvider.items.byGtin(gtin).retrieve();

  const onProposeDeletion = (input: ProposeDeletionInput) =>
    priceListDetailsProvider.proposals
      .create(undefined, {
        ...input,
        changes: [{ gtin: gtin as Gtin, netPrice: null }],
      })
      .mapError(handleErrors)
      .map(() => {
        closeDialog();
        snackbarContext.pushMessage({
          severity: "success",
          content: "Pomyślnie zaproponowano usunięcie ceny z cennika.",
        });
      })
      .then(() => {});

  const onProposeEdit = (input: ProposeEditInput) =>
    priceListDetailsProvider.proposals
      .create(undefined, {
        ...input,
        changes: [{ gtin: gtin as Gtin, netPrice: input.netPrice }],
      })
      .mapError(handleErrors)
      .map(() => {
        closeDialog();
        snackbarContext.pushMessage({
          severity: "success",
          content: "Pomyślnie zaproponowano zmianę.",
        });
      })
      .then(() => {});

  if (item === undefined) return null;
  if (item.isFailure || !profiles) return null;
  return (
    <TradeItemDetailsView
      currency={currency}
      tradeItem={item.value}
      tradeItemDetails={tradeItemDetails}
      onProposeDeletion={onProposeDeletion}
      onProposeEdit={onProposeEdit}
      profiles={profiles}
      gtin={gtin}
      uuid={uuid}
    />
  );
};
