import { Spinner } from "@dash/spinner";
import { MdCheck, MdOutlineWarningAmber } from "react-icons/md";

import { UpdateStatus } from "./CustomerPage";

type Props = {
  status: UpdateStatus;
};

export const UpdateStatusTile: React.FC<Props> = ({ status }) => {
  if (!status) {
    return null;
  }

  if (status === "isLoading") {
    return <Spinner size="20px" color="white" />;
  }

  return (
    <div css={{ display: "flex", alignItems: "center", gap: "0.375rem", fontSize: "0.875rem", color: "white" }}>
      {status === "success" ? "Zapisano" : "Błąd"}
      {status === "success" ? <MdCheck size={18} /> : <MdOutlineWarningAmber size={18} />}
    </div>
  );
};
