import { DateString, fromDateString, newUuid, toDateString, Uuid, validateDateString } from "@legacy-megarax/common";
import { useProfile } from "@legacy-megarax/iam-webapp";
import { useCurrentUser } from "@legacy-megarax/react-client";
import { BreadcrumbMarkers, PageTitleManager } from "@legacy-megarax/ui-components";
import { useCustomers } from "@megarax/crm-resource-hooks";
import { CalendarViewWeek } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { matchPath, useLocation, useNavigate, useParams } from "react-router-dom-v5-compat";

import { useClientManager } from "@megaron/react-clients";
import { isDefined } from "@megaron/utils";

import { ActionButtons } from "./ActionButtons";
import { ActivityList } from "./ActivityList";
import { AddUnplannedVisitDialogContainer } from "./AddUnplannedVisitDialog";
import { ChangeEventDialogContainer } from "./ChangeEventDialog";
import { DaySelect } from "./DaySelect";
import { getActivityListEntries } from "./getActivityListEntries";
import { PlanRouteDialogContainer } from "./PlanRouteDialogContainer";
import { useOnVisitClose, useVisitNavigation } from "./useVisitNavigation";
import { useWorkDay } from "./useWorkDay";
import { ViewLayout } from "./ViewLayout";
import { WorkTimeCardContainer } from "./WorkTimeCard";

export const DayViewContainer = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useParams();

  const dateString = validateDateString(params.date || "").assertOk().value;
  const userId = Number(params.userId);

  const onChangeDateString = (dateStr: DateString) => navigate(`/crm/pos/handlowcy/${userId}/wizyty/dzien/${dateStr}`);
  const onGoToWeek = (dateStr: DateString) => navigate(`/crm/pos/handlowcy/${userId}/wizyty/tydzien/${dateStr}`);

  const date = fromDateString(dateString);

  const profile = useProfile({ id: userId });

  const { workDay, isLoading: workDayIsLoading, refetch: refetchWorkDay } = useWorkDay(date, userId);
  const visitScheduleQuery = useClientManager("legacyMegarax")
    .visitScheduleQuery()
    .useQuery({ date: toDateString(date), userId });
  const interactionsQuery = useClientManager("docs")
    .customerInteractionQuery()
    .useQuery(
      { ownerEmail: profile?.user.email ?? "", dates: [toDateString(date)] },
      { enabled: profile !== undefined },
    );

  const customerUuids = [...(interactionsQuery.data?.items ?? []), ...(visitScheduleQuery.data?.visits ?? [])].map(
    (i) => i.customerUuid,
  );
  const customerMap = useCustomers(customerUuids);

  const { closeVisit, openVisit } = useVisitNavigation();

  const openNewBreakDialog = () => navigate(`${pathname}/przerwa/${newUuid()}`);

  const entries =
    isDefined(visitScheduleQuery.data) && isDefined(interactionsQuery.data) && isDefined(workDay)
      ? getActivityListEntries(visitScheduleQuery.data, interactionsQuery.data.items, workDay, customerMap)
      : undefined;

  const currentUser = useCurrentUser();
  const isMyDay = userId === currentUser?.id;

  useOnVisitClose(() => {
    refetchWorkDay();
    interactionsQuery.refetch();
  });

  const openNewVisitDialog = () => navigate(`${pathname}/nowa_wizyta`);
  const openChangeEventDialog = (eventUuid: Uuid) => navigate(`${pathname}/wydarzenie/${eventUuid}`);
  const openNewEventDialog = () => openChangeEventDialog(newUuid());
  const openPlanRouteDialog = () => navigate(`${pathname}/zaplanuj`);

  const isNewVisitDialogOpen = pathname.endsWith("nowa_wizyta");
  const changeEventMatchPath = matchPath(
    "/crm/pos/handlowcy/:userId/wizyty/dzien/:date/wydarzenie/:eventUuid",
    pathname,
  );
  const isPlanRouteDialogOpen = pathname.endsWith("zaplanuj");

  const basePath = `/crm/pos/handlowcy/${userId}/wizyty/dzien/${dateString}`;

  return (
    <>
      <PageTitleManager title={dateString} />
      <BreadcrumbMarkers
        breadcrumbs={[
          { title: "Handlowcy", to: "/crm/pos/handlowcy", id: "salespeople" },
          { title: profile?.displayedName ?? "?", to: `/crm/pos/handlowcy/${userId}`, id: "salesperson" },
          { title: dateString, id: "date", to: pathname },
        ]}
      />
      {isNewVisitDialogOpen && <AddUnplannedVisitDialogContainer date={date} onClose={() => navigate(basePath)} />}
      {changeEventMatchPath && workDay && (
        <ChangeEventDialogContainer
          date={date}
          eventUuid={(changeEventMatchPath.params.eventUuid as Uuid) || ("" as Uuid)}
          events={workDay.events}
          isOpen={true}
          onChanged={refetchWorkDay}
          onClose={() => navigate(basePath)}
        />
      )}
      {isPlanRouteDialogOpen && (
        <PlanRouteDialogContainer
          date={date}
          onChange={() => {
            setTimeout(() => {
              refetchWorkDay();
              visitScheduleQuery.refetch();
            }, 1700);
          }}
          onClose={() => navigate(basePath)}
          userId={userId}
        />
      )}
      <ViewLayout
        dateSelect={<DaySelect date={date} onChange={(date) => onChangeDateString(toDateString(date))} />}
        activityList={
          <ActivityList
            entries={entries}
            onEventEdit={(eventUuid) => openChangeEventDialog(eventUuid)}
            onVisitSeeMore={openVisit}
          />
        }
        alerts={null}
        buttons={
          <ActionButtons
            isMyDay={isMyDay}
            onBreakClick={openNewBreakDialog}
            onEventClick={openNewEventDialog}
            onVisitClick={openNewVisitDialog}
            onRouteClick={openPlanRouteDialog}
          />
        }
        workTime={<WorkTimeCardContainer userId={userId} date={date} />}
        isLoading={workDayIsLoading || visitScheduleQuery.isLoading || interactionsQuery.isLoading}
        closeVisit={closeVisit}
        viewSwitchButton={
          <IconButton onClick={() => onGoToWeek(dateString)}>
            <CalendarViewWeek />
          </IconButton>
        }
      />
    </>
  );
};
