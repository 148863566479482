import { MSerializers } from "@legacy-megarax/serializers";

export type PostCommentInput = {
  content: string;
  uuid: string;
};

export const postCommentInputSerializer = MSerializers.object<PostCommentInput>({
  uuid: MSerializers.uuid(),
  content: MSerializers.string,
});
