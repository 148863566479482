import { useQsFilters } from "@dash/filters";
import { useCallback, useEffect, useRef } from "react";

import { customerCategories, customerStatuses, relationTypes } from "@megaron/crm-contracts";
import { customerSortFields } from "@megaron/docs-contracts";
import { Serializers, SerializerValue } from "@megaron/serializers";

export const presetFiltersSerializer = Serializers.object({
  sort: Serializers.sortFilter(customerSortFields).optional(),
  isPro: Serializers.boolean,
  isAffiliate: Serializers.boolean,
  isActive: Serializers.boolean.optional(),
  pos: Serializers.array(Serializers.string).optional(),
  scannedProducts: Serializers.array(Serializers.string).optional(),
  tags: Serializers.array(Serializers.string).optional(),
  isMyRegion: Serializers.boolean.optional(),
  regions: Serializers.array(Serializers.string).optional(),
  isUnassigned: Serializers.boolean.optional(),
  status: Serializers.stringOneOf(...customerStatuses)
    .array()
    .optional(),
  categories: Serializers.stringOneOf(...customerCategories)
    .array()
    .optional(),
  parents: Serializers.array(Serializers.string).optional(),
  relation: Serializers.object({
    relationType: Serializers.stringOneOf(...relationTypes),
    parents: Serializers.string.array(),
  }).optional(),
});

export type PresetFilters = SerializerValue<typeof presetFiltersSerializer>;

export const filtersSerializer = Serializers.object({
  page: Serializers.integer,
  searchText: Serializers.string,
  ...presetFiltersSerializer.fields,
});

export type Filters = SerializerValue<typeof filtersSerializer>;

export const useCustomersFilters = (
  presetFilters: PresetFilters,
  onUpdatePresetFilters: (newFilters: PresetFilters) => void,
) => {
  const isPresetUpdatedOnMount = useRef(false);

  const defaultFilters: Filters = {
    page: 0,
    searchText: "",
    sort: presetFilters.sort,
    isPro: presetFilters.isPro,
    isAffiliate: presetFilters.isAffiliate,
    isActive: presetFilters.isActive,
    pos: presetFilters.pos,
    tags: presetFilters.tags,
    isMyRegion: presetFilters.isMyRegion,
    regions: presetFilters.regions,
    isUnassigned: presetFilters.isUnassigned,
    scannedProducts: presetFilters.scannedProducts,
    status: presetFilters.status,
    categories: presetFilters.categories,
    parents: presetFilters.parents,
    relation: presetFilters.relation,
  };

  const { filters, setFilter, setFilters } = useQsFilters(filtersSerializer, defaultFilters);

  useEffect(() => {
    if (!isPresetUpdatedOnMount.current) {
      onUpdatePresetFilters(parseFiltersToPresetFilters(filters));
      isPresetUpdatedOnMount.current = true;
    }
  }, [filters, isPresetUpdatedOnMount, onUpdatePresetFilters]);

  const newSetFilter =
    <Tkey extends keyof Filters>(key: Tkey) =>
    (value: Filters[Tkey]) => {
      setFilter(key)(value);

      if (key === "page" || key === "searchText") {
        return;
      }

      onUpdatePresetFilters({ ...presetFilters, [key]: value });
    };

  const newSetFilters = useCallback(
    (newFilters: Filters) => {
      setFilters(newFilters);
      onUpdatePresetFilters(parseFiltersToPresetFilters(newFilters));
    },
    [onUpdatePresetFilters, setFilters],
  );

  return { filters, setFilter: newSetFilter, defaultFilters, setFilters: newSetFilters };
};

const parseFiltersToPresetFilters = (filters: Filters): PresetFilters => {
  const { page, searchText, ...rest } = filters;

  return rest;
};
