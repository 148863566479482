import { DateStringResourceParam, UuidResourceParam, uuidSerializer } from "@legacy-megarax/common";
import { BaseResource, IdResourceParam, ResourceActionV2 } from "@legacy-megarax/rest-resource";
import { Serializers } from "@legacy-megarax/serializers";

import { FixedRouteFilters } from "./";
import { dayDtoSerializer } from "./dayDto";
import { changeVisitDurationErrors, descheduleVisitErrors, loadFixedRouteErrors, scheduleVisitErrors } from "./errors";
import { fixedRouteSimpleSerializers } from "./fixedRoute";
import { scheduleVisitInputSerializer } from "./inputs";

export const visitScheduleDayResource = BaseResource(["crm", "visitScheduleDays"], {
  byUserId: IdResourceParam("userId", {
    byDate: DateStringResourceParam("date", "date", {
      retrieve: ResourceActionV2({
        name: "retrieve",
        method: "get",
        path: "",
        responseValueSerializer: dayDtoSerializer.nullable(),
      }),

      loadFixedRoute: ResourceActionV2({
        name: "loadFixedRoute",
        method: "post",
        requestBodySerializer: Serializers.object({
          routeUuid: uuidSerializer,
        }),
        responseErrorSerializer: Serializers.stringOneOf(...loadFixedRouteErrors),
      }),

      byVisitUuid: UuidResourceParam("visitUuid", "visits", {
        schedule: ResourceActionV2({
          name: "scheduleVisit",
          method: "post",
          path: "",
          requestBodySerializer: scheduleVisitInputSerializer,
          responseErrorSerializer: Serializers.stringOneOf(...scheduleVisitErrors),
        }),
        deschedule: ResourceActionV2({
          name: "descheduleVisit",
          method: "delete",
          path: "",
          responseErrorSerializer: Serializers.stringOneOf(...descheduleVisitErrors),
        }),
        changeDuration: ResourceActionV2({
          name: "changeVisitDuration",
          method: "put",
          path: "changeDuration",
          requestBodySerializer: Serializers.object({
            durationMinutes: Serializers.integer,
          }),
          responseErrorSerializer: Serializers.stringOneOf(...changeVisitDurationErrors),
        }),
      }),
    }),
  }),
});

const fixedRouteListAction = ResourceActionV2({
  name: "list",
  method: "get",
  path: "",
  querySerializer: Serializers.object<FixedRouteFilters>({
    userId: Serializers.integer.optional(),
  }),
  responseValueSerializer: Serializers.array(fixedRouteSimpleSerializers),
});

export const fixedRouteResource = BaseResource(["crm", "fixedRoutes"], {
  list: fixedRouteListAction,
});
