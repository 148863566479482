import { ResourceAction } from "@legacy-megarax/rest-resource";
import { MSerializers } from "@legacy-megarax/serializers";

import { currencies, gtinSerializer } from "../../common";
import { PriceListDetail, PriceListDetailCurrentPriceDto } from "../read/detail";

const priceListDetailSerializer = MSerializers.object<PriceListDetail>({
  name: MSerializers.string,
  uuid: MSerializers.uuid(),
  currentPrices: MSerializers.array(
    MSerializers.object<PriceListDetailCurrentPriceDto>({
      gtin: gtinSerializer,
      activeFrom: MSerializers.datetime,
      netPrice: MSerializers.decimal,
    }),
  ),
  currency: MSerializers.stringOneOf(...currencies),
});

export default ResourceAction({
  name: "retrieve",
  method: "get",
  path: "",
  responseSerializer: MSerializers.result<PriceListDetail, any>(priceListDetailSerializer),
});
