import { Paper, Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

export const WorkTimeCardSkeleton: React.FC = () => {
  const classes = useStyles();

  return (
    <Paper>
      <Skeleton variant="rectangular" height={215} />
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({}));
