import { isWeekend } from "date-fns";
import Holidays from "date-holidays";

export const getDateWithinBusinessDays = (date: Date, businessDays: number) => {
  const hd = new Holidays("PL");

  // Function to check if a date is a holiday in Poland
  const isHoliday = (date: Date) => {
    const holidays = hd.getHolidays(date.getFullYear());
    return holidays.some((holiday) => {
      const holidayDate = new Date(holiday.date);
      return (
        date.getDate() === holidayDate.getDate() &&
        date.getMonth() === holidayDate.getMonth() &&
        date.getFullYear() === holidayDate.getFullYear()
      );
    });
  };

  // Function to add business days with holiday check
  const addBusinessDaysWithHolidays = (startDate: Date, days: number) => {
    const date = new Date(startDate);
    let businessDaysAdded = 0;

    while (businessDaysAdded < days) {
      date.setDate(date.getDate() + 1);
      if (!isWeekend(date) && !isHoliday(date)) {
        businessDaysAdded++;
      }
    }

    return date;
  };

  // Calculate date
  const deliveryDate = addBusinessDaysWithHolidays(date, businessDays);

  return deliveryDate;
};
