import { useToast } from "@dash/toast";
import { useTheme } from "@emotion/react";
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaFile, FaTrash } from "react-icons/fa";

import { AttachmentUpload } from "@megaron/invoices-contracts";

import { fileListToBase64 } from "./fileListToBase64";

type Props = {
  onChange: (attachments: AttachmentUpload[]) => void;
};

export const FileDropzone: React.FC<Props> = ({ onChange }) => {
  const toast = useToast();
  const [attachments, setAttachments] = useState<AttachmentUpload[]>([]);
  const theme = useTheme();

  const removeAttachment = (uuid: string) => {
    setAttachments((attachments) => attachments.filter((attachment) => attachment.uuid !== uuid));
  };

  useEffect(() => {
    onChange(attachments);
  }, [attachments, onChange]);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const onFilesSelect = async (files: FileList | null) => {
        if (!files) return;
        try {
          const newAttachments = await fileListToBase64(files);
          setAttachments((attachments) => [...attachments, ...newAttachments]);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
          toast.error("Nie udało się załadować plików.");
        }
      };

      onFilesSelect(acceptedFiles);
    },
    [toast],
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Card variant="outlined" css={{ borderColor: theme.colors.border }}>
      <CardContent css={{ display: "flex", justifyContent: "center", cursor: "pointer" }} {...getRootProps()}>
        <input {...getInputProps()} />
        <FaFile size={64} css={{ fontSize: "5rem", color: theme.colors.primaryLight }} />
      </CardContent>
      {attachments.length > 0 && (
        <CardActions css={{ padding: "0" }}>
          <List dense css={{ width: "100%" }}>
            {attachments.map((attachment) => {
              return (
                <div key={attachment.uuid}>
                  <Divider />
                  <ListItem css={{ width: "100%" }}>
                    <ListItemAvatar>
                      <FaFile css={{ color: theme.colors.primary }} />
                    </ListItemAvatar>
                    <ListItemText>{attachment.fileName}</ListItemText>
                    <ListItemSecondaryAction onClick={() => removeAttachment(attachment.uuid)}>
                      <FaTrash css={{ color: theme.colors.primary, cursor: "pointer" }} />
                    </ListItemSecondaryAction>
                  </ListItem>
                </div>
              );
            })}
          </List>
        </CardActions>
      )}
    </Card>
  );
};
