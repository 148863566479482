import { Dialog } from "@dash/dialog";
import { Button } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { useToast } from "@dash/toast";
import { useTheme } from "@emotion/react";
import { MdOutlineWarning } from "react-icons/md";
import { useNavigate } from "react-router-dom-v5-compat";

import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

type Props = {
  onClose: () => void;
  draftUuid: string;
};

export const DeleteDraftDialog = ({ onClose, draftUuid }: Props) => {
  const theme = useTheme();

  const toast = useToast();

  const navigate = useNavigate();

  const { isMobile } = useDeviceType();

  const deleteDraftMutation = useClientManager("crm").discardDraft().useMutation();

  const handleDraftDelete = () => {
    deleteDraftMutation.mutate(
      { uuid: Uuid(draftUuid) },
      {
        onSuccess: () => {
          navigate("/crm/order-drafts");
        },
        onError: (error) => {
          if (error === "DraftNotOpen") {
            toast.error("Wersja robocza nie posiada statusu: otwarta..");
          }

          if (error === "OrderNotFound") {
            toast.error("Nie znaleziono zamówienia.");
          }

          if (error === "UserNotOrderOwner") {
            toast.error("Nie jesteś właścicielem tego zamówienia.");
          }
        },
      },
    );
  };

  return (
    <Dialog
      hideHeader
      placement={isMobile ? "top" : "center"}
      css={{ minHeight: 0, maxWidth: isMobile ? "calc(100% - 2rem)" : "400px" }}
      onClose={onClose}
    >
      <div
        css={{
          color: theme.colors.primary,
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
        }}
      >
        <div css={{ display: "flex", alignItems: "center", gap: "0.75rem", fontSize: "0.875rem" }}>
          <MdOutlineWarning size={18} css={{ flexShrink: 0 }} />
          Czy na pewno chcesz usunąć wersję roboczą zamówienia?
        </div>
        <div css={{ display: "flex", justifyContent: "space-between" }}>
          <Button variant="outline" size="small" onClick={onClose}>
            Wróć
          </Button>
          <Button color="danger" size="small" onClick={handleDraftDelete}>
            Kontynuuj
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
