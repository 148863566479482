import { useTheme } from "@emotion/react";

import { Profile } from "@megaron/auth-contracts";

import { ParticipantProfileAvatar } from "./ParticipantProfileAvatar";

type Props = {
  participantProfiles: (Profile & { isRead: boolean })[];
};

export const ParticipantProfileList: React.FC<Props> = ({ participantProfiles }) => {
  const profiles = participantProfiles.filter(({ isRead }) => !isRead);
  const waitingOnParticipantCount = profiles.length;

  const theme = useTheme();

  return (
    <div css={{ display: "flex", alignItems: "center" }}>
      {profiles.slice(0, 3).map((profile, index) => (
        <ParticipantProfileAvatar
          key={`${profile.email}-${index}`}
          firstName={profile.firstName ?? ""}
          lastName={profile.lastName ?? ""}
          imageUrl={profile.profilePictureUrl}
          css={{ zIndex: 1 + index, marginLeft: index === 0 ? 0 : "-0.5rem" }}
        />
      ))}
      {waitingOnParticipantCount === 4 && (
        <ParticipantProfileAvatar
          key={profiles[3].email}
          firstName={profiles[3].firstName ?? ""}
          lastName={profiles[3].lastName ?? ""}
          imageUrl={profiles[3].profilePictureUrl}
          css={{ zIndex: 4, marginLeft: "-0.5rem" }}
        />
      )}
      {waitingOnParticipantCount > 4 && (
        <div
          key={profiles[3].email}
          css={{
            borderRadius: "100%",
            width: "1.5rem",
            height: "1.5rem",
            border: `1px solid ${theme.colors.border}`,
            backgroundColor: "white",
            fontSize: "0.75rem",
            color: "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "-0.5rem",
            zIndex: 10,
          }}
        >{`+${waitingOnParticipantCount - 3}`}</div>
      )}
    </div>
  );
};
