import { ResourceNodes, ResourceParamNode } from "@legacy-megarax/rest-resource";
import { Serializer, serializerExtensions, SerializerExtensions, ValidationError } from "@legacy-megarax/serializers";

import { Failure, Ok } from "@megaron/result";

export type Gtin = string & { __brand: "gtin" };

export const validateGtin = (str: string) => {
  if (str.match(/^\d+$/) === null) return Failure("InvalidGtin");
  return Ok<Gtin>(str as Gtin);
};

export const gtinSerializer: Serializer<Gtin> & SerializerExtensions<Gtin> = {
  serialize: (gtin) => gtin,
  forceDeserialize: (raw: unknown) => {
    if (typeof raw !== "string") throw new ValidationError("Gtin needs to be a string");
    const result = validateGtin(raw);
    if (result.isFailure) throw new ValidationError("Invalid gtin");
    return result.value;
  },
  ...serializerExtensions(),
};

export const GtinResourceParam = <TNestedActions extends ResourceNodes>(
  paramName: string,
  path: string,
  nested: TNestedActions,
): ResourceParamNode<Gtin, TNestedActions> => ({
  children: nested,
  paramName,
  paramPattern: "\\b\\d+\\b",
  paramSerializer: gtinSerializer,
  path,
});
