import { useTheme } from "@emotion/react";
import { appLinkTreesObject } from "@megarax/links";
import { formatDistanceToNow } from "date-fns";
import { pl } from "date-fns/locale";
import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { AlertObject, alertsMap } from "@megaron/notifications-contracts";

type Props = {
  alert: AlertObject;
};

export const AlertTile: React.FC<Props> = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const notificationGroup = alertsMap[props.alert.type].app;
  const notificationColor = appLinkTreesObject[notificationGroup].color;
  const NotificationIcon = appLinkTreesObject[notificationGroup].Icon;

  const link = props.alert.link;

  const gradient = `radial-gradient(at 35% top, rgba(255, 255, 255, 0.15), transparent)`;

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        borderRadius: theme.smallBorderRadius,
        border: `1px solid ${notificationColor}`,
        background: `${gradient}, ${notificationColor}`,
        color: "rgba(255, 255, 255, 0.7)",
        fontSize: "14px",
        fontWeight: "bold",
        minWidth: "16rem",
        padding: "12px 16px",
        cursor: props.alert.link ? "pointer" : "default",
        gap: "8px",
      }}
      onClick={() => {
        if (link) {
          if (link.startsWith("http") || link.startsWith("https")) {
            window.location.href = link;
          } else {
            navigate(link, { replace: true });
          }
        }
      }}
    >
      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          css={{
            color: "white",
            fontWeight: 700,
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <NotificationIcon css={{ marginRight: "8px", fontSize: "16px" }} />
          {alertsMap[props.alert.type].name}
        </div>

        <span css={{ fontSize: "10px", color: "rgba(255,255,255, 0.6)", fontWeight: 600 }}>
          {formatDistanceToNow(props.alert.createdAt, {
            locale: pl,
          }) + " temu"}
        </span>
      </div>
      <span css={{ fontSize: "12px", fontWeight: 500 }}>{props.alert.message}</span>
    </div>
  );
};
