import { CommentDto } from "@legacy-megarax/crm-contracts";
import { useProfilesDictionary } from "@legacy-megarax/iam-webapp";
import { useAsyncLoad } from "@legacy-megarax/react-utils";
import { Chat as ChatIcon, Send as SendIcon } from "@mui/icons-material";
import { Box, Divider, TextField, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { SkeletonLoader } from "../utility";

interface Props {
  postComment: (content: string) => Promise<void>;
  getComments: () => Promise<CommentDto[]>;
  noBorder?: boolean;
  disableEmotes?: boolean;
}

export const Chat: React.FC<Props> = ({ getComments, postComment, disableEmotes, noBorder }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const chatBox = useRef<HTMLDivElement>(null);

  const [message, setMessage] = useState<string>("");

  const { value: comments, reload } = useAsyncLoad(getComments, []);

  const users = useMemo(
    () =>
      comments
        ? _.uniqBy(
            comments.map((comment) => ({ id: comment.commenterId })),
            "id",
          )
        : undefined,
    [comments],
  );
  const profilesDictionary = useProfilesDictionary(users);

  const onSend = async () => {
    try {
      setLoading(true);
      await postComment(message);
      setMessage("");
      await reload();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const sendEmoji = async (emoji: string) => {
    try {
      setLoading(true);
      await postComment(emoji);
      await reload();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const asyncData = comments && profilesDictionary ? { comments, profilesDictionary } : undefined;

  useEffect(() => {
    if (!asyncData || !chatBox.current) return;
    chatBox.current.scrollTo(0, chatBox.current.scrollHeight);
  }, [asyncData]);

  const onKeyDown = (event: any) => {
    if ((event.code === "Enter" || event.code === "NumpadEnter") && !event.shiftKey) {
      event.preventDefault();
      if (message && !loading) onSend();
    }
  };

  return (
    <div className={clsx(classes.chatContainer, noBorder && classes.noBorder)}>
      <SkeletonLoader
        skeletonProps={{
          variant: "rectangular",
          height: "100%",
        }}
        asyncData={asyncData}
        component={({ comments, profilesDictionary }) => {
          return (
            <div className={classes.messages} ref={chatBox}>
              {comments.map((comment, idx) => {
                const isFirstInGroup = comments[idx - 1]?.commenterId !== comment.commenterId;
                const isLastInGroup = comments[idx + 1]?.commenterId !== comment.commenterId;
                const profile = profilesDictionary[comment.commenterId];
                const isEmoji = /^\p{Emoji}$/u.test(comment.content);

                return (
                  <div className={classes.bubbleContainer} key={comment.uuid}>
                    {isFirstInGroup && (
                      <div className={classes.authorInfo}>
                        <Typography
                          variant="caption"
                          display="inline"
                          color="textSecondary"
                          className={classes.authorText}
                        >
                          {profile?.displayedName ?? "Nieznany użytkownik"}
                        </Typography>
                      </div>
                    )}
                    <Tooltip
                      title={`${comment.commentedAt.toLocaleDateString()} ${comment.commentedAt.toLocaleTimeString()}`}
                      placement="left"
                    >
                      {isEmoji ? (
                        <Box className={classes.emoji}>{comment.content}</Box>
                      ) : (
                        <Box
                          className={classes.bubble}
                          sx={{
                            borderRadius: `${isFirstInGroup ? "20px" : "5px"} 20px 20px ${
                              isLastInGroup ? "20px" : "5px"
                            }`,
                          }}
                        >
                          <Typography variant="body2" className={classes.commentText}>
                            {comment.content}
                          </Typography>
                        </Box>
                      )}
                    </Tooltip>
                  </div>
                );
              })}
              {comments.length === 0 && (
                <Box textAlign="center" marginY={2}>
                  <Box textAlign="center">
                    <ChatIcon />
                  </Box>
                  <Typography align="center" color="textSecondary" variant="caption">
                    Brak komentarzy.
                  </Typography>
                </Box>
              )}
            </div>
          );
        }}
      />
      <div>
        <Divider />
        <div className={classes.chatInput}>
          <TextField
            multiline
            variant="standard"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={onKeyDown}
            fullWidth
          />
          <Box height="35px" display="flex" alignItems="center" justifyContent="center">
            {message || disableEmotes ? (
              <SendIcon
                className={classes.sendIcon}
                onClick={() => !loading && message && onSend()}
                style={{ color: loading ? "gray" : "inherit" }}
              />
            ) : (
              <div className={classes.emojiBar}>
                <Typography display="inline" variant="inherit" onClick={() => !loading && sendEmoji("👍")}>
                  👍
                </Typography>
                <Typography display="inline" variant="inherit" onClick={() => !loading && sendEmoji("👎")}>
                  👎
                </Typography>
              </div>
            )}
          </Box>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    sendIcon: {
      padding: theme.spacing(1),
      fontSize: theme.spacing(2.5),
      cursor: "pointer",
      width: "20px",
      height: "20px",
      "& :hover": {
        color: theme.palette.grey[900],
      },
    },

    chatContainer: {
      maxHeight: "inherit",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      border: `1px solid ${theme.palette.grey[200]}`,
      backgroundColor: "white",
    },
    noBorder: {
      border: 0,
    },
    chatInput: {
      boxSizing: "border-box",
      width: "100%",
      display: "flex",
      flexWrap: "nowrap",
      flexDirection: "row",
      alignItems: "flex-end",
      padding: theme.spacing(1),
      gap: theme.spacing(1),
      maxHeight: "150px",
      overflow: "hidden",
    },
    messages: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(1),
      gap: theme.spacing(0.5),
      justifyContent: "flex-start",
      overflowY: "auto",
    },
    emojiBar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      fontSize: "1.3rem",
      width: "100%",
      "& > span": {
        cursor: "pointer",
      },
    },
    bubbleContainer: {
      width: "100%",
    },
    bubble: {
      maxWidth: "80%",
      padding: theme.spacing(1, 2),
      backgroundColor: theme.palette.grey[100],
      display: "inline-block",
    },
    emoji: {
      maxWidth: "80%",
      display: "inline-block",
      fontSize: "1.5rem",
    },
    authorInfo: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
      justifyContent: "flex-start",
      marginLeft: theme.spacing(1),
    },
    authorText: {
      fontSize: "0.6875rem",
    },
    commentText: {
      wordBreak: "break-word",
      whiteSpace: "pre-line",
    },
    avatar: {
      height: theme.spacing(2),
      width: theme.spacing(2),
      backgroundColor: theme.palette.grey[400],
    },
    title: {
      padding: theme.spacing(0, 2),
    },
  };
});
