import { MyChatFeedContainer } from "@megarax/crm-chat";
import { MyTasksViewContainer } from "@megarax/crm-feed-task";
import { Box, Container, Typography } from "@mui/material";
import React from "react";

export const HomeView: React.FC = () => {
  return (
    <Container maxWidth={"sm"}>
      <Box my={2}>
        <Typography variant="h5">Strona główna</Typography>
      </Box>
      <MyChatFeedContainer />
      <MyTasksViewContainer />
    </Container>
  );
};
