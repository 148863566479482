import { Serializers } from "@legacy-megarax/serializers";

export type CustomerReference = { uuid: string };
export type PriceListReference = { uuid: string };

export const customerReferenceSerializer = Serializers.object<CustomerReference>({
  uuid: Serializers.uuid(),
});

export const priceListReferenceSerializer = Serializers.object<PriceListReference>({
  uuid: Serializers.uuid(),
});
