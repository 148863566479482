import { uuidSerializer } from "@legacy-megarax/common";
import { ResourceActionV2 } from "@legacy-megarax/rest-resource";
import { MSerializers } from "@legacy-megarax/serializers";

import { Group } from "./rest";

export const groupSerializer = MSerializers.object<Group>({
  regionUuid: uuidSerializer,
  groupUuids: MSerializers.array(uuidSerializer),
});

export const listAll = ResourceActionV2({
  name: "list",
  method: "get",
  path: "",
  responseValueSerializer: MSerializers.array(groupSerializer),
});

export const myGroups = ResourceActionV2({
  name: "myGroups",
  method: "get",
  path: "myGroups",
  responseValueSerializer: MSerializers.array(groupSerializer),
  responseErrorSerializer: MSerializers.identity<"UserNotFound" | "UserGroupsRetrievalError">(),
});
