import { WorkDayDto } from "@legacy-megarax/crm-contracts";
import { isNotNull } from "@legacy-megarax/utils";

import { CalendarEvent } from "./WeekCalendar";

export const getWorkDayCalendarEvents = (workDay: WorkDayDto): CalendarEvent[] => {
  const start: CalendarEvent | null = workDay.startTime && {
    color: "#ffc107",
    endTime: null,
    startTime: workDay.startTime,
    key: `dayStart/${workDay.date}`,
    title: "Początek",
  };
  const end: CalendarEvent | null = workDay.endTime && {
    color: "#ffc107",
    endTime: null,
    startTime: workDay.endTime,
    key: `dayEnd/${workDay.date}`,
    title: "Koniec",
  };

  const breaks = workDay.breaks.map<CalendarEvent>((b) => ({
    color: "#ffc107",
    startTime: b.startTime,
    endTime: b.endTime,
    key: `break/${b.uuid}`,
    title: "Przerwa",
  }));

  const other = workDay.events.map<CalendarEvent>((e) => ({
    color: "#006433",
    startTime: e.startTime,
    endTime: e.endTime,
    key: `event/${e.uuid}`,
    title: e.title,
  }));

  return [start, end, ...breaks, ...other].filter(isNotNull);
};
