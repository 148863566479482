import { DecimalField, IntegerField } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { SectionHeader } from "@dash/page";

type Props = {
  values: {
    size: string;
    pallet: number | null;
    layer: number | null;
    box: number | null;
  };
  handlers: {
    onSizeChange: (size: string) => void;
    onPalletChange: (pallet: number | null) => void;
    onLayerChange: (layer: number | null) => void;
    onBoxChange: (box: number | null) => void;
  };
};

export const TradeItemSizes: React.FC<Props> = ({ handlers, values }) => {
  const { isMobile } = useDeviceType();

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>
      <SectionHeader isHr>Wielkości</SectionHeader>
      <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem", width: "100%" }}>
        <div css={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: "0.625rem", width: "100%" }}>
          <DecimalField label="Wielkość" value={values.size} onChange={handlers.onSizeChange} css={{ width: "100%" }} />
          <IntegerField
            label="Paleta [szt.]"
            value={values.pallet}
            onChange={handlers.onPalletChange}
            css={{ width: "100%" }}
            isNullable
          />
        </div>
        <div css={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: "0.625rem", width: "100%" }}>
          <IntegerField
            label="Warstwa [szt.]"
            value={values.layer}
            onChange={handlers.onLayerChange}
            css={{ width: "100%" }}
            isNullable
          />
          <IntegerField
            label="Karton [szt.]"
            value={values.box}
            onChange={handlers.onBoxChange}
            css={{ width: "100%" }}
            isNullable
          />
        </div>
      </div>
    </div>
  );
};
