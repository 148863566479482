import { Uuid, uuidSerializer } from "@legacy-megarax/common";
import { SortFilter } from "@legacy-megarax/rest-resource";
import { Serializers } from "@legacy-megarax/serializers";
import Decimal from "decimal.js";

import { CustomerReference, DeliveryAddress, Gtin, PriceListReference } from "../common";

export interface PlaceOrderInput {
  deliveryAddress: DeliveryAddress;
  deliveryDeadline: Date;
  externalOrderNumber: string;
  comment: string;
}

export type MyDraftListFilters = {
  searchText?: string;
  sortBy?: SortFilter<MyOrderSortField>[];
  limit?: number;
  offset?: number;
};

export type MyDraftSimpleCustomer = {
  uuid: string;
  name?: string;
};

export type MyDraftSimple = {
  uuid: string;
  buyer: MyDraftSimpleCustomer;
  payer: MyDraftSimpleCustomer;

  placedAt: Date | null;
  draftStartedAt: Date;

  isPlaced: boolean;
};

export interface UpdateLineInput {
  quantity: number;
  promoPrice: Decimal | null;
}

export interface AddLineInput {
  lineUuid: string;
  item: { gtin: Gtin };
  quantity: number;
  promoPrice: Decimal | null;
}

export interface StartDraftInput {
  uuid: string;
  payerDiscountRate: Decimal;
  buyer: CustomerReference;
  payer: CustomerReference;
  recipient: CustomerReference;
  priceList: PriceListReference;
}

export const myOrderSortFields = ["placedAt"] as const;
export type MyOrderSortField = (typeof myOrderSortFields)[number];

export type CreateCopyInput = {
  uuid: Uuid;
  templateUuid: Uuid;
};

export const createCopyInputSerializer = Serializers.object<CreateCopyInput>({
  uuid: uuidSerializer,
  templateUuid: uuidSerializer,
});

export type EditCustomersInput = {
  sellToUuid: Uuid;
  billToUuid: Uuid;
  payerDepartmentUuid: Uuid;
  payerDiscountRate: Decimal;
  priceListUuid: Uuid;
};

export const editCustomersInputSerializer = Serializers.object<EditCustomersInput>({
  sellToUuid: uuidSerializer,
  billToUuid: uuidSerializer,
  payerDepartmentUuid: uuidSerializer,
  payerDiscountRate: Serializers.decimal,
  priceListUuid: uuidSerializer,
});
