import { Dialog, useDialogRoute } from "@dash/dialog";
import { Button } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { RowCellContent, Table, TableBody, TableHeadColumn } from "@dash/table";
import { useTheme } from "@emotion/react";
import Decimal from "decimal.js";
import { BsFillBoxFill } from "react-icons/bs";
import { IconType } from "react-icons/lib";
import { MdLayers, MdPallet } from "react-icons/md";

import { OrderDraftLineDto } from "@megaron/crm-contracts";
import { ItemDoc } from "@megaron/docs-contracts";
import { useClientManager } from "@megaron/react-clients";

type PackType = "pallet" | "layer" | "box" | "piece";

type LinePackQuantities = {
  lineUuid: string;
  itemName: string;
} & Record<PackType, number | null>;

type Props = {
  isDisabled?: boolean;
  lines: OrderDraftLineDto[];
};

export const PackSizeButton: React.FC<Props> = ({ lines, isDisabled }) => {
  const { isMobile } = useDeviceType();

  const itemsQuery = useClientManager("docs").searchItems().useQuery({});

  const theme = useTheme();

  const tableColumns: TableHeadColumn<string>[] = [
    {},
    ...tableHeadingList.map(
      ({ heading, Icon }) =>
        ({
          isSortable: false,
          label: heading,
          element: Icon ? (
            <div>
              <Icon size={16} color={theme.colors.primary} />
            </div>
          ) : (
            <strong>{heading}</strong>
          ),
        } as const),
    ),
  ];

  const getTableRowCellsContent = (line: LinePackQuantities): RowCellContent[] => {
    return [
      {
        element: <span css={{ textWrap: "nowrap" }}>{line.itemName}</span>,
      },
      {
        element: line.pallet ?? "-",
      },
      {
        element: line.layer ?? "-",
      },
      {
        element: line.box ?? "-",
      },
      {
        element: line.piece,
      },
    ];
  };

  const packSizeDialog = useDialogRoute("/pack-sizes", ({ onClose }) => {
    const linesPackQuantities = parseLinesToPackQuantities(lines, itemsQuery.data?.items ?? []);

    const summaryRow = {
      uuid: "total",
      cellsContent: [
        {
          element: "Razem",
          cellCss: { fontWeight: 700, color: theme.colors.primary },
        },
        {
          element: getSummaryForPackSize(linesPackQuantities, "pallet"),
          cellCss: { fontWeight: 700, color: theme.colors.primary },
        },
        {
          element: getSummaryForPackSize(linesPackQuantities, "layer"),
          cellCss: { fontWeight: 700, color: theme.colors.primary },
        },
        {
          element: getSummaryForPackSize(linesPackQuantities, "box"),
          cellCss: { fontWeight: 700, color: theme.colors.primary },
        },
        {
          element: getSummaryForPackSize(linesPackQuantities, "piece"),
          cellCss: { fontWeight: 700, color: theme.colors.primary },
        },
      ],
      css: {
        "td:first-child": {
          padding: "0.75rem",
        },
      },
    };

    const rows =
      linesPackQuantities.length > 0
        ? [
            ...linesPackQuantities.map((line) => ({
              uuid: line.lineUuid,
              cellsContent: getTableRowCellsContent(line),
              css: {
                "td:first-child": {
                  padding: "0.75rem",
                },
              },
            })),
            summaryRow,
          ]
        : [
            {
              uuid: "emptyRow",
              cellsContent: [
                {
                  element: <span css={{ textWrap: "nowrap" }}>Brak</span>,
                },
                {
                  element: "",
                },
                {
                  element: "",
                },
                {
                  element: "",
                },
                {
                  element: "",
                },
              ],
              css: {
                "td:first-child": {
                  padding: "0.75rem",
                },
              },
            },
            summaryRow,
          ];

    return (
      <Dialog
        onClose={onClose}
        header="Współczynniki paletowe"
        css={{ width: isMobile ? "100%" : "400px", minHeight: "unset" }}
        placement={isMobile ? "top" : "center"}
      >
        <div css={{ overflow: "auto", margin: "0 -1rem", padding: "0 1rem", maxHeight: "70dvh" }}>
          <Table columns={tableColumns}>
            <TableBody rows={rows} />
          </Table>
        </div>
      </Dialog>
    );
  });

  return (
    <>
      <Button variant="outline" icon={<MdLayers />} onClick={packSizeDialog.open} isDisabled={isDisabled}>
        {isMobile ? "" : "Współczynniki"}
      </Button>
      {packSizeDialog.element}
    </>
  );
};

const tableHeadingList: { heading: string; Icon?: IconType }[] = [
  { heading: "Paleta", Icon: MdPallet },
  { heading: "Warstwa", Icon: MdLayers },
  { heading: "Karton", Icon: BsFillBoxFill },
  { heading: "szt." },
];

const parseLinesToPackQuantities = (lines: OrderDraftLineDto[], items: ItemDoc[]): LinePackQuantities[] => {
  return lines.map((line) => {
    const item = items.find((item) => item.gtin === line.item.gtin);

    if (!item)
      return {
        lineUuid: line.uuid,
        itemName: "Nieznany",
        pallet: null,
        layer: null,
        box: null,
        piece: 0,
      };

    return {
      lineUuid: line.uuid,
      itemName: item.itemName,
      pallet: item.pallet ? Number((line.quantity / item.pallet).toFixed(2)) : null,
      layer: item.layer ? Number((line.quantity / item.layer).toFixed(2)) : null,
      box: item.box ? Number((line.quantity / item.box).toFixed(2)) : null,
      piece: line.quantity,
    };
  });
};

const getSummaryForPackSize = (lines: LinePackQuantities[], packType: PackType) => {
  return Number(
    lines
      .map((line) => line[packType])
      .reduce((acc, curr) => {
        if (!curr) {
          return acc;
        }

        return acc.plus(curr);
      }, new Decimal(0))
      .toFixed(2),
  );
};
