import { Dialog } from "@dash/dialog";
import { Button, TextField } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { useState } from "react";
import { MdCheck, MdOutlineDelete } from "react-icons/md";

import { AnalyticsPresetColumn } from "./analyticsPreset";

type Props = {
  onClose: () => void;
  name: string;
  onDelete: () => void;
  onSave: (name: string, options: { newName: string }) => void;
  columns: AnalyticsPresetColumn[];
};

export const ColumnSettingsDialog: React.FC<Props> = ({ onClose, name, onDelete, onSave, columns }) => {
  const { isMobile } = useDeviceType();

  const [columnName, setColumnName] = useState<string>(name);

  const [columnNameError, setColumnNameError] = useState<string | null>(null);

  const handleSave = () => {
    if (!columnName) {
      setColumnNameError("Nazwa kolumny jest wymagana.");
      return;
    }

    if (columnName !== name) {
      const isNameUnique = !columns.find((column) => column.header === columnName);

      if (!isNameUnique) {
        setColumnNameError("Istnieje już kolumna o takiej nazwie.");
        return;
      }
    }

    setColumnNameError(null);

    onSave(name, { newName: columnName });
    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      header="Ustawienia kolumny"
      placement={isMobile ? "top" : "center"}
      css={{ width: isMobile ? "100%" : "400px" }}
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
        <TextField label="Nazwa kolumny" value={columnName} onChange={setColumnName} error={columnNameError} />
        <div
          css={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            justifyContent: "space-between",
            marginTop: "0.25rem",
          }}
        >
          {onDelete && (
            <Button
              onClick={() => {
                onDelete();
                onClose();
              }}
              icon={<MdOutlineDelete />}
              color="danger"
            >
              Usuń
            </Button>
          )}
          <Button onClick={handleSave} icon={<MdCheck />} css={{ marginLeft: "auto" }}>
            Zatwierdź
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
