import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const productAvailabiliyStatuses = ["unavailable", "available"] as const;
export type ProductAvailabiliyStatus = (typeof productAvailabiliyStatuses)[number];

export const addProductAvailabilitSerializer = Serializers.object({
  itemId: Serializers.string,
  status: Serializers.stringOneOf(...productAvailabiliyStatuses),
  posId: uuidSerializer,
  date: Serializers.datetime,
});

export type ProductAvailabiliy = SerializerValue<typeof addProductAvailabilitSerializer>;

export const allProductAvailabilitSerializer = Serializers.object({
  itemId: Serializers.string,
  itemName: Serializers.string,
  status: Serializers.stringOneOf(...productAvailabiliyStatuses),
  posName: Serializers.string,
  date: Serializers.datetime,
});

export type ProductAvailabiliyListObject = SerializerValue<typeof allProductAvailabilitSerializer>;

export const productAvailabilitSerializer = Serializers.object({
  itemId: Serializers.string,
  itemName: Serializers.string,
  status: Serializers.stringOneOf(...productAvailabiliyStatuses),
  posName: Serializers.string,
  latitude: Serializers.decimal.nullable(),
  longitude: Serializers.decimal.nullable(),
  date: Serializers.datetime,
});

export const productAvailabiliy = {
  productAvailabilityQuery: HttpAction({
    path: "/productAvailability/:itemId",
    method: "get",
    paramsSerializer: Serializers.object({
      itemId: Serializers.string,
    }),
    requiresAuth: true as const,
    valueSerializer: productAvailabilitSerializer.array(),
  }),

  customerProductsAvailabilityQuery: HttpAction({
    path: "/productAvailability/customer/:customerId",
    method: "get",
    paramsSerializer: Serializers.object({
      customerId: uuidSerializer,
    }),
    requiresAuth: true as const,
    valueSerializer: productAvailabilitSerializer.array(),
  }),

  allProductsAvailabilityQuery: HttpAction({
    path: "/allProductsAvailabilityQuery",
    method: "get",
    requiresAuth: true as const,

    qsSerializer: Serializers.object({
      pos: Serializers.jsonString(Serializers.array(uuidSerializer)),
      itemIds: Serializers.jsonString(Serializers.array(Serializers.string)),
      status: Serializers.jsonString(Serializers.array(Serializers.stringOneOf(...productAvailabiliyStatuses))),
      limit: Serializers.integer,
      offset: Serializers.integer,
    }).partial(),
    valueSerializer: Serializers.object({
      items: productAvailabilitSerializer.array(),
      totalCount: Serializers.integer,
    }),
  }),

  addProductAvailability: HttpAction({
    path: "/productAvailability",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: addProductAvailabilitSerializer,
    valueSerializer: addProductAvailabilitSerializer,
  }),
};
