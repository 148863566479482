import { useDialogRoute } from "@dash/dialog";
import { Page } from "@dash/page";
import { QuerySkeleton } from "@dash/skeleton";
import { useEffect, useState } from "react";

import { AggType, CustomerAnalyticsEntry, CustomerCategory, CustomerStatus } from "@megaron/crm-contracts";
import { Uuid } from "@megaron/uuid";

import {
  AggOptions,
  AnalyticsPreset,
  AnalyticsPresetColumn,
  AnalyticsPresetCustomColumn,
  usePresetAnalyticsQuery,
} from "./analyticsPreset";
import { CustomerAnalyticsFiltersBar } from "./CustomerAnalyticsFiltersBar";
import { CustomerAnalyticsFiltersDialog } from "./CustomerAnalyticsFiltersDialog";
import { CustomerAnalyticsTable } from "./CustomerAnalyticsTable";
import { InteractionsAggDialog } from "./InteractionsAggDialog";
import { SalesAggDialog } from "./SalesAggDialog";
import { TimeUnit } from "./TimeRangeSelect";
import { useCustomerAnalyticsGlobalFilters } from "./useCustomerAnalyticsGlobalFilters";

type Props = {
  preset: AnalyticsPreset;
  updatePreset: (preset: AnalyticsPreset) => void;
};

export const CustomerAnalyticsView: React.FC<Props> = ({ preset, updatePreset }) => {
  const [isSalesAggDialogOpen, setIsSalesAggDialogOpen] = useState(false);
  const [activeSalesAggName, setActiveSalesAggName] = useState<string | undefined>(undefined);

  const [isInteractionsAggDialogOpen, setIsInteractionsAggDialogOpen] = useState(false);
  const [activeInteractionsAggName, setActiveInteractionsAggName] = useState<string | undefined>(undefined);

  const { filters, setFilter, setFilters, isPresetUpdatedOnMount } = useCustomerAnalyticsGlobalFilters(
    preset,
    updatePreset,
  );

  const filterHandlers = {
    onCategoriesChange: (v: CustomerCategory[] | undefined) => {
      setFilter("category")(v?.length ? v : undefined);
      updatePreset({
        ...preset,
        filters: [{ ...filters, category: v?.length ? v : undefined }],
      });
    },
    onRegionsChange: (v: string[] | undefined) => {
      setFilters({ ...filters, isMyRegion: false, region: v?.length ? v : undefined });
      updatePreset({
        ...preset,
        filters: [{ ...filters, isMyRegion: false, region: v?.length ? v : undefined }],
      });
    },
    onStatusChange: (v: CustomerStatus[] | undefined) => {
      setFilter("status")(v?.length ? v : undefined);
      updatePreset({
        ...preset,
        filters: [{ ...filters, status: v?.length ? v : undefined }],
      });
    },
    onCustomerIdChange: (v: Uuid[] | undefined) => {
      setFilter("customerId")(v?.length ? v : undefined);
      updatePreset({
        ...preset,
        filters: [{ ...filters, customerId: v?.length ? v : undefined }],
      });
    },
    onDateChange: (
      startDate: Date | null,
      endDate: Date | null,
      timeUnit: TimeUnit | null,
      unitCount: number | null,
      activeOptionName: string | null,
    ) => {
      setFilters({
        ...filters,
        timeStart: startDate ?? undefined,
        timeEnd: endDate ?? undefined,
        timeUnit: timeUnit ?? undefined,
        unitCount: unitCount ?? undefined,
        selectedTimeName: activeOptionName ?? undefined,
      });
      updatePreset({
        ...preset,
        filters: [
          {
            ...filters,
            timeStart: startDate ?? undefined,
            timeEnd: endDate ?? undefined,
            timeUnit: timeUnit ?? undefined,
            unitCount: unitCount ?? undefined,
            selectedTimeName: activeOptionName ?? undefined,
          },
        ],
        aggsOptions: preset.aggsOptions.map((agg) => ({
          ...agg,
          timeStart: startDate ?? undefined,
          timeEnd: endDate ?? undefined,
          timeUnit: undefined,
          unitCount: undefined,
          selectedTimeName: "default",
        })),
      });
    },
    onProductGroupsChange: (v: string[] | undefined) => {
      setFilter("productGroups")(v?.length ? v : undefined);
      updatePreset({
        ...preset,
        filters: [{ ...filters, productGroups: v?.length ? v : undefined }],
        aggsOptions: preset.aggsOptions.map((agg) => {
          if (agg.type !== "sales") {
            return agg;
          }

          return {
            ...agg,
            productGroup: v?.length ? v : undefined,
            isProductGroupsDefault: true,
          };
        }),
      });
    },
    onIsMyRegionChange: (v: boolean | undefined) => {
      setFilter("isMyRegion")(v);
      updatePreset({
        ...preset,
        filters: [{ ...filters, isMyRegion: v }],
      });
    },
  };

  const handleAddAgg = (aggOptions: AggOptions) => {
    updatePreset({
      ...preset,
      aggsOptions: [...preset.aggsOptions, aggOptions],
    });
  };

  const handleUpdateAgg = (aggName: string, aggOptions: AggOptions) => {
    updatePreset({
      ...preset,
      aggsOptions: preset.aggsOptions.map((agg) => {
        if (agg.aggName === aggName) {
          return aggOptions;
        }

        return agg;
      }),
    });
  };

  const handleDeleteAgg = (aggName: string) => {
    updatePreset({
      ...preset,
      aggsOptions: preset.aggsOptions.filter((agg) => agg.aggName !== aggName),
    });
  };

  const handleAddColumn = (column: AnalyticsPresetColumn) => {
    updatePreset({
      ...preset,
      columns: [...preset.columns, column],
    });
  };

  const handleAddCustomColumn = ({
    customColumn,
    column,
  }: {
    customColumn: AnalyticsPresetCustomColumn;
    column: AnalyticsPresetColumn;
  }) => {
    updatePreset({
      ...preset,
      customColumns: [...(preset.customColumns ?? []), customColumn],
      columns: [...preset.columns, column],
    });
  };

  const handleEditColumn = (columnName: string, options: { newName: string }) => {
    updatePreset({
      ...preset,
      columns: preset.columns.map((column) => {
        if (column.header === columnName) {
          return {
            ...column,
            header: options.newName,
          };
        }

        return column;
      }),
    });
  };

  const handleDeleteColumn = (columnName: string) => {
    updatePreset({
      ...preset,
      columns: preset.columns.filter((column) => column.header !== columnName),
    });
  };

  const handleColumnSort = (columns: AnalyticsPresetColumn[]) => {
    updatePreset({
      ...preset,
      columns,
    });
  };

  const filtersDialog = useDialogRoute("/add-filter", ({ onClose }) => (
    <CustomerAnalyticsFiltersDialog
      onClose={onClose}
      filters={filters}
      handlers={filterHandlers}
      onAddAgg={handleAddAgg}
      onUpdateAgg={handleUpdateAgg}
      preset={preset}
    />
  ));

  const { customerAnalyticsInfiniteQuery } = usePresetAnalyticsQuery(preset, { enabled: isPresetUpdatedOnMount });

  const [definedCustomerAnalyticsData, setDefinedCustomerAnalyticsData] =
    useState<(typeof customerAnalyticsInfiniteQuery)["data"]>();

  useEffect(() => {
    if (customerAnalyticsInfiniteQuery.data) {
      setDefinedCustomerAnalyticsData(customerAnalyticsInfiniteQuery.data);
    }
  }, [customerAnalyticsInfiniteQuery.data]);

  const parsedCustomerAnalyticsQuery = { ...customerAnalyticsInfiniteQuery, data: definedCustomerAnalyticsData };

  return (
    <Page
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        maxWidth: "100%",
        maxHeight: "calc(100dvh - 80px)",
        marginTop: "1rem",
      }}
    >
      <CustomerAnalyticsFiltersBar
        filters={filters}
        handlers={filterHandlers}
        handleAddFiltersClick={filtersDialog.open}
        aggs={preset.aggsOptions.map((agg) => ({
          type: agg.type,
          name: agg.name,
          onClick: () => {
            if (agg.type === "sales") {
              setIsSalesAggDialogOpen(true);
              setActiveSalesAggName(agg.aggName);
              return;
            }

            if (agg.type === "interactions") {
              setIsInteractionsAggDialogOpen(true);
              setActiveInteractionsAggName(agg.aggName);
              return;
            }
          },
        }))}
      />

      <QuerySkeleton query={parsedCustomerAnalyticsQuery}>
        {(analytics) => (
          <CustomerAnalyticsTable
            customersAnalytics={
              analytics.pages.flatMap((page) => page.value).filter(Boolean) as CustomerAnalyticsEntry[]
            }
            isLoading={!customerAnalyticsInfiniteQuery.data || customerAnalyticsInfiniteQuery.isFetching}
            preset={preset}
            activeSortValue={filters.sort}
            onSortChange={(v) => {
              setFilter("sort")(v);
              updatePreset({
                ...preset,
                sortBy: {
                  agg: v?.agg ?? null,
                  customColumn: v?.customColumn ?? null,
                  fieldName: v?.fieldName ?? "name",
                  order: v?.order ?? "ASC",
                },
                filters: [{ ...filters, sort: v }],
              });
            }}
            onNextPageReach={() => {
              customerAnalyticsInfiniteQuery.fetchNextPage();
            }}
            onCreateAggClick={(aggType: AggType) => {
              if (aggType === "sales") {
                setIsSalesAggDialogOpen(true);
                return;
              }

              if (aggType === "interactions") {
                setIsInteractionsAggDialogOpen(true);
                return;
              }
            }}
            onAddColumn={handleAddColumn}
            onAddCustomColumn={handleAddCustomColumn}
            onEditColumn={handleEditColumn}
            onDeleteColumn={handleDeleteColumn}
            onColumnSort={handleColumnSort}
          />
        )}
      </QuerySkeleton>

      {isSalesAggDialogOpen && (
        <SalesAggDialog
          onClose={() => setIsSalesAggDialogOpen(false)}
          aggName={activeSalesAggName}
          onDelete={activeSalesAggName ? () => handleDeleteAgg(activeSalesAggName) : undefined}
          analyticsPreset={preset}
          onAddAgg={handleAddAgg}
          onUpdateAgg={handleUpdateAgg}
        />
      )}
      {isInteractionsAggDialogOpen && (
        <InteractionsAggDialog
          onClose={() => setIsInteractionsAggDialogOpen(false)}
          aggName={activeInteractionsAggName}
          onDelete={activeInteractionsAggName ? () => handleDeleteAgg(activeInteractionsAggName) : undefined}
          analyticsPreset={preset}
          onAddAgg={handleAddAgg}
          onUpdateAgg={handleUpdateAgg}
        />
      )}
      {filtersDialog.element}
    </Page>
  );
};
