import { Uuid } from "@legacy-megarax/common";
import { groupResource } from "@legacy-megarax/crm-contracts";
import { useResourceProviderV2 } from "@legacy-megarax/react-client";
import { commonErrorsMap, useSnackbarErrorHandler } from "@legacy-megarax/ui-components";

import { Failure, Ok, Result } from "@megaron/result";

type OnSuccessFunction = (() => void) | (() => Promise<void>);

const voidifyResult = <TValue, TError>(result: Result<TValue, TError>): Result<void, void> =>
  result.flatMapError(() => Failure()).flatMap(() => Ok());

export const useCrmGroupsResource = () => {
  const groupsProvider = useResourceProviderV2(groupResource);

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
  });

  const assignGroups =
    ({ onSuccess }: { onSuccess: OnSuccessFunction }) =>
    (uuid: Uuid, groupUuids: Uuid[]) =>
      groupsProvider
        .byUuid(uuid)
        .assignGroups(undefined, { groupUuids })
        .mapError(handleError)
        .map(onSuccess)
        .then(voidifyResult);

  const retrieveGroup = (regionUuid: Uuid) => groupsProvider.byUuid(regionUuid).retrieve().mapError(handleError);

  const getMyGroups = () => groupsProvider.myGroups().mapError(handleError);

  return {
    assignGroups,
    retrieveGroup,
    getMyGroups,
  };
};
