import { Button } from "@dash/form";
import { useToast } from "@dash/toast";
import { parse } from "csv/browser/esm/sync";
import { useState } from "react";
import { MdOutlineUploadFile } from "react-icons/md";

import { SavePostSaleDiscountResponseValue } from "@megaron/crm-contracts";
import { useClientManager } from "@megaron/react-clients";

import { ImportedPostSaleDiscountsDialog } from "./ImportedPostSaleDiscountsDialog";
import { rawToInput } from "./input";

type Props = {
  onImportSuccess: () => void;
};

export const ImportPostSaleDiscountsButton: React.FC<Props> = ({ onImportSuccess }) => {
  const toast = useToast();

  const savePostSaleDiscountMutation = useClientManager("crm").savePostSaleDiscounts().useMutation();

  const [responseValue, setResponseValue] = useState<SavePostSaleDiscountResponseValue | null>(null);
  const [isImportedPostSalesDiscountsDialogOpen, setIsImportedPostSalesDiscountsDialogOpen] = useState(false);

  const onFileUpload: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;
    const parsed: any[] = parse(await file.text(), {
      columns: true,
    });

    const inputRowsResult = rawToInput(parsed);
    if (inputRowsResult.isFailure) {
      if (inputRowsResult.error === "InvalidCustomerName") {
        toast.error("Nieprawidłowa nazwa klienta");
      }

      if (inputRowsResult.error === "InvalidDiscountRate") {
        toast.error("Nieprawidłowy rabat posprzedażowy");
      }

      if (inputRowsResult.error === "MissingCustomerName") {
        toast.error("Brak nazwy klienta");
      }

      if (inputRowsResult.error === "MissingPostSaleDiscount") {
        toast.error("Brak rabatu posprzedażowego");
      }

      if (inputRowsResult.error === "InvalidInput") {
        toast.error("Nieprawidłowy format danych");
      }

      toast.error(inputRowsResult.error);
      return;
    }

    savePostSaleDiscountMutation.mutate(inputRowsResult.value, {
      onSuccess: (data) => {
        setResponseValue(data);
        setIsImportedPostSalesDiscountsDialogOpen(true);
        onImportSuccess();
        toast.info("Zaimportowano rabaty posprzedażowe");
      },
      onError: () => {
        setResponseValue(null);
        toast.error("Błąd podczas importowania rabatów posprzedażowych");
      },
    });
  };

  return (
    <>
      <input accept=".csv" type="file" id="contained-button-file" style={{ display: "none" }} onChange={onFileUpload} />
      <label htmlFor="contained-button-file">
        <Button variant="outline" icon={<MdOutlineUploadFile />} as="div">
          Importuj
        </Button>
      </label>
      {responseValue && isImportedPostSalesDiscountsDialogOpen && (
        <ImportedPostSaleDiscountsDialog
          onClose={() => setIsImportedPostSalesDiscountsDialogOpen(false)}
          responseValue={responseValue}
        />
      )}
    </>
  );
};
