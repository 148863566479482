import { Uuid, uuidSerializer } from "@legacy-megarax/common";
import { MSerializers } from "@legacy-megarax/serializers";

import { ThreadIdentifier, threadIdentifierStringSerializer } from "./threadIdentifier";

export type ThreadDto = {
  identifier: ThreadIdentifier;

  messages: MessageDto[];
  participants: ParticipantDto[];
};

export type MessageDto = {
  uuid: Uuid;
  content: string;
  userId: number;
  postedAt: Date;
  mentions: MentionDto[];
};

export type MentionDto = {
  uuid: Uuid;
  mentionedUser: number;
  mentionStart: number;
  mentionEnd: number;
};

export type ParticipantDto = {
  userId: number;
  isSubscribed: boolean;
  readMessageCount: number;
};

export const mentionDtoSerializer = MSerializers.object<MentionDto>({
  uuid: uuidSerializer,
  mentionStart: MSerializers.integer,
  mentionEnd: MSerializers.integer,
  mentionedUser: MSerializers.integer,
});

export const messageDtoSerializer = MSerializers.object<MessageDto>({
  uuid: uuidSerializer,
  postedAt: MSerializers.datetime,
  userId: MSerializers.integer,
  content: MSerializers.string,
  mentions: MSerializers.array(mentionDtoSerializer),
});

export const participantDtoSerializer = MSerializers.object<ParticipantDto>({
  isSubscribed: MSerializers.boolean,
  readMessageCount: MSerializers.integer,
  userId: MSerializers.integer,
});

export const threadDtoSerializer = MSerializers.object<ThreadDto>({
  identifier: threadIdentifierStringSerializer,
  messages: MSerializers.array(messageDtoSerializer),
  participants: MSerializers.array(participantDtoSerializer),
});
