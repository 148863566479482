import { Dialog } from "@dash/dialog";
import { Button, Checkbox, DateField, TextField } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { Spinner } from "@dash/spinner";
import { useToast } from "@dash/toast";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { CustomerAddress } from "@megaron/crm-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

type Props = {
  onClose: () => void;
  customerAddress?: CustomerAddress;
  earliestDeliveryDate?: Date;
  draftUuid: string;
};

export const OrderSummaryDialog: React.FC<Props> = ({ onClose, customerAddress, earliestDeliveryDate, draftUuid }) => {
  const { isMobile } = useDeviceType();

  const toast = useToast();

  const navigate = useNavigate();

  const theme = useTheme();

  const [deliveryDate, setDeliveryDate] = useState<Date | null>(earliestDeliveryDate ?? null);
  const [useCustomerAddress, setUseCustomerAddress] = useState(false);
  const [useOtherAddress, setUseOtherAddress] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");
  const [note, setNote] = useState("");

  const [street, setStreet] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("Polska");

  const placeOrderMutation = useClientManager("crm").placeOrder().useMutation();

  const handleSendDraft = () => {
    if (placeOrderMutation.isLoading) {
      return;
    }

    if (!deliveryDate) {
      toast.error("Wybierz datę dostawy.");
      return;
    }

    const address: CustomerAddress | null = useCustomerAddress
      ? {
          country: customerAddress?.country ?? "",
          city: customerAddress?.city ?? "",
          postalCode: customerAddress?.postalCode ?? "",
          street: customerAddress?.street ?? "",
          googlePlaceId: customerAddress?.googlePlaceId ?? null,
          latitude: customerAddress?.latitude ?? null,
          longitude: customerAddress?.longitude ?? null,
        }
      : useOtherAddress
      ? {
          street,
          postalCode,
          city,
          country,
          googlePlaceId: null,
          latitude: null,
          longitude: null,
        }
      : null;

    if (!address) {
      toast.error("Wybierz adres dostawy.");
      return;
    }

    placeOrderMutation.mutate(
      {
        deliveryAddress: address,
        deliveryDeadline: deliveryDate,
        uuid: Uuid(draftUuid),
        externalOrderNumber: orderNumber,
        comment: note,
      },
      {
        onSuccess: () => {
          navigate(`/crm/order-drafts`, { replace: true });
        },
        onError: (error) => {
          if (error === "DraftNotOpen") {
            toast.error("Wersja robocza nie posiada statusu: otwarta.");
          }

          if (error === "ItemNotFound") {
            toast.error("Przedmiot z zamówienia nie istnieje.");
          }

          if (error === "OrderNotFound") {
            toast.error("Nie znaleziono zamówienia.");
          }

          if (error === "PriceListNotFound") {
            toast.error("Nie znaleziono cennika.");
          }

          if (error === "UserNotOrderOwner") {
            toast.error("Nie jesteś właścicielem zamówienia.");
          }
        },
      },
    );
  };

  return (
    <Dialog
      onClose={onClose}
      css={{
        width: isMobile ? "100%" : "400px",
      }}
      placement={isMobile ? "top" : "center"}
      header="Podsumowanie zamówienia"
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "1rem", maxHeight: "70dvh", overflow: "auto" }}>
        <DateField
          label="Data dostawy"
          value={deliveryDate}
          onChange={setDeliveryDate}
          minDate={earliestDeliveryDate}
        />
        <div css={{ gap: "0.5rem", display: "flex", flexDirection: "column" }}>
          <span css={{ fontSize: "0.875rem", fontFamily: theme.displayFontFamily, color: theme.colors.primary }}>
            Użyj adresu nabywcy:
          </span>
          <Checkbox
            label={parseCustomerAddressToString(customerAddress)}
            value={useCustomerAddress}
            onChange={(v) => {
              if (v) {
                setUseOtherAddress(false);
              }

              setUseCustomerAddress(v);
            }}
          />
        </div>
        <div css={{ gap: "0.5rem", display: "flex", flexDirection: "column" }}>
          <span css={{ fontSize: "0.875rem", fontFamily: theme.displayFontFamily, color: theme.colors.primary }}>
            lub wpisz inny:
          </span>
          <div css={{ display: "flex", gap: "0.5rem", alignItems: "flex-start" }}>
            <Checkbox
              value={useOtherAddress}
              onChange={(v) => {
                if (v) {
                  setUseCustomerAddress(false);
                }

                setUseOtherAddress(v);
              }}
              css={{ marginTop: "1.5rem" }}
            />
            <div
              css={{ display: "flex", flexDirection: "column", gap: "0.675rem", width: "100%" }}
              onClick={() => {
                if (!useOtherAddress) {
                  setUseOtherAddress(true);
                  setUseCustomerAddress(false);
                }
              }}
            >
              <TextField label="Ulica" value={street} onChange={setStreet} />
              {useOtherAddress && (
                <>
                  <div css={{ display: "flex", gap: "0.675rem" }}>
                    <TextField
                      label="Kod pocztowy"
                      value={postalCode}
                      onChange={setPostalCode}
                      css={{ maxWidth: "100px" }}
                    />
                    <TextField label="Miasto" value={city} onChange={setCity} />
                  </div>
                  <TextField label="Kraj" value={country} onChange={setCountry} />
                </>
              )}
            </div>
          </div>

          <hr css={{ width: "100%", height: "1px", background: "rgba(0, 0, 0, 0.1)", border: "none" }} />

          <TextField label="Numer zamówienia klienta (opcjonalny)" value={orderNumber} onChange={setOrderNumber} />
          <TextField label="Notatka" value={note} onChange={setNote} multiline />
        </div>

        <div css={{ display: "flex", justifyContent: "space-between" }}>
          <Button variant="outline" onClick={onClose}>
            Anuluj
          </Button>
          <Button onClick={handleSendDraft}>
            <div css={{ display: "flex", alignItems: "center", gap: "0.625rem" }}>
              Wyślij
              {placeOrderMutation.isLoading && <Spinner size="16px" color="white" />}
            </div>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

const parseCustomerAddressToString = (address?: CustomerAddress) => {
  if (!address || Object.values(address).every((value) => !value)) return "-";

  return `${address.street}${address.postalCode ? (address.street ? ", " + address.postalCode : address.city) : ""}${
    address.city
      ? address.street || address.postalCode
        ? address.postalCode
          ? " " + address.city
          : ", " + address.city
        : address.postalCode
      : ""
  }${address.country ? ", " + address.country : ""}`;
};
