import { FilterList, MonthRangeFilter, MultiSelectSimpleFilter, SelectFilter, Sort } from "@dash/filters";

import { OrderStatus } from "@megaron/crm-contracts";
import { OrderSortField } from "@megaron/docs-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Month, Serializers } from "@megaron/serializers";

import { Filters } from "./OrdersPage";

type OrderType = "order" | "creditMemo";

export type SortFilterValue =
  | Serializers.SortFilter<"referenceNumber" | "sellToName" | "billToName" | "orderDate" | "createdAt" | "updatedAt">
  | undefined;

type Props = {
  filters: Filters;
  handlers: {
    onRegionsChange: (regions: string[] | undefined) => void;
    onStatusChange: (status: OrderStatus[] | undefined) => void;
    onTypeChange: (type: OrderType[] | undefined) => void;
    onSortChange: (sort: { field: OrderSortField; order?: "ASC" | "DESC" | undefined } | undefined) => void;
    onDatesChange: (startDate: Month | undefined, endDate: Month | undefined) => void;
  };
};

export const OrdersFilters: React.FC<Props> = ({ filters, handlers }) => {
  const regionsQuery = useClientManager("crm").regionsQuery().useQuery({});

  const regionsOptions = regionsQuery.data
    ? regionsQuery.data.items.map((region) => ({
        label: region.name,
        value: region.name,
      }))
    : [];

  return (
    <FilterList>
      <MultiSelectSimpleFilter
        label={"Status"}
        values={filters.status || []}
        options={orderStatusList}
        onChange={(values: OrderStatus[]) => handlers.onStatusChange(values.length > 0 ? values : undefined)}
      />

      <MultiSelectSimpleFilter
        label={"Typ"}
        values={filters.type || []}
        options={orderTypeList}
        onChange={(values: OrderType[]) => handlers.onTypeChange(values.length > 0 ? values : undefined)}
      />

      <SelectFilter
        label="Regiony"
        initiallySelectedValues={filters.regions ?? []}
        options={regionsOptions ?? []}
        onSelectedChange={(options) =>
          handlers.onRegionsChange(options.length > 0 ? options.map((option) => option.value) : undefined)
        }
        variant="multi-select"
      />

      <Sort
        type="button"
        label={"Sortuj"}
        value={{
          field: filters.sort?.field || "",
          order: filters.sort?.order || "",
        }}
        options={sortOptions}
        onChange={(v) => handlers.onSortChange((v as SortFilterValue) ?? undefined)}
      />

      <MonthRangeFilter
        startDate={filters.startDate}
        endDate={filters.endDate}
        onDatesChange={handlers.onDatesChange}
      />
    </FilterList>
  );
};

const orderStatusList: { label: string; value: OrderStatus }[] = [
  { value: "awaiting", label: "Oczekujące" },
  { value: "fulfilled", label: "Zrealizowane" },
  { value: "cancelled", label: "Anulowane" },
  { value: "inProgress", label: "W realizacji" },
  { value: "suspended", label: "Wstrzymane" },
];

const orderTypeList: { label: string; value: OrderType }[] = [
  { value: "order", label: "Zamówienie" },
  { value: "creditMemo", label: "Korekta" },
];

const sortOptions: { label: string; value: OrderSortField }[] = [
  { label: "Numer zamówienia", value: "referenceNumber" },
  { label: "Nabywca", value: "sellToName" },
  { label: "Odbiorca faktury", value: "billToName" },
  { label: "Data zamówienia", value: "orderDate" },
  { label: "Data utworzenia", value: "createdAt" },
  { label: "Data aktualizacji", value: "updatedAt" },
];
