import { Checkbox, Chip, InputBase, MenuItem, Select } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
import React from "react";

type Props<TOption> = {
  className?: string;
  label: string;
  value: TOption[];
  options: {
    label: string;
    value: TOption;
  }[];
  onChange: (value: TOption[]) => void;
  style?: {
    opaque?: boolean;
  };
};

export const MultiSelectChip = <TOption extends string | number>({
  value,
  className,
  label,
  options,
  onChange,
  style,
}: React.PropsWithChildren<Props<TOption>>): React.ReactElement<any> => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);

  const isSelected = value.length > 0;

  const selectedOptions = options.filter((o) => value.includes(o.value));

  const onDelete = () => {
    onChange([]);
  };

  const chipLabel = isSelected
    ? selectedOptions.length === 1
      ? `${label}: ${selectedOptions[0].label}`
      : `${label} (${selectedOptions.length})`
    : label;

  return (
    <div className={[classes.container, className].join(" ")}>
      <Select
        multiple
        open={isOpen}
        className={classes.select}
        value={value}
        inputProps={{ hidden: true }}
        input={<InputBase />}
        onClose={() => setIsOpen(false)}
        onChange={(e) => onChange(e.target.value as TOption[])}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={selectedOptions.some((o) => o.value === option.value)} />
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <Chip
        className={[classes.chip, style?.opaque && !isSelected ? classes.opaque : ""].join(" ")}
        onClick={() => setIsOpen(!isOpen)}
        clickable
        label={chipLabel}
        color={isSelected ? "primary" : "default"}
        onDelete={isSelected ? onDelete : undefined}
      ></Chip>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  chip: {},
  opaque: {
    backgroundColor: "#ededed",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  container: {
    display: "inline-block",
    verticalAlign: "middle",
  },
  select: {
    visibility: "hidden",
    width: 0,
    height: 0,
    // opacity: 0,
  },
}));
