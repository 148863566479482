import { Uuid, uuidSerializer } from "@legacy-megarax/common";
import { SortFilter, sortFilterSerializer } from "@legacy-megarax/rest-resource";
import { MSerializers } from "@legacy-megarax/serializers";

import { CustomerStatus, customerStatusSerializer } from "../customer/customerStatus";
import { VisitAddress, visitAddressSerialziers } from "./visitAddress";

export type CustomerSimple = {
  uuid: Uuid;

  name: string | null;

  regionUuid: Uuid | null;
  regionName: string | null;

  macroregionUuid: Uuid | null;
  macroregionName: string | null;

  chainUuid: Uuid | null;
  chainName: string | null;

  visitAddress: VisitAddress | null;

  hasPricingRules: boolean;

  lastOrderTime: Date | null;
  lastVisitTime: Date | null;

  tags: string[];

  permanentTradeRoutesCount: number | null;

  status: CustomerStatus | null;
};

export const customerSimpleSerializer = MSerializers.object<CustomerSimple>({
  uuid: uuidSerializer,
  chainName: MSerializers.string.nullable(),
  macroregionName: MSerializers.string.nullable(),
  regionName: MSerializers.string.nullable(),
  name: MSerializers.string.nullable(),
  chainUuid: uuidSerializer.nullable(),
  macroregionUuid: uuidSerializer.nullable(),
  regionUuid: uuidSerializer.nullable(),
  hasPricingRules: MSerializers.boolean,
  visitAddress: visitAddressSerialziers.nullable(),
  lastOrderTime: MSerializers.datetime.nullable(),
  lastVisitTime: MSerializers.datetime.nullable(),
  tags: MSerializers.array(MSerializers.string),
  status: customerStatusSerializer.nullable(),
  permanentTradeRoutesCount: MSerializers.integer.nullable(),
});

export const customerSortFields = [
  "name",
  "chainName",
  "regionName",
  "macroregionName",
  "locality",
  "lastOrderTime",
  "lastVisitTime",
] as const;
export type CustomerSortField = (typeof customerSortFields)[number];

export type CustomerListQueryFilters = {
  limit?: number;
  offset?: number;
  searchText?: string;

  status?: CustomerStatus[];
  regionUuid?: Uuid[];
  macroregionUuid?: Uuid[];
  chainUuid?: Uuid[];
  hasPricingRules?: boolean;
  sortBy?: SortFilter<CustomerSortField>[];
  tags?: string[];
  orderedIn?: number;
  visitedIn?: number;
  isOnRoute?: boolean;
};

export const customerListQueryFiltersSerializer = MSerializers.object<CustomerListQueryFilters>({
  limit: MSerializers.integer.optional(),
  offset: MSerializers.integer.optional(),
  searchText: MSerializers.string.optional(),
  status: MSerializers.array(customerStatusSerializer).optional(),
  chainUuid: MSerializers.array(uuidSerializer).optional(),
  hasPricingRules: MSerializers.booleanString.optional(),
  macroregionUuid: MSerializers.array(uuidSerializer).optional(),
  regionUuid: MSerializers.array(uuidSerializer).optional(),
  sortBy: sortFilterSerializer(...customerSortFields).optional(),
  tags: MSerializers.array(MSerializers.string).optional(),
  orderedIn: MSerializers.integer.optional(),
  visitedIn: MSerializers.integer.optional(),
  isOnRoute: MSerializers.booleanString.optional(),
});

export type MyRegionCustomerListQueryFilters = {
  limit?: number;
  offset?: number;
  searchText?: string;
};

export const listByMyRegionQueryFiltersSerializer = MSerializers.object<MyRegionCustomerListQueryFilters>({
  limit: MSerializers.integer.optional(),
  offset: MSerializers.integer.optional(),
  searchText: MSerializers.string.optional(),
});
