import { Button } from "@dash/form";
import { SectionHeader } from "@dash/page";
import { QuerySkeleton } from "@dash/skeleton";
import { useTheme } from "@emotion/react";
import { formatCurrency } from "@legacy-megarax/react-utils";
import { formatDistanceToNowStrict } from "date-fns";
import { pl } from "date-fns/locale";
import { MdOutlineCancel, MdOutlineCheckCircle, MdOutlineMotionPhotosPaused, MdOutlineSchedule } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom-v5-compat";

import { OrderStatus } from "@megaron/crm-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

type Props = {
  customerUuid: Uuid;
};

export const CustomerLatestOrders: React.FC<Props> = ({ customerUuid }) => {
  const theme = useTheme();

  const navigate = useNavigate();
  const ordersQuery = useClientManager("docs")
    .searchOrders()
    .useQuery({
      sellToId: [customerUuid],
    });

  const goToNewOrderForm = () => {
    navigate(`/crm/order-drafts/new?initialCustomerUuid=${customerUuid}`);
  };

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <SectionHeader isHr actions={<Button onClick={goToNewOrderForm}>Dodaj</Button>}>
        Ostatnie zamówienia
      </SectionHeader>

      <QuerySkeleton query={ordersQuery}>
        {(orders) =>
          orders.items.length > 0 ? (
            <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
              {orders.items.map((order) => (
                <div
                  key={order.id}
                  css={{ display: "flex", alignItems: "center", gap: "0.625rem", justifyContent: "space-between" }}
                >
                  <div css={{ display: "flex", alignItems: "center", gap: "0.625rem" }}>
                    <StatusAvatar status={order.status} />
                    <div css={{ display: "flex", flexDirection: "column" }}>
                      <Link to={`/crm/orders/${order.id}`} css={{ cursor: "pointer", color: theme.colors.primary }}>
                        {order.referenceNumber}
                      </Link>
                      <span css={{ color: theme.colors.secondaryText, fontSize: "0.875rem" }}>
                        {formatDistanceToNowStrict(order.createdAt, { locale: pl, addSuffix: true })}
                      </span>
                    </div>
                  </div>
                  <span>{formatCurrency(order.netTotal, order.currency)}</span>
                </div>
              ))}
            </div>
          ) : (
            <span css={{ color: theme.colors.secondaryText, fontSize: "1rem", marginTop: "-0.5rem" }}>
              Brak zamówień
            </span>
          )
        }
      </QuerySkeleton>
    </div>
  );
};

const StatusAvatar: React.FC<{ status: OrderStatus }> = ({ status }) => {
  const theme = useTheme();

  if (status === "cancelled") return <MdOutlineCancel size={20} color={theme.colors.danger} />;
  if (status === "fulfilled") return <MdOutlineCheckCircle size={20} color={theme.colors.success} />;
  if (status === "suspended") return <MdOutlineMotionPhotosPaused size={20} color={theme.colors.warning} />;

  return <MdOutlineSchedule size={20} color={theme.colors.primary} />;
};
