import { useTheme } from "@emotion/react";
import { MdWarningAmber } from "react-icons/md";
import { Link } from "react-router-dom-v5-compat";

import { Customer } from "./OrderDraftPageContent";

type Props = {
  customer: Customer;
};

export const CustomerAlert = ({ customer }: Props) => {
  const theme = useTheme();

  return (
    <Link
      to={`/crm/customers/id/${customer.uuid}`}
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "0.675rem",
        padding: "1rem",
        borderRadius: theme.smallBorderRadius,
        background: theme.colors.dangerBackground,
        border: `1px solid rgba(0,0,0,0.1)`,
        width: "100%",
        ":hover": {
          textDecoration: "none",
        },
      }}
    >
      <div
        css={{
          display: "flex",
          gap: "0.5rem",
          color: theme.colors.danger,
          fontSize: "1rem",
          alignItems: "center",
          fontWeight: 700,
        }}
      >
        <MdWarningAmber size={18} />
        {customer.name}
      </div>
      <p css={{ margin: 0, fontSize: "0.875rem" }}>{customer.alert}</p>
    </Link>
  );
};
