import { Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

export const useSelectionDialogStyles = makeStyles({
  paper: {
    padding: "0.5rem 1rem",
    boxSizing: "border-box",
  },
  overflow: {
    overflow: "auto",
  },
});

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
