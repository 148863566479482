import { GridProps, InputBase, Paper, SxProps, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { debounce } from "lodash";
import React from "react";
import { FaSearch } from "react-icons/fa";

interface Props extends GridProps {
  onSearchChange: (value: string) => void;
  initialValue?: string;
  width?: string;
  autoFocus?: boolean;
  sx?: SxProps<Theme>;
}

export const SearchBar: React.FC<Props> = ({ onSearchChange, initialValue, width, autoFocus, sx }) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const classes = useStyles();

  const onChange = debounce((e) => onSearchChange(e.target.value), 300);

  return (
    <Paper
      className={`${classes.container} ${isFocused ? classes.containerFocused : ""}`}
      style={{ maxWidth: width, width: width }}
      elevation={isFocused ? 1 : 0}
      sx={{ my: 1, ...sx }}
    >
      <FaSearch className={classes.icon} />
      <InputBase
        defaultValue={initialValue}
        onChange={onChange}
        className={classes.input}
        autoFocus={autoFocus}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder="Szukaj"
      />
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    margin: "0 1rem",
  },
  input: {
    width: "calc(100% - 3rem)",
  },

  container: {
    transitionDuration: "0ms",
    background: theme.palette.grey[200],
    maxWidth: "280px",
    minWidth: "150px",
    width: "100%",
    height: "40px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  containerFocused: {
    background: "unset",
  },
}));
