import { Button } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { HeaderBar, Page } from "@dash/page";
import { useTheme } from "@emotion/react";
import { MdArrowForward, MdOutlineWarning } from "react-icons/md";

type Props = {
  headerBarText: string;
  header: string;
  description: string;
  buttonText: string;
  onButtonClick: () => void;
};

export const ErrorPage: React.FC<Props> = ({ buttonText, description, header, headerBarText, onButtonClick }) => {
  const { isMobile } = useDeviceType();

  const theme = useTheme();

  return (
    <>
      <HeaderBar header={headerBarText} />
      <Page
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          marginBottom: "2rem",
          marginTop: isMobile ? "7rem" : "10rem",
          padding: isMobile ? "0 1rem" : "0 2rem",
          alignItems: "center",
        }}
      >
        <div
          css={{
            display: "flex",
            width: "100%",
            maxWidth: "400px",
            flexDirection: "column",
            gap: "1.5rem",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <MdOutlineWarning size={isMobile ? 64 : 96} color={theme.colors.primaryLight} />
          <h1
            css={{ fontSize: isMobile ? "1.625rem" : "2rem", fontWeight: 700, color: theme.colors.primary, margin: 0 }}
          >
            {header}
          </h1>
          <p css={{ fontSize: "1rem", margin: 0 }}>{description}</p>
          <Button onClick={onButtonClick}>
            <div css={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
              {buttonText}
              <MdArrowForward />
            </div>
          </Button>
        </div>
      </Page>
    </>
  );
};
