import { appLinkTreesObject } from "@megarax/links";

import { AuthAttribute } from "@megaron/access-control";

type AppLinkTreeKeys = keyof typeof appLinkTreesObject;

export const alertsMap: Record<
  string,
  {
    name: string;
    app: AppLinkTreeKeys;
    isApp: boolean;
    isEmail: boolean;
    isWebPush: boolean;
    targetAudianceAttributes: AuthAttribute[];
    links?: string[];
  }
> = {
  invoiceNew: {
    name: "Nowa faktura",
    app: "invoices",
    isApp: false,
    isEmail: false,
    isWebPush: false,
    targetAudianceAttributes: ["userType:megarax"],
    links: ["Moje faktury"],
  },
};

export type AlertType = keyof typeof alertsMap;
export const alertTypes: AlertType[] = Object.keys(alertsMap) as AlertType[];
