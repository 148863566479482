import { Dialog } from "@dash/dialog";
import { useDeviceType } from "@dash/mq";

import { TimeRangeSelect, TimeRangeSelectProps } from "./TimeRangeSelect";

type Props = TimeRangeSelectProps;

export const TimeRangeDialog: React.FC<Props> = ({ onClose, ...props }) => {
  const { isMobile } = useDeviceType();

  return (
    <Dialog
      onClose={onClose}
      header="Wybierz zakres czasu"
      placement={isMobile ? "top" : "center"}
      css={{
        width: isMobile ? "100%" : "414px",
        maxWidth: isMobile ? "380px" : "414px",
        height: isMobile ? "min(calc(100dvh - 180px), 460px)" : "auto",
        overflow: "auto",
      }}
    >
      <TimeRangeSelect onClose={onClose} {...props} />
    </Dialog>
  );
};
