import { Global } from "@emotion/react";
import { Uuid } from "@legacy-megarax/common";
import { SaveAndScheduleRouteInput, ScheduleRouteInput, TradeRouteSimple } from "@legacy-megarax/crm-contracts";
import { FeedbackSnackbarContext } from "@legacy-megarax/ui-components";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RouteIcon from "@mui/icons-material/Route";
import { Box, SwipeableDrawer, Typography, useTheme } from "@mui/material";
import { Dictionary } from "lodash";
import React from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom-v5-compat";
import { v4 } from "uuid";

import { useTradeRoutesResource } from "../../Home";
import { formatGoogleDuration } from "../../utils";
import { QueryFilters, SetQueryFilters, TradeRouteCustomer, TravelData } from "../TradeRouteDetails";
import { DraggableRouteStops } from "./DraggableRouteStops";
import { SelectSalespersonDialog } from "./SalespersonSelect";
import { MobileSpeedDial, SaveButtons } from "./SaveButtons";

interface Props {
  initialTradeRoute: TradeRouteSimple | null;
  isMobile: boolean;
  travelData: TravelData[];
  route: TradeRouteCustomer[];
  salespersonId: number | undefined;
  setRoute: (route: TradeRouteCustomer[]) => void;
  homeSelected: boolean;
  stopDurations: Dictionary<{
    durationMinutes: string;
  }>;
  setStopDurations: (
    durations: Dictionary<{
      durationMinutes: string;
    }>,
  ) => void;
  qs: QueryFilters;
  setQs: SetQueryFilters;
  isEdited: boolean;
}

export const RouteMenu: React.FC<Props> = (props) => {
  const { saveAsPermanent, schedule, saveAndSchedule } = useTradeRoutesResource();
  const snackbarContext = React.useContext(FeedbackSnackbarContext);

  const travelDataSum = props.travelData
    ? props.travelData.reduce(
        (current, sum) => {
          return {
            distance: sum.distance + current.distance,
            duration: sum.duration + current.duration,
          };
        },
        {
          distance: 0,
          duration: 0,
        },
      )
    : undefined;

  const stopDurationsSum = Object.entries(props.stopDurations).reduce((sum, [uuid, duration]) => {
    const parsedDuration = parseFloat(duration.durationMinutes);
    if (Boolean(props.route.find((entry) => entry.uuid === uuid)) && !isNaN(parsedDuration))
      return sum + parsedDuration;
    return sum;
  }, 0);

  const navigate = useNavigate();

  const savePermanent = useMutation(saveAsPermanent, {
    onSuccess: () => navigate("/crm/pos/trasy"),
  });
  const saveAndScheduleRoute = useMutation(
    ({ uuid, ...input }: SaveAndScheduleRouteInput & { uuid: Uuid }) => saveAndSchedule(uuid, input),
    { onSuccess: () => navigate("/crm/pos/trasy") },
  );
  const scheduleRoute = useMutation(
    ({ uuid, ...input }: ScheduleRouteInput & { uuid: Uuid }) => schedule(uuid, input),
    { onSuccess: () => snackbarContext.pushMessage({ content: "Pomyślnie zaplanowano trasę", severity: "success" }) },
  );

  const fullDistanceMeters = travelDataSum?.distance ? Math.ceil(travelDataSum.distance) : 0;
  const fullDurationMinutes = travelDataSum?.duration
    ? Math.ceil(travelDataSum.duration / 60 + (stopDurationsSum ?? 0))
    : 0;

  const onSavePermanent = (name: string) => {
    if (props.salespersonId === undefined) return;
    savePermanent.mutate({
      name: name,
      salespersonId: props.salespersonId,
      route: props.route.map((stop) => {
        return {
          customerUuid: stop.uuid,
          uuid: v4() as Uuid,
          durationMinutes: parseInt(props.stopDurations[stop.uuid].durationMinutes),
        };
      }),
      distanceMeters: fullDistanceMeters,
      fullDurationMinutes: fullDurationMinutes,
    });
  };

  const onSaveAndSchedule = (date: Date) => {
    if (props.salespersonId === undefined) return;
    saveAndScheduleRoute.mutate({
      uuid: v4() as Uuid,
      scheduledAt: date,
      salespersonId: props.salespersonId,
      route: props.route.map((stop) => {
        return {
          customerUuid: stop.uuid as Uuid,
          uuid: v4() as Uuid,
          durationMinutes: parseInt(props.stopDurations[stop.uuid].durationMinutes),
        };
      }),
      distanceMeters: fullDistanceMeters,
      fullDurationMinutes: fullDurationMinutes,
    });
  };

  const onSchedule = (date: Date) => {
    if (props.salespersonId === undefined || !props.initialTradeRoute) return;
    scheduleRoute.mutate({
      uuid: props.initialTradeRoute.uuid as Uuid,
      scheduledAt: date,
      salespersonId: props.salespersonId,
    });
  };
  if (props.isMobile)
    return (
      <MobileRouteMenu
        {...props}
        travelDataSum={travelDataSum}
        stopDurationsSum={stopDurationsSum}
        onSchedule={props.isEdited ? onSaveAndSchedule : onSchedule}
        onSave={onSavePermanent}
        isLoading={savePermanent.isLoading || saveAndScheduleRoute.isLoading || scheduleRoute.isLoading}
      />
    );
  return (
    <DesktopRouteMenu
      {...props}
      travelDataSum={travelDataSum}
      stopDurationsSum={stopDurationsSum}
      onSchedule={props.isEdited ? onSaveAndSchedule : onSchedule}
      onSave={onSavePermanent}
      isLoading={savePermanent.isLoading || saveAndScheduleRoute.isLoading || scheduleRoute.isLoading}
    />
  );
};

interface SaveActionsProps {
  onSchedule: (date: Date) => void;
  onSave: (name: string) => void;
  isLoading: boolean;
}

interface TravelDataProps {
  travelDataSum: TravelData | undefined;
  stopDurationsSum: number;
}

const DesktopRouteMenu: React.FC<Props & TravelDataProps & SaveActionsProps> = (props) => {
  return (
    <Box
      sx={{
        borderLeft: "1px solid lightgray",
        minWidth: "300px",
      }}
    >
      <SelectSalespersonDialog qs={props.qs} setQs={props.setQs} />
      <Box sx={{ height: "calc(100% - 180px)" }}>
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ gap: 2, p: 2 }}>
          <AccessTimeIcon />
          <Typography variant="caption">
            {props.travelDataSum?.duration
              ? formatGoogleDuration(props.travelDataSum?.duration, props.stopDurationsSum)
              : "-"}
          </Typography>
          <RouteIcon />
          <Typography variant="caption">
            {props.travelDataSum?.distance ? `${(props.travelDataSum.distance / 1000).toFixed(2)}km` : "-"}
          </Typography>
        </Box>
        <Box position="relative" sx={{ height: "100%", overflowY: "auto" }}>
          <DraggableRouteStops {...props} />
        </Box>
        <SaveButtons {...props} />
      </Box>
    </Box>
  );
};

const drawerBleeding = 60;

const MobileRouteMenu: React.FC<Props & TravelDataProps & SaveActionsProps> = (props) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  return (
    <>
      <MobileSpeedDial {...props} />
      <Box height={drawerBleeding - 10} />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(50% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />
      <SwipeableDrawer
        anchor="top"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        disableScrollLock={true}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            bottom: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
            backgroundColor: theme.palette.mode === "light" ? "#fff" : theme.palette.grey[800],
          }}
        >
          <Box
            sx={{
              width: 30,
              height: 6,
              backgroundColor: theme.palette.mode === "light" ? theme.palette.grey[300] : theme.palette.grey[900],
              borderRadius: 3,
              position: "absolute",
              bottom: 8,
              left: "calc(50% - 15px)",
            }}
          />

          <Box display="flex" justifyContent="center" alignItems="center" sx={{ gap: 2, p: 2, pb: 3 }}>
            <AccessTimeIcon />
            <Typography variant="caption">
              {props.travelDataSum?.duration
                ? formatGoogleDuration(props.travelDataSum?.duration, props.stopDurationsSum)
                : 0}
            </Typography>
            <RouteIcon />
            <Typography variant="caption">{((props.travelDataSum?.distance ?? 0) / 1000).toFixed(2)}km</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            height: "100%",
            overflow: "auto",
            backgroundColor: theme.palette.mode === "light" ? "#fff" : theme.palette.grey[800],
          }}
        >
          <DraggableRouteStops {...props} />
        </Box>
      </SwipeableDrawer>
    </>
  );
};
