import { TextField } from "@mui/material";
import Decimal from "decimal.js";
import React, { useEffect, useState } from "react";

import { InputProps, PickedTextFieldProps } from "../props";

interface DecimalInputProps extends InputProps<Decimal | undefined>, PickedTextFieldProps {
  caption?: string;
}

const decimalRe = /^-?\d*[,.]\d{0,2}$/;

export const DecimalInput: React.FC<DecimalInputProps> = (props) => {
  const { onChange, value, helperText, label, error, ...muiProps } = props;
  const [isUpdated, setIsUpdated] = useState<boolean>(true);
  const [inputValue, setInputValue] = useState<string>(value ? value.toFixed(2) : "");

  const onNumberChange = (newValue: string) => {
    if (newValue === "") {
      setInputValue("");
      setIsUpdated(false);
      onChange(undefined);
      return;
    }
    if (newValue === "-") {
      setInputValue(newValue);
      setIsUpdated(false);
      onChange(undefined);
      return;
    }
    if (newValue.match(decimalRe)) {
      setInputValue(newValue);
      setIsUpdated(false);
      onChange(new Decimal(newValue.replace(",", ".")).toDecimalPlaces(2));
      return;
    }
  };

  useEffect(() => {
    if (!isUpdated) return setIsUpdated(true);
    setInputValue(value ? value.toFixed(2) : "");
  }, [value]);

  return (
    <TextField
      {...muiProps}
      onBlur={() => value && setInputValue(value.toFixed(2))}
      label={label}
      onChange={(e) => onNumberChange(e.target.value)}
      value={inputValue}
      error={Boolean(error)}
      helperText={helperText ?? error}
    />
  );
};
