import { createTheme } from "@dash/default-theme";
import { Theme, ThemeProvider } from "@emotion/react";
import { useConfig } from "@megarax/dev-config";
import { appLinkTreeToNavEntries, MegaraxWebappContainer } from "@megarax/features";
import { filterLinkTree } from "@megarax/home";
import { Accounts } from "@megarax/iam-accounts";
import { Groups } from "@megarax/iam-groups";
import { MdSecurity } from "react-icons/md";
import { Outlet, Route } from "react-router-dom-v5-compat";

import { LinkDoc } from "@megaron/docs-contracts";

const iamTheme: Theme = createTheme("191");

type Props = {
  linkDocs: LinkDoc[] | undefined;
};

export const useIamRouter = ({ linkDocs }: Props) => {
  return (
    <Route
      path="/iam"
      element={
        <ThemeProvider theme={iamTheme}>
          <IamMegaraxWebappContainer linkDocs={linkDocs} />
        </ThemeProvider>
      }
    >
      <Route path="accounts/*" element={<Accounts />} />
      <Route path="groups/*" element={<Groups />} />
    </Route>
  );
};

const IamMegaraxWebappContainer: React.FC<{
  linkDocs: LinkDoc[] | undefined;
}> = ({ linkDocs }) => {
  const fullLinkTree = filterLinkTree(linkDocs || []);
  const appLinkTree = fullLinkTree.find((appTree) => appTree.app === "iam");

  const { config } = useConfig();

  return (
    <MegaraxWebappContainer
      app={"iam"}
      logo={<MdSecurity width="1em" height="1em" />}
      title="Admin"
      subtitle="megarax.net"
      bypassAuth={config.testUser.enabled}
      navEntries={appLinkTreeToNavEntries(appLinkTree)}
    >
      <Outlet />
    </MegaraxWebappContainer>
  );
};
