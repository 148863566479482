import { Dialog, useDialogRoute } from "@dash/dialog";
import { Button } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { RowCellContent, Table, TableBody, TableHeadColumn } from "@dash/table";
import { useTheme } from "@emotion/react";
import { formatCurrency } from "@legacy-megarax/react-utils";
import Decimal from "decimal.js";
import { MdOutlineMonetizationOn } from "react-icons/md";

import { Currency, OrderDraftLineDto, TransactionDto } from "@megaron/crm-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

type Props = {
  lines: OrderDraftLineDto[];
  billToId?: string;
  sellToId?: string;
  currency?: Currency;
  isDisabled?: boolean;
};

export const MarginButton: React.FC<Props> = ({ lines, isDisabled, billToId, sellToId, currency }) => {
  const { isMobile } = useDeviceType();

  const orderTransactionsPreviewQuery = useClientManager("crm")
    .orderTransactionsPreviewQuery()
    .useQuery(
      {
        billToId: billToId ? Uuid(billToId) : ("" as Uuid),
        sellToId: sellToId ? Uuid(sellToId) : ("" as Uuid),
        lines: lines.map((line) => ({
          itemGtin: line.item.gtin as string,
          itemId: undefined,
          price: line.finalPrice,
          quantity: new Decimal(line.quantity),
        })),
      },
      { enabled: !!(billToId && sellToId && lines.length > 0) },
    );

  const theme = useTheme();

  const tableColumns: TableHeadColumn<string>[] = [
    ...tableHeadingList.map(
      ({ heading }) =>
        ({
          isSortable: false,
          label: heading,
        } as const),
    ),
  ];

  const getTableRowCellsContent = (transaction: TransactionDto): RowCellContent[] => {
    return [
      {
        element: <span css={{ textWrap: "nowrap" }}>{transaction.itemName}</span>,
      },
      {
        element: transaction.quantity.toString(),
      },
      {
        element: transaction.totalProfit ? formatCurrency(transaction.totalProfit, currency ?? "PLN") : "-",
      },
    ];
  };

  const summaryRow = {
    uuid: "total",
    cellsContent: [
      {
        element: "Razem",
        cellCss: { fontWeight: 700, color: theme.colors.primary },
      },
      {
        element: "",
      },
      {
        element: formatCurrency(orderTransactionsPreviewQuery.data?.totalProfit ?? 0, currency ?? "PLN"),
        cellCss: { fontWeight: 700, color: theme.colors.primary },
      },
    ],
    css: {
      "td:first-child": {
        padding: "0.75rem",
      },
    },
  };

  const rows = orderTransactionsPreviewQuery.data
    ? [
        ...orderTransactionsPreviewQuery.data.transactions.filter(Boolean).map((transaction) => ({
          uuid: transaction?.id,
          cellsContent: getTableRowCellsContent(transaction as TransactionDto),
          css: {
            "td:first-child": {
              padding: "0.75rem",
            },
          },
        })),
        summaryRow,
      ]
    : [
        {
          uuid: "emptyRow",
          cellsContent: [
            {
              element: <span css={{ textWrap: "nowrap" }}>Brak</span>,
            },
            {
              element: "",
            },
            {
              element: "",
            },
          ],
          css: {
            "td:first-child": {
              padding: "0.75rem",
            },
          },
        },
        summaryRow,
      ];

  const marginDialog = useDialogRoute("/margins", ({ onClose }) => (
    <Dialog
      onClose={onClose}
      header="Marże"
      placement={isMobile ? "top" : "center"}
      css={{ width: isMobile ? "100%" : "400px", minHeight: "unset" }}
    >
      <div css={{ overflow: "auto", margin: "0 -1rem", padding: "0 1rem", maxHeight: "70dvh" }}>
        <Table columns={tableColumns}>
          <TableBody rows={rows} />
        </Table>
      </div>
    </Dialog>
  ));

  return (
    <>
      <Button variant="outline" icon={<MdOutlineMonetizationOn />} onClick={marginDialog.open} isDisabled={isDisabled}>
        {isMobile ? "" : "Marże"}
      </Button>
      {marginDialog.element}
    </>
  );
};

const tableHeadingList = [
  {
    heading: "Przedmiot",
  },
  {
    heading: "Ilość",
  },
  {
    heading: "Marża",
  },
];
