import { Tile, TileStripe } from "@dash/tile";
import { useTheme } from "@emotion/react";
import { MdOutlineWarning } from "react-icons/md";

import { CustomerDoc } from "@megaron/docs-contracts";

type Props = {
  label: string;
  customer: CustomerDoc;
  color?: string;
};

export const ExistingCustomerError: React.FC<Props> = ({ color, label, customer }) => {
  const theme = useTheme();

  const customerAddress = customer.address
    ? `${customer.address?.street || "-"}, ${customer.address?.postalCode} ${customer.address?.city || "-"}, ${
        customer.address?.country || "-"
      }`
    : "-";

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
      <div
        css={{
          display: "flex",
          alignItems: "center",
          gap: "0.625rem",
          color: color ?? theme.colors.danger,
          fontSize: "1rem",
          fontWeight: 700,
        }}
      >
        <MdOutlineWarning size={20} />
        {label}
      </div>
      <Tile stripe={<TileStripe color={theme.colors.primaryLight} />}>
        <div css={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <span css={{ fontSize: "1rem", color: theme.colors.primary }}>{customer.name}</span>
          <span css={{ fontSize: "0.75rem", color: "black" }}>{customerAddress}</span>
        </div>
      </Tile>
    </div>
  );
};
