import { Dialog } from "@dash/dialog";
import { useDeviceType } from "@dash/mq";

import { InteractionsAggDialogContent } from "./InteractionsAggDialogContent";
import { AggDialogProps } from "./SalesAggDialogContent";

type Props = AggDialogProps;

export const InteractionsAggDialog: React.FC<Props> = ({ onClose, ...props }) => {
  const { isMobile } = useDeviceType();

  return (
    <Dialog
      onClose={onClose}
      placement={isMobile ? "top" : "center"}
      css={{ width: isMobile ? "100%" : "400px" }}
      header={props.aggName ? "Dane agregacji" : "Nowy zestaw danych"}
    >
      <InteractionsAggDialogContent onClose={onClose} {...props} />
    </Dialog>
  );
};
