import { Button } from "@dash/form";
import { Spinner } from "@dash/spinner";
import { MdRefresh } from "react-icons/md";

type Props = {
  onClick: () => void;
  isLoading: boolean;
  className?: string;
};

export const RefreshButton: React.FC<Props> = ({ onClick, isLoading, className }) => {
  return (
    <Button
      onClick={onClick}
      variant="outline"
      icon={isLoading ? <Spinner size="12px" /> : <MdRefresh size={12} />}
      size="small"
      css={className}
    >
      refresh
    </Button>
  );
};
