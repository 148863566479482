import { Dialog } from "@dash/dialog";
import { Button, TextField } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { Select, SelectDialog } from "@dash/select";
import { useToast } from "@dash/toast";
import { useState } from "react";
import { MdAdd } from "react-icons/md";

import { AggType } from "@megaron/crm-contracts";
import { toDateString } from "@megaron/date-string";
import { newUuid } from "@megaron/uuid";

import { analyticsColumns, ColumnType } from "./analyticsColumns";
import { AnalyticsPreset, AnalyticsPresetColumn, AnalyticsPresetCustomColumn } from "./analyticsPreset";

type Props = {
  onClose: () => void;
  preset: AnalyticsPreset;
  onCreateAggClick: (aggType: AggType) => void;
  onAddColumn: (column: AnalyticsPresetColumn) => void;
  onAddCustomColumn: (options: { customColumn: AnalyticsPresetCustomColumn; column: AnalyticsPresetColumn }) => void;
};

export const AddColumnDialog: React.FC<Props> = ({
  onClose,
  preset,
  onCreateAggClick,
  onAddColumn,
  onAddCustomColumn,
}) => {
  const { isMobile } = useDeviceType();

  const toast = useToast();

  const [selectedColumnType, setSelectedColumnType] = useState<ColumnType | null>(null);
  const [selectedSalesAgg, setSelectedSalesAgg] = useState<string | null | undefined>(undefined);
  const [selectedInteractionsAgg, setSelectedInteractionsAgg] = useState<string | null | undefined>(undefined);
  const [columnName, setColumnName] = useState<string>("");

  const [columnNameError, setColumnNameError] = useState<string | null>(null);

  const handleAddColumn = () => {
    const column = analyticsColumns.find((column) => column.columnType === selectedColumnType);

    const customColumnType = column?.customColumnType;

    const isNameUnique = !preset.columns.find((presetColumn) => presetColumn.header === columnName);

    if (!isNameUnique) {
      return setColumnNameError("Istnieje już kolumna o takiej nazwie.");
    }

    setColumnNameError(null);

    if (!columnName) {
      return toast.error("Nazwa kolumny jest wymagana.");
    }

    if (!column) {
      return toast.error("Wystąpił błąd podczas dodawania kolumny.");
    }

    if (
      (column.aggType === "sales" && !selectedSalesAgg) ||
      (customColumnType === "salesHistory" && !selectedSalesAgg) ||
      (customColumnType === "salesPerInteraction" && !selectedSalesAgg)
    ) {
      return toast.error("Agregacja sprzedaży jest wymagana.");
    }

    if (
      (column.aggType === "interactions" && !selectedInteractionsAgg) ||
      (customColumnType === "salesPerInteraction" && !selectedInteractionsAgg)
    ) {
      return toast.error("Agregacja interakcji jest wymagana.");
    }

    if (!selectedSalesAgg && !selectedInteractionsAgg) {
      onAddColumn({
        type: column.columnType,
        aggName: null,
        aggType: null,
        header: columnName,
        sortField: column.sortField ?? null,
        customColumnUuid: undefined,
      });

      onClose();
      return;
    }

    const salesAggOption = aggOptions
      .find((agg) => agg.aggType === "sales")
      ?.options.find((agg) => agg.aggName === selectedSalesAgg);
    const interactionsAggOption = aggOptions
      .find((agg) => agg.aggType === "interactions")
      ?.options.find((agg) => agg.aggName === selectedInteractionsAgg);

    if (!salesAggOption && !interactionsAggOption) {
      return toast.error("Wystąpił błąd podczas dodawania kolumny.");
    }

    if (!customColumnType) {
      onAddColumn({
        type: column.columnType,
        aggName: selectedSalesAgg ?? selectedInteractionsAgg ?? null,
        aggType: salesAggOption?.type ?? interactionsAggOption?.type ?? null,
        header: columnName,
        sortField: column.sortField ?? null,
        customColumnUuid: undefined,
      });

      onClose();
      return;
    }

    if (customColumnType === "salesHistory") {
      if (!selectedSalesAgg) {
        return toast.error("Agregacja sprzedaży jest wymagana.");
      }

      const existingCustomColumn = preset.customColumns?.find(
        (column) => column.salesAgg === selectedSalesAgg && column.type === customColumnType,
      );

      if (existingCustomColumn) {
        onAddColumn({
          type: column.columnType,
          aggName: null,
          aggType: null,
          header: columnName,
          sortField: column.sortField ?? null,
          customColumnUuid: existingCustomColumn.uuid,
        });

        onClose();
        return;
      }

      const customColumnUuid = newUuid();

      onAddCustomColumn({
        customColumn: {
          type: "salesHistory",
          salesAgg: selectedSalesAgg,
          uuid: customColumnUuid,
          bucketCount: 12,
          bucketSizeDays: 30,
          endDate: toDateString(new Date()),
        },
        column: {
          type: column.columnType,
          aggName: null,
          aggType: null,
          header: columnName,
          sortField: column.sortField ?? null,
          customColumnUuid,
        },
      });

      onClose();
      return;
    }

    if (customColumnType === "salesPerInteraction") {
      if (!selectedSalesAgg) {
        return toast.error("Agregacja sprzedaży jest wymagana.");
      }

      if (!selectedInteractionsAgg) {
        return toast.error("Agregacja interakcji jest wymagana.");
      }

      const existingCustomColumn = preset.customColumns?.find(
        (column) =>
          column.type === "salesPerInteraction" &&
          column.salesAgg === selectedSalesAgg &&
          column.interactionAgg === selectedInteractionsAgg,
      );

      if (existingCustomColumn) {
        onAddColumn({
          type: column.columnType,
          aggName: null,
          aggType: null,
          header: columnName,
          sortField: column.sortField ?? null,
          customColumnUuid: existingCustomColumn.uuid,
        });

        onClose();
        return;
      }

      const customColumnUuid = newUuid();

      onAddCustomColumn({
        customColumn: {
          type: "salesPerInteraction",
          salesAgg: selectedSalesAgg,
          interactionAgg: selectedInteractionsAgg,
          uuid: customColumnUuid,
        },
        column: {
          type: column.columnType,
          aggName: null,
          aggType: null,
          header: columnName,
          sortField: column.sortField ?? null,
          customColumnUuid,
        },
      });

      onClose();
      return;
    }
  };

  const columnOptions = [
    ...analyticsColumns
      .filter(
        (column) =>
          !preset.columns.find(
            (presetColumn) =>
              presetColumn.aggName === null &&
              !presetColumn.customColumnUuid &&
              presetColumn.type === column.columnType,
          ),
      )
      .map((column) => ({ label: column.columnName, value: column.columnType })),
  ];

  if (!selectedColumnType) {
    return (
      <SelectDialog
        onClose={onClose}
        options={columnOptions}
        onSelect={(option) => {
          const column = analyticsColumns.find((column) => column.columnType === option?.value);

          setColumnName(column?.columnName ?? "");

          if (column?.aggType === null && !column.customColumnType) {
            setSelectedColumnType(option?.value as ColumnType);
            setSelectedSalesAgg(null);
            setSelectedInteractionsAgg(null);

            return;
          }

          setSelectedColumnType(option?.value as ColumnType);
        }}
        variant="single-select"
        isOpen
        noTrigger
        shouldNotCloseOnSelect
      />
    );
  }

  const aggOptions = getAggOptionsForColumnType(selectedColumnType, preset);

  return (
    <Dialog
      onClose={onClose}
      header="Uzupełnij dane kolumny"
      placement={isMobile ? "top" : "center"}
      css={{ width: isMobile ? "100%" : "400px" }}
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
        <TextField label="Nazwa kolumny" value={columnName} onChange={setColumnName} error={columnNameError} />
        {selectedSalesAgg !== null && selectedInteractionsAgg !== null && selectedColumnType && (
          <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
            {aggOptions.map(({ aggType, options }) => (
              <Select
                options={[
                  ...options.map((option) => ({ label: option.name, value: option.aggName })),
                  { label: "Nowa agregacja", value: "new-agg" },
                ]}
                label={aggType === "sales" ? "Agregacja sprzedaży" : "Agregacja interakcji"}
                value={aggType === "sales" ? selectedSalesAgg : selectedInteractionsAgg}
                onChange={(v) => {
                  if (v === "new-agg") {
                    onCreateAggClick(aggType);
                    return;
                  }

                  aggType === "sales" ? setSelectedSalesAgg(v) : setSelectedInteractionsAgg(v);
                }}
                key={aggType}
              />
            ))}
          </div>
        )}
        <Button onClick={handleAddColumn} icon={<MdAdd />} css={{ marginLeft: "auto", marginTop: "0.375rem" }}>
          Dodaj kolumnę
        </Button>
      </div>
    </Dialog>
  );
};

const getAggOptionsForColumnType = (columnType: ColumnType, preset: AnalyticsPreset) => {
  if (columnType === "sales-history") {
    return [
      {
        aggType: "sales",
        options: preset.aggsOptions.filter(
          (agg) =>
            agg.type === "sales" &&
            !preset.customColumns?.find(
              (presetColumn) => presetColumn.salesAgg === agg.aggName && presetColumn.type === "salesHistory",
            ),
        ),
      } as const,
    ];
  }

  if (["profit-per-interaction", "order-per-interaction", "potential-per-interaction"].includes(columnType)) {
    return [
      {
        aggType: "sales",
        options: preset.aggsOptions.filter((agg) => agg.type === "sales"),
      },
      {
        aggType: "interactions",
        options: preset.aggsOptions.filter((agg) => agg.type === "interactions"),
      },
    ] as const;
  }

  const column = analyticsColumns.find((column) => column.columnType === columnType);

  if (!column || !column.aggType) {
    return [];
  }

  const aggOptions = preset.aggsOptions.filter((agg) => agg.type === column.aggType);

  return [
    {
      aggType: column.aggType,
      options: aggOptions.filter(
        (agg) =>
          !preset.columns.find(
            (presetColumn) => presetColumn.aggName === agg.aggName && presetColumn.type === columnType,
          ),
      ),
    } as const,
  ];
};
