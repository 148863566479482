import { Dialog } from "@dash/dialog";
import { useDeviceType } from "@dash/mq";
import { SearchBox } from "@dash/search";
import { DocsSelectDialog, SelectDialog } from "@dash/select";
import { useTheme } from "@emotion/react";
import { useMemo, useState } from "react";
import { IconType } from "react-icons/lib";
import { MdOutline3P, MdOutlineFilterAlt, MdOutlineShoppingCart } from "react-icons/md";
import tinycolor from "tinycolor2";

import { CustomerCategory, CustomerStatus } from "@megaron/crm-contracts";
import { CustomerDoc } from "@megaron/docs-contracts";
import { useClientManager } from "@megaron/react-clients";
import { ResourceId } from "@megaron/resource";
import { Uuid } from "@megaron/uuid";

import { customerCategories } from "../addCustomer/CustomerCategorySection";
import { customerStatusList } from "../StatusField";
import { AggOptions, AnalyticsPreset } from "./analyticsPreset";
import { InteractionsAggDialogContent } from "./InteractionsAggDialogContent";
import { SalesAggDialogContent } from "./SalesAggDialogContent";
import { Filters } from "./useCustomerAnalyticsGlobalFilters";

type Props = {
  onClose: () => void;
  filters: Filters;
  handlers: {
    onRegionsChange: (regions: string[] | undefined) => void;
    onCategoriesChange: (categories: CustomerCategory[] | undefined) => void;
    onStatusChange: (status: CustomerStatus[] | undefined) => void;
    onCustomerIdChange: (customerId: Uuid[] | undefined) => void;
    onIsMyRegionChange: (isMyRegion: boolean | undefined) => void;
  };
  onAddAgg: (agg: AggOptions) => void;
  onUpdateAgg: (aggName: string, agg: AggOptions) => void;
  preset: AnalyticsPreset;
};

export const CustomerAnalyticsFiltersDialog: React.FC<Props> = ({
  onClose,
  filters,
  handlers,
  onAddAgg,
  onUpdateAgg,
  preset,
}) => {
  const { isMobile } = useDeviceType();

  const theme = useTheme();

  const [searchText, setSearchText] = useState("");

  const [isAddSalesAggDialogOpen, setAddSalesAggDialogOpen] = useState(false);
  const [isAddInteractionsAggDialogOpen, setAddInteractionsAggDialogOpen] = useState(false);

  const regionsQuery = useClientManager("crm").regionsQuery().useQuery({});

  const regionsOptions = useMemo(
    () =>
      regionsQuery.data
        ? regionsQuery.data.items.map((region) => ({
            label: region.name,
            value: region.name,
          }))
        : [],
    [regionsQuery.data],
  );

  const tiles = useMemo(() => {
    const tiles = [
      {
        label: "Sprzedaż",
        Icon: MdOutlineShoppingCart,
        onClick: () => setAddSalesAggDialogOpen(true),
        type: "tile",
      },
      {
        label: "Interakcje",
        Icon: MdOutline3P,
        onClick: () => setAddInteractionsAggDialogOpen(true),
        type: "tile",
      },
      {
        label: "Regiony",
        initiallySelectedValues: filters.region ?? [],
        options: regionsOptions ?? [],
        onSelectedChange: (options) => handlers.onRegionsChange(options.map((option) => option.value) ?? undefined),
        variant: "multi-select",
        renderTrigger: (_, __, ___, onOpen) => <EntryTile label="Region" Icon={MdOutlineFilterAlt} onClick={onOpen} />,
        type: "select",
        isDisabled: false,
      } satisfies React.ComponentProps<typeof SelectDialog> & { type: "select"; isDisabled?: boolean },
      {
        label: "Klienci",
        initiallySelectedDocIds: filters.customerId ? filters.customerId.map((id) => ResourceId("customer", id)) : [],
        docType: ["customer"],
        onSelectedChange: (options) =>
          handlers.onCustomerIdChange((options as CustomerDoc[]).map((option) => option.uuid) ?? undefined),
        variant: "multi-select",
        renderTrigger: (_, __, ___, onOpen) => <EntryTile label="Klienci" Icon={MdOutlineFilterAlt} onClick={onOpen} />,
        type: "docs-select",
      } satisfies React.ComponentProps<typeof DocsSelectDialog> & { type: "docs-select" },
      {
        label: "Kategoria",
        variant: "multi-select",
        initiallySelectedValues: filters.category || [],
        options: customerCategories.map((category) => ({ label: category.label, value: category.value })),
        onSelectedChange: (options) =>
          handlers.onCategoriesChange(options.map((option) => option.value as CustomerCategory) ?? undefined),
        renderTrigger: (_, __, ___, onOpen) => (
          <EntryTile label="Kategoria" Icon={MdOutlineFilterAlt} onClick={onOpen} />
        ),
        type: "select",
        isDisabled: false,
      } satisfies React.ComponentProps<typeof SelectDialog> & { type: "select"; isDisabled?: boolean },
      {
        label: "Status",
        variant: "multi-select",
        initiallySelectedValues: filters.status || [],
        options: customerStatusList,
        onSelectedChange: (options) =>
          handlers.onStatusChange(options.map((option) => option.value as CustomerStatus) ?? undefined),
        renderTrigger: (_, __, ___, onOpen) => <EntryTile label="Status" Icon={MdOutlineFilterAlt} onClick={onOpen} />,
        type: "select",
        isDisabled: false,
      } satisfies React.ComponentProps<typeof SelectDialog> & { type: "select"; isDisabled?: boolean },
    ] as const;

    if (!searchText) {
      return tiles;
    }

    return tiles.filter((tile) => tile.label.toLowerCase().includes(searchText.toLowerCase()));
  }, [filters.category, filters.customerId, filters.region, filters.status, handlers, regionsOptions, searchText]);

  return (
    <Dialog
      onClose={onClose}
      header={
        isAddInteractionsAggDialogOpen || isAddSalesAggDialogOpen
          ? "Nowy zestaw danych"
          : "Wybierz filtr lub typ danych"
      }
      placement={isMobile ? "top" : "center"}
      css={{ width: isMobile ? "100%" : "400px" }}
    >
      {!isAddInteractionsAggDialogOpen && !isAddSalesAggDialogOpen && (
        <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <SearchBox value={searchText} onChange={setSearchText} />
          <div
            css={{
              height: "1px",
              margin: "0 -1rem",
              background: `${tinycolor(theme.colors.primary).setAlpha(0.1)}`,
            }}
          />
          <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
            {tiles.map((tile) => {
              if (tile.type === "select") {
                return (
                  <div css={{ opacity: tile?.isDisabled ? 0.3 : 1 }}>
                    <SelectDialog {...tile} key={`filter-select-${tile.type}-${tile.label}`} onClose={onClose} />
                  </div>
                );
              }

              if (tile.type === "docs-select") {
                return (
                  <DocsSelectDialog {...tile} key={`filter-docs-select-${tile.type}-${tile.label}`} onClose={onClose} />
                );
              }

              return <EntryTile {...tile} key={`filter-${tile.type}-${tile.label}`} />;
            })}
          </div>
        </div>
      )}
      {isAddSalesAggDialogOpen && (
        <SalesAggDialogContent
          onClose={() => {
            setAddSalesAggDialogOpen(false);
            onClose();
          }}
          onAddAgg={onAddAgg}
          onUpdateAgg={onUpdateAgg}
          analyticsPreset={preset}
        />
      )}
      {isAddInteractionsAggDialogOpen && (
        <InteractionsAggDialogContent
          onClose={() => {
            setAddInteractionsAggDialogOpen(false);
            onClose();
          }}
          onAddAgg={onAddAgg}
          onUpdateAgg={onUpdateAgg}
          analyticsPreset={preset}
        />
      )}
    </Dialog>
  );
};

const EntryTile: React.FC<{ label: string; Icon: IconType; onClick: () => void }> = ({ Icon, label, onClick }) => {
  const theme = useTheme();

  return (
    <button
      css={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: "0.5rem",
        padding: "0.75rem",
        background: "white",
        borderRadius: theme.smallBorderRadius,
        color: theme.colors.primary,
        border: `1px solid rgba(0, 0, 0, 0.1)`,
        cursor: "pointer",
        transition: "all 0.1s",
        ":hover": {
          border: `1px solid ${theme.colors.primary}`,
          fontWeight: 700,
        },
      }}
      onClick={onClick}
    >
      <Icon size={14} />
      <span>{label}</span>
    </button>
  );
};
