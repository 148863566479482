import { Uuid, UuidResourceParam, uuidSerializer } from "@legacy-megarax/common";
import { BaseResource, ResourceActionV2 } from "@legacy-megarax/rest-resource";
import { MSerializers } from "@legacy-megarax/serializers";

import { toggleTaskInputSerializer } from "./inputs";

export interface VisitChecklistDetail {
  visitUuid: Uuid;
  tasks: { uuid: Uuid; label: string; completedAt: Date | null }[];
}

const visitChecklistDetailSerializer = MSerializers.object<VisitChecklistDetail>({
  visitUuid: uuidSerializer,
  tasks: MSerializers.array(
    MSerializers.object<{ uuid: Uuid; label: string; completedAt: Date | null }>({
      label: MSerializers.string,
      uuid: uuidSerializer,
      completedAt: MSerializers.datetime.nullable(),
    }),
  ),
});

export const visitChecklistResource = BaseResource(["crm", "visitChecklist"], {
  byUuid: UuidResourceParam("uuid", "uuid", {
    toggleTask: ResourceActionV2({
      name: "toggleTask",
      method: "put",
      requestBodySerializer: toggleTaskInputSerializer,
      responseErrorSerializer: MSerializers.stringOneOf("ChecklistNotFound", "TaskNotFound"),
    }),
    retrieve: ResourceActionV2({
      name: "retrieve",
      method: "get",
      responseValueSerializer: visitChecklistDetailSerializer,
    }),
  }),
});
