import { Button, DateField, IntegerField } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { Select } from "@dash/select";
import { useTheme } from "@emotion/react";
import {
  endOfDay,
  endOfMonth,
  getMonth,
  getYear,
  startOfDay,
  startOfMonth,
  subDays,
  subHours,
  subMonths,
  subYears,
} from "date-fns";
import { useState } from "react";
import { MdCheck } from "react-icons/md";

export type TimeRangeSelectProps = {
  onClose: () => void;
  startDate: Date | null;
  endDate: Date | null;
  activeOptionName: string | null;
  timeUnit: TimeUnit | null;
  unitCount: number | null;
  onDateChange: (
    startDate: Date | null,
    endDate: Date | null,
    timeUnit: TimeUnit | null,
    unitCount: number | null,
    activeOptionName: string | null,
  ) => void;
};

export const TimeRangeSelect: React.FC<TimeRangeSelectProps> = ({
  onClose,
  startDate,
  endDate,
  onDateChange,
  timeUnit: initialTimeUnit,
  unitCount: initialUnitCount,
  activeOptionName,
}) => {
  const theme = useTheme();

  const { isMobile } = useDeviceType();

  const [startTime, setStartTime] = useState<Date | null>(startDate);
  const [endTime, setEndTime] = useState<Date | null>(endDate);
  const [unitCount, setUnitCount] = useState<number | null>(initialUnitCount ?? null);
  const [timeUnit, setTimeUnit] = useState<TimeUnit | null>(initialTimeUnit ?? null);

  const timeRangeOptions = getTimeRangeOptions(new Date());
  const dateOptions = getDateOptions(new Date());

  const handleDateSave = () => {
    onDateChange(startTime, endTime, null, null, null);
    onClose();
  };

  const handleTimeRangeSave = () => {
    if (!timeUnit || !unitCount) {
      return;
    }

    const { startTime, endTime } = getStartTimeAndEndTimeFromTimeRange(timeUnit, unitCount, new Date());
    onDateChange(startTime, endTime, timeUnit, unitCount, null);
    onClose();
  };

  const getButtonStyles = (isActive: boolean) =>
    ({
      border: "none",
      background: isActive ? theme.colors.primaryLight : "none",
      fontWeight: isActive ? 700 : 400,
      color: theme.colors.primary,
      textAlign: "left",
      fontSize: "0.875rem",
      cursor: "pointer",
      borderRadius: theme.smallBorderRadius,
      padding: "0.25rem 0.5rem",
      transition: "all 0.1s",
      ":hover": { fontWeight: 700 },
    } as const);

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        width: "100%",
        textAlign: "left",
      }}
    >
      <div
        css={{
          display: "flex",
          gap: "0.75rem",
          alignItems: isMobile ? "unset" : "flex-end",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <DateField value={startTime} onChange={setStartTime} label="Od" />
        <div css={{ display: "flex", gap: "0.75rem", alignItems: "flex-end" }}>
          <DateField value={endTime} onChange={setEndTime} label="Do" css={{ width: isMobile ? "100%" : "auto" }} />
          <Button icon={<MdCheck />} css={{ height: "2.5rem" }} onClick={handleDateSave} />
        </div>
      </div>
      <div
        css={{
          display: "grid",
          gridTemplateColumns: isMobile
            ? "repeat(auto-fill, minmax(90px, 1fr))"
            : "repeat(auto-fill, minmax(120px, 1fr))",
        }}
      >
        {dateOptions.map((option) => {
          const isActive = option.label === activeOptionName;

          return (
            <button
              key={option.label}
              css={getButtonStyles(isActive)}
              onClick={() => {
                onDateChange(option.startDate, option.endDate, null, null, option.label);
                onClose();
              }}
            >
              {option.label}
            </button>
          );
        })}
      </div>
      <hr
        css={{
          margin: 0,
          width: "100%",
          height: "1px",
          background: "rgba(0, 0, 0, 0.1)",
          border: "none",
        }}
      />
      <div css={{ display: "flex", gap: "0.75rem", alignItems: "flex-end" }}>
        <IntegerField value={unitCount} onChange={setUnitCount} label="Ostatnie" isNullable />
        <Select
          options={timeUnitOptions}
          value={timeUnit}
          onChange={(v) => setTimeUnit(v as TimeUnit)}
          placeholder=""
        />
        <Button icon={<MdCheck />} css={{ height: "2.375rem" }} onClick={handleTimeRangeSave} />
      </div>
      <div css={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))" }}>
        {timeRangeOptions.map((option) => {
          const isActive = option.label === activeOptionName;

          return (
            <button
              key={option.label}
              css={getButtonStyles(isActive)}
              onClick={() => {
                onDateChange(option.startDate, option.endDate, option.timeUnit, option.unitCount, option.label);
                onClose();
              }}
            >
              {option.label}
            </button>
          );
        })}
      </div>
      <Button
        variant="outline"
        size="small"
        css={{ marginLeft: "auto" }}
        onClick={() => {
          onDateChange(null, null, null, null, null);
          setStartTime(null);
          setEndTime(null);
          setUnitCount(null);
          setTimeUnit(null);
          onClose();
        }}
      >
        Wyczyść
      </Button>
    </div>
  );
};

export const timeUnits = ["hours", "days", "months", "years"] as const;
export type TimeUnit = (typeof timeUnits)[number];

const timeUnitOptions: { label: string; value: TimeUnit }[] = [
  { label: "godzin", value: "hours" },
  { label: "dni", value: "days" },
  { label: "miesiące", value: "months" },
  { label: "lata", value: "years" },
];

const getDateOptions = (today: Date) => {
  const currentYear = getYear(today);
  const quarters = getQuarters(today);

  return [
    {
      label: currentYear.toString(),
      startDate: startOfDay(new Date(currentYear, 0, 1)),
      endDate: endOfDay(new Date(currentYear, 11, 31)),
    },
    { ...quarters[0] },
    { label: formatDateToString(today), startDate: startOfMonth(today), endDate: endOfMonth(today) },
    {
      label: (currentYear - 1).toString(),
      startDate: startOfDay(new Date(currentYear - 1, 0, 1)),
      endDate: endOfDay(new Date(currentYear - 1, 11, 31)),
    },
    { ...quarters[1] },
    {
      label: formatDateToString(subMonths(today, 1)),
      startDate: startOfMonth(subMonths(today, 1)),
      endDate: endOfMonth(subMonths(today, 1)),
    },
    {
      label: (currentYear - 2).toString(),
      startDate: startOfDay(new Date(currentYear - 2, 0, 1)),
      endDate: endOfDay(new Date(currentYear - 2, 11, 31)),
    },
    { ...quarters[2] },
    {
      label: formatDateToString(subMonths(today, 2)),
      startDate: startOfMonth(subMonths(today, 2)),
      endDate: endOfMonth(subMonths(today, 2)),
    },
    {
      label: (currentYear - 3).toString(),
      startDate: startOfDay(new Date(currentYear - 3, 0, 1)),
      endDate: endOfDay(new Date(currentYear - 3, 11, 31)),
    },
    { ...quarters[3] },
    {
      label: formatDateToString(subMonths(today, 3)),
      startDate: startOfMonth(subMonths(today, 3)),
      endDate: endOfMonth(subMonths(today, 3)),
    },
  ];
};

const getQuarterOptions = (quarter: 1 | 2 | 3 | 4, year: number) => {
  const label = `Q${quarter} ${year}`;

  if (quarter === 1) {
    return { label, startDate: startOfDay(new Date(year, 0, 1)), endDate: endOfDay(new Date(year, 2, 31)) };
  }

  if (quarter === 2) {
    return { label, startDate: startOfDay(new Date(year, 3, 1)), endDate: endOfDay(new Date(year, 5, 30)) };
  }

  if (quarter === 3) {
    return { label, startDate: startOfDay(new Date(year, 6, 1)), endDate: endOfDay(new Date(year, 8, 30)) };
  }

  if (quarter === 4) {
    return { label, startDate: startOfDay(new Date(year, 9, 1)), endDate: endOfDay(new Date(year, 11, 31)) };
  }

  return { label, startDate: new Date(), endDate: new Date() };
};

const getQuarters = (date: Date) => {
  const quarter1Options = getQuarterOptions(Math.ceil(getMonth(date) / 3) as 1 | 2 | 3 | 4, getYear(date));
  const quarter2Options = getQuarterOptions(
    Math.ceil(getMonth(subMonths(date, 3)) / 3) as 1 | 2 | 3 | 4,
    getYear(subMonths(date, 3)),
  );
  const quarter3Options = getQuarterOptions(
    Math.ceil(getMonth(subMonths(date, 6)) / 3) as 1 | 2 | 3 | 4,
    getYear(subMonths(date, 6)),
  );
  const quarter4Options = getQuarterOptions(
    Math.ceil(getMonth(subMonths(date, 9)) / 3) as 1 | 2 | 3 | 4,
    getYear(subMonths(date, 9)),
  );

  return [quarter1Options, quarter2Options, quarter3Options, quarter4Options];
};

const formatDateToString = (date: Date) => {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}`;
};

const getTimeRangeOptions = (today: Date) =>
  [
    { label: "Ostatnie 24 h", timeUnit: "hours", unitCount: 24, startDate: subHours(today, 24), endDate: today },
    { label: "Ostatnie 30 dni", timeUnit: "days", unitCount: 30, startDate: subDays(today, 30), endDate: today },
    { label: "Ostatnie 6 mies.", timeUnit: "months", unitCount: 6, startDate: subMonths(today, 6), endDate: today },
    { label: "Ostatnie 7 dni", timeUnit: "days", unitCount: 7, startDate: subDays(today, 7), endDate: today },
    { label: "Ostatnie 60 dni", timeUnit: "days", unitCount: 60, startDate: subDays(today, 60), endDate: today },
    { label: "Ostatni rok", timeUnit: "years", unitCount: 1, startDate: subYears(today, 1), endDate: today },
    { label: "Ostatnie 14 dni", timeUnit: "days", unitCount: 14, startDate: subDays(today, 14), endDate: today },
    { label: "Ostatnie 90 dni", timeUnit: "days", unitCount: 90, startDate: subDays(today, 90), endDate: today },
    { label: "Ostatnie 2 lata", timeUnit: "years", unitCount: 2, startDate: subYears(today, 2), endDate: today },
  ] as const;

const getStartTimeAndEndTimeFromTimeRange = (timeUnit: TimeUnit, unitCount: number, today: Date) => {
  const endTime = today;

  if (timeUnit === "hours") {
    return { startTime: subHours(today, unitCount), endTime };
  }

  if (timeUnit === "days") {
    return { startTime: subDays(today, unitCount), endTime };
  }

  if (timeUnit === "months") {
    return { startTime: subMonths(today, unitCount), endTime };
  }

  if (timeUnit === "years") {
    return { startTime: subYears(today, unitCount), endTime };
  }

  return { startTime: today, endTime };
};
