import { meResource } from "@legacy-megarax/iam-contracts";
import React, { useEffect, useState } from "react";

import { useResourceProviderV2 } from "./useResourceProviderV2";

export type CurrentUser = {
  email: string;
  id: number;
  groups: {
    uuid: string;
    name: string;
  }[];
};

export const CurrentUserContext = React.createContext<CurrentUser | undefined>(undefined);

interface Props {
  ifConnectionFailed: React.ReactNode;
  children?: React.ReactNode;
}

export const CurrentUserContextProvider: React.FunctionComponent<Props> = ({ children, ifConnectionFailed }) => {
  const [currentUser, setCurrentUser] = useState<CurrentUser>();
  const [connectionFailed, setConnectionFailed] = useState<boolean>(false);

  const meResourceProvider = useResourceProviderV2(meResource);

  const loadUser = async () => {
    const result = await meResourceProvider.user();

    if (result.isOk) setCurrentUser(result.value);
    else if (result.error === "ConnectionFailed") setConnectionFailed(true);
  };

  useEffect(() => {
    loadUser();
  }, []);

  if (connectionFailed) return <>{ifConnectionFailed}</>;

  return <CurrentUserContext.Provider value={currentUser}>{children}</CurrentUserContext.Provider>;
};

export const useCurrentUser = () => React.useContext(CurrentUserContext);
