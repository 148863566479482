import { TradeRouteSimple } from "@legacy-megarax/crm-contracts";
import { useProfilesDictionary } from "@legacy-megarax/iam-webapp";
import { DateInput, TextInput } from "@legacy-megarax/ui-components";
import SaveIcon from "@mui/icons-material/Save";
import ScheduleIcon from "@mui/icons-material/Schedule";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Typography,
} from "@mui/material";
import { useSalespeopleResource } from "libs/@megarax/crm/sales-people/src";
import React from "react";
import { useQuery } from "react-query";

import { QueryFilters, SetQueryFilters, TravelData } from "../TradeRouteDetails";

interface Props {
  travelDataSum: TravelData | undefined;
  stopDurationsSum: number;
  onSchedule: (date: Date) => void;
  onSave: (name: string) => void;
  isLoading: boolean;
  salespersonId: number | undefined;
  initialTradeRoute: TradeRouteSimple | null;
  isEdited: boolean;
  qs: QueryFilters;
  setQs: SetQueryFilters;
}

export const SaveButtons: React.FC<Props> = (props) => {
  return (
    <Box display="flex" flexDirection="column" mt="auto" height="80px">
      <NameSelectDialog
        action={(name) => props.onSave(name)}
        disabled={props.isLoading || props.salespersonId === undefined || !props.isEdited}
        initialName={props.initialTradeRoute?.name ?? ""}
      >
        <Button color="primary" disabled={props.isLoading || !props.isEdited} sx={{ width: "100%" }}>
          Zapisz jako stała trasa
        </Button>
      </NameSelectDialog>
      <DateSelectDialog
        action={(date) => props.onSchedule(date)}
        disabled={props.isLoading || props.salespersonId === undefined}
      >
        <Button color="primary" disabled={props.isLoading} sx={{ width: "100%" }}>
          Zaplanuj na dzień
        </Button>
      </DateSelectDialog>
    </Box>
  );
};

export const MobileSpeedDial: React.FC<Props> = ({ qs, setQs, ...props }) => {
  const enabled = props.salespersonId !== undefined;

  const [open, setOpen] = React.useState(false);
  const { listAll } = useSalespeopleResource();

  const salespeopleQuery = useQuery(["salespeople"], () => listAll());
  const salespeople = salespeopleQuery.data?.isOk ? salespeopleQuery.data.value.items : [];
  const profiles = useProfilesDictionary(salespeople.map((salesperson) => ({ id: salesperson.userId })));

  return (
    <>
      <Dialog
        open={open || qs.salespersonId === undefined}
        fullWidth
        maxWidth="xs"
        onClose={() => qs.salespersonId && setOpen(false)}
      >
        <Box sx={{ overflowY: "auto" }}>
          <Box p={2} pb={1}>
            <Typography variant="subtitle2">Wybierz Handlowca:</Typography>
          </Box>
          <List>
            {profiles &&
              salespeople.map((salesperson, idx) => {
                const selected = qs.salespersonId === salesperson.userId;
                return (
                  <ListItem
                    button
                    key={salesperson.userId}
                    sx={{ backgroundColor: selected ? "#d1e6d6" : "transparent" }}
                    secondaryAction={<Radio checked={selected} sx={{ pointerEvents: "none" }} />}
                    onClick={() => {
                      setQs("salespersonId", salesperson.userId);
                      setOpen(false);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={profiles[salesperson.userId]?.displayedName ?? idx.toString()}
                        src={profiles[salesperson.userId]?.pictureUrl ?? ""}
                      />
                    </ListItemAvatar>
                    <ListItemText>{profiles[salesperson.userId]?.displayedName ?? "Nieznany Handlowiec"}</ListItemText>
                  </ListItem>
                );
              })}
          </List>
        </Box>
      </Dialog>
      <SpeedDial
        ariaLabel={"Mobile menu"}
        sx={{ position: "absolute", bottom: 70, left: 15, zIndex: 1000 }}
        icon={<SpeedDialIcon />}
      >
        <SpeedDialAction
          key="salesperson"
          icon={<TransferWithinAStationIcon />}
          tooltipOpen
          tooltipTitle="Handlowiec"
          tooltipPlacement="right"
          onClick={() => setOpen(true)}
        />
        <SpeedDialAction
          key="save"
          icon={
            <NameSelectDialog
              action={(name) => props.onSave(name)}
              disabled={props.isLoading || !enabled || !props.isEdited}
              initialName={props.initialTradeRoute?.name ?? ""}
            >
              <IconButton disabled={props.isLoading || !enabled || !props.isEdited}>
                <SaveIcon />
              </IconButton>
            </NameSelectDialog>
          }
          tooltipOpen
          tooltipTitle="Zapisz"
          tooltipPlacement="right"
        />
        <SpeedDialAction
          key="schedule"
          icon={
            <DateSelectDialog action={(date) => props.onSchedule(date)} disabled={props.isLoading || !enabled}>
              <ScheduleIcon />
            </DateSelectDialog>
          }
          tooltipOpen
          tooltipTitle="Zaplanuj"
          tooltipPlacement="right"
        />
      </SpeedDial>
    </>
  );
};

const NameSelectDialog: React.FC<{
  action: (name: string) => void;
  disabled: boolean;
  initialName: string;
  children: React.ReactNode;
}> = (props) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>(props.initialName);

  const onAccept = () => {
    if (!name || props.disabled) return;
    props.action(name);
  };

  React.useEffect(() => {
    setName(props.initialName);
  }, [props.initialName]);

  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Nazwij trasę</DialogTitle>
        <DialogContent>
          <TextInput value={name} onChange={setName} label="Nazwa" />
        </DialogContent>
        <DialogActions>
          <Box mr={"auto"}>
            <Button onClick={() => setIsOpen(false)}>Anuluj</Button>
          </Box>
          <Button onClick={onAccept} color={"primary"} disabled={props.disabled}>
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
      <Box display="flex" justifyContent="center" onClick={() => !props.disabled && setIsOpen(true)}>
        {props.children}
      </Box>
    </>
  );
};

const DateSelectDialog: React.FC<{
  action: (date: Date) => void;
  disabled: boolean;
  children: React.ReactNode;
}> = (props) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [date, setDate] = React.useState<Date | null>(null);

  const onAccept = () => {
    if (!date || props.disabled) return;
    props.action(date);
  };

  React.useEffect(() => {
    if (isOpen) setDate(null);
  }, [isOpen]);

  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Wybierz datę</DialogTitle>
        <DialogContent>
          <DateInput
            disablePast={undefined}
            inputFormat={undefined}
            maxDate={undefined}
            minDate={undefined}
            value={date}
            onChange={setDate}
            label="Data"
          />
        </DialogContent>
        <DialogActions>
          <Box mr={"auto"}>
            <Button onClick={() => setIsOpen(false)}>Anuluj</Button>
          </Box>
          <Button onClick={onAccept} color={"primary"} disabled={props.disabled}>
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
      <Box display="flex" justifyContent="center" onClick={() => !props.disabled && setIsOpen(true)}>
        {props.children}
      </Box>
    </>
  );
};
