import { Uuid } from "@legacy-megarax/common";
import { DesignateHomeInput, SetManagerInput } from "@legacy-megarax/crm-contracts";
import { useProfile, useProfilesDictionary, useUsersResource } from "@legacy-megarax/iam-webapp";
import { BreadcrumbMarkers, PageTitleManager } from "@legacy-megarax/ui-components";
import { useRegionsResource } from "libs/@megarax/crm/resource-hooks/src";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

import { Result } from "@megaron/result";

import { useSalespeopleResource } from "../useSalespeopleResource";
import { SalespersonDetails } from "./SalespersonDetails";

export const SalespersonDetailsContainer = () => {
  const salespeopleResource = useSalespeopleResource();
  const regionsResource = useRegionsResource();
  const userResource = useUsersResource();

  const navigate = useNavigate();

  const params = useParams();
  const userId = Number(params.userId);

  const {
    data: salesperson,
    isFetching: isSalespeopleFetching,
    refetch,
  } = useQuery(["salesperson", userId], () => salespeopleResource.retrieve(userId), {});

  const { data: regionsDictionary, isFetching: isRegionsFetching } = useQuery(
    ["regions"],
    regionsResource.getRegionsDictionary,
    {},
  );

  const profile = useProfile({ id: userId });

  const profilesDict = useProfilesDictionary(
    salesperson?.value?.managerId ? [{ id: salesperson.value.managerId }] : [],
  );
  const managerProfile =
    salesperson?.value?.managerId && profilesDict ? profilesDict[salesperson.value.managerId] : undefined;

  const navigateToVisitSchedule = () => navigate(`/crm/pos/handlowcy/${userId}/wizyty`);

  if (!userId) {
    return null;
  }

  return (
    <>
      <PageTitleManager title={profile?.displayedName ?? "Handlowiec"} />
      <BreadcrumbMarkers
        breadcrumbs={[
          { title: "Handlowcy", to: "/crm/pos/handlowcy", id: "salespeople" },
          { title: profile?.displayedName ?? "?", to: `/crm/pos/handlowcy/${userId}`, id: "salesperson" },
        ]}
      />
      <SalespersonDetails
        regionsDictionary={regionsDictionary}
        salesperson={salesperson}
        profile={profile}
        managerProfile={salesperson?.value?.managerId !== null ? managerProfile : null}
        navigateToVisitSchedule={navigateToVisitSchedule}
        dataLoading={profile === undefined || isSalespeopleFetching || isRegionsFetching}
        reassignRegion={(regionUuid: Uuid) =>
          salespeopleResource.updateRegion({ onSuccess: refetch })(userId, { regionUuid }) as Result
        }
        getRegions={(searchText: string) => regionsResource.getRegionsItems({ searchText, limit: 50 })}
        getUsers={userResource.getUsers}
        setWorkStartTime={(workStartTime: number) =>
          salespeopleResource.setWorkStartTime({ onSuccess: refetch })(userId, { workStartTime }) as Result
        }
        designateHome={(input: DesignateHomeInput) =>
          salespeopleResource.designateHome({ onSuccess: refetch })(userId, input) as Result
        }
        setManager={(input: SetManagerInput) =>
          salespeopleResource.setManager({ onSuccess: refetch })(userId, input) as Result
        }
      />
    </>
  );
};
