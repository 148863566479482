import { Button } from "@dash/form";
import { useTheme } from "@emotion/react";
import { GlobalSearchDialog } from "@megarax/home";
import { motion } from "motion/react";
import React, { useEffect, useState } from "react";
import { GoAlertFill } from "react-icons/go";
import { MdMenu, MdOutlineHome, MdSearch } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { NavEntry } from "./NavEntry";
import { NavHeader } from "./NavHeader";
import { NotificationButton } from "./notifications/NotificationButton";
import { ProductionEnvAlert } from "./ProductionEnvAlert";
import { useDraggableDrawer } from "./useDraggableDrawer";

type Props = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  logo: React.ReactNode;
  footer?: React.ReactNode;
  hideHeader?: boolean;
  showProductionEnvAlert?: boolean;
  app?: string;
  entries: {
    to: string;
    icon?: React.ReactNode;
    children?: React.ReactNode;
  }[];
};

export const NavDrawer: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const navElement = React.useRef<HTMLDivElement>(null);
  const drawer = useDraggableDrawer({ baseHeight: 640, navElement });
  const [visibleEntries, setVisibleEntries] = useState(props.entries);

  useOnRouteChange(() => drawer.setIsExpanded(false));

  useEffect(() => {
    const handleResize = () => {
      if (navElement.current) {
        const availableWidth = navElement.current.offsetWidth;
        let totalWidth = 0;
        const entriesToShow = props.entries.filter(() => {
          const entryWidth = 50;
          totalWidth += entryWidth;
          return totalWidth <= availableWidth - 80;
        });
        setVisibleEntries(entriesToShow);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [props.entries]);

  return (
    <div css={{ position: "fixed", bottom: "0", width: "100%", zIndex: 1009 }}>
      <div
        ref={navElement}
        onTouchStart={drawer.onTouchStart}
        css={{
          maxHeight: drawer.drawerHeight,
          minHeight: "44px",
          transition: drawer.isDragging ? undefined : "max-height 0.15s linear",
          zIndex: 2,
          position: "relative",
        }}
      >
        <div
          css={{
            marginTop: "-44px",
            display: "flex",
            flexDirection: "column",
            padding: drawer.isExpanded ? "16px 12px" : "6px 12px",
            gap: "0.75rem",
            minHeight: "0",
            background: `linear-gradient(rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 50%), ${theme.colors.primary}`,
          }}
        >
          <div css={{ display: "flex", gap: "8px", justifyContent: "space-between" }}>
            {drawer.isExpanded ? (
              <NavHeader title={props.title} logo={props.logo} hideNotification />
            ) : (
              <div css={{ display: "flex", gap: "1rem" }}>
                {visibleEntries.map((entry) => (
                  <NavEntry {...entry} key={entry.to} color="white" isCollapsed />
                ))}
              </div>
            )}
            <div css={{ display: "flex", gap: "8px" }}>
              <GlobalSearchDialog
                renderTrigger={(onOpen) => (
                  <SearchDrawerButton
                    onClick={() => {
                      onOpen();
                      drawer.setIsExpanded(false);
                    }}
                    closeDrawer={() => drawer.setIsExpanded(false)}
                  />
                )}
              />
              <NotificationButton closeDrawer={() => drawer.setIsExpanded(false)} />
              {location.pathname !== "/" && drawer.isExpanded && (
                <Button
                  css={{
                    background: "rgba(225, 225, 225, 0.15)",
                    color: "white",
                    height: "32px",
                    width: "32px",
                  }}
                  icon={<MdOutlineHome size="16px" />}
                  onClick={() => navigate("/")}
                />
              )}
              <NavDrawerButton
                app={props.app}
                onToggle={() => drawer.setIsExpanded(!drawer.isExpanded)}
                isExpanded={drawer.isExpanded}
                showAlert={props.showProductionEnvAlert}
              />
            </div>
          </div>
          {props.showProductionEnvAlert && <ProductionEnvAlert />}

          {props.entries.length > 0 && (
            <div
              css={{
                display: "flex",
                flexDirection: "row",
                gap: "1.5rem",
              }}
            >
              <div
                css={{
                  flexGrow: 1,
                  display: "grid",
                  padding: "6px 1px",
                  gridTemplateColumns: "repeat(auto-fill, minmax(75px, 1fr))",
                  gap: "0.25rem",
                  overflow: "hidden",
                }}
              >
                {props.entries.map((entry) => (
                  <NavEntry {...entry} key={entry.to} color="white" />
                ))}
              </div>
            </div>
          )}
          <div css={{ marginTop: "auto" }}>{props.footer}</div>
        </div>
      </div>
      {drawer.isExpanded && (
        <motion.div
          onClick={() => drawer.setIsExpanded(false)}
          onTap={() => drawer.setIsExpanded(false)}
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1 }}
          css={{
            zIndex: 1,
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "black",
          }}
        />
      )}
    </div>
  );
};

const NavDrawerButton: React.FC<{
  onToggle?: () => void;
  isExpanded: boolean;
  showAlert?: boolean;
  app?: string;
}> = (props) => {
  const theme = useTheme();

  return (
    <div>
      <Button
        onClick={(e) => {
          props.onToggle && props.onToggle();
          e.stopPropagation();
        }}
        css={{
          background: "white",
          color: theme.colors.primary,
          height: "32px",
          width: "32px",
        }}
        icon={<MdMenu size="16px" />}
      />
      {props.showAlert && (
        <GoAlertFill
          size="16px"
          css={{ color: theme.colors.danger, position: "absolute", right: "-0.125rem", top: "-0.125rem" }}
        />
      )}
    </div>
  );
};

const SearchDrawerButton: React.FC<{ onClick: () => void; closeDrawer: () => void }> = ({ onClick, closeDrawer }) => {
  return (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        onClick();
        closeDrawer();
      }}
      css={{
        background: "rgba(225, 225, 225, 0.15)",
        color: "white",
        height: "32px",
        width: "32px",
      }}
      icon={<MdSearch size="16px" />}
    />
  );
};

const useOnRouteChange = (callback: () => void) => {
  useEffect(() => {
    callback();
  }, [window.location.pathname]);
};
