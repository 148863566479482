import { Currency, Gtin, PriceListChangeDto } from "@legacy-megarax/crm-contracts";
import { formatCurrency } from "@legacy-megarax/react-utils";
import { Table } from "@legacy-megarax/ui-components";
import { Alert, AlertTitle, Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { TradeItemDictionary } from "../PriceListDetailsContainer";

interface Props {
  openItemDetailsDialog: (gtin: Gtin) => void;
  upcomingChanges: PriceListChangeDto[];
  currency: Currency;
  tradeItems: TradeItemDictionary | null;
}

export const UpcomingChangesTable: React.FC<Props> = ({
  openItemDetailsDialog,
  upcomingChanges,
  currency,
  tradeItems,
}) => {
  const classes = useStyles();

  const nameColumn = tradeItems
    ? {
        title: "Nazwa",
        getValue: (item: PriceListChangeDto) => tradeItems[item.gtin].itemName,
      }
    : null;

  const otherColumns = [
    {
      title: "Cena netto",
      getValue: (item: PriceListChangeDto) =>
        item.netPrice ? formatCurrency(item.netPrice.toNumber(), currency) : "Usunięcie",
    },
    { title: "GTIN (EAN)", getValue: (item: PriceListChangeDto) => item.gtin },
    {
      title: "Data wprowadzenia",
      getValue: (item: PriceListChangeDto) => item.activeFrom.toLocaleDateString(),
    },
  ];

  const columnsToDisplay = nameColumn ? [nameColumn, ...otherColumns] : otherColumns;

  return (
    <>
      {upcomingChanges.length !== 0 && (
        <Alert severity="info" variant="outlined" className={classes.alert}>
          <AlertTitle>Nadchodzące zmiany</AlertTitle>
          <Container maxWidth="md" disableGutters>
            <Table
              items={upcomingChanges}
              columns={columnsToDisplay}
              onSelected={(key) => {
                const gtin = upcomingChanges.find((change) => change.uuid === key)?.gtin;
                if (!gtin) return;
                openItemDetailsDialog(gtin);
              }}
              getKey={(item) => item.uuid}
            />
          </Container>
        </Alert>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    alert: {
      margin: theme.spacing(0, 1),
      overflow: "auto",
      "& > .MuiAlert-message": {
        width: "100%",
      },
    },
  };
});
