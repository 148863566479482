import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useRef } from "react";
import { Page } from "react-pdf";
import { Document } from "react-pdf/dist/esm/entry.webpack";
import { useReactToPrint } from "react-to-print";

import { ThreadDto } from "@megaron/invoices-contracts";

import { ApprovalTile } from "../ApprovalTile";
import { SUPPORTED_TYPES } from "../attachment/FilePreview";
import { ThreadHistoryTile } from "../history/ThreadHistoryTile";
import { SignatureSection } from "../signatures/SignatureSection";
import { ThreadDetailsCard } from "../ThreadDetailsCard";
import { ThreadReceivedCheckbox } from "../ThreadReceivedCheckbox";
import { LoadingModal } from "./LoadingModal";
import { useDocumentPrint } from "./useDocumentPrint";

interface Props {
  displayConditions: {
    isApproved: boolean;
  };
  thread: ThreadDto;
  onEndPrint: () => void;
}

export const PrintView: React.FC<Props> = (props) => {
  const classes = useStyles();
  const componentRef = useRef(null);
  const { state, onDocumentload, onPageRender, onError, allPagesCount, pagesLoaded, pages } = useDocumentPrint(
    props.thread.attachments,
  );
  const resolvePrint = useRef<() => void>(undefined);

const handlePrint = useReactToPrint({
  contentRef: componentRef,
  documentTitle: props.thread.title,
  onAfterPrint: props.onEndPrint,
  onBeforePrint: () =>
    new Promise((resolve) => {
      resolvePrint.current = resolve as any;
    }),
});
  useEffect(() => {
    if (componentRef.current && handlePrint) handlePrint();
  }, [componentRef.current, state]);

  useEffect(() => {
    if (state === "loaded" && resolvePrint.current) {
      resolvePrint.current();
    }
  }, [state, resolvePrint]);

  const approvalEvent = props.thread.history.find((entry) => entry.entryType === "threadApproved");

  return (
    <>
      <LoadingModal loading={state !== "loaded"} />
      <div className={classes.printBoxWrapper}>
        <div ref={componentRef} className={classes.printBox}>
          <div>
            <div className={classes.attachmentsContainer}>
              {props.thread.attachments.map((attachment) => {
                if (!SUPPORTED_TYPES.includes(attachment.fileType)) return null;
                if (pages && pages[attachment.uuid]?.error) return null;
                if (attachment.fileType === "application/pdf")
                  return (
                    <Document
                      key={attachment.uuid}
                      file={attachment.url}
                      onLoadSuccess={onDocumentload(attachment.uuid)}
                      onLoadError={() => onError(attachment.uuid)}
                    >
                      {pages &&
                        pages[attachment.uuid] &&
                        [...Array(pages[attachment.uuid].number).keys()].map((_v, idx) => {
                          return (
                            <Page
                              key={`page_${idx}`}
                              renderAnnotationLayer={false}
                              renderTextLayer={false}
                              pageNumber={idx + 1}
                              width={1000}
                              className={classes.document}
                              onRenderSuccess={onPageRender}
                            />
                          );
                        })}
                    </Document>
                  );
                return (
                  <div className={classes.document} key={attachment.uuid}>
                    <img src={attachment.url} />
                  </div>
                );
              })}
            </div>
          </div>

          <div className={props.thread.attachments.length > 0 ? classes.invoice : classes.document}>
            <div css={{ display: "flex", flexDirection: "column", maxWidth: "1000px", margin: "20px", gap: "16px" }}>
              <h1>{`${props.thread.referenceNumber} ${props.thread.title}`} </h1>
              <div css={{ display: "flex", gap: "16px" }}>
                <aside css={{ display: "flex", flexDirection: "column", gap: "16px", flex: 1 }}>
                  <ThreadDetailsCard thread={props.thread} queryKey={""} isPrint />
                  <ApprovalTile actingUser={approvalEvent?.actingUser ?? ""} time={approvalEvent?.time} />
                  <ThreadReceivedCheckbox thread={props.thread} queryKey={""} />
                  {props.thread.decreeNumber && (
                    <span
                      css={{
                        fontSize: "16px",
                        fontWeight: 700,
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      {`Numer dekretu: ${props.thread.decreeNumber}`}
                    </span>
                  )}
                </aside>
                <main
                  css={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    marginTop: "16px",
                    width: "100%",
                    flex: 1,
                  }}
                >
                  <SignatureSection thread={props.thread} queryKey={""} isPrint />
                </main>
              </div>
              <div css={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                {props.thread.history.map((entry, i) => (
                  <ThreadHistoryTile key={i} entry={entry} isPrint approvedAt={props.thread.approvedAt} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const useStyles = makeStyles((theme) => {
  return {
    printBoxWrapper: { display: "none" },
    printBox: {
      width: "1000px",
    },
    attachmentsContainer: {
      display: "block",
      width: "1000px",
    },
    document: {
      display: "block",
      width: "1000px",
      "& > img": {
        maxWidth: "1000px",
      },
      pageBreakInside: "avoid",
    },
    invoice: {
      display: "block",
      width: "1000px",
      "& > img": {
        maxWidth: "1000px",
      },
      pageBreakInside: "avoid",
      pageBreakBefore: "auto",
    },
    column: {
      display: "flex",
      flexDirection: "column",
    },

    reverse: {
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },

    main: {
      minWidth: "0",
    },
    titleBox: {
      maxWidth: "1000px",
      margin: theme.spacing(2),
      wordWrap: "break-word",
      alignItems: "center",
      justifyContent: "space-between",
      "& > h5": {
        marginRight: "1rem",
      },
    },
  };
});
