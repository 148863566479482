import { sortFilterSerializer } from "@legacy-megarax/rest-resource";
import { Serializers } from "@legacy-megarax/serializers";

export interface TradeItemListSortFilter {
  field: "gtin" | "name";
  order?: "DESC" | "ASC";
}

export interface TradeItemListQueryFilters {
  limit?: number;
  offset?: number;
  searchText?: string;
  sortBy?: TradeItemListSortFilter[];
}

export const tradeItemListQueryFiltersSerializer = Serializers.object<TradeItemListQueryFilters>({
  limit: Serializers.integer.optional(),
  offset: Serializers.integer.optional(),
  searchText: Serializers.string.optional(),
  sortBy: sortFilterSerializer("gtin", "name").optional(),
});
