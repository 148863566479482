import { useContext } from "react";

import { FeedbackSnackbarContext } from "../display/FeedbackSnackbar";

export const useSnackbarErrorHandler = <TErrorCode extends string>(
  errorMessages: {
    [errorCode in TErrorCode]: string;
  },
  fallbackMessage = "Wystąpił nieoczekiwany błąd.",
) => {
  const snackbarContext = useContext(FeedbackSnackbarContext);

  return <TInputCode extends TErrorCode | string>(code: TInputCode) => {
    snackbarContext.pushMessage({
      content: errorMessages[code as TErrorCode] ?? fallbackMessage,
      severity: "error",
    });
    return code;
  };
};

export const commonErrorsMap = {
  PermissionDenied: "Nie masz wystarczających uprawnień do wykonania tej operacji.",
  AuthenticationFailed: "Uwierzytelnienie nie powiodło się.",
  InternalServerError: "Wystąpił błąd po stronie serwera.",
  ConnectionFailed: "Błąd połączenia z serwerem.",
};
