import { Serializer, Serializers, ValidationError } from "@legacy-megarax/serializers";

import { Failure, Ok, Result } from "@megaron/result";

export interface ResourceAction<TResponse, TQuery, TReqBody> {
  readonly path: string;
  readonly name: string;
  readonly method: "get" | "post" | "put" | "delete";
  readonly responseSerializer: Serializer<TResponse>;
  readonly querySerializer?: Serializer<TQuery>;
  readonly requestBodySerializer?: Serializer<TReqBody>;
}

export interface ResourceParamNode<TParam, TNestedActions extends ResourceNodes> {
  readonly paramPattern: string;
  readonly paramName: string;
  readonly paramSerializer: Serializer<TParam>;
  readonly children: TNestedActions;
  readonly path?: string;
}

export interface NestedResourceNode<TNestedActions extends ResourceNodes> {
  path: string;
  children: TNestedActions;
}

export type ResourceNode = ResourceAction<any, any, any> | ResourceParamNode<any, any> | NestedResourceNode<any>;

export const isNodeAction = (node: ResourceNode): node is ResourceAction<any, any, any> => "method" in node;
export const isNodeParam = (node: ResourceNode): node is ResourceParamNode<any, any> => "paramSerializer" in node;
export const isNodeNestedResource = (node: ResourceNode): node is NestedResourceNode<any> =>
  "path" in node && "children" in node;

export interface ResourceNodes {
  [name: string]: ResourceNode;
}

const idParamSerialzier: Serializer<number> = {
  forceDeserialize: (raw: any) => {
    if (Number.isInteger(parseInt(raw))) return parseInt(raw);
    throw new ValidationError();
  },
  serialize: (id: number) => id,
};

export const StringResourceParam = <TNestedActions extends ResourceNodes>(
  paramName: string,
  path: string,
  nested: TNestedActions,
): ResourceParamNode<string, TNestedActions> => ({
  children: nested,
  paramName,
  paramPattern: "\\b[a-zA-Z0-9]+\\b",
  paramSerializer: Serializers.string,
  path,
});

export const IntegerStringResourceParam = <TNestedActions extends ResourceNodes>(
  paramName: string,
  nested: TNestedActions,
): ResourceParamNode<string, TNestedActions> => ({
  children: nested,
  paramName,
  paramPattern: "\\d+",
  paramSerializer: Serializers.string,
});

export const IdResourceParam = <TNestedActions extends ResourceNodes>(
  paramName: string,
  nested: TNestedActions,
): ResourceParamNode<number, TNestedActions> => ({
  children: nested,
  paramName,
  paramPattern: "\\d+",
  paramSerializer: idParamSerialzier,
});

export const UuidResourceParam = <TNestedActions extends ResourceNodes>(
  paramName: string,
  nested: TNestedActions,
): ResourceParamNode<string, TNestedActions> => ({
  paramName,
  children: nested,
  paramPattern: "\\b[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}\\b",
  paramSerializer: Serializers.uuid(),
});

export const ResourceAction = <TResponse, TQuery = undefined, TReqBody = undefined>({
  path,
  name,
  method,
  responseSerializer = Serializers.identity(),
  querySerializer,
  requestBodySerializer,
}: {
  name: string;
  path?: string;
  method?: "get" | "post" | "put" | "delete";
  responseSerializer?: Serializer<TResponse>;
  querySerializer?: Serializer<TQuery>;
  requestBodySerializer?: Serializer<TReqBody>;
}): ResourceAction<TResponse, TQuery, TReqBody> => ({
  method: method ?? "get",
  name: name,
  path: path ?? name,
  responseSerializer,
  querySerializer,
  requestBodySerializer,
});

export const ResourceActionV2 = <TResValue, TResError = never, TQuery = undefined, TReqBody = undefined>({
  path,
  name,
  method,
  responseValueSerializer = Serializers.identity(),
  responseErrorSerializer = Serializers.identity(),
  querySerializer,
  requestBodySerializer,
}: {
  name: string;
  path?: string;
  method?: "get" | "post" | "put" | "delete";
  responseValueSerializer?: Serializer<TResValue>;
  responseErrorSerializer?: Serializer<TResError>;
  querySerializer?: Serializer<TQuery>;
  requestBodySerializer?: Serializer<TReqBody>;
}): ResourceAction<Ok<TResValue> | Failure<TResError>, TQuery, TReqBody> => ({
  method: method ?? "get",
  name: name,
  path: path ?? name,
  responseSerializer: Serializers.result<TResValue, TResError>(responseValueSerializer, responseErrorSerializer),
  querySerializer,
  requestBodySerializer,
});

// TODO rename to NestedResource
export const NestedResourceNode = <TNested extends ResourceNodes>(
  path: string,
  nested: TNested,
): NestedResourceNode<TNested> => ({
  path,
  children: nested,
});

export interface BaseResource<TResource extends ResourceNodes> {
  nodes: TResource;
  basePath: string[];
}

export const BaseResource = <TResource extends ResourceNodes>(
  basePath: string[],
  actions: TResource,
): BaseResource<TResource> => ({ nodes: actions, basePath });
