import { useToast } from "@dash/toast";
import {
  AsyncButton,
  AutocompleteInput,
  DateInput,
  DecimalInput,
  MiniSelectInput,
  SwitchInput,
  TextInput,
} from "@legacy-megarax/ui-components";
import { Alert, AlertTitle, Box, Container, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom-v5-compat";

import { AttachmentUpload, Invoice } from "@megaron/invoices-contracts";
import { useClientManager } from "@megaron/react-clients";

import { FileDropzone } from "./FileDropzone";
import {
  FormErrors,
  formValidator,
  FormValue,
  getErrorMessage,
  initialFormState,
  InvoiceFormValue,
} from "./newThreadFormValidations";

export const NewThreadForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [formValue, setFormValue] = useState<FormValue>(initialFormState());
  const [formErrors, setFormErrors] = useState<FormErrors>();

  const invoicesMutation = useClientManager("invoices").startNewThread().useMutation();

  const toast = useToast();

  const onTitleInput = (title: string) => {
    setFormValue({ ...formValue, title });
  };
  const onInvoiceInput = (fieldName: keyof InvoiceFormValue) => (value: Invoice[typeof fieldName] | undefined) => {
    setFormValue({
      ...formValue,
      invoice: { ...formValue.invoice, [fieldName]: value },
    });
  };
  const onFileInput = (attachments: AttachmentUpload[]) => {
    setFormValue({ ...formValue, attachments });
  };
  const onCreated = () => {
    toast.info("Pomyślnie utworzono wątek");

    navigate(`/faktury/watki/${formValue.uuid}`, { replace: true });
  };

  const onSubmit = () => {
    const result = formValidator(formValue);
    if (result.successful) {
      invoicesMutation.mutate(result.value, {
        onSuccess: onCreated,
        onError: () => toast.error("Nie udało się utworzyć wątku"),
      });
      return;
    }
    setFormErrors(result.failure);

    toast.error("Popraw błędy w formularzu aby kontynuować.");

    return;
  };

  return (
    <Container component={"form"} maxWidth="sm" className={classes.root}>
      <AutocompleteInput
        fullWidth
        variant="outlined"
        value={formValue.title}
        label="Tytuł wątku"
        getOptions={() =>
          new Promise<string[]>((resolve) => {
            resolve(["test"]);
          })
        }
        onChange={onTitleInput}
        error={getErrorMessage(formErrors?.title)}
      />
      <TextInput
        fullWidth
        label={"Numer faktury"}
        variant="outlined"
        value={formValue.invoice.invoiceNumber ?? ""}
        onChange={onInvoiceInput("invoiceNumber")}
        error={getErrorMessage(formErrors?.invoice?.invoiceNumber)}
      />
      <AutocompleteInput
        fullWidth
        label={"Wystawca faktury"}
        variant="outlined"
        getOptions={() =>
          new Promise<string[]>((resolve) => {
            resolve(["test"]);
          })
        }
        value={formValue.invoice.issuer ?? ""}
        onChange={onInvoiceInput("issuer")}
        error={getErrorMessage(formErrors?.invoice?.issuer)}
      />
      <Box className={classes.currencyBox}>
        <DecimalInput
          label={"Kwota netto"}
          variant="outlined"
          value={formValue.invoice.total}
          onChange={onInvoiceInput("total")}
          error={getErrorMessage(formErrors?.invoice?.total)}
        />

        <MiniSelectInput
          label={"Waluta"}
          variant="outlined"
          value={formValue.invoice.currency ?? "PLN"}
          onChange={onInvoiceInput("currency")}
          options={["PLN", "EUR", "USD", "GBP"]}
        />
      </Box>
      <DateInput
        value={formValue.invoice.issueDate}
        onChange={onInvoiceInput("issueDate")}
        fullWidth
        label="Data wystawienia"
        error={getErrorMessage(formErrors?.invoice?.issueDate)}
        disablePast={undefined}
        inputFormat={undefined}
        maxDate={undefined}
        minDate={undefined}
      />
      <DateInput
        value={formValue.invoice.dueDate}
        onChange={onInvoiceInput("dueDate")}
        fullWidth
        label="Data płatności"
        disablePast={undefined}
        inputFormat={undefined}
        maxDate={undefined}
        minDate={undefined}
      />
      <DateInput
        value={formValue.invoice.receivedDate}
        onChange={onInvoiceInput("receivedDate")}
        fullWidth
        label="Data otrzymania faktury"
        error={getErrorMessage(formErrors?.invoice?.receivedDate)}
        disablePast={undefined}
        inputFormat={undefined}
        maxDate={undefined}
        minDate={undefined}
      />
      <FileDropzone onChange={onFileInput} />
      <SwitchInput
        label={"Tylko elektroniczna"}
        value={formValue.invoice.isDigital}
        setValue={onInvoiceInput("isDigital")}
      />
      <AsyncButton className={classes.nextButton} variant="contained" color="primary" asyncAction={onSubmit}>
        Dalej
      </AsyncButton>
    </Container>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      "& > *": {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
    currencyBox: {
      "& > :first-child": {
        marginRight: theme.spacing(1),
        width: `calc(70% - ${theme.spacing(1)})`,
      },
      "& > :last-child": {
        marginLeft: theme.spacing(1),
        width: `calc(30% - ${theme.spacing(1)})`,
      },
    },

    nextButton: {
      display: "block",
      marginLeft: "auto",
    },
  }),
);
