import { Checkbox } from "@dash/form";
import { useToast } from "@dash/toast";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { ProductAvailabiliyStatus } from "@megaron/crm-contracts";
import { useClientManager, useServiceClient } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

type Props = {
  customerUuid: Uuid;
  queryKey: string | string[];
};

type ItemOption = {
  label: string;
  value: string;
};

export const SaveProductAvailability: React.FC<Props> = ({ customerUuid, queryKey }) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const [items, setItems] = useState<ItemOption[]>([]);
  const [availabilityMap, setAvailabilityMap] = useState<Record<string, boolean>>({});

  const addProductAvailabilityMutation = useClientManager("crm").addProductAvailability().useMutation();
  const customerAvailabilityQuery = useClientManager("crm")
    .customerProductsAvailabilityQuery()
    .useQuery({ customerId: customerUuid });

  const docs = useServiceClient("docs");

  useEffect(() => {
    const fetchItems = async () => {
      const results = await docs.searchItems({ searchText: "" });
      if (!results?.value?.items) return;
      const filteredItems = results.value.items.filter((i) => i.isInAvailability === true);
      const order = [
        "A-2_1,5",
        "A-2_5",
        "A-2_20",
        "A-6_10",
        "C-50_1,5",
        "C-50_5",
        "C-50_10",
        "C-50_17",
        "C-50s_5",
        "S-8_20",
        "A-11_17",
        "Dv-21_17",
        "Dv-20_1,5",
        "Dv-20_5",
        "Dv-20_20",
        "Df-16_20",
        "F-60_5",
        "F-60_15",
        "F-60_18",
        "F-2_5",
        "F-2_15",
        "Dw-13_5",
        "Dw-13_15",
        "Gt-120_10",
        "Gt-120_20",
        "Gs-1_10",
        "Gs-1_20",
        "Gv-15_20",
        "Gs-2_20",
        "Gs-3_20",
        "Gl-5_20",
        "Gl-3_20",
        "Uv-903_1",
        "Uv-811_1",
        "Uv-715_1",
        "Uv-213_1",
        "Uv-903_3",
        "Uv-811_3",
        "Uv-715_3",
        "Uv-213_3",
        "MR-1_17",
        "SMT-1_1,5",
        "SMT-1_5",
        "SMT-1_17",
      ];
      const normalizeName = (name: string) => name.replace(/\/\d+/g, "").trim();
      const inOrderItems = order
        .map((name) => filteredItems.find((item) => normalizeName(item.itemName) === normalizeName(name)))
        .filter((item): item is (typeof filteredItems)[number] => !!item);
      const leftoverItems = filteredItems.filter(
        (item) => !order.some((name) => normalizeName(item.itemName) === normalizeName(name)),
      );
      const sortedItems = [...inOrderItems, ...leftoverItems];
      const options: ItemOption[] = sortedItems.map((item) => ({
        label: item.itemName ?? item.itemId,
        value: item.itemId,
      }));

      setItems(options);
      const availabilityData = customerAvailabilityQuery.data ?? [];
      const initialMap: Record<string, boolean> = {};
      for (const item of options) {
        const match = availabilityData.find((a) => a.itemId === item.value);
        initialMap[item.value] = match?.status === "available";
      }
      setAvailabilityMap(initialMap);
    };
    fetchItems();
  }, [customerAvailabilityQuery.data]);

  const toggleAvailability = (itemId: string, newValue: boolean) => {
    const status: ProductAvailabiliyStatus = newValue ? "available" : "unavailable";

    setAvailabilityMap((prev) => ({
      ...prev,
      [itemId]: newValue,
    }));

    addProductAvailabilityMutation.mutate(
      {
        itemId,
        posId: customerUuid,
        status,
        date: new Date(),
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(queryKey);
        },
        onError: () => {
          toast.error(`Błąd podczas zapisywania dostępności produktu`);
        },
      },
    );
  };

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "18px",
        overflowY: "auto",
        maxHeight: "75dvh",
        padding: "2px",
        justifyContent: "center",
      }}
    >
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "12px 16px",
          overflowY: "auto",
          borderRadius: "8px",
          padding: "12px",
          justifyContent: "center",
        }}
      >
        {items.map((item) => (
          <Checkbox
            key={item.value}
            value={availabilityMap[item.value] ?? false}
            onChange={(val) => toggleAvailability(item.value, val)}
            label={item.label}
          />
        ))}
      </div>
    </div>
  );
};
