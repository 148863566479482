import { BaseResource, NestedResourceNode, ResourceAction, UuidResourceParam } from "@legacy-megarax/rest-resource";
import { MSerializers } from "@legacy-megarax/serializers";

import { currencies, gtinSerializer } from "../../common";
import list from "./list";
import maintenance from "./maintenance";
import priceListItems from "./priceListItems";
import priceListProposals from "./priceListProposals";
import retrieve from "./retrieve";
import { upcomingChangesAction } from "./upcomingChanges";

const createPriceListInputSerializer = MSerializers.object({
  name: MSerializers.string,
  uuid: MSerializers.uuid(),
  currency: MSerializers.stringOneOf(...currencies),
});

const renamePriceListInputSerializer = MSerializers.object({
  name: MSerializers.string,
});

const proposeChangesInputSerializer = MSerializers.object({
  activeFrom: MSerializers.datetime.nullable(),
  comment: MSerializers.string,
  changes: MSerializers.array(
    MSerializers.object({
      gtin: gtinSerializer,
      netPrice: MSerializers.decimal.nullable(),
    }),
  ),
});

export const priceListResource = BaseResource(["pricing", "priceLists"], {
  maintenance,
  create: ResourceAction({
    name: "create",
    method: "post",
    path: "",
    requestBodySerializer: createPriceListInputSerializer,
    responseSerializer: MSerializers.result<void, any>(),
  }),
  list,
  byUuid: UuidResourceParam("priceListUuid", {
    retrieve,
    items: priceListItems,
    rename: ResourceAction({
      name: "rename",
      method: "put",
      path: "",
      requestBodySerializer: renamePriceListInputSerializer,
      responseSerializer: MSerializers.result<void, any>(),
    }),
    upcomingChanges: upcomingChangesAction,
    proposals: NestedResourceNode("proposals", {
      all: priceListProposals,

      create: ResourceAction({
        name: "proposeChanges",
        method: "post",
        path: "",
        requestBodySerializer: proposeChangesInputSerializer,
        responseSerializer: MSerializers.result<void, any>(),
      }),
      byUuid: UuidResourceParam("proposalUuid", {
        approve: ResourceAction({
          name: "approveProposal",
          path: "approve",
          method: "post",
          responseSerializer: MSerializers.result<void, any>(),
        }),
        reject: ResourceAction({
          name: "rejectProposal",
          path: "reject",
          method: "post",
          responseSerializer: MSerializers.result<void, any>(),
        }),
        retract: ResourceAction({
          name: "retractProposal",
          path: "",
          method: "delete",
          responseSerializer: MSerializers.result<void, any>(),
        }),
      }),
    }),
  }),
});
