import { BaseResource, NestedResourceNode, ResourceActionV2, UuidResourceParam } from "@legacy-megarax/rest-resource";
import { MSerializers } from "@legacy-megarax/serializers";

import { commentDtoSerializer } from "./commentDto";
import { postCommentCommandErrors } from "./errors";
import { postCommentInputSerializer } from "./inputs";

const commentNestedResource = () => ({
  commentList: ResourceActionV2({
    name: "commentList",
    method: "get",
    path: "",
    responseValueSerializer: MSerializers.array(commentDtoSerializer),
  }),
  post: ResourceActionV2({
    name: "post",
    method: "post",
    path: "",
    requestBodySerializer: postCommentInputSerializer,
    responseErrorSerializer: MSerializers.stringOneOf(...postCommentCommandErrors),
  }),
});

export const orderCommentResource = BaseResource(["discussion", "orderComments"], {
  byOrderUuid: UuidResourceParam("orderUuid", commentNestedResource()),
});

export const customerCommentResource = BaseResource(["discussion", "customers"], {
  byCustomerUuid: UuidResourceParam("customerUuid", commentNestedResource()),
});

export const priceListProposalCommentResource = BaseResource(["discussion", "priceListProposalComments"], {
  byPriceListUuid: UuidResourceParam("priceListUuid", {
    proposalComments: NestedResourceNode("proposals", {
      byProposalUuid: UuidResourceParam("proposalUuid", commentNestedResource()),
    }),
  }),
});
