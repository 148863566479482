import { DateString, dateStringSerializer, Uuid, uuidSerializer } from "@legacy-megarax/common";
import { Serializers } from "@legacy-megarax/serializers";
import Decimal from "decimal.js";

import { currencies, Currency, Gtin, gtinSerializer } from "../common";

export type NewOrderInput = {
  sellToCustomerUuid: Uuid;
  billToCustomerUuid: Uuid;
  payerDepartmentUuid: Uuid;
  orderDate: DateString;
  currency: Currency;
  comment: string;
  externalDocumentNumber: string;
  lines: NewOrderLineInput[];
  shipToCity?: string;
  shipToPostCode?: string;
  shipToAddressLine1?: string;
  shipToAddressLine2?: string;
  shipToCountry?: string;
  requestedDeliveryDate?: Date;
};

type NewOrderLineInput = {
  gtin: Gtin;
  quantity: Decimal;
  price: Decimal;
  discountRate: Decimal;
};

export const newOrderLineInputSerializer = Serializers.object<NewOrderLineInput>({
  gtin: gtinSerializer,
  quantity: Serializers.decimal,
  price: Serializers.decimal,
  discountRate: Serializers.decimal,
});

export const newOrderInputSerializer = Serializers.object<NewOrderInput>({
  comment: Serializers.string,
  payerDepartmentUuid: uuidSerializer,
  sellToCustomerUuid: uuidSerializer,
  billToCustomerUuid: uuidSerializer,
  shipToCity: Serializers.string.optional(),
  shipToPostCode: Serializers.string.optional(),
  externalDocumentNumber: Serializers.string,
  shipToAddressLine1: Serializers.string.optional(),
  shipToAddressLine2: Serializers.string.optional(),
  shipToCountry: Serializers.string.optional(),
  requestedDeliveryDate: Serializers.dateOnly.optional(),
  lines: Serializers.array(newOrderLineInputSerializer),
  orderDate: dateStringSerializer,
  currency: Serializers.stringOneOf(...currencies),
});
