import { AmqpTopic } from "@legacy-megarax/amqp";
import { UserReference } from "@legacy-megarax/common";
import { Serializers } from "@legacy-megarax/serializers";
import Decimal from "decimal.js";

import { Currency, Gtin, gtinSerializer } from "../common";
import { PriceListChangeDto, PriceListProposalDto } from "./restApi";

export interface CreatePriceListInput {
  uuid: string;
  name: string;
  currency: Currency;
}

export interface ProposeChangeInput {
  activeFrom: Date | null;
  comment: string;
  gtin: Gtin;
  netPrice: Decimal | null;
}

export interface RenamePriceListInput {
  name: string;
}

export type PriceListProposalStatusDto = {
  priceLists: {
    uuid: string;
    awaitingProposalCount: number;
  }[];
};

export type PriceListProposalStatusEventMessage = {
  changeType: "proposalCountIncreased" | "proposalCountDecreased";
  status: PriceListProposalStatusDto;
};

export const priceListProposalStatusSerializer = Serializers.object<PriceListProposalStatusDto>({
  priceLists: Serializers.array(
    Serializers.object({
      awaitingProposalCount: Serializers.integer,
      uuid: Serializers.uuid(),
    }),
  ),
});

export const priceListProposalStatusEventAmqpTopic: AmqpTopic<PriceListProposalStatusEventMessage> = {
  key: "pricing.priceList.proposalStatusEvent",
  serializer: Serializers.object<PriceListProposalStatusEventMessage>({
    changeType: Serializers.identity(),
    status: priceListProposalStatusSerializer,
  }),
};

export type PriceChangeApprovedEventMessage = {
  type: "priceChangeApproved";
  proposalUuid: string;
  priceListUuid: string;
  gtin: Gtin;
  newPrice: Decimal | null;
  activeFrom: Date;
};

export type PriceChangeProposedEventMessage = {
  type: "priceChangeProposed";
  priceListUuid: string;
  proposalUuid: string;
};

export type ProposalRetractedEventMessage = {
  type: "proposalRetracted";
  priceListUuid: string;
  proposalUuid: string;
};

export type ProposalRejectedEventMessage = {
  type: "proposalRejected";
  priceListUuid: string;
  proposalUuid: string;
};

export type PriceListEventMessage =
  | PriceChangeApprovedEventMessage
  | PriceChangeProposedEventMessage
  | ProposalRetractedEventMessage
  | ProposalRejectedEventMessage;

export const priceListEventMessageSerializer = Serializers.object<PriceListEventMessage>({
  priceListUuid: Serializers.uuid(),
  proposalUuid: Serializers.uuid(),
  type: Serializers.stringOneOf(
    "priceChangeApproved",
    "priceChangeProposed",
    "proposalRetracted",
    "proposalRejected",
  ) as any,
  gtin: gtinSerializer.optional() as any,
  newPrice: Serializers.optional(Serializers.decimal.nullable()) as any,
  activeFrom: Serializers.datetime.optional() as any,
});

export const priceListEventAmqpTopic: AmqpTopic<PriceListEventMessage> = {
  key: "pricing.priceList.priceListEvent",
  serializer: priceListEventMessageSerializer,
};

export interface PriceListRejectedChange {
  uuid: string;
  gtin: Gtin;
  activeFrom: Date | null;
  netPrice: Decimal | null;
  proposedBy: UserReference;
  proposedAt: Date;
  comment: string;
  rejectedBy: UserReference;
  rejectedAt: Date;
}

export interface PriceListItemDetail {
  gtin: Gtin;
  current: {
    netPrice: Decimal | null;
    activeFrom: Date;
  } | null;
  proposals: PriceListProposalDto[];
  allChanges: PriceListChangeDto[];
  rejectedChanges: PriceListRejectedChange[];
}
