import {
  Currency,
  Gtin,
  PriceListChangeDto,
  PriceListDetailCurrentPriceDto,
  TradeItemDto,
} from "@legacy-megarax/crm-contracts";
import { PageTitleManager } from "@legacy-megarax/ui-components";
import React from "react";
import { PathMatch, useMatch, useNavigate, useParams } from "react-router-dom-v5-compat";

import { Uuid } from "@megaron/uuid";

import { CURRENT_DETAILS, getCurrentDetailsUrl, PROPOSAL_NEW } from "../../routes";
import { TradeItemDictionary } from "../PriceListDetailsContainer";
import { NewProposalView } from "../Proposals/NewProposal";
import { CurrentPricesTable } from "./CurrentPricesTable";
import { TradeItemDetailsContainer } from "./TradeItemDetails";
import { UpcomingChangesTable } from "./UpcomingChangesTable";

interface Props {
  currentPrices: PriceListDetailCurrentPriceDto[];
  upcomingChanges: PriceListChangeDto[];
  tradeItems: TradeItemDictionary | null;
  permissions: {
    seeUpcoming: boolean;
    seeCurrent: boolean;
  };
  getTradeItemDetails: (gtin: Gtin) => TradeItemDto | undefined;
  currency: Currency;
}

export const PriceListCurrentView: React.FC<Props> = ({
  currentPrices,
  upcomingChanges,
  permissions,
  getTradeItemDetails,
  tradeItems,
  currency,
}) => {
  const params = useParams<{ uuid: string }>();
  const navigate = useNavigate();

  const tradeItemsDetailsMatchPatch = useMatch(`${CURRENT_DETAILS}/*`);
  const newProposalMatchPatch = useMatch(PROPOSAL_NEW);

  const openItemDetailsDialog = (gtin: Gtin) => navigate(getCurrentDetailsUrl(params.uuid || ("" as Uuid))(gtin));

  return (
    <>
      <PageTitleManager title="Aktualne cenniki" />
      {tradeItemsDetailsMatchPatch && (
        <TradeItemDetailsContainer
          getTradeItemDetails={getTradeItemDetails}
          closeDialog={() => navigate(-1)}
          currency={currency}
          gtin={(tradeItemsDetailsMatchPatch as PathMatch<"uuid" | "*" | "gtin"> | null)?.params.gtin as Gtin}
          uuid={params.uuid as Uuid}
        />
      )}
      {newProposalMatchPatch && (
        <NewProposalView tradeItems={tradeItems} reloadProposals={() => null} currency={currency} />
      )}
      {permissions.seeUpcoming && (
        <UpcomingChangesTable
          openItemDetailsDialog={openItemDetailsDialog}
          upcomingChanges={upcomingChanges}
          currency={currency}
          tradeItems={tradeItems}
        />
      )}
      {permissions.seeCurrent && (
        <CurrentPricesTable
          currentPrices={currentPrices}
          openItemDetailsDialog={openItemDetailsDialog}
          currency={currency}
          tradeItems={tradeItems}
        />
      )}
    </>
  );
};
