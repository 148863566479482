import { SectionHeader } from "@dash/page";
import { RowCellContent, Table, TableBody, TableHeadColumn } from "@dash/table";

import { OrderLineDto } from "@megaron/crm-contracts";

type Props = {
  orderItems: OrderLineDto[];
};

export const OrderItems: React.FC<Props> = ({ orderItems }) => {
  const tableColumns: TableHeadColumn<string>[] = [
    ...tableHeadings.map(
      (heading) =>
        ({
          isSortable: false,
          label: heading,
        } as const),
    ),
  ];

  const getTableRowCellsContent = (item: OrderLineDto): RowCellContent[] => {
    return [
      {
        cellCss: { textWrap: "nowrap" },
        element: item.itemName,
      },
      {
        element: item.quantity.toFixed(0),
      },
      {
        element: item.price.toFixed(2),
      },
      {
        element: item.price.mul(item.quantity).toFixed(2),
      },
    ];
  };

  return (
    <div css={{ display: "flex", flexDirection: "column", width: "100%", gap: "1rem" }}>
      <SectionHeader isHr>Szczegóły zamówienia</SectionHeader>
      <div css={{ overflow: "auto", margin: "0 -2rem 1rem", padding: "0 2rem" }}>
        <Table columns={tableColumns}>
          <TableBody
            rows={orderItems.map((item) => ({
              uuid: item.id,
              cellsContent: getTableRowCellsContent(item),
              css: {
                "td:first-child": {
                  padding: "0.75rem",
                },
              },
            }))}
          />
        </Table>
      </div>
    </div>
  );
};

const tableHeadings = ["Jednostka", "Ilość", "Cena jednostkowa", "Kwota netto"];
