import { idObjectReferenceSerializer, ResourceAction } from "@legacy-megarax/rest-resource";
import { MSerializers } from "@legacy-megarax/serializers";

import { gtinSerializer } from "../../common";
import { PriceListProposalDto } from "../read/detail";

export const priceListProposalDtoSerializer = MSerializers.object<PriceListProposalDto>({
  uuid: MSerializers.uuid(),
  changes: MSerializers.array(
    MSerializers.object({
      gtin: gtinSerializer,
      netPrice: MSerializers.decimal.nullable(),
    }),
  ),
  activeFrom: MSerializers.datetime.nullable(),
  comment: MSerializers.string,
  proposedAt: MSerializers.datetime,
  proposedBy: idObjectReferenceSerializer,
});

export default ResourceAction({
  name: "priceListProposals",
  method: "get",
  path: "",
  responseSerializer: MSerializers.result<PriceListProposalDto[], any>(
    MSerializers.array(priceListProposalDtoSerializer),
  ),
});
