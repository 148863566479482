import Decimal from "decimal.js";
import _ from "lodash";

import { Failure, Ok } from "@megaron/result";
import { Serializer, serializerExtensions, SerializerExtensions, Serializers } from "@megaron/serializers";
import { isNotNull } from "@megaron/utils";

export const pointOrigins = [
  "productCode",
  "affiliateCode",
  "affiliateCommission",
  "gift",
  "referralBonus",
  "affiliateCodeClaimBonus",
  "taxReimbursement",
] as const;

export type PointOrigin = (typeof pointOrigins)[number];

export type PointCategory = string & { __brand: "PointCategory" };

type ProductCodePointCategoryOptions = {
  productId: string;
  productMassKg: Decimal;
  userStatus: "pro" | null;
};

export function PointCategory(origin: "productCode", options: ProductCodePointCategoryOptions): PointCategory;
export function PointCategory(origin: Exclude<PointOrigin, "productCode">): PointCategory;
export function PointCategory(origin: PointOrigin, options?: ProductCodePointCategoryOptions): PointCategory {
  if (origin !== "productCode") return origin as PointCategory;

  if (!options) throw new Error("Missing productCode options");
  return [origin, options.productId, options.productMassKg.toString(), options.userStatus]
    .filter(isNotNull)
    .join("_") as PointCategory;
}

export const deconstructPointCategory = (
  pointCategory: PointCategory,
): [origin: PointOrigin, options?: ProductCodePointCategoryOptions] => {
  const parts = pointCategory.split("_");
  const origin = parts[0] as PointOrigin;

  if (origin === "productCode") {
    const productId = parts[1];
    const productSize = new Decimal(parts[2]);
    const status = (parts[3] ?? null) as "pro" | null;

    return [origin, { productId, productMassKg: productSize, userStatus: status }];
  }

  return [origin];
};

const isPointCategory = (raw: unknown): raw is PointCategory => {
  if (typeof raw !== "string") return false;

  return pointOrigins.some((origin) => raw.startsWith(origin));
};

export const pointCategorySerializer: Serializer<PointCategory> & SerializerExtensions<PointCategory> = {
  serialize: (str) => {
    if (!isPointCategory(str)) throw new Error("Failed to serialize PointCategory");
    return str;
  },
  deserialize: (raw: unknown) => {
    if (!isPointCategory(raw)) return Failure("InvalidPointCategory");
    return Ok(raw);
  },
  ...serializerExtensions(),
};

export type PointTransfer = {
  amount: Decimal;
  category: PointCategory;
};

export const pointTransferSerializer = Serializers.object<PointTransfer>({
  amount: Serializers.decimal,
  category: pointCategorySerializer,
});

export type OriginBreakdown = {
  [key in PointOrigin]: Decimal;
};

export const originBreakdownSerializer = Serializers.object<OriginBreakdown>({
  productCode: Serializers.decimal,
  affiliateCode: Serializers.decimal,
  affiliateCommission: Serializers.decimal,
  gift: Serializers.decimal,
  referralBonus: Serializers.decimal,
  affiliateCodeClaimBonus: Serializers.decimal,
  taxReimbursement: Serializers.decimal,
});

export const transferTotal = (transfers: PointTransfer[]) => Decimal.sum(0, ...transfers.map((t) => t.amount));

export const summarizeTransfersByOrigin = (transfers: PointTransfer[]): OriginBreakdown => {
  const breakdown: Partial<OriginBreakdown> = {};
  for (const origin of pointOrigins) {
    breakdown[origin] = transferTotal(
      transfers.filter((t) => deconstructPointCategory(t.category)[0] === origin),
    ).negated();
  }
  return breakdown as OriginBreakdown;
};
