import { useTheme } from "@emotion/react";
import { Loader } from "@googlemaps/js-api-loader";
import Decimal from "decimal.js";
import React, { useEffect, useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

import { CustomerAddress } from "@megaron/crm-contracts";

type Props = {
  address?: CustomerAddress;
  onChange: (value: CustomerAddress) => void;
  value: string;
  onValueChange: (value: string) => void;
  label?: string;
};
type PlacePrediction = google.maps.places.AutocompletePrediction;

export const AddressSearchField: React.FC<Props & { predictionTypes?: string[] }> = ({
  onChange,
  predictionTypes,
  value,
  onValueChange,
  label,
}) => {
  const theme = useTheme();
  const [predictions, setPredictions] = useState<PlacePrediction[]>([]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env["NX_PUBLIC_GOOGLE_MAPS_API_KEY"]!,
      version: "weekly",
      libraries: ["places"],
      language: "pl",
    });

    loader.load().then(() => {
      // eslint-disable-next-line no-console
      console.log("Google Maps API loaded");
    });
  }, []);

  const fetchPredictions = async (input: string) => {
    if (!input.trim()) {
      setPredictions([]);
      return;
    }

    const result = await getPredictions(input);
    if (result && Array.isArray(result)) {
      setPredictions(result);
    } else {
      // eslint-disable-next-line no-console
      console.error("Unexpected response from Google Places API");
    }
  };

  const handlePredictionSelect = async (prediction: PlacePrediction) => {
    onValueChange(prediction.description);
    setPredictions([]);
    const placeService = new google.maps.places.PlacesService(document.createElement("div"));
    try {
      const details = await new Promise<google.maps.places.PlaceResult | null>((resolve, reject) => {
        placeService.getDetails({ placeId: prediction.place_id }, (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(place);
          } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
            resolve(null);
          } else {
            reject(new Error(`Failed to get place details. Status: ${status}`));
          }
        });
      });
      if (details) {
        const addressComponents = details.address_components;
        if (addressComponents) {
          const googlePlaceId = details.types?.includes("establishment") ? details.place_id || null : null;
          const country = addressComponents.find((component) => component.types.includes("country"))?.long_name || "";
          const city = addressComponents.find((component) => component.types.includes("locality"))?.long_name || "";
          const street = addressComponents.find((component) => component.types.includes("route"))?.long_name || "";
          const streetNumber = addressComponents.find((component) =>
            component.types.includes("street_number"),
          )?.long_name;
          const postalCode =
            addressComponents.find((component) => component.types.includes("postal_code"))?.long_name || "";
          const latitude =
            details.geometry && details.geometry.location ? new Decimal(details.geometry.location.lat()) : null;
          const longitude =
            details.geometry && details.geometry.location ? new Decimal(details.geometry.location.lng()) : null;

          const updatedAddress: CustomerAddress = {
            country,
            city,
            street: street + (streetNumber ? ` ${streetNumber}` : ""),
            postalCode,
            latitude,
            longitude,
            googlePlaceId,
          };

          onChange(updatedAddress);
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error occurred while fetching place details:", error);
    }
  };

  const getPredictions = async (input: string): Promise<PlacePrediction[]> => {
    const autocompleteService = new google.maps.places.AutocompleteService();
    const result = await new Promise<PlacePrediction[]>((resolve, reject) => {
      autocompleteService.getPlacePredictions(
        {
          input,
          types: predictionTypes ?? ["geocode"],
        },
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            if (predictions) {
              resolve(predictions);
            } else {
              resolve([]);
            }
          } else {
            resolve([]);
          }
        },
      );
    });
    return result;
  };

  return (
    <div css={{ display: "flex", flexDirection: "column", position: "relative" }}>
      <span
        css={{
          fontFamily: theme.displayFontFamily,
          fontSize: "14px",
          color: theme.colors.primary,
          marginBottom: "4px",
        }}
      >
        {label || "Wyszukiwanie adresu"}
      </span>

      <div css={{ position: "relative", display: "flex", alignItems: "center" }}>
        <input
          id="autocomplete"
          type="text"
          value={value}
          onChange={(e) => {
            onValueChange(e.target.value);
            fetchPredictions(e.target.value);
          }}
          placeholder="Szukaj adresu..."
          css={{
            width: "100%",
            border: "none",
            padding: "0.5rem 2.25rem 0.5rem 0.75rem",
            fontSize: "1rem",
            borderRadius: theme.smallBorderRadius,
            backgroundColor: theme.colors.primaryLight,
            ":focus": {
              outline: `1px solid ${theme.colors.primary}`,
            },
          }}
        />
        <div css={{ position: "absolute", right: "5px", top: "60%", transform: "translateY(-50%)" }}>
          {predictions.length > 0 ? (
            <MdKeyboardArrowUp
              onClick={() => setPredictions([])}
              css={{ cursor: "pointer", color: theme.colors.primary, fontSize: "20px" }}
            />
          ) : (
            <MdKeyboardArrowDown
              onClick={() => fetchPredictions(value)}
              css={{ cursor: "pointer", color: theme.colors.primary, fontSize: "20px" }}
            />
          )}
        </div>
      </div>

      {predictions.length > 0 && (
        <ul
          css={{
            listStyleType: "none",
            padding: 0,
            backgroundColor: theme.colors.primaryLight,
            borderRadius: theme.smallBorderRadius,
            border: `1px solid ${theme.colors.border}`,
            marginTop: "4px",
            width: "100%",
            position: "absolute",
            top: "62px",
            zIndex: 1,
          }}
        >
          {predictions.map((prediction) => (
            <li
              key={prediction.place_id}
              onClick={() => handlePredictionSelect(prediction)}
              css={{
                padding: "8px",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: theme.colors.primary,
                  color: "#fff",
                },
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {prediction.description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
