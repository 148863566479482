import { RegionSimple, SalespersonSimple } from "@legacy-megarax/crm-contracts";
import { UserSimple } from "@legacy-megarax/iam-contracts";
import { ProfilesDictionary } from "@legacy-megarax/iam-webapp";
import { LoadingBar, NewItemFab, SearchBar } from "@legacy-megarax/ui-components";
import { Box, Button, Container, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";

interface Props {
  query: {
    loading: boolean;
    items: SalespersonSimple[] | undefined;
  };
  search: {
    onSearchChange: (search: string) => void;
    value: string;
  };
  profilesDictionary: ProfilesDictionary | undefined;
  regionsDictionary: _.Dictionary<RegionSimple> | undefined;
  allUsersDictionary: _.Dictionary<UserSimple> | undefined;
  navigation: {
    openAddSalespersonDialog: () => void;
    navigateToDetails: (userId: number) => void;
    navigateToPlan: (userId: number) => void;
  };
  onOpenMilageReport: () => void;
  onOpenWorkTimeReport: () => void;
}

export const SalespeopleHome: React.FC<Props> = ({
  navigation,
  search,
  query,
  profilesDictionary,
  regionsDictionary,
  onOpenMilageReport,
  onOpenWorkTimeReport,
  allUsersDictionary,
}) => {
  const ready = profilesDictionary && regionsDictionary && allUsersDictionary && query.items;

  const salespeopleToDisplay = React.useMemo(() => {
    if (!ready) return undefined;

    const filteredItems = query?.items?.filter((item) => {
      if (allUsersDictionary[item.userId]?.isDeleted === true) return false;
      const searchText = search.value.toLocaleLowerCase();
      if (searchText === "") return true;
      const region = regionsDictionary[item.regionUuid]?.name.toLocaleLowerCase() ?? "";
      const username = profilesDictionary[item.userId]?.displayedName.toLocaleLowerCase() ?? "";
      return region.includes(searchText) || username.includes(searchText);
    });

    const sortedItems = filteredItems?.sort((a, b) => {
      if (regionsDictionary[a.regionUuid].name === regionsDictionary[b.regionUuid].name) {
        if (regionsDictionary[a.regionUuid].name === "Nieaktywne") {
          return 0;
        }

        return 1;
      }

      return regionsDictionary[a.regionUuid].name === "Nieaktywne" ? 1 : -1;
    });

    return sortedItems;
  }, [ready, query?.items, allUsersDictionary, search.value, regionsDictionary, profilesDictionary]);

  return (
    <>
      <LoadingBar loading={query.loading} />
      {ready && salespeopleToDisplay !== undefined && (
        <Container maxWidth="md" disableGutters>
          <Box display="flex" justifyContent="space-between" py={2} alignItems="center" px={1}>
            <SearchBar onSearchChange={search.onSearchChange} initialValue={search.value} />
            <Box display="flex">
              <Button variant="outlined" onClick={onOpenWorkTimeReport} sx={{ mr: 2 }}>
                Czas pracy
              </Button>
              <Button variant="outlined" onClick={onOpenMilageReport} sx={{ mr: 2 }}>
                Kilometry
              </Button>
              <NewItemFab onClick={navigation.openAddSalespersonDialog} text="Nowy" />
            </Box>
          </Box>
          <Box>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell width="50%">Handlowiec</TableCell>
                  <TableCell width="50%">Region</TableCell>
                  <TableCell width="50px" />
                </TableRow>
              </TableHead>
              <TableBody>
                {salespeopleToDisplay.map((salesperson) => {
                  return (
                    <TableRow
                      key={salesperson.userId}
                      sx={{
                        cursor: "pointer",
                        opacity: regionsDictionary[salesperson.regionUuid].name === "Nieaktywne" ? 0.4 : 1,
                      }}
                    >
                      <TableCell width="50%" onClick={() => navigation.navigateToDetails(salesperson.userId)}>
                        {profilesDictionary[salesperson.userId]?.displayedName ?? "Nieznany"}
                      </TableCell>
                      <TableCell width="50%" onClick={() => navigation.navigateToDetails(salesperson.userId)}>
                        {regionsDictionary[salesperson.regionUuid]?.name ?? "Nieznany"}
                      </TableCell>
                      <TableCell width="50px">
                        <Button
                          variant="text"
                          size="small"
                          onClick={() => navigation.navigateToPlan(salesperson.userId)}
                        >
                          Wizyty
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Container>
      )}
    </>
  );
};
