import { SelectDialog } from "@dash/select";
import { useToast } from "@dash/toast";

import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

type Props = {
  region: string | null;
  onSuccess: (region?: string | null, ownerEmail?: string | null | undefined) => void;
  onClose: () => void;
} & ({ skipRegionMutation: true; customerUuid?: undefined } | { skipRegionMutation?: false; customerUuid: Uuid });

export const EditRegionDialog: React.FC<Props> = (props) => {
  const toast = useToast();
  const regionsQuery = useClientManager("crm").regionsQuery().useQuery({});
  const saveRegion = useClientManager("crm").saveCustomer().useMutation();

  const saveRegionMutation = async (region: string | null) => {
    if (!props.customerUuid) {
      return;
    }

    saveRegion.mutate(
      { uuid: props.customerUuid, region },
      {
        onSuccess: (r) => {
          const ownerEmail = regionsQuery.data?.items.find((r) => r.name === region)?.ownerEmail;
          props.onSuccess(region, ownerEmail);
        },
        onError: (e) => {
          switch (e) {
            case "Unauthorized":
              toast.error("Error", "Brak upoważnienia do zmiany regionu");
              break;
            default:
              toast.error("Error", "Coś poszło nie tak");
          }
        },
      },
    );
  };

  const options = regionsQuery.data
    ? regionsQuery.data.items.map((region) => ({
        label: region.name,
        value: region.name,
      }))
    : [];

  return (
    <SelectDialog
      variant="single-select"
      noTrigger
      initiallySelectedValues={props.region ? [props.region] : []}
      onClose={props.onClose}
      options={options}
      onSelect={(region) =>
        props.skipRegionMutation ? props.onSuccess(region?.value || null) : saveRegionMutation(region?.value || null)
      }
      isOpen
      shouldNotCloseOnSelect
    />
  );
};
