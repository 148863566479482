import { BackdroppedPopper, usePopper } from "@legacy-megarax/react-utils";
import { AsyncButton } from "@legacy-megarax/ui-components";
import { IconButton, InputBase, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { FaCheck, FaEdit } from "react-icons/fa";

interface Props {
  name: string;
  renamePriceList: (name: string) => Promise<void>;
}

export const PriceListName: React.FC<Props> = ({ name, renamePriceList }) => {
  const classes = useStyles();
  const [input, setInput] = useState<string>(name);
  const { togglePopper, closePopper, popperAnchor } = usePopper();

  const onSubmit = () => renamePriceList(input).then(closePopper);

  return (
    <div className={classes.row}>
      <Typography variant="h6" className={classes.title}>
        {name}
      </Typography>
      <IconButton
        className={classes.editIcon}
        onClick={(e) => {
          setInput(name);
          togglePopper(e);
        }}
        size="large"
      >
        <FaEdit className={classes.editIcon} />
      </IconButton>
      <BackdroppedPopper
        closePopper={() => {
          closePopper();
          setInput("");
        }}
        anchor={popperAnchor}
      >
        <div className={classes.row}>
          <InputBase value={input} onChange={(e) => setInput(e.target.value)} />
          <AsyncButton asyncAction={onSubmit} className={classes.button} color="primary">
            <FaCheck />
          </AsyncButton>
        </div>
      </BackdroppedPopper>
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    button: {
      display: "block",
      width: "20px",
      height: "100%",
    },
    popper: {
      zIndex: 51,
    },
    paper: {
      marginTop: theme.spacing(1),
      "& .MuiInputBase-input": {
        padding: theme.spacing(1),
      },
    },
    backdrop: {
      zIndex: 50,
    },
    title: {
      marginLeft: theme.spacing(2),
    },
    row: {
      marginTop: theme.spacing(2),
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
    },
    editIcon: {
      padding: "0",
      marginLeft: theme.spacing(1),
      fontSize: "1rem",
      color: theme.palette.grey[400],
      width: "20px",
      height: "20px",
    },
  };
});
