import { Button } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { SearchBox } from "@dash/search";
import { useTheme } from "@emotion/react";
import { LoadingBar } from "@legacy-megarax/ui-components";
import { GoogleMap, PolygonF, useJsApiLoader } from "@react-google-maps/api";
import React, { useState } from "react";
import { MdList } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { useClientManager } from "@megaron/react-clients";
import { useRegion } from "@megaron/region-context";

import { MapPreset } from "./customerMapPreset";
import { CustomerMarkers } from "./CustomerMarkers";
import { CustomersFilters } from "./CustomersFilters";
import {
  Bialystok,
  BielskoBiala,
  Bydgoszcz,
  Czestochowa,
  Gdansk,
  JeleniaGora,
  Kielce,
  Koszalin,
  Krakow,
  Lodz,
  Lublin,
  Olsztyn,
  Poznan,
  Poznan2,
  Przemysl,
  Radom,
  Szczecin,
  Warszawa,
  Warszawa2,
  Wroclaw,
  ZielonaGora,
} from "./PolygonPaths";
import { useCustomersFilters } from "./useCustomersFilters";

type Props = {
  preset: MapPreset;
  updatePreset: (preset: MapPreset) => void;
};

export const CustomerMapView: React.FC<Props> = ({ preset, updatePreset }) => {
  const { isMobile } = useDeviceType();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env["NX_PUBLIC_GOOGLE_MAPS_API_KEY"]!,
  });
  const [map, setMap] = useState<google.maps.Map>();

  const [zoom, setZoom] = useState(7);
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>({ lat: 52.0692, lng: 19.4802 });

  const theme = useTheme();

  const location = useLocation();
  const navigate = useNavigate();

  const { region } = useRegion();

  const { filters, setFilter, setFilters } = useCustomersFilters(preset.filters, (newFilters) =>
    updatePreset({ ...preset, filters: newFilters }),
  );

  const searchQuery = useClientManager("docs")
    .searchCustomers()
    .useQuery(
      {
        limit: 10000,
        searchText: filters.searchText,
        sort: filters.sort,
        isPro: filters.isPro,
        isAffiliate: filters.isAffiliate,
        tags: filters.tags,
        scannedProducts: filters.scannedProducts,
        regions: filters.isMyRegion ? (region ? [region] : undefined) : filters.regions,
        isUnassigned: filters.isUnassigned,
        categories: filters.categories,
        status: filters.status,
        relation: filters.relation,
        isActive: filters.isActive,
      },
      {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    );

  return (
    <div
      css={{
        position: "relative",
        height: isMobile ? "calc(100dvh - 26px)" : "100vh",
        maxHeight: "100dvh",
        overflow: "hidden",
        marginBottom: isMobile ? "-26px" : 0,
      }}
    >
      <div
        css={{
          position: "absolute",
          zIndex: 5,
          display: "flex",
          width: "100%",
          padding: isMobile ? "1rem" : "1rem 2rem",
          flexWrap: "wrap",
          justifyContent: "right",
          top: 0,
          gap: "0.75rem",
        }}
      >
        <SearchBox onChange={setFilter("searchText")} value={filters.searchText} css={{ width: "100%", flex: "1" }} />
        <Button
          css={{ background: "white", color: theme.colors.primary, padding: "0.25rem 0.625rem" }}
          icon={<MdList />}
          onClick={() => navigate("/crm/customers/new-list" + location.search)}
        />
      </div>

      <div
        css={{
          position: "absolute",
          top: "4rem",
          left: isMobile ? "1rem" : "2rem",
          zIndex: 5,
          width: isMobile ? "calc(100% - 2rem)" : "calc(100% - 4rem)",
        }}
      >
        <CustomersFilters
          filters={filters}
          handlers={{
            onSortChange: setFilter("sort"),
            onProChange: setFilter("isPro"),
            onAffiliateChange: setFilter("isAffiliate"),
            onUnassignedChange: setFilter("isUnassigned"),
            onScannedProductsChange: setFilter("scannedProducts"),
            onRegionsChange: (v) => {
              setFilters({ ...filters, regions: v, isMyRegion: false });
            },
            onTagsChange: setFilter("tags"),
            onStatusChange: setFilter("status"),
            onCategoriesChange: setFilter("categories"),
            onRelationChange: setFilter("relation"),
            onActiveChange: setFilter("isActive"),
            onIsMyRegionChange: setFilter("isMyRegion"),
          }}
          hideSortFilter
        />
      </div>
      <LoadingBar loading={searchQuery.isFetching} />
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100%", overflow: "hidden" }}
          center={mapCenter}
          zoom={zoom}
          options={{
            disableDefaultUI: true,
            streetViewControl: false,
            styles: [
              { elementType: "labels", featureType: "poi.business", stylers: [{ visibility: "off" }] },
              { featureType: "poi", stylers: [{ visibility: "off" }] },
            ],
          }}
          onLoad={(newMap) => {
            setMap(newMap);
          }}
        >
          {!searchQuery.isFetching && searchQuery.data && (
            <CustomerMarkers
              key={JSON.stringify(filters)}
              customers={searchQuery.data.items}
              queryKey={searchQuery.key}
              map={map}
              onMarkerClick={(customer) => {
                setZoom(14);
                setMapCenter({ lat: Number(customer.address.latitude), lng: Number(customer.address.longitude) });
              }}
            />
          )}

          <PolygonF path={Szczecin} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Bydgoszcz} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Koszalin} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Gdansk} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Olsztyn} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Lodz} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Warszawa} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Radom} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Lublin} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Czestochowa} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={BielskoBiala} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Krakow} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Wroclaw} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={JeleniaGora} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={ZielonaGora} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Kielce} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Przemysl} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Bialystok} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Poznan} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Warszawa2} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Poznan2} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
        </GoogleMap>
      )}
    </div>
  );
};
