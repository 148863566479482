import { Currency, PriceListProposalDto } from "@legacy-megarax/crm-contracts";
import { ProfilesDictionary } from "@legacy-megarax/iam-webapp";
import { useSnackbarErrorHandler } from "@legacy-megarax/ui-components";
import { Box, Button, Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { FaFileUpload, FaPlus } from "react-icons/fa";

import { TradeItemDictionary } from "../PriceListDetailsContainer";
import { csvToInput, InputRow } from "./csvInput";
import { ProposalItem } from "./ProposalItem";

interface Props {
  onOpenNewProposalDialog: () => void;
  onCsvImport: (rows: InputRow[]) => void;
  permissions: {
    create: boolean;
    approve: boolean;
    reject: boolean;
  };
  isMyProposal: (userId: number) => boolean;
  data: {
    priceListUuid: string;
    currency: Currency;
    tradeItems: TradeItemDictionary | null;
    profiles: ProfilesDictionary;
    proposals: PriceListProposalDto[];
  };
  proposalActions: {
    retractProposal: (proposalUuid: string) => Promise<void>;
    rejectProposal: (proposalUuid: string) => Promise<void>;
    approveProposal: (proposalUuid: string) => Promise<void>;
  };
}

export const ProposalsList: React.FC<Props> = ({
  onOpenNewProposalDialog,
  onCsvImport,
  permissions,
  isMyProposal,
  data,
  proposalActions,
}) => {
  const classes = useStyles();

  const handleError = useSnackbarErrorHandler({
    InvalidInput: "Niepoprawny plik.",
    MissingGtin: "Plik musi zawierać kolumnę 'ean'",
    MissingPrice: "Plik musi zawierać kolumnę 'cena netto'",
    InvalidGtin: "Niepoprawny EAN",
    InvalidPrice: "Niepoprawnay cena",
  });

  const onFileUpload: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;

    const inputRowsResult = csvToInput(await file.text());
    if (inputRowsResult.isFailure) {
      handleError(inputRowsResult.error);
      return;
    }
    onCsvImport(inputRowsResult.value);
  };

  return (
    <Container maxWidth="md" disableGutters className={classes.mainContainer}>
      <Box display="flex">
        {permissions.create && (
          <div className={classes.buttons}>
            <input
              accept=".csv"
              type="file"
              id="contained-button-file"
              style={{ display: "none" }}
              onChange={onFileUpload}
            />
            <label htmlFor="contained-button-file">
              <Button component="span" startIcon={<FaFileUpload />} sx={{ mr: 1 }}>
                Importuj CSV
              </Button>
            </label>
            <Button variant="contained" color="primary" onClick={onOpenNewProposalDialog}>
              <FaPlus className={classes.icon} />
              Nowa propozycja
            </Button>
          </div>
        )}
      </Box>
      <div>
        {data.proposals.map((proposal) => {
          return (
            <ProposalItem
              proposal={proposal}
              data={data}
              isMyProposal={isMyProposal}
              proposalActions={proposalActions}
              permissions={permissions}
            />
          );
        })}
      </div>
    </Container>
  );
};

const useStyles = makeStyles((theme: any) => {
  return {
    mainContainer: {
      padding: theme.spacing(1),
    },

    icon: {
      marginRight: theme.spacing(1),
    },
    buttons: {
      marginLeft: "auto",
    },
  };
});
