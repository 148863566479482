import { useCurrentUser } from "@legacy-megarax/react-client";
import { Navigate } from "react-router-dom-v5-compat";

export const MyVisitsRedirect = () => {
  const currentUser = useCurrentUser();

  if (currentUser) {
    return <Navigate to={`/crm/pos/handlowcy/${currentUser.id}/wizyty/dzien`} />;
  }

  return null;
};
