import { RegisterSalespersonInput, SalespersonSimple } from "@legacy-megarax/crm-contracts";
import { useUsersResource } from "@legacy-megarax/iam-webapp";
import { useRegionsResource } from "libs/@megarax/crm/resource-hooks/src";
import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { Result } from "@megaron/result";

import { useSalespeopleResource } from "../useSalespeopleResource";
import { NewSalespersonDialog } from "./NewSalespersonDialog";

interface Props {
  onClose: () => void;
  salespeople: SalespersonSimple[] | undefined;
}

export const NewSalespersonDialogContainer: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const salespeopleResource = useSalespeopleResource();
  const userResource = useUsersResource();
  const regionsResource = useRegionsResource();

  const onSuccess = (id: number) => {
    navigate("/crm/pos/handlowcy/" + id);
  };

  const registeredIds = props.salespeople?.map((item) => item.userId) ?? [];

  return (
    <NewSalespersonDialog
      close={props.onClose}
      open={true}
      getUsers={(searchText: string) =>
        userResource.getUsers(searchText).then((items) => items.filter((item) => !registeredIds.includes(item.id)))
      }
      getRegions={(searchText: string) => regionsResource.getRegionsItems({ searchText, limit: 50 })}
      registerSalesperson={(id: number) => (input: RegisterSalespersonInput) =>
        salespeopleResource.registerSalesperson({ onSuccess: () => onSuccess(id) })(id, input) as Result}
    />
  );
};
