import { Tile, TileStripe } from "@dash/tile";
import { useTheme } from "@emotion/react";
import { formatDistanceToNowStrict } from "date-fns";
import { pl } from "date-fns/locale";
import { Link } from "react-router-dom-v5-compat";

import { MyOrderDraftDto } from "@megaron/crm-contracts";

type Props = {
  order: MyOrderDraftDto;
};

export const OrderDraftTile: React.FC<Props> = ({ order }) => {
  const theme = useTheme();

  return (
    <Link to={`/crm/order-drafts/${order.uuid}`}>
      <Tile stripe={<TileStripe color={theme.colors.primaryLight} />}>
        <div css={{ display: "flex", flexDirection: "column", gap: "0.325rem", fontSize: "0.875rem" }}>
          <div css={{ display: "flex", alignItems: "center", gap: "0.5rem", justifyContent: "space-between" }}>
            <h3 css={{ color: theme.colors.primary, margin: 0, fontSize: "0.9375rem", fontWeight: 700 }}>
              {order.sellToName}
            </h3>
            <span css={{ color: theme.colors.secondaryText }}>
              {formatDistanceToNowStrict(order.draftStartedAt, { addSuffix: true, locale: pl })}
            </span>
          </div>
          <span css={{ color: theme.colors.secondaryText, margin: 0, fontSize: "0.875rem" }}>
            Odbiorca faktury: <strong>{order.billToName}</strong>
          </span>
        </div>
      </Tile>
    </Link>
  );
};
