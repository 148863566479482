import { Nav, NavEntryProps, SiteContainer } from "@dash/nav";
import { useTheme } from "@emotion/react";
import {
  API_URL,
  CRM_URL,
  DOCS_URL,
  INVOICES_URL,
  ORDERS_URL,
  POINTS_URL,
  QR_SYNC_URL,
  useConfig,
} from "@megarax/dev-config";
import { AppLinkTree } from "@megarax/links";
import { useContext, useEffect, useState } from "react";

import { IamAuthContext } from "@megaron/auth-react";

import { MegaraxNavFooter } from "./MegaraxNavFooter";
import { WelcomeScreen } from "./WelcomeScreen";

const ENVIRONMENT_TEMPLATE = process.env["NX_PUBLIC_ENVIRONMENT_TEMPLATE"];

type Props = {
  children?: React.ReactNode;
  navEntries: NavEntryProps[];
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  logo?: React.ReactNode;
  backgroundImageSrc?: string;
  className?: string;
  bypassAuth?: boolean;
  hideHeader?: boolean;
  app?: string;
};

export const MegaraxWebappContainer: React.FunctionComponent<Props> = (props) => {
  const authCtx = useContext(IamAuthContext);

  const theme = useTheme();

  const { config } = useConfig();
  const { apiUrl, crmUrl, docsUrl, invoicesUrl, ordersUrl, pointsUrl, qrSyncUrl } = config;

  const [isProductionUsed, setIsProductionUsed] = useState<boolean>(false);

  useEffect(() => {
    if (
      apiUrl === API_URL ||
      crmUrl === CRM_URL ||
      docsUrl === DOCS_URL ||
      invoicesUrl === INVOICES_URL ||
      ordersUrl === ORDERS_URL ||
      pointsUrl === POINTS_URL ||
      qrSyncUrl === QR_SYNC_URL
    ) {
      setIsProductionUsed(true);
      return;
    }

    setIsProductionUsed(false);
  }, [apiUrl, crmUrl, docsUrl, invoicesUrl, ordersUrl, pointsUrl, qrSyncUrl]);

  useEffect(() => {
    updateThemeColor(theme.colors.primary);
  }, [theme.colors.primary]);

  return (
    <SiteContainer
      className={props.className}
      welcomeScreen={
        authCtx.iamUser || props.bypassAuth ? undefined : (
          <WelcomeScreen logo={props.logo} title={props.title} subtitle={props.subtitle} />
        )
      }
      backgroundImageSrc={props.backgroundImageSrc}
      nav={
        <Nav
          app={props.app}
          title={props.title}
          subtitle={props.subtitle}
          logo={props.logo}
          backgroundImageSrc={props.backgroundImageSrc}
          footer={<MegaraxNavFooter />}
          entries={props.navEntries}
          hideHeader={props.hideHeader}
          showProductionEnvAlert={isProductionUsed && !ENVIRONMENT_TEMPLATE}
        />
      }
    >
      {props.children}
    </SiteContainer>
  );
};

export const appLinkTreeToNavEntries = (appLinkTree: AppLinkTree | undefined): NavEntryProps[] =>
  appLinkTree?.pageLinks.map((link) => ({
    to: `${appLinkTree.baseUrl || ""}${link.url}`,
    icon: <link.Icon />,
    children: link.name || "",
    isActive: link.isActive,
  })) ?? [];

const updateThemeColor = (color: string) => {
  const metaThemeColor = document.querySelector("meta[name=theme-color]");
  if (metaThemeColor) {
    metaThemeColor.setAttribute("content", color);
  } else {
    const meta = document.createElement("meta");
    meta.setAttribute("name", "theme-color");
    meta.setAttribute("content", color);
    document.head.appendChild(meta);
  }
};
