import { priceListResource, PriceListSimple } from "@legacy-megarax/crm-contracts";
import { HttpRequestError } from "@legacy-megarax/http-client";
import { useIndependentAsyncLoad, useResourceProviderV2 } from "@legacy-megarax/react-client";
import { useDepPagination } from "@legacy-megarax/react-utils";
import {
  BreadcrumbMarker,
  commonErrorsMap,
  LoadingBar,
  PageTitleManager,
  useSnackbarErrorHandler,
} from "@legacy-megarax/ui-components";
import { LinearProgress } from "@mui/material";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useLocation, useMatch, useNavigate } from "react-router-dom-v5-compat";

import { Failure, Ok } from "@megaron/result";

import { getPriceListDetailsUrl, getPriceListNewUrl } from "../routes";
import { NewPriceListContainer } from "./NewPriceList";
import { PriceListsHome } from "./PriceListsHome";

const perPage = 20;

type PriceListQuery =
  | Ok<{
      count: number;
      items: PriceListSimple[];
    }>
  | Failure<HttpRequestError>;

export const PriceListHomeContainer: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
  });

  const initialValues = getInitialValuesFromQs(location.search);

  const priceListProvider = useResourceProviderV2(priceListResource);
  const [priceListQuery, setPriceListQuery] = useState<PriceListQuery>();

  const [searchText, setSearchText] = useState<string>(initialValues.search);
  const { paginationProps, page } = useDepPagination(
    {
      perPage,
      allCount: priceListQuery?.value?.count,
      initialPage: initialValues.page,
    },
    [searchText],
  );

  const navigateToDetails = (uuid: string) => navigate(getPriceListDetailsUrl(uuid));
  const openNewItemDialog = () => navigate(getPriceListNewUrl());

  const isNewItemDialogOpen = useMatch(getPriceListNewUrl());

  const { loading: priceListLoading } = useIndependentAsyncLoad(
    () =>
      priceListProvider.list({
        limit: perPage,
        offset: page * perPage,
        searchText: searchText,
      }),
    setPriceListQuery,
    [page, searchText],
  );

  useEffect(() => {
    if (priceListQuery?.isFailure) handleError(priceListQuery.error);
  }, [priceListQuery, handleError]);

  useEffect(() => {
    navigate(
      {
        search: qs.stringify({
          currentPage: page,
          searchTerm: searchText,
        }),
      },
      { replace: true },
    );
  }, [searchText, page, navigate]);

  if (priceListQuery === undefined) return <LinearProgress />;
  if (priceListQuery.isFailure) return null;

  return (
    <>
      <PageTitleManager title="Cenniki" />
      <BreadcrumbMarker title="Cenniki" to={location.pathname} id="priceLists" />
      <LoadingBar loading={priceListLoading} />
      {isNewItemDialogOpen && <NewPriceListContainer />}
      <PriceListsHome
        priceListQuery={priceListQuery.value}
        pagination={paginationProps}
        search={{ setSearchText, initialValue: initialValues.search }}
        openNewItemDialog={openNewItemDialog}
        navigateToDetails={navigateToDetails}
      />
    </>
  );
};

const getInitialValuesFromQs = (locationSearch: string) => {
  const { currentPage, searchTerm } = qs.parse(locationSearch.substring(1));
  return {
    search: typeof searchTerm === "string" ? searchTerm : "",
    page: typeof currentPage === "string" && !isNaN(parseInt(currentPage)) ? parseInt(currentPage) : 0,
  };
};
