import { ResourceAction } from "@legacy-megarax/rest-resource";
import { MSerializers } from "@legacy-megarax/serializers";

import { PriceListChangeDto } from "../read/detail";
import { priceListChangeDtoSerializer } from "./priceListChangeDtoSerializer";

export const upcomingChangesAction = ResourceAction({
  name: "upcomingChanges",
  method: "get",
  path: "upcomingChanges",
  responseSerializer: MSerializers.result<PriceListChangeDto[], any>(MSerializers.array(priceListChangeDtoSerializer)),
});
