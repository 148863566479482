import { Button } from "@dash/form";
import { Spinner } from "@dash/spinner";
import { stringify } from "csv/browser/esm/sync";
import fileDownload from "js-file-download";
import { useState } from "react";
import { MdOutlineSimCardDownload } from "react-icons/md";

import { RegionTarget } from "@megaron/crm-contracts";
import { useServiceClient } from "@megaron/react-clients";

export const DownloadProfitTargetsButton = () => {
  const crm = useServiceClient("crm");

  const [isFetching, setIsFetching] = useState(false);

  const fetchRegionTargets = async () => {
    const regionTargets = await crm.regionTargetQuery({});

    return regionTargets.value || [];
  };

  const handleDownload = async () => {
    setIsFetching(true);

    const regionTargets = await fetchRegionTargets();
    setIsFetching(false);

    downloadCsv(regionTargets);
  };

  return (
    <Button
      variant="outline"
      icon={
        isFetching ? (
          <div css={{ width: "20px" }}>
            <Spinner size="16px" />
          </div>
        ) : (
          <MdOutlineSimCardDownload size={20} />
        )
      }
      onClick={handleDownload}
    >
      Pobierz
    </Button>
  );
};

const mapRegionTargetsToCsvEntry = (regionTargets: RegionTarget[]) => {
  return regionTargets.map((regionTarget) => {
    const parsedTargets = Object.entries(regionTarget.targets).reduce((acc, [month, target]) => {
      acc[month] = target?.toFixed(2) ?? "";
      return acc;
    }, {} as Record<string, string>);

    return {
      "Nazwa regionu": regionTarget.regionName,
      ...parsedTargets,
    };
  });
};

const downloadCsv = (regionTargets: RegionTarget[]) => {
  const entries = mapRegionTargetsToCsvEntry(regionTargets.slice(0, 10000));

  const columns = ["Nazwa regionu", ...Object.keys(regionTargets[0].targets)];

  const csv = stringify(entries, {
    header: true,
    columns: columns,
  });

  fileDownload(csv, `plan_marzy.csv`);
};
