import { TableCell, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

interface Props {
  title: string;
  handleSort: () => void;
  order: "ASC" | "DESC" | undefined;
}

export const SortableCell: React.FC<Props> = ({ title, handleSort, order }) => {
  const classes = useStyles();

  return (
    <TableCell onClick={handleSort} className={classes.hover}>
      <div>
        <Typography variant="inherit">{title}</Typography>
        {order && order === "ASC" ? (
          <FaArrowUp className={classes.sortIcon} />
        ) : (
          <FaArrowDown className={classes.sortIcon} />
        )}
      </div>
    </TableCell>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    hover: {
      whiteSpace: "nowrap",
      cursor: "pointer",
      "& > span": {
        whiteSpace: "normal",
      },
    },
    sortIcon: {
      color: theme.palette.grey[800],
      marginLeft: "4px",
    },
  };
});
