import { Uuid } from "@legacy-megarax/common";
import React from "react";

import { ActivityListEvent, EventCard } from "./EventCard";
import { ActivityListVisit, VisitCard } from "./VisitCard";

export type ActivityListEntry = ActivityListEvent | ActivityListVisit;

interface Props {
  entry: ActivityListEntry;
  onVisitSeeMore: (uuid: Uuid, customerUuid: Uuid | null, isEnded: boolean) => void;
  onEventEdit: (uuid: Uuid) => void;
}

export const ActivityCard: React.FunctionComponent<Props> = ({ entry, onVisitSeeMore, onEventEdit }) => {
  if (entry.type === "event") return <EventCard entry={entry} onEdit={() => onEventEdit(entry.uuid)} />;
  return <VisitCard entry={entry} onSeeMore={() => onVisitSeeMore(entry.uuid, entry.customerUuid, !!entry.endTime)} />;
};
