import {
  BaseResource,
  NestedResourceNode,
  paginatedListSerializer,
  ResourceAction,
  ResourceActionV2,
} from "@legacy-megarax/rest-resource";
import { MSerializers } from "@legacy-megarax/serializers";

import { gtinSerializer } from "../common";
import { PricedItemDto, pricedItemQueryErrors, PricedItemQueryFilters } from "./common";

const searchAction = ResourceActionV2({
  name: "search",
  method: "get",
  path: "",
  querySerializer: MSerializers.object<PricedItemQueryFilters>({
    discountRate: MSerializers.decimal,
    postSaleDiscountRate: MSerializers.decimal,
    limit: MSerializers.integer.optional(),
    offset: MSerializers.integer.optional(),
    priceListUuid: MSerializers.uuid(),
    searchText: MSerializers.string.optional(),
    time: MSerializers.datetime.optional(),
  }),
  responseValueSerializer: paginatedListSerializer(
    MSerializers.object<PricedItemDto>({
      basePrice: MSerializers.decimal.nullable(),
      cost: MSerializers.decimal.nullable(),
      finalPrice: MSerializers.decimal.nullable(),
      gtin: gtinSerializer,
      name: MSerializers.string.nullable(),
      absoluteProfitMargin: MSerializers.decimal.nullable(),
      relativeProfitMargin: MSerializers.decimal.nullable(),
    }),
  ),
  responseErrorSerializer: MSerializers.stringOneOf(...pricedItemQueryErrors),
});

export const pricingPricedItemResource = BaseResource(["pricing", "pricedItems"], {
  maintenance: NestedResourceNode("maintenance", {
    rebuild: ResourceAction({
      name: "maintenance.rebuild",
      method: "post",
      path: "rebuild",
    }),
  }),
  search: searchAction,
});
