import { BoolFilter, FilterList, SelectFilter, useQsFilters } from "@dash/filters";
import { Chip } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { Pagination } from "@dash/search";
import { QuerySkeleton } from "@dash/skeleton";
import { useTheme } from "@emotion/react";
import { rewardIds } from "@megarax/loyalty-orders-rewards";
import React from "react";

import { FulfillmentDto } from "@megaron/loyalty-orders-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { BlikCheckStatusChip } from "./BlikCheckStatusChip";
import { OrdersTable } from "./OrdersTable";
import { ShoperStatusChip } from "./ShoperStatusChip";

const filteredRewardIds = rewardIds.filter((r) => !r.toLowerCase().includes("testreward"));

const pageSize = 30;

const filtersSerializer = Serializers.object({
  page: Serializers.integer,
  rewardId: Serializers.array(Serializers.string),
  isFulfilled: Serializers.boolean.optional(),
  userUuid: uuidSerializer.optional(),
});

export type Filters = SerializerValue<typeof filtersSerializer>;

export const OrderList: React.FunctionComponent = () => {
  const { mq } = useDeviceType();

  const { filters, setFilter } = useQsFilters(filtersSerializer, {
    page: 0,
    rewardId: [],
    isFulfilled: undefined,
    userUuid: undefined,
  });

  const query = useClientManager("loyaltyOrders")
    .orders()
    .useQuery({
      userUuid: filters.userUuid,
      rewardId: filters.rewardId.length > 0 ? filters.rewardId : undefined,
      isFulfilled: filters.isFulfilled,
      limit: pageSize,
      offset: filters.page * pageSize,
    });

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginBottom: "1rem" }}>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
          margin: " 0 0 1rem",
          [mq("desktop")]: {
            flexDirection: "row",
            alignItems: "center",
          },
        }}
      >
        <FilterList css={{ flexGrow: 1 }}>
          <SelectFilter
            label="Nagroda"
            initiallySelectedValues={filters.rewardId}
            options={filteredRewardIds.map((r) => ({ label: r, value: r }))}
            onSelectedChange={(options) => setFilter("rewardId")(options.map((o) => o.value))}
            variant="multi-select"
          />
          <BoolFilter
            label="Zrealizowane?"
            value={filters.isFulfilled}
            falseLabel="Nie zrealizowane"
            trueLabel="Zrealizowane"
            onChange={setFilter("isFulfilled")}
          />
        </FilterList>
        <Pagination
          itemCount={query.data?.count}
          pageSize={pageSize}
          onPageChange={setFilter("page")}
          page={filters.page}
        />
      </div>
      <QuerySkeleton query={query}>
        {(page) => (
          <OrdersTable
            orders={page.items}
            onRewardClick={(v) => setFilter("rewardId")([v])}
            onUserClick={setFilter("userUuid")}
          />
        )}
      </QuerySkeleton>
    </div>
  );
};

export const FulfillmentChip: React.FunctionComponent<{
  fulfillment: FulfillmentDto;
}> = (props) => {
  const theme = useTheme();

  if (props.fulfillment.type === "blikCheck") {
    return <BlikCheckStatusChip status={props.fulfillment.status} isLabeled />;
  }
  if (props.fulfillment.type === "custom")
    return props.fulfillment.isFailed ? (
      <Chip color={theme.colors.danger}>Nieudane</Chip>
    ) : props.fulfillment.isFulfilled ? (
      <Chip>Zrealizowane</Chip>
    ) : (
      <Chip variant="secondary">Oczekuje</Chip>
    );
  if (props.fulfillment.type === "shoper") return <ShoperStatusChip status={props.fulfillment.status} isLabeled />;
  return null;
};
