import React, { useState } from "react";

import { InvoiceThreadDoc, ThreadDto } from "@megaron/invoices-contracts";
import { Uuid } from "@megaron/uuid";

type StoredThread = Partial<ThreadDto> & { uuid: Uuid; version: number };

type ThreadStateContext = {
  storeThread: (thread: StoredThread) => void;
  integrateLocalState: (doc: InvoiceThreadDoc) => InvoiceThreadDoc;
};

export const ThreadStateContext = React.createContext<ThreadStateContext>({
  integrateLocalState: (doc) => doc,
  storeThread: (thread) => null,
});

export const ThreadStateContextProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = (props) => {
  const [store, setStore] = useState({} as Record<Uuid, StoredThread | undefined>);

  const storeThread = (thread: StoredThread) => {
    const existing = store[thread.uuid];
    if (existing && existing.version >= thread.version) return;

    setStore((prev) => ({ ...prev, [thread.uuid]: thread }));
  };

  const integrateLocalState = (doc: InvoiceThreadDoc) => {
    const existing = store[doc.threadUuid];
    if (!existing || existing.version <= doc.version) return doc;

    return { ...doc, ...existing };
  };

  return (
    <ThreadStateContext.Provider value={{ storeThread, integrateLocalState }}>
      {props.children}
    </ThreadStateContext.Provider>
  );
};
