import { useTheme } from "@emotion/react";
import { Loader } from "@googlemaps/js-api-loader";
import Decimal from "decimal.js";
import { useEffect, useRef, useState } from "react";

const loader = new Loader({
  apiKey: process.env["NX_PUBLIC_GOOGLE_MAPS_API_KEY"]!,
  version: "weekly",
  libraries: ["places"],
  language: "pl",
});

type LatLng = {
  latitude: Decimal;
  longitude: Decimal;
};

type Props = {
  latLng: LatLng;
  height?: string;
  width?: string;
};

export type Google = typeof google;

export const useGoogleMapsLoader = (
  mapOptions: google.maps.MapOptions,
  onInit?: (ggl: typeof google, map: google.maps.Map) => void,
) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const googleRef = useRef<Google>(undefined);
  const mapRef = useRef<google.maps.Map>(undefined);

  const setMapRef = (ref: HTMLDivElement | null) => {
    if (!ref || mapRef.current !== undefined) return;
    loader.load().then((google) => {
      const map = new google.maps.Map(ref, mapOptions);

      mapRef.current = map;
      googleRef.current = google;
      if (onInit) onInit(google, map);

      setLoaded(true);
    });
  };

  return {
    setMapRef,
    google: googleRef.current,
    map: mapRef.current,
    loaded,
  };
};

export const GoogleMap = ({ latLng, height, width }: Props) => {
  const theme = useTheme();
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);

  const onInit = (ggl: typeof google, map: google.maps.Map) => {
    if (!map || !ggl) return;

    const newMarker = new ggl.maps.Marker({
      map: map,
      title: "Wizyta",
      draggable: false,
    });

    setMarker(newMarker);

    ggl.maps.event.addListenerOnce(map, "idle", () => {
      if (latLng) {
        const location = new ggl.maps.LatLng(latLng.latitude.toNumber(), latLng.longitude.toNumber());
        map.setCenter(location);
        newMarker.setPosition(location);
      }
    });
  };

  const mapsLoaderResult = useGoogleMapsLoader(getMapOptions(), onInit);

  useEffect(() => {
    if (mapsLoaderResult.map && latLng && mapsLoaderResult.google) {
      const location = new mapsLoaderResult.google.maps.LatLng(latLng.latitude.toNumber(), latLng.longitude.toNumber());
      mapsLoaderResult.map.setCenter(location);
      if (marker) {
        marker.setPosition(location);
      }
    }
  }, [latLng, mapsLoaderResult.google]);

  return (
    (<div
      ref={ref => {
        mapsLoaderResult.setMapRef(ref);
      }}
      style={{
        zIndex: 0,
        position: "relative",
        height: height ? height : "200px",
        width: width ? width : "100%",
        flexGrow: 1,
        borderRadius: theme.smallBorderRadius,
      }}
    />)
  );
};

const getMapOptions = (): google.maps.MapOptions => {
  return {
    zoom: 13,
    streetViewControl: false,
    disableDefaultUI: true,
    zoomControl: true,
    draggable: true,
    mapTypeControlOptions: {
      mapTypeIds: [],
    },
  };
};
