import { Tile, TileStripe } from "@dash/tile";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom-v5-compat";
import tinycolor from "tinycolor2";

import { BudgetDoc } from "@megaron/invoices-contracts";

import { BudgetTileAlert } from "./BudgetTileAlert";
import { BudgetTileHeader } from "./BudgetTileHeader";

type Props = {
  budget: BudgetDoc;
  className?: string;
};

export const BudgetTile: React.FC<Props> = ({ budget, className }) => {
  const theme = useTheme();

  return (
    <Link
      to={`/faktury/budzety/${budget.uuid}`}
      className={className}
      css={{
        flexGrow: 1,
        minWidth: "20rem",
        ":hover": {
          textDecoration: "none",
          h4: {
            textDecoration: "underline",
          },
        },
      }}
    >
      <Tile
        stripe={<TileStripe color={budget.isArchived ? theme.colors.border : theme.colors.primary}></TileStripe>}
        css={{
          borderRadius: theme.mediumBorderRadius,
          border: `1px solid ${tinycolor(theme.colors.primary).setAlpha(0.1)}`,
        }}
      >
        <div css={{ display: "flex", flexDirection: "column", gap: "0.375rem" }}>
          <BudgetTileHeader budget={budget} />
          {budget.description && (
            <p css={{ fontSize: "0.75rem", color: theme.colors.default, margin: "0 0 auto" }}>{budget.description}</p>
          )}
          {budget.alert && <BudgetTileAlert alert={budget.alert} />}
        </div>
      </Tile>
    </Link>
  );
};
