import { Currency, Gtin, PriceListDetailCurrentPriceDto } from "@legacy-megarax/crm-contracts";
import { formatCurrency } from "@legacy-megarax/react-utils";
import { SearchBar, SortableCell } from "@legacy-megarax/ui-components";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { formatDistanceToNowStrict } from "date-fns";
import { pl } from "date-fns/locale";
import React, { useState } from "react";

import { TradeItemDictionary } from "../PriceListDetailsContainer";

interface Props {
  currentPrices: PriceListDetailCurrentPriceDto[];
  openItemDetailsDialog: (gtin: Gtin) => void;
  currency: Currency;
  tradeItems: TradeItemDictionary | null;
}

const filterCurrentPrices = (
  search: string,
  currentPrices: PriceListDetailCurrentPriceDto[],
  tradeItems: TradeItemDictionary | null,
) => {
  const reg = new RegExp(search);
  if (!tradeItems) return currentPrices.filter((price) => reg.test(price.gtin));
  return currentPrices.filter((price) => reg.test(price.gtin) || reg.test(tradeItems[price.gtin].itemName));
};

type SortableField = "gtin" | "activeFrom" | "netPrice" | "name";
type Order = "ASC" | "DESC";

const sortCurrentPrices = (
  field: SortableField,
  order: Order,
  currentPrices: PriceListDetailCurrentPriceDto[],
  tradeItems: TradeItemDictionary | null,
) => {
  const orderDirection = order === "ASC" ? 1 : -1;
  if (field === "name") {
    if (!tradeItems) return currentPrices;
    return currentPrices.sort((a, b) => {
      if ((tradeItems[a.gtin]?.itemName ?? "") < (tradeItems[b.gtin]?.itemName ?? "")) return -1 * orderDirection;
      if ((tradeItems[a.gtin]?.itemName ?? "") > (tradeItems[b.gtin]?.itemName ?? "")) return 1 * orderDirection;
      return 0;
    });
  }
  if (field === "netPrice") {
    return currentPrices.sort((a, b) => {
      if (a.netPrice.lessThan(b.netPrice)) return -1 * orderDirection;
      if (a.netPrice.greaterThan(b.netPrice)) return 1 * orderDirection;
      return 0;
    });
  }
  return currentPrices.sort((a, b) => {
    if (a[field] < b[field]) return -1 * orderDirection;
    if (a[field] > b[field]) return 1 * orderDirection;
    return 0;
  });
};

export const CurrentPricesTable: React.FC<Props> = ({ currentPrices, openItemDetailsDialog, currency, tradeItems }) => {
  const classes = useStyles();
  const [search, setSearch] = useState<string>("");

  const [sort, setSort] = useState<{
    field: SortableField;
    order: Order;
  }>({ field: "gtin", order: "ASC" });

  const onSort = (field: SortableField) => {
    if (field === sort.field)
      return setSort({
        field,
        order: sort.order === "ASC" ? "DESC" : "ASC",
      });
    return setSort({
      field,
      order: "DESC",
    });
  };

  const nameColumn = tradeItems
    ? {
        field: "name",
        title: "Nazwa",
        getValue: (item: PriceListDetailCurrentPriceDto) => tradeItems[item.gtin]?.itemName ?? "",
      }
    : null;

  const otherColumns = [
    {
      field: "netPrice",
      title: "Cena netto",
      getValue: (item: PriceListDetailCurrentPriceDto) => formatCurrency(item.netPrice.toNumber(), currency),
    },
    {
      field: "gtin",
      title: "GTIN (EAN)",
      getValue: (item: PriceListDetailCurrentPriceDto) => item.gtin,
    },
    {
      field: "activeFrom",
      title: "Ostatnia zmiana",
      getValue: (item: PriceListDetailCurrentPriceDto) =>
        formatDistanceToNowStrict(item.activeFrom, {
          locale: pl,
          addSuffix: true,
        }),
    },
  ];

  const columnsToDisplay = nameColumn ? [nameColumn, ...otherColumns] : otherColumns;

  const pricesToDisplay = filterCurrentPrices(search, currentPrices, tradeItems);
  const sortedPricesToDisplay = sortCurrentPrices(sort.field, sort.order, pricesToDisplay, tradeItems);

  return (
    <>
      <Typography variant="subtitle2" className={classes.title}>
        Aktualne:
      </Typography>
      <div className={classes.search}>
        <SearchBar onSearchChange={setSearch} />
      </div>
      <div className={classes.table}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columnsToDisplay.map((col) => {
                return (
                  <SortableCell
                    key={`column-${col.title}`}
                    title={col.title}
                    handleSort={() => onSort(col.field as SortableField)}
                    order={sort.field === col.field ? sort.order : undefined}
                  />
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedPricesToDisplay.map((price) => {
              return (
                <TableRow
                  key={`proposal-${price.gtin}`}
                  hover={true}
                  className={classes.hover}
                  onClick={() => openItemDetailsDialog(price.gtin)}
                >
                  {columnsToDisplay.map((col) => {
                    return <TableCell key={`cell-${price.gtin}-${col.title}`}>{col.getValue(price)}</TableCell>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    search: {
      marginLeft: theme.spacing(1),
    },
    table: {
      padding: theme.spacing(1, 0),
      overflow: "auto",
    },
    title: {
      margin: theme.spacing(2, 2, 1, 2),
    },
    hover: {
      whiteSpace: "nowrap",
      cursor: "pointer",
      "& > span": {
        whiteSpace: "normal",
      },
    },
  };
});
