import { Failure } from "@megaron/result";

import { SerializerExtensions, serializerExtensions } from "../extensions";
import { Serializer } from "../serializer";
import { SerializerValue } from "../serializerType";

type CombinedType<T extends Serializer<any>[]> = SerializerValue<T[number]>;

export const first = <T extends Serializer<any>[]>(
  ...serializers: T
): Serializer<CombinedType<T>> & SerializerExtensions<CombinedType<T>> => ({
  serialize: (value: T) => {
    for (const serializer of serializers) {
      try {
        return serializer.serialize(value);
      } catch (e) {
        //
      }
    }

    throw new Error("No serializer matched");
  },
  deserialize: (value: any) => {
    const errors: any[] = [];
    for (const serializer of serializers) {
      try {
        const r = serializer.deserialize(value);
        if (r.isFailure) {
          errors.push(r.error);
          continue;
        }
        if (r.isOk) return r;
      } catch (e) {
        //
      }
    }

    return Failure({
      message: "NoMatchingSerializer",
      serializerErrors: errors,
    });
  },
  ...serializerExtensions(),
});
