import { Tile } from "@dash/tile";
import { useTheme } from "@emotion/react";
import { formatCurrency } from "@legacy-megarax/react-utils";
import { differenceInDays, endOfDay } from "date-fns";
import { BsPersonCheckFill } from "react-icons/bs";
import { MdAttachMoney } from "react-icons/md";
import { Link } from "react-router-dom-v5-compat";

import { InvoiceThreadDoc } from "@megaron/invoices-contracts";

import { getThreadTextColor } from "./AllThreadsTableBody";
import { RemainingDaysTile } from "./RemainingDaysTile";
import { SignatureTile } from "./SignatureTile";
import { ThreadStatusTile } from "./ThreadStatusTile";

type Props = {
  thread: InvoiceThreadDoc;
  userEmail: string;
};

export const AllThreadsMobileCard: React.FC<Props> = ({ thread, userEmail }) => {
  const theme = useTheme();

  const userData = thread.participants?.find((profile) => profile.user === userEmail);

  const remainingDays = differenceInDays(endOfDay(thread.deadline), endOfDay(new Date()));

  return (
    <Tile
      stripe={
        <div css={{ position: "relative" }}>
          <ThreadStatusTile remainingDays={remainingDays} thread={thread} userData={userData} />
        </div>
      }
      css={{
        color: getThreadTextColor(theme, thread.status, remainingDays),
      }}
    >
      <Link
        to={`/faktury/watki/${thread.threadUuid}`}
        css={{
          width: "100%",
          ":hover": {
            textDecoration: "none",
            h4: {
              textDecoration: "underline",
            },
          },
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "0.375rem",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <h4
            css={{
              fontSize: "0.875rem",
              margin: 0,
              fontWeight: !userData || userData?.isRead ? 400 : 700,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {thread.referenceNumber} {thread.title}
          </h4>
          <p
            css={{
              fontSize: "0.75rem",
              color: theme.colors.secondaryText,
              margin: 0,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {thread.invoice.issuer} {thread.invoice.invoiceNumber}
          </p>
          <div css={{ display: "flex", alignItems: "center", gap: "0.375rem", marginTop: "auto" }}>
            <span css={{ fontSize: "0.875rem", fontWeight: 700, color: "black" }}>
              {formatCurrency(thread.invoice.total.toNumber(), thread.invoice.currency)}
            </span>
            <SignatureTile
              Icon={BsPersonCheckFill}
              isFullySigned={thread.signedValidityRelative.toNumber() === 1}
              remainingDays={remainingDays}
              isThreadOpen={thread.status === "open"}
            />
            <SignatureTile
              Icon={MdAttachMoney}
              isFullySigned={thread.signedBudgetRelative.toNumber() === 1}
              remainingDays={remainingDays}
              isThreadOpen={thread.status === "open"}
            />
            {thread.status === "open" && <RemainingDaysTile deadline={thread.deadline} remainingDays={remainingDays} />}
          </div>
        </div>
      </Link>
    </Tile>
  );
};
