import { TextField } from "@dash/form";
import { useToast } from "@dash/toast";
import { useDebouncedValue, useShallowEffect } from "@mantine/hooks";
import { useContext, useState } from "react";
import { useQueryClient } from "react-query";

import { IamAuthContext } from "@megaron/auth-react";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

type Props = {
  alert: string | null;
  customerUuid: string;
  queryKey: string | string[];
};

export const CustomerAlert = ({ alert: defaultAlert, customerUuid, queryKey }: Props) => {
  const [alert, setAlert] = useState(defaultAlert);
  const [debouncedValue] = useDebouncedValue(alert, 200);

  const authContext = useContext(IamAuthContext);

  const hasUserPermission = authContext.iamUser?.roles.some((role) =>
    ["crm.admin", "crm.supervisor", ""].includes(role),
  );

  const queryClient = useQueryClient();

  const toast = useToast();

  const { mutate } = useClientManager("crm").saveCustomer().useMutation();

  useShallowEffect(() => {
    if (debouncedValue === defaultAlert) {
      return;
    }

    mutate(
      {
        alert: debouncedValue,
        uuid: Uuid(customerUuid),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
        },
        onError: (error) => {
          if (error === "Unauthorized") {
            toast.error("Nie posiadasz uprawnień do zaktualizowania komentarza");
            return;
          }

          toast.error("Nie udało się zaktualizować kometarza");
        },
      },
    );
  }, [customerUuid, debouncedValue, defaultAlert, toast, mutate, queryClient, queryKey]);

  return (
    <TextField
      label="Przypomnienie"
      value={alert ?? ""}
      onChange={(value) => setAlert(value || null)}
      multiline
      isDisabled={!hasUserPermission}
    />
  );
};
