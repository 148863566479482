import { Button, IconButton } from "@dash/form";
import { ErrorScreen } from "@dash/skeleton";
import { Spinner } from "@dash/spinner";
import { useTheme } from "@emotion/react";
import React from "react";
import { MdLogin, MdLogout } from "react-icons/md";

import { IamAuthContext } from "@megaron/auth-react";

import { TitleBar } from "./TitleBar";

type Props = {
  children?: React.ReactNode;
  logo?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
};

export const WelcomeScreen: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const authCtx = React.useContext(IamAuthContext);

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        background: "radial-gradient(circle, rgba(255,255,255,0.25) 0%, rgba(255,255,255,0) 80%)",
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
          width: "380px",
          maxWidth: "100%",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <TitleBar
          onClick={() => {
            if (!authCtx.isLoaded || authCtx.iamUser) return;
            return authCtx.signInWithGoogle();
          }}
          css={{
            cursor: "pointer",
          }}
          actions={
            authCtx.isLoaded && !authCtx.iamUser ? (
              <IconButton>
                <MdLogin />
              </IconButton>
            ) : (
              <Spinner color={theme.colors.primary} size="2rem" />
            )
          }
          logo={props.logo}
          title={props.title}
          subtitle={props.subtitle}
        />
        {authCtx.iamError ? (
          <ErrorScreen height="6rem">
            {authCtx.iamError}
            <Button color="danger" icon={<MdLogout />} onClick={authCtx.signOut}>
              Wyloguj
            </Button>
          </ErrorScreen>
        ) : (
          <span css={{ color: "white", opacity: 0.8 }}>Zaloguj się kontem Google.</span>
        )}
      </div>
    </div>
  );
};
