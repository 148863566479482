import { Loader } from "@googlemaps/js-api-loader";
import { useRef, useState } from "react";

const loader = new Loader({
  apiKey: process.env["NX_PUBLIC_GOOGLE_MAPS_API_KEY"]!,
  version: "weekly",
  libraries: ["places"],
  language: "pl",
});

interface LatLng {
  lat: number;
  lng: number;
}

export type Google = typeof google;

export const useGoogleMapsLoader = (
  mapOptions: google.maps.MapOptions,
  onInit?: (ggl: typeof google, map: google.maps.Map) => void,
) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const googleRef = useRef<Google>(undefined);
  const mapRef = useRef<google.maps.Map>(undefined);
  const geocoderRef = useRef<google.maps.Geocoder>(undefined);

  const setMapRef = (ref: HTMLDivElement | null) => {
    if (!ref || mapRef.current !== undefined) return;
    loader.load().then((google) => {
      const map = new google.maps.Map(ref, mapOptions);
      const geocoder = new google.maps.Geocoder();
      mapRef.current = map;
      googleRef.current = google;
      geocoderRef.current = geocoder;
      if (onInit) onInit(google, map);

      setLoaded(true);
    });
  };

  return {
    setMapRef,
    google: googleRef.current,
    geocoder: geocoderRef.current,
    map: mapRef.current,
    loaded,
  };
};
