import { Serializer, Serializers } from "@legacy-megarax/serializers";

export interface IdObjectDto {
  id: number;
  str: string;
}

export interface ActiveRevisionDto extends IdObjectDto {
  activeId: number;
}

export interface AnyRevisionDto extends IdObjectDto {
  activeId: number | null;
}

export const idObjectSerializer = Serializers.object<IdObjectDto>({
  id: Serializers.integer,
  str: Serializers.string,
});

export const activeRevisionSerializer = Serializers.object<ActiveRevisionDto>({
  id: Serializers.integer,
  str: Serializers.string,
  activeId: Serializers.integer,
});

export interface IdObjectReference {
  id: number;
}
export const idObjectReferenceSerializer = Serializers.object<IdObjectReference>({
  id: Serializers.integer,
});

export interface UuidObjectReference {
  uuid: string;
}
export const uuidObjectReferenceSerializer = Serializers.object<UuidObjectReference>({
  uuid: Serializers.uuid(),
});

export interface RevisionReference {
  activeId: number;
}

export const revisionReferenceSerializer = Serializers.object<RevisionReference>({
  activeId: Serializers.integer,
});

export interface PaginatedList<T> {
  /**
   * A list of matched items or a part of it if the list was paginated.
   */
  items: T[];
  /**
   * Total number of matched items, counted before pagination.
   */
  count: number;
}

export const paginatedListSerializer = <T>(unitSerializer: Serializer<T>) =>
  Serializers.object<{
    count: number;
    items: T[];
  }>({
    count: Serializers.integer,
    items: Serializers.array<T>(unitSerializer),
  });

export const revisionDetailDtoSerializer = Serializers.object({
  ...idObjectSerializer.fields,
  activeId: Serializers.integer.nullable(),
  isActive: Serializers.boolean,
  target: activeRevisionSerializer.optional(),
  isDraft: Serializers.boolean,
  isFinalized: Serializers.boolean,
  drafts: Serializers.object({
    ...idObjectSerializer.fields,
    draftCreatedAt: Serializers.datetime,
  }),
});
