import { EntityList } from "@dash/entity-list";
import { Page, PageHeader } from "@dash/page";
import { QuerySkeleton } from "@dash/skeleton";
import React from "react";
import { Link } from "react-router-dom-v5-compat";

import { useClientManager } from "@megaron/react-clients";

export const QrSyncHome: React.FC = () => {
  const query = useClientManager("qrSync").linesQuery().useQuery({});

  return (
    <Page>
      <PageHeader>Linie produkcyjne</PageHeader>
      <QuerySkeleton query={query}>
        {(lines) => (
          <EntityList
            items={lines}
            getKey={(line) => line.id}
            columns={(line) => [{ header: "Nazwa", content: <Link to={`line/${line.id}`}>{line.name}</Link> }]}
          />
        )}
      </QuerySkeleton>
    </Page>
  );
};
