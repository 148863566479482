import { Currency } from "@legacy-megarax/crm-contracts";
import React from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

import { Uuid } from "@megaron/uuid";

import { TradeItemDictionary } from "../../PriceListDetailsContainer";
import { NewProposalContainer } from "./NewProposalContainer";

interface Props {
  reloadProposals: () => void;
  currency: Currency;
  tradeItems: TradeItemDictionary | null;
}

export const NewProposalView: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const params = useParams<{ uuid: string }>();

  const closeDialog = () => navigate(-1);

  return <NewProposalContainer uuid={params.uuid || ("" as Uuid)} {...props} onDialogClose={closeDialog} />;
};
