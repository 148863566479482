import { useDeviceType } from "@dash/mq";
import { useTheme } from "@emotion/react";
import React from "react";

type Props = {
  children?: React.ReactNode;
  className?: string;
};

export const Page: React.FunctionComponent<Props> = (props) => {
  const { mq } = useDeviceType();
  const theme = useTheme();
  return (
    <div
      className={props.className}
      css={{
        maxWidth: theme.siteMaxWidth,
        width: "100%",
        padding: "0 1rem",
        [mq("desktop")]: {
          padding: "0 2rem",
        },
      }}
    >
      {props.children}
    </div>
  );
};
