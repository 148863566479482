import { useDeviceType } from "@dash/mq";
import { HeaderBar, Page, SectionHeader } from "@dash/page";
import { QuerySkeleton, Skeleton } from "@dash/skeleton";
import { useTheme } from "@emotion/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

import { AttachmentDto } from "@megaron/invoices-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { ThreadStateContext } from "../threadStateContext";
import { useScrollToTop } from "../useScrollToTop";
import { AddedToBcCheckbox } from "./AddedToBcCheckbox";
import { ApprovalSelect } from "./ApprovalSelect";
import { ApprovalTile } from "./ApprovalTile";
import { AddAttachment } from "./attachment/AddAttachment";
import { FileView } from "./attachment/FileView";
import { ChangeDecretNumberTile } from "./ChangeDecretNumberTile";
import { ErrorPage } from "./ErrorPage";
import { HeaderBarSettings } from "./HeaderBarSettings";
import { IsReadSwitch } from "./IsReadSwitch";
import { IssuerThreads } from "./IssuerThreads";
import { ParticipantsSection } from "./ParticipantsSection";
import { SignatureSection } from "./signatures/SignatureSection";
import { SimilarThreadAlert } from "./SimilarThreadAlert";
import { ThreadAttachmentCard } from "./ThreadAttachmentCard";
import { ThreadDetailsCard } from "./ThreadDetailsCard";
import { ThreadHistory } from "./ThreadHistory";
import { ThreadPageLayout } from "./ThreadPageLayout";
import { ThreadReceivedCheckbox } from "./ThreadReceivedCheckbox";

export const ThreadPage = () => {
  const { isMobile, isDesktop } = useDeviceType();
  const { storeThread } = useContext(ThreadStateContext);

  const params = useParams<{ uuid: string }>();

  const navigate = useNavigate();

  useScrollToTop();

  const threadQuery = useClientManager("invoices")
    .threadQuery()
    .useQuery({ uuid: params.uuid ? Uuid(params.uuid) : ("" as Uuid) }, { enabled: !!params.uuid });

  const [selectedAttachment, setSelectedAttachment] = useState<AttachmentDto | null>(null);

  useEffect(() => {
    if (threadQuery.data) storeThread(threadQuery.data);
  }, [threadQuery.data?.version]);

  const handleAttachmentClick = (attachment: AttachmentDto) => {
    setSelectedAttachment(attachment);
  };

  const handleClosePreview = () => {
    setSelectedAttachment(null);
  };

  const approvalEvent = threadQuery.data?.history
    .filter((entry) => entry.entryType === "threadApproved")
    .sort((a, b) => a.time.getTime() - b.time.getTime())
    .pop();

  if (!params.uuid) {
    return <div>Error: Thread ID not provided.</div>;
  }

  if (threadQuery.error === "ThreadNotFound") {
    return (
      <ErrorPage
        buttonText="Przejdź do listy wątków"
        description="Wątek o podanym identyfikatorze nie istnieje. Wybierz wątek z listy."
        header="Nie znaleziono wątku"
        headerBarText="Nieistniejący wątek"
        onButtonClick={() => {
          navigate("/faktury/watki");
        }}
      />
    );
  }

  if (threadQuery.error === "Unauthorized") {
    return (
      <ErrorPage
        buttonText="Przejdź do listy wątków"
        description="Nie masz uprawnień do wyświetlenia tego wątku."
        header="Brak uprawnień"
        headerBarText="Brak uprawnień"
        onButtonClick={() => {
          navigate("/faktury/watki");
        }}
      />
    );
  }

  if (threadQuery.error) {
    return (
      <ErrorPage
        buttonText="Spróbuj ponownie"
        description="Wystąpił błąd podczas pobierania wątku. Spróbuj ponownie."
        header="Błąd pobierania wątku"
        headerBarText="Błąd"
        onButtonClick={() => {
          threadQuery.refetch();
        }}
      />
    );
  }

  return (
    <>
      <QuerySkeleton query={threadQuery} height="58px" width="100%">
        {(invoice) => (
          <HeaderBar
            header={`${invoice.referenceNumber} ${invoice.title}`}
            actions={<HeaderBarSettings thread={invoice} queryKey={threadQuery.key} />}
          />
        )}
      </QuerySkeleton>
      <Page
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          marginBottom: "2rem",
          marginTop: "4.5rem",
          padding: isMobile ? "0 1rem" : "0 2rem",
        }}
      >
        <ThreadPageLayout
          invoiceCard={
            <QuerySkeleton query={threadQuery} height={"156px"} width={isDesktop ? "720px" : "auto"}>
              {(invoice) => <ThreadDetailsCard thread={invoice} queryKey={threadQuery.key} />}
            </QuerySkeleton>
          }
          approvalTile={
            <QuerySkeleton query={threadQuery} height={"0px"}>
              {(invoice) =>
                invoice.status === "approved" ? (
                  <ApprovalTile actingUser={approvalEvent?.actingUser ?? ""} time={approvalEvent?.time} />
                ) : null
              }
            </QuerySkeleton>
          }
          similarThreadAlert={
            <QuerySkeleton query={threadQuery} height={"0px"}>
              {(invoice) => <SimilarThreadAlert thread={invoice} />}
            </QuerySkeleton>
          }
          signatureSection={
            <QuerySkeleton
              query={threadQuery}
              height={"132px"}
              customSekelton={
                <div css={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                  <SignatureHeaderSkeleton text="Podpis jakości" />
                  <Skeleton height="132px" />
                  <SignatureHeaderSkeleton text="Podpis budżetu" />
                  <Skeleton height="132px" />
                </div>
              }
            >
              {(invoice) => <SignatureSection thread={invoice} queryKey={threadQuery.key} />}
            </QuerySkeleton>
          }
          threadHistory={
            <QuerySkeleton
              query={threadQuery}
              customSekelton={
                <div css={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                  {["h1", "h2", "h3"].map((e) => (
                    <Skeleton key={e} variant="avatarWithLabel" height="30px" width="30px" />
                  ))}
                </div>
              }
            >
              {(invoice) => <ThreadHistory thread={invoice} queryKey={threadQuery.key} />}
            </QuerySkeleton>
          }
          selectedAttachment={!!selectedAttachment}
          participants={
            <QuerySkeleton
              query={threadQuery}
              customSekelton={
                isDesktop ? (
                  <div css={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                    <SectionHeader isHr css={{ h2: { fontSize: "16px" } }}>
                      Uczestnicy
                    </SectionHeader>
                    <Skeleton variant="avatarWithLabel" />
                    <Skeleton variant="avatarWithLabel" />
                    <Skeleton height="36px" />
                  </div>
                ) : (
                  <Skeleton variant="avatarGroup" />
                )
              }
            >
              {(invoice) => <ParticipantsSection thread={invoice} queryKey={threadQuery.key} />}
            </QuerySkeleton>
          }
          approvalSelect={
            <QuerySkeleton query={threadQuery} height={"42px"} width={isDesktop ? "auto" : "118px"}>
              {(invoice) => <ApprovalSelect thread={invoice} queryKey={threadQuery.key} />}
            </QuerySkeleton>
          }
          threadReceived={
            <QuerySkeleton query={threadQuery} height={"42px"}>
              {(invoice) => <ThreadReceivedCheckbox thread={invoice} queryKey={threadQuery.key} />}
            </QuerySkeleton>
          }
          addedToBcCheckbox={
            <QuerySkeleton query={threadQuery} height={"42px"}>
              {(invoice) => <AddedToBcCheckbox thread={invoice} queryKey={threadQuery.key} />}
            </QuerySkeleton>
          }
          isReadSwitch={
            <QuerySkeleton query={threadQuery} height={"42px"}>
              {(invoice) => (
                <IsReadSwitch thread={invoice} queryKey={threadQuery.key} isDisabled={threadQuery.isFetching} />
              )}
            </QuerySkeleton>
          }
          decreeNumber={
            <QuerySkeleton query={threadQuery}>
              {(invoice) => <ChangeDecretNumberTile thread={invoice} />}
            </QuerySkeleton>
          }
          attachments={
            <QuerySkeleton query={threadQuery} height={"183px"}>
              {(invoice) => (
                <>
                  <div
                    css={{
                      display: "flex",
                      flexDirection: !isDesktop ? "row" : "column",
                      gap: "16px",
                      overflowX: !isDesktop ? "scroll" : "auto",
                    }}
                  >
                    {invoice.attachments.map((attachment) => (
                      <ThreadAttachmentCard
                        attachment={attachment}
                        key={attachment.uuid}
                        onAttachmentClick={() => handleAttachmentClick(attachment)}
                        thread={invoice}
                        queryKey={threadQuery.key}
                        isOpen={selectedAttachment?.uuid === attachment.uuid}
                      />
                    ))}
                  </div>
                  <AddAttachment threadUuid={invoice.uuid} queryKey={threadQuery.key} />
                </>
              )}
            </QuerySkeleton>
          }
          issuerThreads={
            <QuerySkeleton
              query={threadQuery}
              customSekelton={
                <div css={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                  {["i1", "i2", "i3"].map((element) => (
                    <Skeleton key={element} height="60px" />
                  ))}
                </div>
              }
              height={"60px"}
            >
              {(invoice) => <IssuerThreads threadUuid={invoice.uuid} issuer={invoice.invoice.issuer} />}
            </QuerySkeleton>
          }
          fileView={
            selectedAttachment ? (
              <FileView
                url={selectedAttachment.url}
                fileType={selectedAttachment.fileType}
                onClose={handleClosePreview}
              />
            ) : null
          }
        />
      </Page>
    </>
  );
};

const SignatureHeaderSkeleton = (props: { text: string }) => {
  const theme = useTheme();

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        gap: "1em",
        whiteSpace: "nowrap",
      }}
    >
      <div
        css={{
          display: "flex",
          gap: "12px",
          fontSize: "18px",
          fontWeight: 700,
          color: theme.colors.primary,
          alignItems: "center",
        }}
      >
        <button
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "100%",
            border: "none",
            padding: "0.25rem",
            width: "1.25rem",
            height: "1.25rem",
            background: theme.colors.primaryLight,
            flexShrink: 0,
          }}
        />
        <span>{props.text}</span>
      </div>
      <hr
        css={{
          width: "100%",
          border: "none",
          borderTop: `1px solid ${theme.colors.border}`,
          margin: 0,
        }}
      />
    </div>
  );
};
