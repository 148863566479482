import { stringify } from "csv/browser/esm/sync";

import { UserWorkTimeReport } from "@megaron/crm-contracts";

type CsvRow = {
  data: string;
  email: string;
  początek: string;
  koniec: string;
  "przerwy [min]": string;
  "czas pracy [min]": string;
  liczbaInterakcji: number;
  liczbaWizyt: number;
  liczbaWizytPos: number;
};

export const reportToCsv = (report: UserWorkTimeReport): string => {
  const dayRows = report.days.map<CsvRow>((d) => ({
    data: d.date,
    email: d.email,
    początek: d.startTime?.toLocaleTimeString() ?? "",
    koniec: d.endTime?.toLocaleTimeString() ?? "",
    "przerwy [min]": d.breakDurationMinutes.toString(),
    "czas pracy [min]": d.workTimeMinutes?.toString() ?? "",
    liczbaInterakcji: d.interactionCount,
    liczbaWizyt: d.visitCount,
    liczbaWizytPos: d.posVisitCount,
  }));

  const summaryRow: CsvRow = {
    data: "razem",
    email: "",
    początek: "",
    koniec: "",
    "przerwy [min]": "",
    "czas pracy [min]": report.totalWorkTimeMinutes.toString(),
    liczbaWizyt: report.days.reduce((sum, d) => sum + d.visitCount, 0),
    liczbaInterakcji: report.days.reduce((sum, d) => sum + d.interactionCount, 0),
    liczbaWizytPos: report.days.reduce((sum, d) => sum + d.posVisitCount, 0),
  };

  return stringify([summaryRow, ...dayRows], {
    header: true,
    columns: [
      "data",
      "email",
      "początek",
      "koniec",
      "przerwy [min]",
      "czas pracy [min]",
      "",
      "",
      "",
      "liczbaWizytPos",
      "liczbaWizyt",
      "liczbaInterakcji",
    ],
  });
};
