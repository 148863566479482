import { Dialog } from "@dash/dialog";
import { Button } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { useTheme } from "@emotion/react";
import { format } from "date-fns";
import { useState } from "react";
import { MdDeleteOutline, MdOpenInNew } from "react-icons/md";
import { useQueryClient } from "react-query";
import tinycolor from "tinycolor2";

import { AttachmentDto, ThreadDto } from "@megaron/invoices-contracts";
import { useClientManager } from "@megaron/react-clients";

import { FilePreview } from "./attachment/FilePreview";

type Props = {
  attachment: AttachmentDto;
  onAttachmentClick: () => void;
  thread: ThreadDto;
  queryKey: string | string[];
  isOpen?: boolean;
};

export const ThreadAttachmentCard: React.FC<Props> = ({ attachment, onAttachmentClick, thread, queryKey, isOpen }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { isMobile } = useDeviceType();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const removeAttachmentMutation = useClientManager("invoices").removeAttachment().useMutation();
  const formattedDate = format(new Date(attachment.attachedAt), "dd.MM.yyyy");

  const isAfterApproved = thread.approvedAt && attachment.attachedAt > thread.approvedAt;

  const removeAttachment = () => {
    removeAttachmentMutation.mutate(
      {
        threadUuid: thread.uuid,
        attachmentUuid: attachment.uuid,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
        },
      },
    );
  };

  return (
    <>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          borderRadius: theme.smallBorderRadius,
          borderWidth: isOpen ? 2 : 1,
          borderStyle: "solid",
          borderColor: isOpen ? theme.colors.primary : theme.colors.border,
          background: "white",
          overflow: "hidden",
          minWidth: "260px",
        }}
      >
        <FilePreview attachment={attachment} showPreviewFunction={onAttachmentClick} />
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            background: isAfterApproved
              ? `${tinycolor(theme.colors.warning).setAlpha(0.1)}`
              : theme.colors.primaryLight,
            padding: "12px",
            gap: "0.625rem",
          }}
        >
          <div
            css={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <div
              css={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                minWidth: 0,
              }}
            >
              <span
                css={{
                  fontSize: "12px",
                  color: isAfterApproved ? theme.colors.warning : theme.colors.primary,
                  fontWeight: 700,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {attachment.fileName}
              </span>
              <span css={{ fontSize: "10px" }}>
                {attachment.attachedBy} {formattedDate}
              </span>
            </div>
            <div css={{ display: "flex", gap: "0.5rem" }}>
              <Button
                icon={<MdDeleteOutline />}
                css={{
                  background: `${tinycolor(theme.colors.primary).setAlpha(0.15)}`,
                  color: theme.colors.primary,
                  width: "32px",
                  height: "32px",
                }}
                isDisabled={thread.status !== "open"}
                onClick={() => setIsDialogOpen(true)}
              />
              <Button
                icon={<MdOpenInNew />}
                css={{
                  background: `${tinycolor(theme.colors.primary).setAlpha(0.15)}`,
                  color: theme.colors.primary,
                  width: "32px",
                  height: "32px",
                }}
                onClick={() => window.open(attachment.url, "_blank")}
              />
            </div>
          </div>
        </div>
      </div>
      {isDialogOpen && (
        <Dialog
          onClose={() => setIsDialogOpen(false)}
          placement={isMobile ? "top" : "center"}
          css={{ width: "400px" }}
          hideHeader
        >
          <div css={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <span css={{ fontSize: "16px", color: theme.colors.danger, fontWeight: 700 }}>Usuń załącznik</span>

            <span css={{ fontSize: "14px" }}>Czy na pewno chcesz usunąc ten załącznik? </span>
            <div css={{ display: "flex", gap: "12px", alignSelf: "flex-end" }}>
              <Button onClick={() => setIsDialogOpen(false)} variant="outline">
                Anuluj
              </Button>
              <Button
                icon={<MdDeleteOutline />}
                color="danger"
                onClick={removeAttachment}
                isLoading={removeAttachmentMutation.isLoading}
              >
                Usuń
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};
