import { Currency, PriceListChangeDto } from "@legacy-megarax/crm-contracts";
import { ProfilesDictionary } from "@legacy-megarax/iam-webapp";
import { formatCurrency } from "@legacy-megarax/react-utils";
import { Avatar, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {
  history: PriceListChangeDto[];
  currency: Currency;
  profiles: ProfilesDictionary;
  currentDate: Date | undefined;
}

export const TradeItemHistory: React.FC<Props> = ({ history, currency, profiles, currentDate }) => {
  const classes = useStyles();

  const getColorClassByDate = (date: Date) => {
    if (!date) return classes.past;
    if (date.getTime() === currentDate?.getTime()) return classes.current;
    if (date > new Date()) return classes.future;
    return classes.past;
  };

  return (
    <div>
      {history
        .sort((a, b) => (a.activeFrom.getTime() < b.activeFrom.getTime() ? 1 : -1))
        .map((proposal) => {
          const isCurrent = proposal.activeFrom.getTime() === currentDate?.getTime();
          const paperClass = getColorClassByDate(proposal.activeFrom);
          return (
            <Paper key={proposal.uuid} className={paperClass}>
              <div className={classes.paper}>
                <div className={classes.container}>
                  <div>
                    <div className={classes.container}>
                      <div className={classes.row}>
                        <Avatar className={classes.avatar} src={profiles[proposal.approvedBy.id]?.pictureUrl ?? ""}>
                          {proposal.approvedBy.id}
                        </Avatar>
                        <div className={classes.userData}>
                          <Typography className={classes.text}>
                            {profiles[proposal.approvedBy.id]?.displayedName ?? "Nieznany użytkownik"}
                          </Typography>
                          <Typography className={classes.date}>
                            {`Zatw. - ${proposal.approvedAt.toLocaleDateString()}`}
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.row}>
                        <Avatar className={classes.avatar} src={profiles[proposal.proposedBy.id]?.pictureUrl ?? ""}>
                          {proposal.proposedBy.id}
                        </Avatar>
                        <div className={classes.userData}>
                          <Typography className={classes.text}>
                            {profiles[proposal.proposedBy.id]?.displayedName ?? "Nieznany użytkownik"}
                          </Typography>
                          <Typography className={classes.date}>
                            {`Zapr. - ${proposal.proposedAt.toLocaleDateString()}`}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div className={classes.proposalData}>
                      <Typography className={classes.text} variant="body2">
                        {isCurrent ? "Aktualna" : `Aktywna od ${proposal.activeFrom.toLocaleDateString()}`}
                      </Typography>
                      <Typography className={classes.price}>
                        {proposal.netPrice ? formatCurrency(proposal.netPrice.toNumber(), currency) : "Usunięcie"}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.commentBox}>
                    <Typography variant="subtitle2" className={classes.comment}>
                      {proposal.comment ? "Komentarz" : ""}
                    </Typography>
                    <Typography variant="body2" className={classes.comment}>
                      {proposal.comment}
                    </Typography>
                  </div>
                </div>
              </div>
            </Paper>
          );
        })}
    </div>
  );
};

const useStyles = makeStyles((theme: any) => {
  return {
    current: {
      backgroundColor: theme.palette.primary.main,
      paddingLeft: theme.spacing(1),
    },
    past: {
      backgroundColor: theme.palette.grey[500],
      paddingLeft: theme.spacing(1),
    },
    future: {
      backgroundColor: theme.palette.info.main,
      paddingLeft: theme.spacing(1),
    },
    paper: {
      borderRadius: "0 4px 4px 0",
      backgroundColor: "white",
      padding: theme.spacing(1, 2),
      margin: theme.spacing(2, 0),
    },

    avatar: {
      width: "25px",
      height: "25px",
      fontSize: "0.8rem",
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(2),
      },
    },
    proposalData: {
      marginTop: theme.spacing(1),
    },
    userData: {
      fontSize: "0.8rem",
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(1),
      "& > p": {
        fontSize: "0.75rem",
      },
    },
    text: {
      whiteSpace: "nowrap",
    },
    date: {
      color: theme.palette.grey[600],
    },
    commentBox: {
      width: "100%",
    },
    comment: {
      display: "block",
      marginLeft: "auto",
      textAlign: "justify",
      textJustify: "inter-word",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0",
        marginTop: theme.spacing(0.5),
      },
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    container: {
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    price: {
      fontWeight: theme.typography.fontWeightMedium as any,
      display: "block",
      marginRight: "auto",
    },
    table: {
      padding: theme.spacing(1, 0),
      overflow: "auto",
    },
  };
});
