import { Uuid, UuidResourceParam, uuidSerializer } from "@legacy-megarax/common";
import { BaseResource, ResourceActionV2 } from "@legacy-megarax/rest-resource";
import { MSerializers } from "@legacy-megarax/serializers";

import list from "./list";

export interface NameMacroregionInput {
  name: string;
}

const nameMacroregionInputSerialized = MSerializers.object<NameMacroregionInput>({
  name: MSerializers.string,
});

export interface MacroregionSimple {
  uuid: Uuid;
  name: string;
}

export const macroregionResource = BaseResource(["crm", "macroregions"], {
  byUuid: UuidResourceParam("uuid", "uuid", {
    nameMacroregion: ResourceActionV2({
      name: "nameMacroregion",
      method: "post",
      path: "nameMacroregion",
      requestBodySerializer: nameMacroregionInputSerialized,
    }),
  }),
  list: list,
});
