import { PriceReportDto } from "@legacy-megarax/crm-contracts";
import { formatCurrency } from "@legacy-megarax/react-utils";
import { Table } from "@legacy-megarax/ui-components";
import makeStyles from "@mui/styles/makeStyles";
import { formatDistanceToNowStrict } from "date-fns";
import { pl } from "date-fns/locale";
import React from "react";

interface Props {
  reports: PriceReportDto[];
}

export const ReportTable: React.FunctionComponent<Props> = ({ reports }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Table
        items={reports}
        getKey={(item) => item.uuid}
        columns={[
          {
            title: "Cena netto [PLN]",
            getValue: (item) => formatCurrency(item.netPrice.toNumber(), "PLN"),
          },
          { title: "Produkt", getValue: (item) => item.subjectName },
          {
            title: "Utworzono",
            getValue: (item) =>
              formatDistanceToNowStrict(item.time, {
                locale: pl,
                addSuffix: true,
              }),
          },
        ]}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "auto",
  },
}));
