import { Button } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { Page, PageHeader } from "@dash/page";
import { QuerySkeleton } from "@dash/skeleton";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom-v5-compat";

import { useClientManager } from "@megaron/react-clients";

import { OrderDraftsList } from "./OrderDraftsList";
import { OrderDraftsTable } from "./OrderDraftsTable";

export const OrderDraftsPage = () => {
  const { isMobile } = useDeviceType();

  const navigate = useNavigate();

  const orderDraftsQuery = useClientManager("crm").myDraftListQuery().useQuery({});

  return (
    <Page
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        maxWidth: "100%",
      }}
    >
      <PageHeader
        actions={
          <Button icon={<MdAdd />} onClick={() => navigate("/crm/order-drafts/new")}>
            Dodaj
          </Button>
        }
      >
        Zamówienia robocze
      </PageHeader>

      <QuerySkeleton query={orderDraftsQuery}>
        {(orderDrafts) =>
          isMobile ? (
            <OrderDraftsList orders={orderDrafts.items} />
          ) : (
            <OrderDraftsTable isLoading={false} orders={orderDrafts.items} />
          )
        }
      </QuerySkeleton>
    </Page>
  );
};
