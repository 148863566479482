import { TextField } from "@dash/form";
import React, { useState } from "react";

import { CustomerAddress } from "@megaron/crm-contracts";

import { AddressSearchField } from "./AddressSearchField";

type Props = {
  address?: CustomerAddress;
  onChange: (value: CustomerAddress) => void;
  value?: string;
};

export const LocationPicker: React.FC<Props> = ({ address, onChange, value }) => {
  const [inputValue, setInputValue] = useState(value ?? "");
  const [localAddress, setLocalAddress] = useState<CustomerAddress>(
    address ?? {
      country: "",
      city: "",
      street: "",
      postalCode: "",
      latitude: null,
      longitude: null,
      googlePlaceId: null,
    },
  );

  const handleInputChange = (field: keyof CustomerAddress) => (value: string) => {
    setLocalAddress((prevAddress) => ({
      ...prevAddress,
      [field]: value,
    }));
    onChange({
      ...localAddress,
      [field]: value,
    });
    setInputValue(value);
  };

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <AddressSearchField
        value={inputValue}
        onValueChange={setInputValue}
        onChange={(v) => {
          onChange(v);
          setLocalAddress(v);
        }}
        predictionTypes={["geocode", "establishment"]}
      />
      <div css={{ display: "flex", gap: "1rem" }}>
        <TextField label="Kraj" value={localAddress.country} onChange={handleInputChange("country")} />
        <TextField label="Miasto" value={localAddress.city} onChange={handleInputChange("city")} />
      </div>
      <div css={{ display: "flex", gap: "1rem" }}>
        <TextField label="Ulica" value={localAddress.street} onChange={handleInputChange("street")} />
        <TextField label="Kod pocztowy" value={localAddress.postalCode} onChange={handleInputChange("postalCode")} />
      </div>
    </div>
  );
};
