import { AnyUser } from "@megaron/access-control";
import { HttpService } from "@megaron/http-service";
import { HandlerMap } from "@megaron/http-service-host";

import { accountActions } from "./account";
import { authActions } from "./auth";
import { groupActions } from "./group";
import { jwksActions } from "./jwks";
import { registerActions } from "./register";

export const authHttpService = HttpService({
  ...authActions,
  ...accountActions,
  ...groupActions,
  ...registerActions,
  ...jwksActions,
});

export type AuthHandlers = HandlerMap<typeof authHttpService, AnyUser>;
