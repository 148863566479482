import { useDeviceType } from "@dash/mq";
import { useTheme } from "@emotion/react";
import { AnimatePresence, motion } from "motion/react";
import React from "react";

type Props = {
  children?: React.ReactNode;
  nav?: React.ReactNode;
  backgroundImageSrc?: string;
  welcomeScreen?: React.ReactNode;
  className?: string;
};

export const SiteContainer: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const { mq } = useDeviceType();

  const transitionDurationSec = 0.25;

  return (
    <>
      <AnimatePresence>
        {props.welcomeScreen && (
          <motion.div
            className={props.className}
            key="modal"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            transition={{ duration: transitionDurationSec }}
            exit={{ opacity: 0 }}
            css={{
              width: "100%",
              height: "100%",
              position: "fixed",
              left: 0,
              bottom: 0,
              overflow: "hidden",
              background: theme.colors.primary,
            }}
          >
            {props.welcomeScreen}
          </motion.div>
        )}
      </AnimatePresence>

      <div css={{ height: "100%" }}>
        <AnimatePresence>
          {!props.welcomeScreen && (
            <motion.div
              key="modal"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: transitionDurationSec }}
              exit={{ opacity: 0 }}
              css={{
                display: "flex",
                maxWidth: "100%",
                minHeight: "100%",
                flexDirection: "row",
              }}
            >
              {props.nav}
              <div
                css={{
                  background: theme.colors.background,
                  width: "100%",
                  minHeight: "100%",
                  paddingBottom: "52px",
                  overflow: "hidden",
                  [mq("desktop")]: {
                    paddingBottom: "0",
                  },
                }}
              >
                {props.children}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};
