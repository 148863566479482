import { Avatar } from "@dash/avatar";
import { useTheme } from "@emotion/react";
import { useProfiles } from "@megarax/features";

type Props = {
  currentRealization: number;
  goalRealization: number;
  label: string;
  regionOwnerEmail?: string | null;
  variant?: "primary" | "secondary";
  goalReachedText?: string;
  isDisabled?: boolean;
};

export const RealizationProgressBar: React.FC<Props> = ({
  currentRealization,
  goalRealization,
  regionOwnerEmail,
  label,
  variant = "primary",
  isDisabled,
  goalReachedText,
}) => {
  const theme = useTheme();

  const { profile } = useProfiles();

  const regionOwner = regionOwnerEmail ? profile(regionOwnerEmail) : undefined;

  const isPrimary = variant === "primary";

  const realizationPercentage = goalRealization ? Math.floor((currentRealization / goalRealization) * 100) : 0;

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
        opacity: isDisabled ? 0.5 : 1,
      }}
    >
      <div css={{ display: "flex", gap: "1rem", alignItems: "center", justifyContent: "space-between" }}>
        <div css={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          {regionOwnerEmail && <Avatar src={regionOwner?.profilePictureUrl} />}
          <span
            css={{
              fontSize: isPrimary ? "1rem" : "0.875rem",
              fontWeight: isPrimary ? 700 : 400,
              color: theme.colors.primary,
            }}
          >
            {label}
          </span>
        </div>
        {
          <span css={{ fontSize: "0.875rem", color: theme.colors.secondaryText }}>
            {isPrimary ? (
              <>
                {formatNumber(Number(currentRealization))} / {formatNumber(Number(goalRealization))}
                <strong> ({realizationPercentage}%)</strong>
              </>
            ) : currentRealization > 0 ? (
              Number(goalRealization - currentRealization) > 0 ? (
                `brakuje ${formatNumber(Number(goalRealization - currentRealization))} zł`
              ) : (
                <strong>{goalReachedText ?? "Cel osiągnięty"}</strong>
              )
            ) : (
              "0%"
            )}
          </span>
        }
      </div>
      <div
        css={{
          width: "100%",
          height: "1.25rem",
          borderRadius: theme.extraSmallBorderRadius,
          position: "relative",
          overflow: "hidden",
          backgroundColor: theme.colors.primaryLight,
        }}
      >
        <div
          css={{
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            width: `${realizationPercentage}%`,
            background: theme.colors.primary,
          }}
        />
      </div>
    </div>
  );
};

const formatNumber = (number: number) => {
  return number.toLocaleString("fr-FR", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};
