import { Button, CommentInput, Tag } from "@dash/form";
import { EntriesList } from "@dash/history";
import { useState } from "react";
import { MdOutlineSend } from "react-icons/md";
import { useQueryClient } from "react-query";

import { CommentMention, ThreadDto, threadTags } from "@megaron/invoices-contracts";
import { useClientManager } from "@megaron/react-clients";
import { newUuid } from "@megaron/uuid";

import { ThreadHistoryTile } from "./history/ThreadHistoryTile";

type Props = { thread: ThreadDto; queryKey: string | string[]; isPrint?: boolean };

export const ThreadHistory: React.FC<Props> = (props) => {
  const queryClient = useQueryClient();
  const postCommentMutation = useClientManager("invoices").postComment().useMutation();

  const [comment, setComment] = useState<string>("");
  const [mentions, setMentions] = useState<CommentMention[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);

  const handleCommentChange = (comment: string, mentions?: CommentMention[], tags?: Tag[]) => {
    setComment(comment);
    setMentions(mentions || []);
    setTags(tags || []);
  };

  const postComment = () => {
    if (comment) {
      postCommentMutation.mutate(
        {
          commentUuid: newUuid(),
          message: comment,
          threadUuid: props.thread.uuid,
          mentions: mentions.map(({ user, tagPosition, tagLength }) => ({
            user,
            tagPosition,
            tagLength,
          })),
          hashtags: tags.map((t) => t.tag),
        },
        {
          onSuccess: () => {
            setComment("");
            setMentions([]);
            setTags([]);
            queryClient.invalidateQueries(props.queryKey);
          },
        },
      );
    }
  };

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <EntriesList>
        {props.thread.history.map((entry, i) => (
          <ThreadHistoryTile key={i} entry={entry} isPrint={props.isPrint} approvedAt={props.thread.approvedAt} />
        ))}
      </EntriesList>
      <CommentInput
        tags={[...threadTags] as string[]}
        onChange={handleCommentChange}
        enableMentions={true}
        enableTags={true}
        commentValue={comment}
        mentionsValues={mentions}
        tagsValues={tags}
        disableMaxLength
      />
      <div css={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          icon={<MdOutlineSend />}
          css={{
            borderRadius: "50px",
            width: "auto",
            whiteSpace: "nowrap",
          }}
          onClick={postComment}
          isDisabled={!comment}
          isLoading={postCommentMutation.isLoading}
        >
          Wyślij
        </Button>
      </div>
    </div>
  );
};
