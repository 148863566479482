import { Currency, PriceListItemDetail, TradeItemDto } from "@legacy-megarax/crm-contracts";
import { ProfilesDictionary } from "@legacy-megarax/iam-webapp";
import { formatCurrency } from "@legacy-megarax/react-utils";
import { Button, Dialog, DialogActions, DialogContent, Typography, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { TradeItemHistory } from "./TradeitemHistory";

interface Props {
  tradeItem: PriceListItemDetail;
  tradeItemDetails?: TradeItemDto;
  onDialogClose: () => void;
  openProposeEditDialog: () => void;
  openProposeDeletionDialog: () => void;
  currency: Currency;
  profiles: ProfilesDictionary;
}

export const TradeItemDetailsDialog: React.FC<Props> = ({
  onDialogClose,
  tradeItem,
  tradeItemDetails,
  openProposeEditDialog,
  openProposeDeletionDialog,
  currency,
  profiles,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  return (
    <Dialog open={true} onClose={onDialogClose} fullScreen={isMobile} fullWidth maxWidth="md">
      <div className={classes.dialogHeader}>
        <div>
          <Typography variant="h6">{tradeItemDetails?.itemName ?? tradeItem.gtin}</Typography>
          <Typography variant="subtitle1">
            {tradeItem.current ? tradeItem.current.activeFrom.toLocaleDateString() : "-"}
          </Typography>
        </div>
        <Typography variant="h6">
          {tradeItem.current?.netPrice ? formatCurrency(tradeItem.current?.netPrice.toNumber(), currency) : "Usunięta"}
        </Typography>
      </div>
      <DialogContent>
        <Typography variant="subtitle2" className={classes.title}>
          Wszystkie zmiany:
        </Typography>
        <TradeItemHistory
          history={tradeItem.allChanges}
          currency={currency}
          profiles={profiles}
          currentDate={tradeItem.current?.activeFrom}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onDialogClose}>
          Zamknij
        </Button>
        <Button variant="text" onClick={openProposeDeletionDialog} color="secondary">
          Zaproponuj usunięcie
        </Button>
        <Button variant="text" onClick={openProposeEditDialog} color="primary">
          Zaproponuj zmianę
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    dialogHeader: {
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(2),
    },
    title: {
      margin: theme.spacing(2, 2, 1, 0),
    },
  };
});
