import Decimal from "decimal.js";

import { Serializers } from "@megaron/serializers";
import { Uuid, uuidSerializer } from "@megaron/uuid";

import { OriginBreakdown, originBreakdownSerializer, PointTransfer, pointTransferSerializer } from "./pointCategory";

export type Purchase = {
  uuid: Uuid;
  userUuid: Uuid;
  listingUuid: Uuid;
  rewardId: string;
  price: Decimal;
  time: Date;
  isBuyerPro: boolean;
  isRefunded: boolean;
  transfers: PointTransfer[];
  originBreakdown: OriginBreakdown;
};

export const purchaseSerializer = Serializers.object<Purchase>({
  uuid: uuidSerializer,
  userUuid: uuidSerializer,
  listingUuid: uuidSerializer,
  rewardId: Serializers.string,
  price: Serializers.decimal,
  time: Serializers.datetime,
  transfers: pointTransferSerializer.array(),
  isBuyerPro: Serializers.boolean,
  isRefunded: Serializers.boolean,
  originBreakdown: originBreakdownSerializer,
});

export type PurchaseSimple = {
  uuid: Uuid;
  userUuid: Uuid;
  listingUuid: Uuid;
  rewardId: string;
  price: Decimal;
  time: Date;
};

export const purchaseSimpleSerializer = Serializers.object<PurchaseSimple>({
  uuid: uuidSerializer,
  userUuid: uuidSerializer,
  listingUuid: uuidSerializer,
  rewardId: Serializers.string,
  price: Serializers.decimal,
  time: Serializers.datetime,
});
