import { createTheme } from "@dash/default-theme";
import { Theme, ThemeProvider } from "@emotion/react";
import { NewRouterBreadcrumbsBar } from "@legacy-megarax/ui-components";
import { useConfig } from "@megarax/dev-config";
import { appLinkTreeToNavEntries, MegaraxWebappContainer } from "@megarax/features";
import { filterLinkTree } from "@megarax/home";
import { BudgetPage, BudgetsHome, NewBudgetPage } from "@megarax/invoices-budgets";
import {
  AllDraftsPage,
  AllThreadsHome,
  CreateDraftPage,
  DraftPage,
  MyThreadsRedirect,
  ThreadPage,
  ThreadStateContextProvider,
} from "@megarax/invoices-invoice-thread";
import { MegaraxProviders } from "@megarax/root";
import { MdRequestQuote } from "react-icons/md";
import { Outlet, Route } from "react-router-dom-v5-compat";

import { LinkDoc } from "@megaron/docs-contracts";

const invoicesTheme: Theme = createTheme("234");

type Props = {
  linkDocs: LinkDoc[] | undefined;
};

export const useInvoicesRouter = ({ linkDocs }: Props) => {
  return (
    <Route
      path="/faktury"
      element={
        <ThemeProvider theme={invoicesTheme}>
          <InvoicesMegaraxWebappContainer linkDocs={linkDocs} />
        </ThemeProvider>
      }
    >
      <Route path="wersje-robocze">
        <Route path="" element={<AllDraftsPage />} />
        <Route path="nowa" element={<CreateDraftPage />} />
        <Route path=":uuid/*" element={<DraftPage />} />
      </Route>
      <Route path="watki">
        <Route path="" element={<AllThreadsHome />} />
        <Route path="moje-faktury" element={<MyThreadsRedirect />} />
        <Route path="moje_faktury" element={<MyThreadsRedirect />} />
        <Route path=":uuid/*" element={<ThreadPage />} />
      </Route>
      <Route path="budzety">
        <Route path="" element={<BudgetsHome />} />
        <Route path=":uuid" element={<BudgetPage />} />
        <Route path="nowy" element={<NewBudgetPage />} />
      </Route>
    </Route>
  );
};

const InvoicesMegaraxWebappContainer: React.FC<{
  linkDocs: LinkDoc[] | undefined;
}> = ({ linkDocs }) => {
  const fullLinkTree = filterLinkTree(linkDocs || []);
  const invoicesLinkTree = fullLinkTree.find((appTree) => appTree.app === "invoices");

  const { config } = useConfig();

  return (
    <MegaraxWebappContainer
      app={"invoices"}
      logo={<MdRequestQuote width="1em" height="1em" />}
      title="Faktury"
      subtitle="megarax.net"
      bypassAuth={config.testUser.enabled}
      navEntries={appLinkTreeToNavEntries(invoicesLinkTree)}
    >
      <ThreadStateContextProvider>
        <MegaraxProviders themeConfig="invoices">
          <NewRouterBreadcrumbsBar />
          <Outlet />
        </MegaraxProviders>
      </ThreadStateContextProvider>
    </MegaraxWebappContainer>
  );
};
