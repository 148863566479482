import { RowCellContent, Table, TableBody, TableHeadColumn } from "@dash/table";
import { useTheme } from "@emotion/react";
import { formatCurrency } from "@legacy-megarax/react-utils";

import { DraftListEntry } from "@megaron/invoices-contracts";

import { DeleteButton } from "./DeleteButton";

type Props = {
  isLoading: boolean;
  drafts: DraftListEntry[];
  queryKey: string | string[];
};

export const AllDraftsTable: React.FC<Props> = ({ isLoading, drafts, queryKey }) => {
  const theme = useTheme();

  const tableColumns: TableHeadColumn<string>[] = [
    ...tableHeadingsList.map(
      (heading) =>
        ({
          isSortable: false,
          label: heading,
        } as const),
    ),
    {},
  ];

  const getTableRowCellsContent = (draft: DraftListEntry): RowCellContent[] => {
    return [
      {
        isLink: true,
        cellCss: {
          color: theme.colors.primary,
          overflow: "hidden",
        },
        element: (
          <span css={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "8rem" }}>
            {draft.title}
          </span>
        ),
      },
      {
        isLink: true,
        cellCss: { overflow: "hidden" },
        element: (
          <span css={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "12rem" }}>
            {draft.invoice.issuer}
          </span>
        ),
      },
      {
        isLink: true,
        cellCss: { overflow: "hidden" },
        element: draft.invoice.issuerTaxId,
      },
      {
        isLink: true,
        cellCss: { overflow: "hidden" },
        element: (
          <span css={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "8rem" }}>
            {draft.invoice.invoiceNumber}
          </span>
        ),
      },
      {
        isLink: true,
        cellCss: { justifyContent: "flex-end" },
        element: formatCurrency(draft.invoice.total.toNumber(), draft.invoice.currency),
      },
      {
        isLink: true,
        cellCss: { color: theme.colors.secondaryText },
        element: draft.invoice.issueDate.toLocaleDateString(),
      },
      {
        isLink: true,
        cellCss: { color: theme.colors.secondaryText },
        element: draft.invoice.receivedDate?.toLocaleDateString(),
      },

      {
        isLink: true,
        cellCss: { color: theme.colors.secondaryText },
        element: draft.invoice.dueDate?.toLocaleDateString() ?? "-",
      },
      {
        isLink: true,
        cellCss: { color: theme.colors.secondaryText },
        element: draft.emailSender ?? "",
      },
      {
        isLink: true,
        cellCss: { color: theme.colors.secondaryText },
        element: (
          <span css={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "12rem" }}>
            {draft.emailSubject ?? ""}
          </span>
        ),
      },
      {
        cellCss: { padding: "0.5rem", maxWidth: "2.5rem" },
        element: <DeleteButton draftUuid={draft.uuid} queryKey={queryKey} />,
      },
    ];
  };

  return (
    <div css={{ overflow: "auto", margin: "0 -2rem 1rem", padding: "0 2rem" }}>
      <Table columns={tableColumns}>
        <TableBody
          isLoading={isLoading}
          rows={drafts.map((draft) => ({
            uuid: draft.uuid,
            linkTo: `/faktury/wersje-robocze/${draft.uuid}`,
            cellsContent: getTableRowCellsContent(draft),
            css: {
              "&:hover": {
                "td:nth-child(1)": {
                  textDecoration: "underline",
                },
              },
            },
          }))}
        />
      </Table>
    </div>
  );
};

const tableHeadingsList = [
  "Tytuł",
  "Wystawca",
  "NIP wystawcy",
  "Numer Faktury",
  "Kwota netto",
  "Data wyst.",
  "Data otrz.",
  "Termin płatności",
  "Nadawca maila",
  "Temat maila",
];
