import { priceReportSubjectResource, ReportSubject } from "@legacy-megarax/crm-contracts";
import { useResourceProviderV2 } from "@legacy-megarax/react-client";
import { useAsyncLoad } from "@legacy-megarax/react-utils";
import { BreadcrumbMarkers, LoadingBar, PageTitleManager } from "@legacy-megarax/ui-components";
import makeStyles from "@mui/styles/makeStyles";

import { SubjectsView } from "./SubjectsView";

export const SubjectsViewContainer = () => {
  const classes = useStyles();
  const subjectProvider = useResourceProviderV2(priceReportSubjectResource);

  const {
    value: result,
    loading: isLoading,
    reload,
    error,
  } = useAsyncLoad(
    () =>
      subjectProvider.all().then((r) => {
        // eslint-disable-next-line no-console
        console.log("done", r);
        return r;
      }),
    [],
  );

  const onInput = (subjects: ReportSubject[]) => subjectProvider.update(undefined, subjects).then(reload);

  return (
    <div className={classes.background}>
      <PageTitleManager title="Wszystkie produkty" />
      <BreadcrumbMarkers
        breadcrumbs={[
          { title: "Ankiety cenowe", id: "priceReports" },
          { title: "Wszystkie produkty", id: "priceReportSubjects" },
        ]}
      />

      <LoadingBar loading={isLoading} />
      {result?.value && <SubjectsView subjects={result?.value} onInput={onInput} />}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.grey[50],
    flexGrow: 1,
    width: "100%",
  },
}));
