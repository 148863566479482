import { useDeviceType } from "@dash/mq";

import { CustomerAlert } from "./CustomerAlert";
import { CustomerTile, SelectedCustomer } from "./CustomerTile";
import { Customer } from "./OrderDraftPageContent";

type Props = {
  sellTo?: Customer;
  billTo?: Customer;
  payerDepartment?: Customer;
  handlers: {
    onSellToChange: (customer: SelectedCustomer) => void;
    onBillToChange: (customer: SelectedCustomer) => void;
    onPayerDepartmentChange: (customer: SelectedCustomer) => void;
  };
};

export const OrderDetailsForm: React.FC<Props> = ({ billTo, sellTo, payerDepartment, handlers }) => {
  const { isMobile } = useDeviceType();

  const customersWithAlerts = [sellTo, billTo, payerDepartment]
    .filter((customer) => customer?.alert)
    .reduce((acc, customer) => {
      const existingCustomer = acc.find((c) => c.uuid === customer?.uuid);

      if (existingCustomer) {
        return acc;
      }

      return [...acc, customer as Customer];
    }, [] as Customer[]);

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>
      {customersWithAlerts.length > 0 && (
        <div css={{ display: "flex", flexDirection: "column", gap: "0.635rem", width: "100%" }}>
          {customersWithAlerts.map((customer) => (
            <CustomerAlert key={customer.uuid} customer={customer} />
          ))}
        </div>
      )}
      <div
        css={{
          display: "grid",
          gridTemplateColumns: isMobile ? "1fr" : "repeat(auto-fill, minmax(360px, 1fr))",
          gap: "0.625rem",
          width: "100%",
        }}
      >
        <CustomerTile
          customerName={sellTo?.name}
          customerUuid={sellTo?.uuid}
          customerType="sellTo"
          onSelect={handlers.onSellToChange}
        />
        <CustomerTile
          customerName={billTo?.name}
          customerUuid={billTo?.uuid}
          customerType="billTo"
          onSelect={handlers.onBillToChange}
        />
        <CustomerTile
          customerName={payerDepartment?.name}
          customerUuid={payerDepartment?.uuid}
          customerType="payerDepartment"
          onSelect={handlers.onPayerDepartmentChange}
        />
      </div>
    </div>
  );
};
