import { geoPointSerialzier } from "@legacy-megarax/common";
import {
  BaseResource,
  NestedResourceNode,
  paginatedListSerializer,
  ResourceActionV2,
} from "@legacy-megarax/rest-resource";
import { MSerializers } from "@legacy-megarax/serializers";

import {
  customerListQueryFiltersSerializer,
  customerSimpleSerializer,
  listByMyRegionQueryFiltersSerializer,
} from "./customerList";

const listAction = ResourceActionV2({
  name: "list",
  method: "get",
  path: "",
  querySerializer: customerListQueryFiltersSerializer,
  responseValueSerializer: paginatedListSerializer(customerSimpleSerializer),
});

const nearbyCustomersAction = ResourceActionV2({
  name: "nearbyCustomers",
  method: "get",
  querySerializer: geoPointSerialzier,
  responseValueSerializer: MSerializers.array(customerSimpleSerializer),
});

export const customerListResource = BaseResource(["search", "customers"], {
  list: listAction,
  nearbyCustomers: nearbyCustomersAction,
  maintenance: NestedResourceNode("maintenance", {
    rebuild: ResourceActionV2({
      name: "maintenance.rebuild",
      method: "post",
      path: "rebuild",
    }),
    refresh: ResourceActionV2({
      name: "maintenance.refresh",
      method: "post",
      path: "refresh",
    }),
  }),
});
