import { Currency, PriceListItemDetail, TradeItemDto, validateGtin } from "@legacy-megarax/crm-contracts";
import { ProfilesDictionary } from "@legacy-megarax/iam-webapp";
import { InvalidGtinDialog } from "@legacy-megarax/ui-components";
import React from "react";
import { useMatch, useNavigate } from "react-router-dom-v5-compat";

import { getProposeDeletionUrl, getProposeEditUrl, PROPOSE_CHANGE, PROPOSE_DELETION } from "../../../routes";
import { ProposeDeletionDialog } from "./ProposeDeletion/ProposeDeletionDialog";
import { ProposeDeletionInput } from "./ProposeDeletion/proposeDeletionForm";
import { ProposeEditDialog } from "./ProposeEdit/ProposeEditDialog";
import { ProposeEditInput } from "./ProposeEdit/proposeEditForm";
import { TradeItemDetailsDialog } from "./TradeItemDetailsDialog";

interface Props {
  tradeItem: PriceListItemDetail;
  tradeItemDetails?: TradeItemDto;
  onProposeDeletion: (input: ProposeDeletionInput) => Promise<void>;
  onProposeEdit: (input: ProposeEditInput) => Promise<void>;
  currency: Currency;
  profiles: ProfilesDictionary;
  gtin: string;
  uuid: string;
}

export const TradeItemDetailsView: React.FC<Props> = ({ onProposeDeletion, onProposeEdit, gtin, uuid, ...props }) => {
  const isGtinValid = validateGtin(gtin);

  const navigate = useNavigate();

  const closeDialog = () => navigate(-1);

  const openProposeDeletionDialog = () => navigate(getProposeDeletionUrl(uuid, gtin));
  const openProposeEditDialog = () => navigate(getProposeEditUrl(uuid, gtin));

  const proposeDeletionDialogMatchPath = useMatch(PROPOSE_DELETION);
  const proposeEditDialogMatchPath = useMatch(PROPOSE_CHANGE);

  if (isGtinValid.isFailure) return <InvalidGtinDialog gtin={gtin} />;

  return (
    <>
      <TradeItemDetailsDialog
        openProposeDeletionDialog={openProposeDeletionDialog}
        openProposeEditDialog={openProposeEditDialog}
        onDialogClose={closeDialog}
        {...props}
      />
      {proposeEditDialogMatchPath && <ProposeEditDialog item={props.tradeItem} onProposeEdit={onProposeEdit} />}
      {proposeDeletionDialogMatchPath && <ProposeDeletionDialog onProposeDeletion={onProposeDeletion} />}
    </>
  );
};
