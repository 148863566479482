import { useDialogRoute } from "@dash/dialog";
import { Button } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { useToast } from "@dash/toast";
import { useTheme } from "@emotion/react";
import { PayerDetail } from "@legacy-megarax/crm-contracts";
import Decimal from "decimal.js";
import { useEffect, useState } from "react";
import { MdArrowForward } from "react-icons/md";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom-v5-compat";

import { CustomerDetails, DraftInput, EditCustomersInput, OrderDraftDto } from "@megaron/crm-contracts";
import { useClientManager, useServiceClient } from "@megaron/react-clients";
import { newUuid, Uuid } from "@megaron/uuid";

import { MarginButton } from "./MarginButton";
import { OrderDetailsForm } from "./OrderDetailsForm";
import { OrderItemsSection } from "./OrderItemsSection";
import { OrderSummaryDialog } from "./OrderSummaryDialog";
import { PackSizeButton } from "./PackSizeButton";

export type Customer = {
  uuid: string | undefined;
  name: string | undefined;
  alert: string | null;
};

export type CustomerIds = {
  billToId?: string;
  sellToId?: string;
  payerDepartmentId?: string;
};

type Props = {
  isNew: boolean;
  initialCustomer?: CustomerDetails;
  orderDraft?: OrderDraftDto;
  queryKey?: string | string[];
};

export const OrderDraftPageContent: React.FC<Props> = ({ isNew, orderDraft, queryKey, initialCustomer }) => {
  const { isMobile } = useDeviceType();

  const navigate = useNavigate();

  const theme = useTheme();

  const toast = useToast();

  const queryClient = useQueryClient();

  const [sellTo, setSellTo] = useState<Customer | undefined>(
    orderDraft
      ? {
          uuid: orderDraft.sellToUuid,
          name: orderDraft.sellToName,
          alert: orderDraft.sellToAlert,
        }
      : initialCustomer
      ? {
          uuid: initialCustomer.uuid,
          name: initialCustomer.name,
          alert: initialCustomer.alert,
        }
      : undefined,
  );

  const [billTo, setBillTo] = useState<Customer | undefined>(
    orderDraft
      ? {
          uuid: orderDraft.billToUuid,
          name: orderDraft.billToName,
          alert: orderDraft.billToAlert,
        }
      : undefined,
  );

  const [payerDepartment, setPayerDepartment] = useState<Customer | undefined>(
    orderDraft
      ? {
          uuid: orderDraft.payerDepartmentUuid,
          name: orderDraft.payerDepartmentName,
          alert: orderDraft.payerDepartmentAlert,
        }
      : initialCustomer
      ? {
          uuid: initialCustomer.uuid,
          name: initialCustomer.name,
          alert: initialCustomer.alert,
        }
      : undefined,
  );

  const [currentPayer, setCurrentPayer] = useState<PayerDetail | undefined>(undefined);

  const [areCustomersEdited, setAreCustomersEdited] = useState(false);

  useEffect(() => {
    if (isNew) {
      setAreCustomersEdited(true);
      return;
    }

    if (
      orderDraft?.sellToUuid !== sellTo?.uuid ||
      orderDraft?.billToUuid !== billTo?.uuid ||
      orderDraft?.payerDepartmentUuid !== payerDepartment?.uuid
    ) {
      setAreCustomersEdited(true);
      return;
    }

    setAreCustomersEdited(false);
  }, [sellTo, billTo, payerDepartment, orderDraft, isNew]);

  const legacyMegarax = useServiceClient("legacyMegarax");

  const earliestDeliveryDateQuery = useClientManager("legacyMegarax").fastestDeliveryDate().useQuery({});

  const isSendDraftDisabled = !earliestDeliveryDateQuery.data;

  const orderSummaryDialog = useDialogRoute("/summary", ({ onClose }) => {
    if (isSendDraftDisabled || !orderDraft) {
      return null;
    }

    return (
      <OrderSummaryDialog
        onClose={onClose}
        earliestDeliveryDate={earliestDeliveryDateQuery.data}
        customerAddress={orderDraft.payerDepartmentAddress}
        draftUuid={orderDraft.uuid}
      />
    );
  });

  const fetchPayer = async (payerUuid: string) => {
    const payer = (await legacyMegarax.retrievePayer({ customerUuid: Uuid(payerUuid) })).value;

    setCurrentPayer(payer);

    return payer;
  };

  const createDraftMutation = useClientManager("crm").startDraft().useMutation();
  const updateCustomersMutation = useClientManager("crm").editDraftCustomers().useMutation();

  const createDraft = async ({ billToId, sellToId, payerDepartmentId }: CustomerIds) => {
    if (!sellToId || !billToId || !payerDepartmentId) {
      return;
    }

    const payer = await fetchPayer(billToId);

    if (!payer) {
      toast.error("Nie znaleziono płatnika");
      setBillTo(undefined);
      return;
    }

    if (!payer.priceList) {
      toast.error("Nie znaleziono cennika dla płatnika");
      return;
    }

    const uuid = newUuid();

    const input: DraftInput = {
      uuid,
      payerDiscountRate: payer.baseDiscount ?? new Decimal(0),
      buyer: sellToId as Uuid,
      payer: billToId as Uuid,
      priceList: payer.priceList.uuid as Uuid,
      recipient: payerDepartmentId as Uuid,
    };

    createDraftMutation.mutate(input, {
      onSuccess: () => {
        navigate(`/crm/order-drafts/${uuid}`, { replace: true });
      },
      onError: (error) => {
        if (error === "InvalidDiscountRate") {
          toast.error("Nieprawidłowy rabat");
          return;
        }

        if (error === "Unauthorized") {
          toast.error("Nie masz uprawnień do wykonania tej akcji");
          return;
        }

        toast.error("Wystąpił błąd podczas tworzenia zamówienia");
        return;
      },
    });
  };

  const updateCustomers = async ({ billToId, sellToId, payerDepartmentId }: CustomerIds) => {
    if (!orderDraft || !sellToId || !billToId || !payerDepartmentId) {
      return;
    }

    let payer = currentPayer;

    if (billToId !== currentPayer?.customerUuid) {
      payer = await fetchPayer(billToId);
    }

    if (!payer) {
      toast.error("Nie znaleziono płatnika");
      setBillTo(undefined);
      return;
    }

    if (!payer.priceList) {
      toast.error("Nie znaleziono cennika dla płatnika");
      return;
    }

    const input: EditCustomersInput & { uuid: Uuid } = {
      uuid: orderDraft.uuid,
      payerDiscountRate: payer?.baseDiscount,
      sellToUuid: Uuid(sellToId),
      billToUuid: Uuid(billToId),
      priceListUuid: Uuid(payer.priceList.uuid),
      payerDepartmentUuid: Uuid(payerDepartmentId),
    };

    updateCustomersMutation.mutate(input, {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        toast.info("Klienci zostali zaktualizowani");
      },
      onError: (error) => {
        if (error === "DraftNotOpen") {
          toast.error("Wersja robocza nie posiada statusu: otwarta.");
          return;
        }

        if (error === "Unauthorized") {
          toast.error("Nie masz uprawnień do wykonania tej akcji");
          return;
        }

        if (error === "OrderNotFound") {
          toast.error("Nie znaleziono zamówienia");
          return;
        }

        if (error === "UserNotOrderOwner") {
          toast.error("Nie jesteś właścicielem tego zamówienia");
          return;
        }

        toast.error("Wystąpił błąd podczas aktualizacji klientów");
      },
    });
  };

  const handleCustomersSave = (customerIds: CustomerIds) => {
    if (isNew) {
      createDraft(customerIds);
      return;
    }

    updateCustomers(customerIds);
  };

  return (
    <>
      <OrderDetailsForm
        sellTo={sellTo}
        billTo={billTo}
        payerDepartment={payerDepartment}
        handlers={{
          onBillToChange: (customer) => {
            setBillTo(customer);
            handleCustomersSave({
              sellToId: sellTo?.uuid,
              billToId: customer.uuid,
              payerDepartmentId: payerDepartment?.uuid,
            });
          },
          onPayerDepartmentChange: (customer) => {
            setPayerDepartment(customer);
            handleCustomersSave({
              sellToId: sellTo?.uuid,
              billToId: billTo?.uuid,
              payerDepartmentId: customer?.uuid,
            });
          },
          onSellToChange: (customer) => {
            setSellTo(customer);
            setPayerDepartment(customer);

            const payer = customer?.parents?.find((parent) => parent.relationType === "payer");

            setBillTo({
              uuid: payer?.uuid,
              name: payer?.name,
              alert: payer?.alert ?? null,
            });

            handleCustomersSave({
              sellToId: customer?.uuid,
              billToId: payer?.uuid ?? undefined,
              payerDepartmentId: customer?.uuid,
            });
          },
        }}
      />

      <div css={{ fontSize: "1rem", color: theme.colors.primary }}>
        {billTo?.uuid
          ? `Rabat: ${
              currentPayer
                ? `${currentPayer.baseDiscount.mul(100)}%`
                : orderDraft
                ? `${orderDraft.payerDiscountRate.mul(100)}%`
                : "-"
            }`
          : "Rabat: -"}
      </div>

      <OrderItemsSection
        lines={orderDraft?.lines ?? []}
        isDisabled={areCustomersEdited}
        orderUuid={orderDraft?.uuid}
        queryKey={queryKey}
        currency={orderDraft?.currency ?? "PLN"}
        priceListUuid={currentPayer?.priceList?.uuid ?? orderDraft?.priceListUuid}
        baseDiscount={currentPayer?.baseDiscount ?? orderDraft?.payerDiscountRate}
      />

      <div
        css={{
          display: "flex",
          gap: isMobile ? "1rem" : "0.625rem",
          justifyContent: "space-between",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "flex-end" : "center",
        }}
      >
        <div css={{ display: "flex", gap: "0.625rem", alignSelf: "stretch" }}>
          <PackSizeButton isDisabled={areCustomersEdited} lines={orderDraft?.lines ?? []} />
          <MarginButton
            isDisabled={areCustomersEdited}
            lines={orderDraft?.lines ?? []}
            billToId={orderDraft?.billToUuid}
            sellToId={orderDraft?.sellToUuid}
            currency={orderDraft?.currency}
          />
        </div>

        {!orderDraft?.isFinalized && (
          <Button onClick={orderSummaryDialog.open} isDisabled={areCustomersEdited || isSendDraftDisabled}>
            <div css={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
              Wyślij
              <MdArrowForward size={16} />
            </div>
          </Button>
        )}
      </div>

      {orderSummaryDialog.element}
    </>
  );
};
