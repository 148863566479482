import { Button } from "@dash/form";
import { css, useTheme } from "@emotion/react";
import { getAppColor } from "@megarax/home";
import React, { useState } from "react";
import { MdOutlineAlternateEmail, MdOutlineDesktopWindows, MdOutlineNotifications } from "react-icons/md";
import { useQueryClient } from "react-query";

import { NotificationType } from "@megaron/notifications-contracts";
import { useClientManager } from "@megaron/react-clients";

import { notificationPermissionMap } from "./notificationsPermissionMap";

type Props = {
  permission: {
    type: NotificationType;
    isEmail: boolean;
    isWebPush: boolean;
    isApp: boolean;
  };
  permissionsQuery: string | string[];
  notificationsQueryKey: string | string[];
};

export const PermissionTile: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { permission } = props;
  const details = notificationPermissionMap[permission.type];
  const color = getAppColor(details.app, theme);
  const queryClient = useQueryClient();

  const [isEmailEnabled, setIsEmailEnabled] = useState(permission.isEmail);
  const [isWebPushEnabled, setIsWebPushEnabled] = useState(permission.isWebPush);
  const [isAppEnabled, setIsAppEnabled] = useState(permission.isApp);

  const savePermissionMutation = useClientManager("notifications").savePermission().useMutation();

  const handlePermissionToggle = (type: "isEmail" | "isWebPush" | "isApp") => {
    const newEmailStatus = type === "isEmail" ? !isEmailEnabled : isEmailEnabled;
    const newWebPushStatus = type === "isWebPush" ? !isWebPushEnabled : isWebPushEnabled;
    const newAppStatus = type === "isApp" ? !isAppEnabled : isAppEnabled;

    setIsEmailEnabled(newEmailStatus);
    setIsWebPushEnabled(newWebPushStatus);
    setIsAppEnabled(newAppStatus);

    savePermissionMutation.mutate(
      {
        type: permission.type,
        isApp: newAppStatus,
        isEmail: newEmailStatus,
        isWebPush: newWebPushStatus,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(props.permissionsQuery);
          queryClient.invalidateQueries(props.notificationsQueryKey);
        },
      },
    );
  };

  const hexToRgb = (hex: string) => {
    hex = hex.replace(/^#/, "");

    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `${r}, ${g}, ${b}`;
  };

  const activeButtonStyle = css({
    backgroundColor: color,
    padding: "7px",
  });

  const unactiveButtonStyle = css({
    backgroundColor: `rgba(${hexToRgb(color)}, 0.2)`,
    color: `rgba(${hexToRgb(color)}, 0.7)`,
    padding: "7px",
    ":disable": { cursor: "not-allowed" },
  });

  return (
    <div
      css={{
        borderRadius: theme.smallBorderRadius,
        border: `1px solid ${theme.colors.border}`,
        background: "white",
        display: "flex",
        fontSize: "14px",
        fontWeight: "bold",
        alignItems: "center",
        padding: "8px 12px",
        justifyContent: "space-between",
        color: color,
        gap: "16px",
      }}
    >
      {details.permissionName}
      <div css={{ display: "flex", gap: "8px" }}>
        <Button
          css={isAppEnabled ? activeButtonStyle : unactiveButtonStyle}
          icon={<MdOutlineDesktopWindows />}
          onClick={() => handlePermissionToggle("isApp")}
        />
        <Button
          css={isEmailEnabled ? activeButtonStyle : unactiveButtonStyle}
          icon={<MdOutlineAlternateEmail />}
          onClick={() => handlePermissionToggle("isEmail")}
        />
        <Button
          css={isWebPushEnabled ? activeButtonStyle : unactiveButtonStyle}
          icon={<MdOutlineNotifications />}
          onClick={() => handlePermissionToggle("isWebPush")}
        />
      </div>
    </div>
  );
};
