import { identityValidator, objectValidator, ObjectValidatorSchema } from "@legacy-megarax/validators";

export interface ProposeDeletionInput {
  activeFrom: Date | null;
  comment: string;
}

const activeFrom = new Date();
activeFrom.setDate(activeFrom.getDate() + 1);

export const validatorSchema = ObjectValidatorSchema<ProposeDeletionInput, ProposeDeletionInput>()({
  activeFrom: identityValidator(),
  comment: identityValidator(),
});

const errorMessages = {
  required: "To pole jest wymagane.",
};

export const getErrorMessage = (failure: "required" | undefined) => (failure ? errorMessages[failure] : undefined);

export const formValidator = objectValidator(validatorSchema);
