import { Uuid, uuidSerializer } from "@legacy-megarax/common";
import { MSerializers } from "@legacy-megarax/serializers";

export interface SaveContactInput {
  contact: {
    uuid: Uuid;
    name: string;
    email: string;
    phone: string;
    position: string;
  };
}

export const saveContactInputSerializer = MSerializers.object<SaveContactInput>({
  contact: MSerializers.object<{
    uuid: Uuid;
    name: string;
    email: string;
    phone: string;
    position: string;
  }>({
    uuid: uuidSerializer,
    name: MSerializers.string,
    email: MSerializers.string,
    phone: MSerializers.string,
    position: MSerializers.string,
  }),
});

export interface RemoveContactInput {
  uuid: Uuid;
}

export const removeContactInputSerializer = MSerializers.object<RemoveContactInput>({
  uuid: uuidSerializer,
});
