import { Button } from "@dash/form";
import { useTheme } from "@emotion/react";
import { MdOpenInNew } from "react-icons/md";
import tinycolor from "tinycolor2";

import { DraftAttachmentDto } from "@megaron/invoices-contracts";

import { FilePreview } from "../thread/attachment/FilePreview";

type Props = {
  attachment: DraftAttachmentDto;
  onAttachmentClick: () => void;
  isOpen?: boolean;
};

export const DraftAttachmentCard: React.FC<Props> = ({ attachment, onAttachmentClick, isOpen }) => {
  const theme = useTheme();

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        borderRadius: theme.smallBorderRadius,
        borderWidth: isOpen ? 2 : 1,
        borderStyle: "solid",
        borderColor: isOpen ? theme.colors.primary : theme.colors.border,
        background: "white",
        overflow: "hidden",
        minWidth: "260px",
        maxWidth: "400px",
      }}
    >
      <FilePreview attachment={attachment} showPreviewFunction={onAttachmentClick} />
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          background: theme.colors.primaryLight,
          padding: "12px",
          gap: "0.625rem",
        }}
      >
        <div
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "8px",
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              minWidth: 0,
            }}
          >
            <span
              css={{
                fontSize: "12px",
                color: theme.colors.primary,
                fontWeight: 700,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {attachment.fileName}
            </span>
          </div>

          <Button
            icon={<MdOpenInNew />}
            css={{
              background: `${tinycolor(theme.colors.primary).setAlpha(0.15)}`,
              color: theme.colors.primary,
              width: "32px",
              height: "32px",
            }}
            onClick={() => window.open(attachment.url, "_blank")}
          />
        </div>
      </div>
    </div>
  );
};
