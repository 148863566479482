import { DescriptionField } from "@dash/description-list";
import { useDialogRoute } from "@dash/dialog";
import { AddressSearchField, GoogleMap, usePlaceDetails } from "@dash/google-map";
import { useDeviceType } from "@dash/mq";
import { SectionHeader } from "@dash/page";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom-v5-compat";

import { CustomerAddress as CustomerAddressType } from "@megaron/crm-contracts";

import { parseCustomerAddressToString } from "./CustomerTable";
import { EditCustomerAddressDialog } from "./EditCustomerAddressDialog";

type Props = {
  customerId: string;
  address: CustomerAddressType;
  onAddressChange: (address: CustomerAddressType) => void;
  isLoading: boolean;
  isLoyalty: boolean;
};

export const CustomerAddress: React.FC<Props> = ({ address, isLoading, onAddressChange, isLoyalty, customerId }) => {
  const theme = useTheme();

  const { isMobile } = useDeviceType();

  const [placeFormattedAddress, setPlaceFormattedAddress] = useState<string>("");

  const [newAddress, setNewAddress] = useState<CustomerAddressType>(address);

  const editAddressDialog = useDialogRoute(`/edit-address`, ({ onClose }) => (
    <EditCustomerAddressDialog
      onClose={onClose}
      address={newAddress}
      onAddressChange={setNewAddress}
      onSave={() => {
        onAddressChange(newAddress);
        onClose();
      }}
      isLoading={isLoading}
    />
  ));

  const place = usePlaceDetails(address.googlePlaceId);

  useEffect(() => {
    if (place?.formatted_address && place.name) {
      setPlaceFormattedAddress(`${place.name}, ${place.formatted_address}`);
    }

    if (!address.googlePlaceId) {
      setPlaceFormattedAddress("");
    }
  }, [place?.formatted_address, place?.name, address.googlePlaceId]);

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%", flexGrow: 0 }}>
      <SectionHeader isHr>Adres</SectionHeader>

      {!isLoyalty && (
        <AddressSearchField
          value={placeFormattedAddress}
          onValueChange={setPlaceFormattedAddress}
          onChange={onAddressChange}
          predictionTypes={["establishment"]}
          label="Placówka"
        />
      )}

      {place?.photos && (
        <div
          css={{
            overflow: "auto",
            width: isMobile ? "calc(100% + 2rem)" : "100%",
            display: "flex",
            flexGrow: 0,
            margin: isMobile ? "0 -1rem" : 0,
          }}
        >
          <div css={{ display: "flex", gap: "0.5rem", width: "auto", padding: isMobile ? "0.5rem 1rem" : "0.5rem 0" }}>
            {place.photos.map((photo) => (
              <a
                href={photo.getUrl()}
                target="_blank"
                rel="noreferrer"
                css={{
                  width: "200px",
                  height: "150px",
                  position: "relative",
                  borderRadius: theme.smallBorderRadius,
                  overflow: "hidden",
                }}
                key={photo.getUrl()}
              >
                <img
                  src={photo.getUrl()}
                  alt={place.name}
                  css={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                    position: "absolute",
                  }}
                />
              </a>
            ))}
          </div>
        </div>
      )}

      <button
        onClick={editAddressDialog.open}
        css={{ background: "none", border: "none", textAlign: "start", cursor: "pointer", padding: 0 }}
      >
        <DescriptionField label="Adres">{parseCustomerAddressToString(address)}</DescriptionField>
      </button>

      <div
        css={{
          display: "flex",
          alignItems: "center",
          gap: "0.25rem",
          color: theme.colors.primary,
          textDecoration: "underline",
        }}
      >
        {address.googlePlaceId ? (
          <a
            href={`https://www.google.com/maps/place/?q=place_id:${address.googlePlaceId}`}
            rel="noreferrer"
            target="_blank"
          >
            Zobacz na mapie
          </a>
        ) : (
          <Link to={`/crm/customers/new-map?customerId=${customerId}`}>Zobacz na mapie</Link>
        )}
      </div>

      {address.latitude && address.longitude && (
        <GoogleMap latLng={{ latitude: address.latitude, longitude: address.longitude }} />
      )}

      {editAddressDialog.element}
    </div>
  );
};
