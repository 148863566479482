import {
  addressSerializer,
  BasicAddress,
  GeoPoint,
  geoPointSerialzier,
  Uuid,
  uuidSerializer,
} from "@legacy-megarax/common";
import { MSerializers } from "@legacy-megarax/serializers";

export interface SalespersonDto {
  userId: number;
  homeLocation: GeoPoint | null;
  address: BasicAddress | null;
  workStartTime: number | null;
  managerId: number | null;
  regionUuid: Uuid;
}

export const salespersonSerializer = MSerializers.object<SalespersonDto>({
  userId: MSerializers.integer,
  homeLocation: geoPointSerialzier.nullable(),
  address: addressSerializer.nullable(),
  workStartTime: MSerializers.integer.nullable(),
  managerId: MSerializers.integer.nullable(),
  regionUuid: uuidSerializer,
});
