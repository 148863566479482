import { useDeviceType } from "@dash/mq";
import { HeaderBar, Page } from "@dash/page";
import { QuerySkeleton } from "@dash/skeleton";
import { useParams } from "react-router-dom-v5-compat";

import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { BudgetDetails } from "./BudgetDetails";

// import { BudgetMenu } from "./BudgetMenu";

export const BudgetPage = () => {
  const { uuid } = useParams<{ uuid: string }>();

  if (!uuid) {
    return <div>Error: Budget ID not provided.</div>;
  }

  return <BudgetPageContent budgetUuid={uuid} />;
};

const BudgetPageContent: React.FC<{ budgetUuid: string }> = ({ budgetUuid }) => {
  const budgetQuery = useClientManager("invoices")
    .budgetDocsQuery()
    .useQuery({ uuid: Uuid(budgetUuid) });

  const { isMobile } = useDeviceType();

  return (
    <>
      <QuerySkeleton query={budgetQuery} height="58px" width="100%">
        {(budgets) => (
          <HeaderBar
            // actions={<BudgetMenu />}
            header={budgets[0].name}
          />
        )}
      </QuerySkeleton>

      <Page
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          marginBottom: "2rem",
          marginTop: "4.5rem",
          padding: isMobile ? "0 1rem" : "0 2rem",
        }}
      >
        <QuerySkeleton query={budgetQuery}>
          {(budgets) => <BudgetDetails budget={budgets[0]} onRefetch={budgetQuery.refetch} />}
        </QuerySkeleton>
      </Page>
    </>
  );
};
