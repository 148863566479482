import { useDeviceType } from "@dash/mq";
import { Page, PageHeader } from "@dash/page";
import { QuerySkeleton } from "@dash/skeleton";
import { useToast } from "@dash/toast";
import { useContext, useState } from "react";

import { IamAuthContext } from "@megaron/auth-react";
import { DocType } from "@megaron/docs-contracts";
import { useClientManager } from "@megaron/react-clients";

import { DocsMobileList } from "./DocsMobileList";
import { DocsTable } from "./DocsTable";

export const DocsPage = () => {
  const { isMobile } = useDeviceType();

  const toast = useToast();

  const auth = useContext(IamAuthContext);

  const indexModuleQuery = useClientManager("docs").indexModulesQuery().useQuery({}, { enabled: !!auth.iamUser });

  const refreshMutation = useClientManager("docs").refreshIndexDocs().useMutation();

  const [refreshingDoctype, setRefreshingDoctype] = useState<DocType | null>(null);

  const handleRefresh = (module: DocType) => {
    setRefreshingDoctype(module);

    refreshMutation.mutate(
      { module },
      {
        onSuccess: () => {
          indexModuleQuery.refetch();
          setRefreshingDoctype(null);
        },
        onError: () => {
          toast.error("Failed to refresh index");
          setRefreshingDoctype(null);
        },
      },
    );
  };

  return (
    <Page css={{ maxWidth: "100%", marginTop: "2rem" }}>
      <PageHeader>Docs indices</PageHeader>
      <QuerySkeleton query={indexModuleQuery}>
        {(entries) =>
          isMobile ? (
            <DocsMobileList
              docsIndexModules={entries}
              onRefresh={handleRefresh}
              isLoading={indexModuleQuery.isFetching}
              isRefreshing={refreshMutation?.isLoading}
            />
          ) : (
            <DocsTable
              docsIndexModules={entries}
              onRefresh={handleRefresh}
              isLoading={indexModuleQuery.isFetching}
              isRefreshing={refreshMutation?.isLoading}
              refreshingDoctype={refreshingDoctype}
            />
          )
        }
      </QuerySkeleton>
    </Page>
  );
};
