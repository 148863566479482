import { useDeviceType } from "@dash/mq";
import { useTheme } from "@emotion/react";
import React from "react";
import { NavLink, useLocation, useSearchParams } from "react-router-dom-v5-compat";
import tinycolor from "tinycolor2";

type Props = {
  to: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  isCollapsed?: boolean;
  className?: string;
  color: string;
  isActive?: (pathname: string, searchParams: URLSearchParams) => boolean;
};

export const NavEntry: React.FunctionComponent<Props> = (props) => {
  const { mq } = useDeviceType();
  const theme = useTheme();

  const [searchParams] = useSearchParams();

  const { pathname } = useLocation();

  const isActive = props.isActive ? props.isActive(pathname, searchParams) : pathname.startsWith(props.to);

  return (
    <NavLink
      className={props.className}
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "0.65rem",
        fontSize: props.isCollapsed ? "18px" : "1.5rem",
        padding: props.isCollapsed ? "7px 0.25rem" : "0.5rem 0.5rem",
        color: props.isCollapsed && !isActive ? `${tinycolor(props.color).setAlpha(0.35)}` : props.color,
        borderRadius: theme.smallBorderRadius,
        textAlign: "center",
        lineHeight: "1",
        justifyContent: props.isCollapsed ? "center" : undefined,
        background: isActive ? tinycolor(props.color).setAlpha(0.1).toString() : "transparent",
        outline: isActive ? `1px solid ${tinycolor(props.color).setAlpha(0.25).toString()}` : "none",
        fontWeight: isActive ? 700 : 400,
        [mq("desktop")]: {
          gap: "1rem",
          fontSize: "1.25rem",
          padding: "0.75rem 1rem",
          flexDirection: "row",
          textAlign: "left",
        },
      }}
      to={props.to}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {props.icon}
      {!props.isCollapsed && (
        <span
          css={{
            fontSize: "0.7rem",
            [mq("desktop")]: {
              fontSize: "1rem",
              fontWeight: "400",
            },
          }}
        >
          {props.children}
        </span>
      )}
    </NavLink>
  );
};
