import { UuidResourceParam, uuidSerializer } from "@legacy-megarax/common";
import { BaseResource, ResourceActionV2 } from "@legacy-megarax/rest-resource";
import { Serializers } from "@legacy-megarax/serializers";

import { newOrderInputSerializer } from "./newOrderInput";

export const orderResource = BaseResource(["bc", "orders"], {
  byUuid: UuidResourceParam("uuid", "uuid", {
    placeNewOrder: ResourceActionV2({
      name: "placeNewOrder",
      method: "post",
      path: "",
      requestBodySerializer: newOrderInputSerializer,
      responseErrorSerializer: Serializers.stringOneOf("OrderAlreadyExists", "UnknownGtin"),
      responseValueSerializer: uuidSerializer,
    }),
  }),
});
