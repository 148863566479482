import { SectionHeader } from "@dash/page";
import { QuerySkeleton } from "@dash/skeleton";
import { TileList } from "@dash/tile";
import { FC } from "react";

import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { ProductGroupSaleTile } from "./ProductGroupSaleTile";

type Props = { customerId: Uuid };

export const CustomerPotentialsList: FC<Props> = (props) => {
  const potentialsQuery = useClientManager("crm").customerPotentialsQuery().useQuery({ customerId: props.customerId });

  return (
    <QuerySkeleton query={potentialsQuery}>
      {(potentials) => (
        <>
          {potentials.length > 0 && <SectionHeader isHr>Potencjał</SectionHeader>}
          <TileList
            elementsList={potentials}
            renderElement={({ groupName, potential, profit }) => (
              <ProductGroupSaleTile
                key={groupName}
                label={groupName}
                currentValue={profit.toNumber()}
                goalValue={potential ? potential.toNumber() : potential}
                customerId={props.customerId}
                queryKey={potentialsQuery.key}
              />
            )}
          />
        </>
      )}
    </QuerySkeleton>
  );
};
