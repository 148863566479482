import { Uuid, uuidSerializer } from "@legacy-megarax/common";
import { MSerializers } from "@legacy-megarax/serializers";
import Decimal from "decimal.js";

import { CustomerStatus, customerStatuses } from "./customerStatus";
import { VisitLocation } from "./inputs";

export type CustomerDetail = {
  uuid: Uuid;
  name: string;
  regionUuid: Uuid;
  chainUuid: Uuid | null;
  visitLocation: CustomerDetailVisitLocation | null;
  createdAt: Date;
  createdBy: number;
  lastModificationAt: Date;
  lastModificationBy: number;
  tags: string[];
  status: CustomerStatus;
};

export interface CustomerDetailVisitLocation {
  name: string;
  locality: string;
  street: string;
  postalCode: string;
  country: string;
  lat: Decimal;
  lng: Decimal;
}

export const visitLocationSerializer = MSerializers.object<VisitLocation>({
  name: MSerializers.string,
  locality: MSerializers.string,
  street: MSerializers.string,
  postalCode: MSerializers.string,
  country: MSerializers.string,
  lat: MSerializers.decimal,
  lng: MSerializers.decimal,
});

export const customerDetailSerializer = MSerializers.object<CustomerDetail>({
  uuid: uuidSerializer,
  name: MSerializers.string,
  regionUuid: uuidSerializer,
  chainUuid: uuidSerializer.nullable(),
  createdAt: MSerializers.datetime,
  createdBy: MSerializers.integer,
  lastModificationAt: MSerializers.datetime,
  lastModificationBy: MSerializers.integer,
  tags: MSerializers.array(MSerializers.string),
  visitLocation: visitLocationSerializer.nullable(),
  status: MSerializers.stringOneOf(...customerStatuses),
});
