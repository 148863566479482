import { useDialogRoute } from "@dash/dialog";
import { Button } from "@dash/form";
import { SelectDialog } from "@dash/select";
import { Tile } from "@dash/tile";
import { useTheme } from "@emotion/react";
import { MdApartment, MdAttachMoney, MdOutlineAdd, MdOutlineEdit, MdOutlinePerson } from "react-icons/md";
import { Link } from "react-router-dom-v5-compat";

import { CustomerDoc } from "@megaron/crm-contracts";
import { useServiceClient } from "@megaron/react-clients";

type CustomerType = "sellTo" | "billTo" | "payerDepartment";

export type SelectedCustomer = CustomerDoc & { label: string; value: string };

type Props = {
  customerUuid: string | undefined;
  customerName: string | undefined;
  customerType: CustomerType;
  onSelect: (customer: SelectedCustomer) => void;
};

export const CustomerTile: React.FC<Props> = ({ customerUuid, customerType, customerName, onSelect }) => {
  const theme = useTheme();
  const docs = useServiceClient("docs");

  const customerData: Record<CustomerType, { Icon: React.ComponentType; label: string }> = {
    sellTo: { Icon: MdOutlinePerson, label: "Nabywca" },
    billTo: { Icon: MdAttachMoney, label: "Odbiorca faktury" },
    payerDepartment: { Icon: MdApartment, label: "Oddział płatnika" },
  };

  const dialog = useDialogRoute(`/${customerType}`, ({ onClose }) => {
    return (
      <SelectDialog
        label={customerData[customerType].label}
        search={async (searchText) => {
          const results = await docs.searchCustomers({
            categories: customerType === "billTo" ? ["payer"] : ["pos"],
            limit: 1000,
            searchText: searchText,
          });

          return results
            ? results.value?.items.map((result) => ({ ...result, label: result.name, value: result.uuid })) ?? []
            : [];
        }}
        initiallySelectedValues={customerUuid ? [customerUuid] : []}
        variant="single-select"
        onSelect={(option) => {
          if (!option) {
            return;
          }

          onSelect(option);
        }}
        isOpen
        isUnselectDisabled
        onClose={onClose}
        noTrigger
      />
    );
  });

  const Icon = customerData[customerType].Icon;

  const TileContent = (
    <Tile
      css={{
        "&:hover": {
          cursor: customerUuid ? "pointer" : "default",
        },
      }}
      stripe={
        <div
          css={{
            color: customerUuid ? "white" : theme.colors.primary,
            background: customerUuid ? theme.colors.primary : "transparent",
            padding: "0.5rem 0.875rem",
            minHeight: 0,
            alignSelf: "stretch",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRight: `1px solid ${customerUuid ? theme.colors.primary : "rgba(0, 0, 0, 0.1)"}`,
          }}
        >
          <Icon />
        </div>
      }
    >
      <div css={{ display: "flex", alignItems: "center", gap: "0.5rem", justifyContent: "space-between" }}>
        <div css={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <span css={{ fontSize: "0.75rem", color: theme.colors.secondaryText }}>
            {customerData[customerType].label}
          </span>
          <span
            css={{
              fontSize: "0.875rem",
              color: customerUuid ? theme.colors.primary : theme.colors.secondaryText,
              fontWeight: customerUuid ? 700 : 400,
            }}
          >
            {customerName || "Brak"}
          </span>
        </div>
        <div css={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              dialog.open();
            }}
            icon={customerUuid ? <MdOutlineEdit size={16} /> : <MdOutlineAdd size={16} />}
            variant="outline"
            css={{ border: "none", padding: "0.5rem" }}
          />
        </div>
      </div>
    </Tile>
  );

  return (
    <>
      {customerUuid ? (
        <Link
          to={`/crm/customers/id/${customerUuid}`}
          css={{
            "&:hover": {
              textDecoration: "none",
            },
          }}
        >
          {TileContent}
        </Link>
      ) : (
        TileContent
      )}
      {dialog.element}
    </>
  );
};
