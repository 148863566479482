import { Uuid } from "@legacy-megarax/common";
import { Check, LocationOn as LocationIcon, QuestionMark } from "@mui/icons-material";
import { Button, Card, CardActions, CardHeader, Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";

export type ActivityListVisit = {
  type: "visit";

  uuid: Uuid;
  customerUuid: Uuid | null;
  customerName: string | null | undefined;
  startTime: Date | null;
  endTime: Date | null;

  scheduledStartTime: Date | null;
  scheduledDurationMinutes: number | null;
};

interface Props {
  entry: ActivityListVisit;
  onSeeMore: (uuid: Uuid) => void;
  className?: string;
}

export const VisitCard: React.FunctionComponent<Props> = ({ className, entry, onSeeMore }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  // const onVisitClick = () =>
  //   navigate(`/crm/customers/id/${entry.customerUuid}/add-interaction?interactionUuid=${entry.uuid}&tab=historia`);

  const subheader = getSubheader(entry);

  const hasEnded = entry.endTime !== null;
  const isUnplanned = entry.scheduledStartTime === null;

  const avatarIcon = hasEnded ? <Check /> : isUnplanned ? <QuestionMark /> : <LocationIcon />;

  return (
    <Card className={clsx(className, classes.paper)}>
      <CardHeader
        avatar={avatarIcon}
        title={entry.customerName === undefined ? <Skeleton width={200} variant="text" /> : entry.customerName}
        subheader={subheader}
      ></CardHeader>
      <CardActions>
        <Button size="small" onClick={() => onSeeMore(entry.uuid)}>
          Więcej
        </Button>
      </CardActions>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(0, 0, 2),
    background: theme.palette.mode,
  },
  content: {
    padding: theme.spacing(1.5),
  },
}));

const getSubheader = ({ startTime, scheduledDurationMinutes, scheduledStartTime, endTime }: ActivityListVisit) => {
  if (startTime !== null) {
    if (endTime !== null)
      return `Zakończona o ${endTime.toLocaleTimeString(undefined, {
        hour: "numeric",
        minute: "2-digit",
      })}`;

    return `Rozpoczęta o ${startTime.toLocaleTimeString(undefined, {
      hour: "numeric",
      minute: "2-digit",
    })}`;
  }

  if (scheduledStartTime !== null && scheduledDurationMinutes !== null)
    return `Zaplanowana na ${scheduledStartTime.toLocaleTimeString(undefined, {
      hour: "numeric",
      minute: "2-digit",
    })} (${scheduledDurationMinutes} min)`;

  return "Nieplanowana wizyta";
};
