import { useConfig } from "@megarax/dev-config";
import { MegaraxWebappContainer } from "@megarax/features";
import { HomePage } from "@megarax/home";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom-v5-compat";

import { LinkDoc } from "@megaron/docs-contracts";
import { useClientManager } from "@megaron/react-clients";

import logo from "./megaronLogo.png";
import { useCrmRouter } from "./useCrmRouter";
import { useDevRouter } from "./useDevRouter";
import { useIamRouter } from "./useIamRouter";
import { useInvoicesRouter } from "./useInvoicesRouter";
import { useLoyaltyRouter } from "./useLoyaltyRouter";

type Props = {
  isAuthLoaded: boolean;
};

export const AppRouter: React.FC<Props> = ({ isAuthLoaded }) => {
  const linkQuery = useClientManager("docs")
    .search()
    .useQuery({ docType: ["link"], limit: 10000 }, { enabled: isAuthLoaded });

  const linkDocs = linkQuery.data?.items as LinkDoc[] | undefined;

  const location = useLocation();
  const pathname = location.pathname;

  const LoyaltyRouter = useLoyaltyRouter({ linkDocs });
  const CrmRouter = useCrmRouter({ linkDocs });
  const IamRouter = useIamRouter({ linkDocs });
  const InvoicesRouter = useInvoicesRouter({ linkDocs });
  const DevRouter = useDevRouter({ linkDocs });

  if (pathname.endsWith("/") && pathname !== "/") {
    return <Navigate to={pathname.slice(0, -1)} />;
  }

  if (pathname.endsWith("/crm")) {
    return <Navigate to={"/crm/user-home"} replace />;
  }

  if (pathname.endsWith("/iam")) {
    return <Navigate to={"/iam/accounts"} replace />;
  }

  if (pathname.endsWith("/dev")) {
    return <Navigate to={"/dev/environment"} replace />;
  }

  if (pathname.endsWith("/specjalista")) {
    return <Navigate to={"/specjalista/qr-sync"} replace />;
  }

  return (
    <Routes>
      <Route path="/" element={<DefaultMegaraxWebappContainer />}>
        <Route path="" element={<HomePage linkDocs={linkDocs} />} />
      </Route>

      {LoyaltyRouter}

      {CrmRouter}

      {IamRouter}

      {InvoicesRouter}

      {DevRouter}
    </Routes>
  );
};

const DefaultMegaraxWebappContainer = () => {
  const { config } = useConfig();

  return (
    <MegaraxWebappContainer
      navEntries={[]}
      bypassAuth={config.testUser.enabled}
      title="Megarax.net"
      logo={<img src={logo} alt="Megarax.net" css={{ width: "2rem", height: "2rem" }} />}
    >
      <Outlet />
    </MegaraxWebappContainer>
  );
};
