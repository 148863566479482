import { ResourceNodes, ResourceParamNode } from "@legacy-megarax/rest-resource";
import { Serializer, serializerExtensions, SerializerExtensions, ValidationError } from "@legacy-megarax/serializers";
import { v4 } from "uuid";

import { Failure, Ok } from "@megaron/result";

export type Uuid = string & { __brand: "uuid" };

export const validateUuid = (str: string) => {
  if (str.match(uuidPattern) === null) return Failure("InvalidUuid");
  return Ok<Uuid>(str as Uuid);
};

export const uuidSerializer: Serializer<Uuid> & SerializerExtensions<Uuid> = {
  serialize: (uuid) => uuid,
  forceDeserialize: (raw: unknown) => {
    if (typeof raw !== "string") throw new ValidationError("Uuid needs to be a string");
    const result = validateUuid(raw.toLowerCase());
    if (result.isFailure) throw new ValidationError("Invalid uuid");
    return result.value;
  },
  ...serializerExtensions(),
};

export const newUuid = () => v4() as Uuid;

export const UuidResourceParam = <TNestedActions extends ResourceNodes>(
  paramName: string,
  path: string,
  nested: TNestedActions,
): ResourceParamNode<Uuid, TNestedActions> => ({
  children: nested,
  paramName,
  paramPattern: "\\b[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}\\b",
  paramSerializer: uuidSerializer,
  path,
});

const uuidPattern = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;

export const validateUuidFormat = (uuid: string) => uuidPattern.test(uuid);
