import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";

export const shippingOrderActions = {
  fastestDeliveryDate: HttpAction({
    path: "/shipping/orders/fastestDeliveryDate",
    method: "get",
    valueSerializer: Serializers.dateOnly,
  }),
};
