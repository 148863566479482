import { useDeviceType } from "@dash/mq";
import { SectionHeader } from "@dash/page";
import React from "react";

type ThreadPageLayoutProps = {
  threadHistory: React.ReactNode;
  signatureSection: React.ReactNode;
  approvalTile?: React.ReactNode;
  similarThreadAlert: React.ReactNode;
  fileView: React.ReactNode;
  selectedAttachment: boolean;
  participants: React.ReactNode;
  approvalSelect: React.ReactNode;
  invoiceCard: React.ReactNode;
  threadReceived: React.ReactNode;
  addedToBcCheckbox: React.ReactNode;
  isReadSwitch: React.ReactNode;
  decreeNumber: React.ReactNode;
  attachments: React.ReactNode;
  issuerThreads: React.ReactNode;
};

export const ThreadPageLayout: React.FC<ThreadPageLayoutProps> = ({
  threadHistory,
  signatureSection,
  approvalTile,
  similarThreadAlert,
  fileView,
  selectedAttachment,
  participants,
  approvalSelect,
  invoiceCard,
  threadReceived,
  addedToBcCheckbox,
  isReadSwitch,
  decreeNumber,
  attachments,
  issuerThreads,
}) => {
  const { isDesktop } = useDeviceType();

  return (
    <div
      css={{
        display: "flex",
        flexDirection: isDesktop ? "row" : "column",
        gap: "2rem",
      }}
    >
      {!isDesktop ? (
        <>
          <div css={{ display: "flex", flexDirection: "column", flex: "1", minWidth: "19rem", gap: "1rem" }}>
            <div css={{ display: "flex", gap: "0.625rem", alignItems: "center" }}>
              {participants}
              <div css={{ display: "flex", marginLeft: "auto", gap: "0.625rem" }}>
                {approvalSelect}
                {isReadSwitch}
              </div>
            </div>
            {invoiceCard}
            {threadReceived}
            {addedToBcCheckbox}
            {decreeNumber}

            {attachments}
            {isDesktop && { issuerThreads }}
          </div>
          {selectedAttachment ? (
            fileView
          ) : (
            <div css={{ display: "flex", flexDirection: "column", flex: "1 1 auto", gap: "32px" }}>
              {similarThreadAlert}
              {approvalTile}
              {signatureSection}
              <SectionHeader isHr>Historia wątku</SectionHeader>
              {threadHistory}
            </div>
          )}
        </>
      ) : (
        <>
          {selectedAttachment ? (
            <div css={{ display: "flex", flexDirection: "column", flex: "1 1 auto", gap: "32px" }}>
              {invoiceCard}
              {fileView}
            </div>
          ) : (
            <div css={{ display: "flex", flexDirection: "column", flex: "1 1 auto", gap: "32px" }}>
              {invoiceCard}
              {similarThreadAlert}
              {approvalTile}
              {signatureSection}
              <SectionHeader isHr>Historia wątku</SectionHeader>
              {threadHistory}
            </div>
          )}
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              flex: "1",
              minWidth: "19rem",
              gap: "1rem",
              marginTop: "1rem",
            }}
          >
            {isReadSwitch}
            {approvalSelect}
            {threadReceived}
            {addedToBcCheckbox}
            {participants}
            {decreeNumber}
            <SectionHeader isHr css={{ h2: { fontSize: "16px" } }}>
              Załączniki
            </SectionHeader>
            {attachments}
            {issuerThreads}
          </div>
        </>
      )}
    </div>
  );
};
