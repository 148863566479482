import { useToast } from "@dash/toast";
import { MdAlternateEmail, MdCheck } from "react-icons/md";
import { useQueryClient } from "react-query";

import { ThreadDto } from "@megaron/invoices-contracts";
import { useClientManager } from "@megaron/react-clients";

import { Checkbox } from "./Checkbox";

type Props = {
  thread: ThreadDto;
  queryKey: string | string[];
};

export const ThreadReceivedCheckbox: React.FC<Props> = ({ thread, queryKey }) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const receivedMutation = useClientManager("invoices").setReceptionStatus().useMutation();

  const isDigital = thread.invoice.isDigital;
  const isThreadReceived = thread.isReceived;

  const handleReceivedChange = (isReceived: boolean) => {
    receivedMutation.mutate(
      { threadUuid: thread.uuid, status: isReceived },
      {
        onSuccess: () => {
          toast.info("Status odbioru zmieniony pomyślnie");
          queryClient.invalidateQueries(queryKey);
        },
        onError: () => {
          toast.error("Nie udało się zmienić statusu odbioru");
        },
      },
    );
  };

  return (
    <Checkbox
      icon={
        isDigital ? (
          <MdAlternateEmail size={12} css={{ color: "white" }} />
        ) : (
          isThreadReceived && <MdCheck size={12} css={{ color: "white" }} />
        )
      }
      isChecked={isThreadReceived}
      label={
        isDigital
          ? "Faktura elektroniczna"
          : isThreadReceived
          ? "Oryginał faktury dotarł do firmy"
          : "Oryginał faktury nie dotarł do firmy"
      }
      isDisabled={isDigital}
      onCheck={(newIsChecked) => {
        if (!isDigital) handleReceivedChange(newIsChecked);
      }}
      showSuccessColor={!isDigital}
    />
  );
};
