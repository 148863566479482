import { ChangeRangeButton, MonthSelectDialog } from "@dash/filters";
import { Button } from "@dash/form";
import { useTheme } from "@emotion/react";
import { getMonth, getYear } from "date-fns";
import { useState } from "react";

import { Month } from "@megaron/serializers";

type Props = {
  startDate: Month;
  endDate: Month;
  onDatesChange: (startDate: Month, endDate: Month) => void;
};

export const TimeRangeSelect: React.FC<Props> = ({ startDate, endDate, onDatesChange }) => {
  const theme = useTheme();

  const startMonth = Number(startDate.split("-")[1]);
  const startYear = Number(startDate.split("-")[0]);
  const endMonth = Number(endDate.split("-")[1]);
  const endYear = Number(endDate.split("-")[0]);

  const [isDialogFromOpen, setIsDialogFromOpen] = useState(false);
  const [isDialogToOpen, setIsDialogToOpen] = useState(false);

  const isButtonForwardDisabled = endMonth === getMonth(new Date()) + 1 && endYear === getYear(new Date());

  const handleButtonBackClick = () => {
    if (startMonth === 1) {
      if (endMonth === 1) {
        onDatesChange(`${startYear - 1}-12` as Month, `${endYear - 1}-12` as Month);
        return;
      }

      onDatesChange(`${startYear - 1}-12` as Month, `${endYear}-${getMonthString(endMonth - 1)}` as Month);
      return;
    }

    if (endMonth === 1) {
      onDatesChange(`${startYear}-${getMonthString(startMonth - 1)}` as Month, `${endYear - 1}-12` as Month);
      return;
    }

    onDatesChange(
      `${startYear}-${getMonthString(startMonth - 1)}` as Month,
      `${endYear}-${getMonthString(endMonth - 1)}` as Month,
    );
  };

  const handleButtonForwardClick = () => {
    if (isButtonForwardDisabled) {
      return;
    }

    if (endMonth === 12) {
      if (startMonth === 12) {
        onDatesChange(`${startYear + 1}-01` as Month, `${endYear + 1}-01` as Month);
        return;
      }

      onDatesChange(`${startYear}-${getMonthString(startMonth + 1)}` as Month, `${endYear + 1}-01` as Month);
      return;
    }

    if (startMonth === 12) {
      onDatesChange(`${startYear + 1}-01` as Month, `${endYear}-${getMonthString(endMonth + 1)}` as Month);
      return;
    }

    onDatesChange(
      `${startYear}-${getMonthString(startMonth + 1)}` as Month,
      `${endYear}-${getMonthString(endMonth + 1)}` as Month,
    );
  };

  const activePreset = endMonth - startMonth + 1;

  const handlePreset = (months: 1 | 2) => {
    const totalMonths = endYear * 12 + endMonth;
    const startTotalMonths = totalMonths - (months - 1);
    const startYearAdjusted = Math.floor((startTotalMonths - 1) / 12);
    const startAdjustedMonth = ((startTotalMonths - 1) % 12) + 1;

    const newStart = `${startYearAdjusted}-${getMonthString(startAdjustedMonth)}` as Month;
    const newEnd = `${endYear}-${getMonthString(endMonth)}` as Month;

    onDatesChange(newStart, newEnd);
  };

  return (
    <div
      css={{
        display: "flex",
        gap: "0.2rem",
        flexDirection: "column",
      }}
    >
      <div
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "0.5rem",
          borderRadius: theme.smallBorderRadius,
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
          padding: "0.5rem 0.75rem",
          background: "white",
          flexWrap: "wrap",
        }}
      >
        <ChangeRangeButton onClick={handleButtonBackClick} />
        <div css={{ display: "flex", alignItems: "center", gap: "0.275rem" }}>
          <ChangeDateButton
            onClick={() => setIsDialogFromOpen(true)}
            label="Od"
            value={getDateString(startMonth, startYear)}
          />
          <hr css={{ width: "1.25rem", height: "1px", background: theme.colors.primary, opacity: 0.3 }} />
          <ChangeDateButton
            onClick={() => setIsDialogToOpen(true)}
            label="Do"
            value={getDateString(endMonth, endYear)}
          />
        </div>
        <ChangeRangeButton onClick={handleButtonForwardClick} isForward isDisabled={isButtonForwardDisabled} />
      </div>
      <div
        css={{
          display: "flex",
          gap: "0.5rem",
          marginTop: "16px",
        }}
      >
        <Button
          variant="outline"
          size="small"
          css={{
            fontWeight: activePreset === 1 ? 600 : 400,
            borderWidth: activePreset === 1 ? 1.5 : 1,
          }}
          onClick={() => handlePreset(1)}
        >
          1 miesiąc
        </Button>
        <Button
          variant="outline"
          size="small"
          css={{
            fontWeight: activePreset === 2 ? 600 : 400,
            borderWidth: activePreset === 2 ? 1.5 : 1,
          }}
          onClick={() => handlePreset(2)}
        >
          2 miesiące
        </Button>
      </div>
      {isDialogFromOpen && (
        <MonthSelectDialog
          header="Od"
          onClose={() => setIsDialogFromOpen(false)}
          year={startYear}
          month={startMonth}
          onDateChange={(year, month) => {
            if (year > endYear || (year === endYear && month > endMonth)) {
              onDatesChange(`${year}-${getMonthString(month)}` as Month, `${year}-${getMonthString(month)}` as Month);
              return;
            }

            onDatesChange(`${year}-${getMonthString(month)}` as Month, endDate);
          }}
          checkIsDisabled={(year, month) => month > getMonth(new Date()) + 1 && year >= getYear(new Date())}
        />
      )}
      {isDialogToOpen && (
        <MonthSelectDialog
          header="Do"
          onClose={() => setIsDialogToOpen(false)}
          year={endYear}
          month={endMonth}
          onDateChange={(year, month) => {
            if (year < startYear || (year === startYear && month < startMonth)) {
              onDatesChange(`${year}-${getMonthString(month)}` as Month, `${year}-${getMonthString(month)}` as Month);
              return;
            }

            onDatesChange(startDate, `${year}-${getMonthString(month)}` as Month);
          }}
          checkIsDisabled={(year, month) => month > getMonth(new Date()) + 1 && year >= getYear(new Date())}
        />
      )}
    </div>
  );
};

const ChangeDateButton: React.FC<{ onClick: () => void; label: string; value: string }> = ({
  onClick,
  label,
  value,
}) => {
  const theme = useTheme();

  return (
    <button
      css={{
        display: "flex",
        flexDirection: "column",
        padding: "0 0.25rem",
        background: "none",
        border: "none",
        alignItems: "center",
        color: theme.colors.primary,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <span css={{ fontSize: "0.75rem" }}>{label}</span>
      <span css={{ fontSize: "1rem", fontWeight: 700 }}>{value}</span>
    </button>
  );
};

const getMonthString = (month: number) => {
  return Number(month).toString().padStart(2, "0");
};

const getDateString = (month: number, year: number) => {
  const monthString = getMonthString(month);
  return `${monthString}.${year}`;
};
