import { useTheme } from "@emotion/react";
import React, { useState } from "react";

type DateSelectProps = {
  value: Date | null;
  onChange: (value: Date) => void;
  label?: string;
  className?: string;
  isDisabled?: boolean;
  minDate?: Date;
};

export const DateField: React.FunctionComponent<DateSelectProps> = ({
  value,
  onChange,
  label,
  className,
  isDisabled,
  minDate,
}) => {
  const theme = useTheme();

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const [inputValue, setInputValue] = useState<string>(value ? formatDate(value) : "");

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dateValue = e.target.value;

    if (/^\d{0,4}-\d{0,2}-\d{0,2}$/.test(dateValue)) {
      setInputValue(dateValue);

      if (dateValue.length === 10) {
        const [year, month, day] = dateValue.split("-").map(Number);
        const selectedDate = new Date(year, month - 1, day);
        if (!isNaN(selectedDate.getTime())) {
          onChange(selectedDate);
        }
      }
    }
  };

  return (
    <div
      css={{ display: "flex", flexDirection: "column", opacity: isDisabled ? 0.5 : 1 }}
      className={className}
    >
      {label && (
        <label
          css={{
            color: theme.colors.primary,
            fontSize: "0.875rem",
            fontFamily: theme.displayFontFamily,
            marginBottom: "0.25rem",
          }}
        >
          {label}
        </label>
      )}
      <input
        value={inputValue}
        onChange={handleDateChange}
        css={{
          width: "100%",
          border: "none",
          padding: "0.5rem 0.75rem",
          fontSize: "1rem",
          borderRadius: "6px",
          backgroundColor: theme.colors.primaryLight,
          ":focus": {
            outline: `1px solid ${theme.colors.primary}`,
            outlineOffset: "-1px",
          },
        }}
        type="date"
        disabled={isDisabled}
        min={minDate ? formatDate(minDate) : undefined}
      />
    </div>
  );
};
