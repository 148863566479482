import { Button, TextField } from "@dash/form";
import { SelectDialog } from "@dash/select";
import { useProfiles } from "@megarax/features";
import { useState } from "react";

import { Region } from "@megaron/crm-contracts";
import { useClientManager } from "@megaron/react-clients";

type Props = {
  region?: Region;
  onSuccess: () => void;
};

export const AddRegion: React.FC<Props> = (props) => {
  const { allProfiles } = useProfiles();
  const saveRegion = useClientManager("crm").saveRegion().useMutation();
  const [name, setName] = useState<string>(props.region?.name ?? "");
  const [ownerEmail, setOwnerEmail] = useState<string | null>(props.region?.ownerEmail || null);

  const addCustomerMutation = async () => {
    if (!name) {
      alert("Please provide a name and owner email.");
      return;
    }

    saveRegion.mutate(
      {
        name,
        ownerEmail,
      },
      {
        onSuccess: (r) => {
          props.onSuccess();
        },
      },
    );
  };

  const options = allProfiles?.map((profile) => ({
    label: profile.firstName ? `${profile.firstName} ${profile.lastName}` : `${profile.email}`,
    value: profile.email,
  }));

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <TextField
        css={{ input: { height: "46px" } }}
        label={"Region"}
        value={name}
        onChange={(value) => setName(value)}
        autoFocus
      />

      <SelectDialog
        label={"Handlowiec"}
        options={options ?? []}
        onSelect={(option) => setOwnerEmail(option?.value || null)}
        variant="single-select"
        initiallySelectedValues={ownerEmail ? [ownerEmail] : []}
      />
      <Button css={{ alignSelf: "flex-end", margin: "4px 0" }} onClick={addCustomerMutation} isLoading={saveRegion.isLoading}>
        Zapisz
      </Button>
    </div>
  );
};
