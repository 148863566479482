import { CreatePriceListInput } from "@legacy-megarax/crm-contracts";
import {
  ExtractValidatorError,
  objectValidator,
  ObjectValidatorSchema,
  requiredValidator,
} from "@legacy-megarax/validators";

export type PriceListInput = Omit<CreatePriceListInput, "uuid">;

export const INITIAL_VALUE: PriceListInput = {
  currency: "PLN",
  name: "",
};

export const validatorSchema = ObjectValidatorSchema<PriceListInput, PriceListInput>()({
  name: requiredValidator(),
  currency: requiredValidator(),
});

const errorMessages = {
  required: "To pole jest wymagane.",
  outOfRange: "Ilość musi być wyższa od zera.",
};

export const getErrorMessage = (failure: "required" | "outOfRange" | undefined) =>
  failure ? errorMessages[failure] : undefined;

export const formValidator = objectValidator(validatorSchema);
export type FormErrors = ExtractValidatorError<typeof formValidator>;
