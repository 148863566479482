import { priceListResource } from "@legacy-megarax/crm-contracts";
import { useResourceProviderV2 } from "@legacy-megarax/react-client";
import { commonErrorsMap, useSnackbarErrorHandler } from "@legacy-megarax/ui-components";
import React from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { v4 } from "uuid";

import { getPriceListDetailsUrl } from "../../routes";
import { NewPriceListDialog } from "./NewPriceListDialog";
import { PriceListInput } from "./newPriceListForm";

export const NewPriceListContainer = () => {
  const navigate = useNavigate();

  const onDialogClose = () => navigate(-1);
  const navigateToDetails = (uuid: string) => navigate(getPriceListDetailsUrl(uuid));

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
  });
  const priceListProvider = useResourceProviderV2(priceListResource);

  const createPriceList = async (input: PriceListInput) => {
    const uuid = v4();
    await priceListProvider
      .create(undefined, { ...input, uuid })
      .mapError(handleError)
      .map(() => navigateToDetails(uuid));
  };

  return <NewPriceListDialog createPriceList={createPriceList} closeDialog={onDialogClose} />;
};
