import { Avatar } from "@dash/avatar";
import { AnyDocSelectOption, DocsSelectDialog } from "@dash/select";
import { Tile } from "@dash/tile";
import { useToast } from "@dash/toast";
import { useTheme } from "@emotion/react";
import { useProfiles } from "@megarax/features";
import { formatDistanceToNow } from "date-fns";
import { pl } from "date-fns/locale";
import { useContext, useEffect, useState } from "react";
import { MdOutlineBookmarkAdd, MdOutlineBookmarkAdded } from "react-icons/md";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom-v5-compat";

import { IamAuthContext } from "@megaron/auth-react";
import { UserDoc } from "@megaron/docs-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { getViewIcon } from "./ViewsBar";

type Props = {
  onClose: () => void;
  queryKey: string | string[];
};

export const AllViewsDialog: React.FC<Props> = ({ onClose, queryKey }) => {
  const auth = useContext(IamAuthContext);

  const toast = useToast();

  const queryClient = useQueryClient();

  const saveBookmarkMutation = useClientManager("docs").saveBookmark().useMutation();

  const handleBookmark = async (isBookmarked: boolean, viewId: Uuid, onError: () => void) => {
    try {
      await saveBookmarkMutation.mutateAsync({ isBookmarked, savedViewId: viewId });

      queryClient.invalidateQueries(queryKey);
    } catch {
      toast.error("Nie udało się zapisać widoku");
      onError();
    }
  };

  const { allProfiles } = useProfiles();

  const userEmail = auth.iamUser?.userType === "megarax" ? auth.iamUser.email : undefined;

  return (
    <DocsSelectDialog
      onClose={onClose}
      variant="single-select"
      docType={["saved-view"]}
      isOpen
      noTrigger
      label="Wybierz widok"
      renderItem={(view) => (
        <SavedViewTile
          view={view}
          allProfiles={allProfiles ?? []}
          userEmail={userEmail ?? undefined}
          onBookmark={handleBookmark}
        />
      )}
      shouldNotCloseOnSelect
    />
  );
};

const SavedViewTile: React.FC<{
  view: AnyDocSelectOption;
  allProfiles: UserDoc[];
  userEmail?: string;
  onBookmark: (isBookmarked: boolean, viewId: Uuid, onError: () => void) => void;
}> = ({ view, allProfiles, userEmail, onBookmark }) => {
  const theme = useTheme();

  const [isBookmarked, setIsBookmarked] = useState(
    view.docType === "saved-view" ? !!(userEmail && view.bookmarkedBy.includes(userEmail)) : false,
  );

  useEffect(() => {
    if (userEmail) {
      setIsBookmarked(view.docType === "saved-view" ? !!(userEmail && view.bookmarkedBy.includes(userEmail)) : false);
    }
  }, [userEmail, view.docType === "saved-view" && view.bookmarkedBy, view.docType]);

  if (view.docType !== "saved-view") {
    return null;
  }

  const Icon = getViewIcon(view.viewType);

  const avatar = view.owner
    ? allProfiles.find((profile) => profile.email === view.owner)?.profilePictureUrl
    : undefined;

  return (
    <div css={{ width: "100%", position: "relative" }}>
      <Link
        to={`/crm/customers/${view.id}`}
        css={{
          width: "100%",
          ":hover": {
            textDecoration: "none",
          },
        }}
      >
        <Tile
          key={view.docId}
          css={{
            cursor: "pointer",
            width: "100%",
            border: "none",
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              padding: "0.25rem 0",
              alignItems: "flex-start",
              textDecoration: "none",
              textAlign: "left",
            }}
          >
            <div
              css={{
                display: "flex",
                alignItems: "center",
                gap: "0.75rem",
                fontSize: "0.875rem",
                textDecoration: "none",
                width: "calc(100% - 3rem)",
                color: theme.colors.primary,
                textAlign: "left",
              }}
            >
              <Icon size={14} css={{ flexShrink: 0 }} />
              {view.name}
            </div>
            <p css={{ margin: 0, color: theme.colors.secondaryText, fontSize: "0.75rem" }}>{view.description}</p>
            <div css={{ display: "flex", alignItems: "center", gap: "0.75rem", alignSelf: "flex-end" }}>
              <span css={{ color: theme.colors.secondaryText, fontSize: "0.75rem" }}>
                {formatDistanceToNow(view.createdAt, { addSuffix: true, locale: pl })}
              </span>
              <Avatar src={avatar} size="18px" />
            </div>
          </div>
        </Tile>
      </Link>
      <button
        css={{
          padding: 0,
          border: "none",
          background: "none",
          cursor: "pointer",
          position: "absolute",
          top: "1rem",
          right: "1rem",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setIsBookmarked(!isBookmarked);
          onBookmark(!isBookmarked, view.id, () => setIsBookmarked((prev) => !prev));
        }}
      >
        {isBookmarked ? (
          <MdOutlineBookmarkAdded size={18} />
        ) : (
          <MdOutlineBookmarkAdd
            size={18}
            css={{ opacity: 0.3, transition: "opacity 0.15s", ":hover": { opacity: 1 } }}
          />
        )}
      </button>
    </div>
  );
};
