import { useDialogRoute } from "@dash/dialog";
import { Button } from "@dash/form";
import { SectionHeader } from "@dash/page";
import { RowCellContent, Table, TableBody, TableHeadColumn } from "@dash/table";
import { useTheme } from "@emotion/react";
import { formatCurrency } from "@legacy-megarax/react-utils";
import Decimal from "decimal.js";
import { useState } from "react";
import { MdAdd } from "react-icons/md";

import { Currency, OrderDraftLineDto } from "@megaron/crm-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { AddItemDialog } from "./AddItemDialog";
import { EditItemDialog } from "./EditItemDialog";

type Props = {
  lines: OrderDraftLineDto[];
  isDisabled?: boolean;
  orderUuid?: Uuid;
  queryKey?: string | string[];
  currency: Currency;
  priceListUuid?: string;
  baseDiscount?: Decimal;
};

export const OrderItemsSection: React.FC<Props> = ({
  lines,
  isDisabled,
  orderUuid,
  queryKey,
  currency,
  priceListUuid,
  baseDiscount,
}) => {
  const theme = useTheme();

  const [editedLine, setEditedLine] = useState<OrderDraftLineDto | null>(null);

  const pricedItemsQuery = useClientManager("legacyMegarax")
    .getPricedItems()
    .useQuery(
      {
        limit: 1000,
        discountRate: baseDiscount || new Decimal(0),
        postSaleDiscountRate: new Decimal(0),
        priceListUuid: priceListUuid ? Uuid(priceListUuid) : ("" as Uuid),
        searchText: "",
      },
      { enabled: !!(priceListUuid && baseDiscount) },
    );

  const addItemDialog = useDialogRoute("/add-item", ({ onClose }) => (
    <AddItemDialog
      onClose={onClose}
      orderUuid={orderUuid}
      queryKey={queryKey}
      currency={currency}
      pricedItems={pricedItemsQuery.data?.items}
      baseDiscount={baseDiscount}
    />
  ));

  const editItemDialog = useDialogRoute("/edit-item", ({ onClose }) => {
    if (!editedLine) return null;

    return (
      <EditItemDialog
        onClose={onClose}
        line={editedLine}
        orderUuid={orderUuid}
        queryKey={queryKey}
        currency={currency}
        pricedItems={pricedItemsQuery.data?.items}
        baseDiscount={baseDiscount}
      />
    );
  });

  const tableColumns: TableHeadColumn<string>[] = [
    ...tableHeadings.map(
      (heading) =>
        ({
          isSortable: false,
          label: heading,
        } as const),
    ),
  ];

  const getTableRowCellsContent = (line: OrderDraftLineDto): RowCellContent[] => {
    return [
      {
        cellCss: { textWrap: "nowrap" },
        element: (
          <div css={{ display: "flex", alignItems: "center", gap: "0.75rem" }}>
            {line.item.name}
            {line.promoPrice && Number(line.promoPrice) === 0 && <PromoPriceBadge text="RW" />}
            {line.promoPrice && Number(line.promoPrice) === 1 && <PromoPriceBadge text="1zł" />}
          </div>
        ),
        isLink: true,
      },
      {
        element: line.quantity.toFixed(0),
        cellCss: { cursor: "pointer" },
        isLink: true,
      },
      {
        element: formatCurrency(line.lineNetTotal, "PLN"),
        cellCss: { cursor: "pointer" },
        isLink: true,
      },
      {
        element: Number(line.lineDiscountRate) === 0 ? "-" : Number(line.lineDiscountRate.mul(100).toFixed(2)) + "%",
        cellCss: { cursor: "pointer" },
        isLink: true,
      },
    ];
  };

  const handleLineClick = (line: OrderDraftLineDto) => {
    setEditedLine(line);
    editItemDialog.open();
  };

  const fullPrice = lines.reduce((acc, line) => acc.add(line.lineNetTotal), new Decimal(0));

  const summaryRow = {
    uuid: "lines-total",
    cellsContent: [
      {
        element: <span css={{ fontSize: "1rem", fontWeight: 700, color: theme.colors.primary }}>Razem:</span>,
      },
      { element: "" },
      {
        element: (
          <span css={{ fontSize: "1rem", fontWeight: 700, color: theme.colors.primary }}>
            {formatCurrency(fullPrice, "PLN")}
          </span>
        ),
      },
      { element: "" },
    ],
    css: {
      "td:first-child": {
        padding: "0.75rem",
        borderLeftStyle: "none",
      },
      td: {
        background: "none",
        borderTopStyle: "none",
      },
      "td:last-child": {
        borderRightStyle: "none",
      },
      "&:last-child td": {
        borderBottomStyle: "none",
      },
    },
  };

  const rows =
    lines.length > 0
      ? [
          ...lines.map((line) => ({
            uuid: line.item.gtin,
            cellsContent: getTableRowCellsContent(line),
            onClick: () => handleLineClick(line),
            css: {
              "td:first-child": {
                padding: "0.75rem",
                cursor: "pointer",
              },
            },
          })),
          summaryRow,
        ]
      : [
          {
            uuid: "empty-line",
            cellsContent: [
              {
                element: "Brak",
              },
              { element: "" },
              {
                element: "",
              },
              { element: "" },
            ],
            css: {
              "td:first-child": {
                padding: "0.75rem",
              },
            },
          },
          summaryRow,
        ];

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%", opacity: isDisabled ? 0.5 : 1 }}>
      <SectionHeader
        isHr
        actions={
          <Button variant="outline" size="small" icon={<MdAdd />} isDisabled={isDisabled} onClick={addItemDialog.open}>
            Dodaj
          </Button>
        }
      >
        Przedmioty
      </SectionHeader>

      <div css={{ overflow: "auto", margin: "0 -2rem 1rem", padding: "0 2rem" }}>
        <Table columns={tableColumns}>
          <TableBody
            rows={rows}
            css={{
              "tr:nth-last-child(2) td:last-child": {
                borderBottomRightRadius: theme.smallBorderRadius,
                overflow: "hidden",
              },
              "tr:nth-last-child(2) td:first-child": {
                borderBottomLeftRadius: theme.smallBorderRadius,
                overflow: "hidden",
              },
              "tr:nth-last-child(2) td": {
                borderBottomStyle: "solid",
              },
            }}
          />
        </Table>
      </div>

      {addItemDialog.element}

      {editItemDialog.element}
    </div>
  );
};

const tableHeadings = ["Jednostka", "Ilość", "Wartość netto", "Dodatkowy rabat"];

const PromoPriceBadge = ({ text }: { text: string }) => {
  const theme = useTheme();

  return (
    <div
      css={{
        display: "flex",
        padding: "0.25rem 0.375rem",
        borderRadius: theme.smallBorderRadius,
        border: `1px solid ${theme.colors.primary}`,
        color: theme.colors.primary,
        fontSize: "0.75rem",
      }}
    >
      {text}
    </div>
  );
};
