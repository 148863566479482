import { DescriptionField } from "@dash/description-list";
import { Card } from "@dash/page";
import _ from "lodash";
import React from "react";

import { toDateString } from "@megaron/date-string";
import { CodeGroupDto } from "@megaron/loyalty-points-contracts";

type Props = {
  groups: CodeGroupDto[];
};

export const DailySummaryCard: React.FunctionComponent<Props> = (props) => {
  const byUser = _(props.groups)
    .groupBy((g) => g.properties?.createdBy)
    .mapValues((userGroups) => _.sum(userGroups.map((g) => g.total)))
    .entries()
    .value();

  const total = _.sum(byUser.map(([, total]) => total));

  return (
    <Card variant="secondary" css={{ maxWidth: "400px" }}>
      <DescriptionField
        css={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "0.875rem",
          fontWeight: "bold",
        }}
        label={`Razem`}
      >
        {total}
      </DescriptionField>
      {byUser.map(([user, total]) => (
        <DescriptionField
          css={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "0.875rem",
          }}
          key={user}
          label={user}
        >
          {total}
        </DescriptionField>
      ))}
    </Card>
  );
};
