import { UserReference } from "@legacy-megarax/common";
import { idObjectReferenceSerializer } from "@legacy-megarax/rest-resource";
import { Serializers } from "@legacy-megarax/serializers";
import Decimal from "decimal.js";

import {
  currencies,
  Currency,
  CustomerReference,
  customerReferenceSerializer,
  Gtin,
  gtinSerializer,
  PriceListReference,
  priceListReferenceSerializer,
} from "../common";
import { OrderDraftStatus } from "./draftStatus";

export type OrderDraftLineDto = {
  uuid: string;
  quantity: number;
  promoPrice: Decimal | null;
  basePrice: Decimal;
  finalPrice: Decimal;
  lineNetTotal: Decimal;
  item: {
    gtin: Gtin;
  };
};

export type OrderDraftDto<TStatus extends OrderDraftStatus = OrderDraftStatus> = {
  uuid: string;
  owner: UserReference;

  lines: OrderDraftLineDto[];

  payer: CustomerReference;
  buyer: CustomerReference;
  recipient: CustomerReference;

  payerDiscountRate: Decimal;
  priceList: PriceListReference;

  isFinalized: boolean;
  isDiscarded: boolean;

  netTotal: Decimal;
  currency: Currency;

  startedAt: Date;

  finalizedAt: TStatus extends "finalized" ? Date : null;
  discardedAt: TStatus extends "finalized" ? Date : null;
};

const orderDraftLineDtoSerializer = Serializers.object<OrderDraftLineDto>({
  uuid: Serializers.uuid(),
  item: Serializers.object({ gtin: gtinSerializer }),
  promoPrice: Serializers.decimal.nullable(),
  quantity: Serializers.integer,
  lineNetTotal: Serializers.decimal,
  finalPrice: Serializers.decimal,
  basePrice: Serializers.decimal,
});
export const orderDraftDtoSerializer = Serializers.object<OrderDraftDto>({
  uuid: Serializers.uuid(),
  payerDiscountRate: Serializers.decimal,
  buyer: customerReferenceSerializer,
  payer: customerReferenceSerializer,
  recipient: customerReferenceSerializer,
  isFinalized: Serializers.boolean,
  finalizedAt: Serializers.datetime.nullable(),
  priceList: priceListReferenceSerializer,
  lines: Serializers.array(orderDraftLineDtoSerializer),
  owner: idObjectReferenceSerializer,
  isDiscarded: Serializers.boolean,
  startedAt: Serializers.datetime,
  discardedAt: Serializers.datetime.nullable(),
  netTotal: Serializers.decimal,
  currency: Serializers.stringOneOf(...currencies),
});
