import { useDeviceType } from "@dash/mq";
import { QuerySkeleton } from "@dash/skeleton";
import React from "react";
import { Bar } from "react-chartjs-2";

import { Training } from "@megaron/crm-contracts";
import { useClientManager } from "@megaron/react-clients";

import { getEventColor } from "./TrainingHome";

type Props = {
  data: Training[];
};

type RegionCount = {
  planned: number;
  completed: number;
  canceled: number;
  gpw: number;
  inCompany: number;
};

export const TrainingDataChart: React.FC<Props> = ({ data }) => {
  const regionsQuery = useClientManager("crm").regionsQuery().useQuery({});
  const { isMobile } = useDeviceType();

  const regions = regionsQuery.data?.items.map((region) => region.name) || [];

  const processTrainings = (trainings: Training[], allRegions: string[]) => {
    const regionCount = trainings.reduce((acc, training) => {
      if (!acc[training.region]) {
        acc[training.region] = { planned: 0, completed: 0, canceled: 0, gpw: 0, inCompany: 0 };
      }
      const status = training.status as keyof RegionCount;
      acc[training.region][status] += 1;
      return acc;
    }, {} as Record<string, RegionCount>);

    return allRegions.map((region) => ({
      region,
      planned: regionCount[region]?.planned || 0,
      completed: regionCount[region]?.completed || 0,
      canceled: regionCount[region]?.canceled || 0,
      gpw: regionCount[region]?.gpw || 0,
      inCompany: regionCount[region]?.inCompany || 0,
    }));
  };

  const processedData = processTrainings(data, regions);

  const chartData = {
    labels: regions,
    datasets: [
      {
        label: "Planowane szkolenie",
        data: processedData.map((item) => item.planned),
        backgroundColor: getEventColor("planned"),
      },
      {
        label: "Zakończone",
        data: processedData.map((item) => item.completed),
        backgroundColor: getEventColor("completed"),
      },
      {
        label: "Odwołane",
        data: processedData.map((item) => item.canceled),
        backgroundColor: getEventColor("canceled"),
      },
      {
        label: "Szkoła GPW",
        data: processedData.map((item) => item.gpw),
        backgroundColor: getEventColor("gpw"),
      },
      {
        label: "Szkolenie w firmie",
        data: processedData.map((item) => item.inCompany),
        backgroundColor: getEventColor("inCompany"),
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Szkolenia na region",
      },
    },
    indexAxis: isMobile ? ("y" as const) : ("x" as const),
    scales: {
      x: {
        stacked: true,
        ticks: {
          callback: (value: string | number, index: number) => (!isMobile ? regions[index] : value),
          stepSize: 1,
        },
      },
      y: {
        stacked: true,
        ticks: {
          callback: (value: string | number) =>
            isMobile ? regions[value as number] : Number.isInteger(value) ? value : null,
          stepSize: 1,
        },
      },
    },
  };

  return (
    <QuerySkeleton query={regionsQuery} height={"300px"}>
      {() => (
        <div
          style={{
            width: "100%",
            maxWidth: "800px",
            height: "600px",
            margin: "0 auto",
            padding: "20px",
          }}
        >
          <Bar data={chartData} options={options} />
        </div>
      )}
    </QuerySkeleton>
  );
};
