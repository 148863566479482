import { Gtin, validateGtin } from "@legacy-megarax/crm-contracts";
import { Serializers } from "@legacy-megarax/serializers";
import { parse } from "csv/browser/esm/sync";
import Decimal from "decimal.js";

import { Failure, Ok } from "@megaron/result";

export type InputRow = {
  gtin: Gtin;
  netPrice: Decimal | null;
};

export const csvToInput = (csv: string) => {
  try {
    const parsed: any[] = parse(csv, {
      columns: true,
      delimiter: ";",
    });
    return rawToInput(parsed);
  } catch (err) {
    return Failure("InvalidInput");
  }
};

export const rawToInput = (raw: unknown) => {
  if (!Array.isArray(raw)) return Failure("InvalidInput");
  if (raw.length === 0) return Failure("InvalidInput");

  const mapped: InputRow[] = [];

  for (const rawRow of raw) {
    if (typeof rawRow !== "object") return Failure("InvalidInput");
    const row = rawToInputRow(rawRow);
    if (row.isFailure) return row;
    mapped.push(row.value);
  }

  return Ok(mapped);
};

export const rawToInputRow = (raw: { [key: string]: unknown }) => {
  if (!("ean" in raw)) return Failure("MissingGtin");
  if (typeof raw["ean"] !== "string") return Failure("InvalidGtin");
  const gtinValidationResult = validateGtin(raw["ean"]);
  if (gtinValidationResult.isFailure) return Failure("InvalidGtin");

  if (!("cena netto" in raw)) return Failure("MissingPrice");
  if (typeof raw["cena netto"] !== "string") return Failure("InvalidPrice");
  const priceString = raw["cena netto"].replace(",", ".");
  const netPrice = Serializers.decimal.nullable().forceDeserialize(priceString || null);

  return Ok<InputRow>({
    gtin: gtinValidationResult.value,
    netPrice,
  });
};
