import { Dialog } from "@dash/dialog";
import { useDeviceType } from "@dash/mq";
import { QuerySkeleton } from "@dash/skeleton";
import { useToast } from "@dash/toast";
import { PricedItemDto } from "@legacy-megarax/crm-contracts";
import Decimal from "decimal.js";
import { useQueryClient } from "react-query";

import { AddDraftLineInput, Currency, OrderDraftLineDto, UpdateDraftLineInput } from "@megaron/crm-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { ItemDialogContent } from "./ItemDialogContent";

type Props = {
  onClose: () => void;
  line: OrderDraftLineDto;
  orderUuid?: Uuid;
  currency: Currency;
  queryKey?: string | string[];
  pricedItems?: PricedItemDto[];
  baseDiscount?: Decimal;
};

export const EditItemDialog: React.FC<Props> = ({
  onClose,
  line,
  orderUuid,
  queryKey,
  currency,
  pricedItems,
  baseDiscount,
}) => {
  const { isMobile } = useDeviceType();

  const toast = useToast();

  const queryClient = useQueryClient();

  const itemQuery = useClientManager("crm").itemQuery().useQuery({ gtin: line.item.gtin });

  const getPricedItem = (itemGtin?: string) => {
    if (!pricedItems || !itemGtin) {
      return undefined;
    }

    return pricedItems.find((item) => item.gtin === itemGtin);
  };

  const updateLineMutation = useClientManager("crm").updateDraftLine().useMutation();
  const removeLineMutation = useClientManager("crm").removeDraftLine().useMutation();

  const handleEditLine = (line: AddDraftLineInput) => {
    if (!orderUuid) {
      return;
    }

    const input: UpdateDraftLineInput & { uuid: Uuid; lineUuid: Uuid } = {
      promoPrice: line.promoPrice,
      quantity: line.quantity,
      lineUuid: line.lineUuid,
      uuid: orderUuid,
      lineDiscountRate: line.lineDiscountRate,
    };

    updateLineMutation.mutate(input, {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        onClose();
      },
      onError: (error) => {
        if (error === "DraftNotOpen") {
          toast.error("Wersja robocza nie posiada statusu: otwarta.");
        }

        if (error === "InvalidLineQuantity") {
          toast.error("Nieprawidłowa ilość przedmiotu.");
        }

        if (error === "OrderNotFound") {
          toast.error("Nie znaleziono zamówienia.");
        }

        if (error === "UserNotOrderOwner") {
          toast.error("Nie jesteś właścicielem zamówienia.");
        }

        if (error === "LineDoesNotExist") {
          toast.error("Przedmiot nie istnieje w zamówieniu.");
        }

        if (error === "InvalidLineDiscountRate") {
          toast.error("Nieprawidłowy rabat na przedmiocie. Suma rabatów nie może przekraczać 100%.");
        }
      },
    });
  };

  const handleRemoveLine = (lineUuid: Uuid) => {
    if (!orderUuid) {
      return;
    }

    removeLineMutation.mutate(
      { uuid: orderUuid, lineUuid },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
          onClose();
        },
        onError: (error) => {
          if (error === "DraftNotOpen") {
            toast.error("Wersja robocza nie posiada statusu: otwarta.");
          }

          if (error === "OrderNotFound") {
            toast.error("Nie znaleziono zamówienia.");
          }

          if (error === "UserNotOrderOwner") {
            toast.error("Nie jesteś właścicielem zamówienia.");
          }

          if (error === "LineDoesNotExist") {
            toast.error("Przedmiot nie istnieje w zamówieniu.");
          }
        },
      },
    );
  };

  return (
    <Dialog
      onClose={onClose}
      placement={isMobile ? "top" : "center"}
      css={{ width: isMobile ? "100%" : "400px" }}
      header={itemQuery.data?.itemName ?? ""}
    >
      <div css={{ maxHeight: "70dvh", overflow: "auto" }}>
        <QuerySkeleton query={itemQuery} height="158px" width="100%">
          {(item) => (
            <ItemDialogContent
              item={item}
              onSave={handleEditLine}
              line={line}
              onDelete={() => handleRemoveLine(line.uuid)}
              currency={currency}
              pricedItem={getPricedItem(item.gtin ?? undefined)}
              baseDiscount={baseDiscount}
            />
          )}
        </QuerySkeleton>
      </div>
    </Dialog>
  );
};
