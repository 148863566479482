import { useDialogRoute } from "@dash/dialog";
import { Button } from "@dash/form";
import { Page, PageHeader, SectionHeader } from "@dash/page";
import { QuerySkeleton } from "@dash/skeleton";
import { TileGrid } from "@dash/tile";
import React from "react";
import { MdConveyorBelt, MdDelete, MdNewLabel } from "react-icons/md";
import { Link, useSearchParams } from "react-router-dom-v5-compat";

import { dateStringSerializer, toDateString } from "@megaron/date-string";
import { useClientManager } from "@megaron/react-clients";

import { DailySummaryCard } from "./DailySummaryCard";
import { GroupTile } from "./GroupTile";
import { NewLabelBatchDialog } from "./NewLabelBatchDialog";
import { NewProductionBatchDialog } from "./NewProductionBatchDialog";

export const BatchesHome: React.FC = () => {
  // get date from qs
  const [searchParams] = useSearchParams();
  const date = dateStringSerializer.deserialize(searchParams.get("date")).value ?? toDateString(new Date());

  const productionGroupsQuery = useClientManager("loyaltyPoints")
    .codeGroupsQuery()
    .useQuery({ type: ["produkcja_pokrywki"] });

  const labelGroupsQuery = useClientManager("loyaltyPoints")
    .codeGroupsQuery()
    .useQuery({ type: ["pokrywki"], creationDate: date, limit: 200 });

  const newProductionBatchDialog = useDialogRoute("/new-production-batch", ({ onClose }) => (
    <NewProductionBatchDialog onClose={onClose} />
  ));
  const newLabelBatchDialog = useDialogRoute("/new-label-batch", ({ onClose }) => (
    <NewLabelBatchDialog onClose={onClose} />
  ));

  return (
    <Page>
      <PageHeader
        actions={
          <Link to={`./ungroup-code`}>
            <Button variant="outline" color="danger" size="small" icon={<MdDelete />}>
              Usuń kody
            </Button>
          </Link>
        }
      >
        Pokrywki
      </PageHeader>
      <div css={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
        <SectionHeader
          isHr
          actions={
            <Button size="small" icon={<MdConveyorBelt />} onClick={newProductionBatchDialog.open}>
              Nowa grupa
            </Button>
          }
        >
          Produkcja
        </SectionHeader>
        <QuerySkeleton query={productionGroupsQuery}>
          {(groups) => (
            <TileGrid minWidth="280px">
              {groups.map((group) => (
                <Link to={`./production-batch/${group.name}`}>
                  <GroupTile key={group.name} group={group} />
                </Link>
              ))}
            </TileGrid>
          )}
        </QuerySkeleton>
      </div>
      <div css={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
        <SectionHeader
          isHr
          actions={
            <Button size="small" icon={<MdNewLabel />} onClick={newLabelBatchDialog.open}>
              Nowa grupa
            </Button>
          }
        >
          {`Etykietowanie ${date}`}
        </SectionHeader>
        <QuerySkeleton query={labelGroupsQuery}>
          {(groups) => (
            <>
              <DailySummaryCard groups={groups} />
              <TileGrid minWidth="280px">
                {groups.map((group) => (
                  <Link to={`./label-batch/${group.name}`}>
                    <GroupTile key={group.name} group={group} />
                  </Link>
                ))}
              </TileGrid>
            </>
          )}
        </QuerySkeleton>
      </div>
      {newProductionBatchDialog.element}
      {newLabelBatchDialog.element}
    </Page>
  );
};
