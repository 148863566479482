import React, { createContext, useContext } from "react";

import { AlertObject, alertsMap, NotificationDto, NotificationsListObject } from "@megaron/notifications-contracts";
import { useClientManager } from "@megaron/react-clients";

interface NotificationsContextType {
  data: NotificationsListObject | undefined;
  key: string | string[];
}

const NotificationsContext = createContext<NotificationsContextType>({
  data: undefined,
  key: "",
});

export const NotificationsProvider: React.FC<{ children: React.ReactNode; isAuthLoaded: boolean }> = ({
  children,
  isAuthLoaded,
}) => {
  const notificationsQuery = useClientManager("notifications")
    .getNotifications()
    .useQuery({}, { enabled: isAuthLoaded });

  const key = Array.isArray(notificationsQuery.key) ? notificationsQuery.key.join(",") : notificationsQuery.key;

  return (
    <NotificationsContext.Provider value={{ data: notificationsQuery.data, key }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => {
  const { data: notifications, key } = useContext(NotificationsContext);

  const getAlertsMetricSum = (link: string | undefined) => {
    if (!link) {
      return 0;
    }

    const matchingAlertTypes = Object.entries(alertsMap)
      .filter(([_, details]) => details.links?.includes(link))
      .map(([key]) => key);

    if (matchingAlertTypes.length === 0) {
      return 0;
    }

    return (
      notifications?.alerts
        .filter((alert) => matchingAlertTypes.includes(alert.type))
        .reduce((sum, alert) => sum + (alert.metric || 0), 0) || 0
    );
  };

  const getUnreadAlertsAndNotificationsMetricSumByApp = (app?: string) => {
    if (!notifications || !app) {
      return 0;
    }

    const unreadAlertsMetricSum = notifications.alerts
      .filter((alert) => alertsMap[alert.type]?.app === app)
      .reduce((sum, alert) => sum + (alert.metric || 0), 0);

    const unreadNotificationsCount = notifications.notifications.filter(
      (notification) => alertsMap[notification.type]?.app === app && !notification.readAt,
    ).length;

    return unreadAlertsMetricSum + unreadNotificationsCount;
  };

  const getUnreadNotifications = (): { alerts: AlertObject[]; notifications: NotificationDto[]; count: number } => {
    if (!notifications) {
      return {
        alerts: [],
        notifications: [],
        count: 0,
      };
    }

    const unreadAlerts = notifications.alerts.filter((alert) => alert.metric && alert.metric > 0);
    const unreadNotifications = notifications.notifications.filter((notification) => !notification.readAt);

    return {
      alerts: unreadAlerts,
      notifications: unreadNotifications,
      count: unreadAlerts.length + unreadNotifications.length,
    };
  };

  return {
    allNotifications: notifications,
    appUnreadAlertsAndNotificationsMetricSum: getUnreadAlertsAndNotificationsMetricSumByApp,
    alertsMetric: getAlertsMetricSum,
    queryKey: key,
    unreadNotifications: getUnreadNotifications,
  };
};
