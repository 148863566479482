import { useDialogRoute } from "@dash/dialog";
import { useDeviceType } from "@dash/mq";
import { useTheme } from "@emotion/react";
import { MdOutlineFilterAlt } from "react-icons/md";

import { AggType, CustomerCategory, CustomerStatus } from "@megaron/crm-contracts";
import { Uuid } from "@megaron/uuid";

import { FiltersAndAggDialog } from "./FiltersAndAggDialog";
import { FiltersAndAggList } from "./FiltersAndAggList";
import { TimeUnit } from "./TimeRangeSelect";
import { Filters } from "./useCustomerAnalyticsGlobalFilters";

export type FiltersProps = {
  filters: Filters;
  handlers: {
    onRegionsChange: (regions: string[] | undefined) => void;
    onCategoriesChange: (categories: CustomerCategory[] | undefined) => void;
    onStatusChange: (status: CustomerStatus[] | undefined) => void;
    onCustomerIdChange: (customerId: Uuid[] | undefined) => void;
    onDateChange: (
      startDate: Date | null,
      endDate: Date | null,
      timeUnit: TimeUnit | null,
      unitCount: number | null,
      activeOptionName: string | null,
    ) => void;
    onProductGroupsChange: (productGroups: string[]) => void;
    onIsMyRegionChange: (isMyRegion: boolean | undefined) => void;
  };
  aggs: { type: AggType; name: string; onClick: () => void }[];
};

export const CustomerAnalyticsFilters: React.FC<
  FiltersProps & {
    handleAddFiltersClick: () => void;
  }
> = ({ filters, handlers, aggs, handleAddFiltersClick }) => {
  const theme = useTheme();

  const { isMobile } = useDeviceType();

  const activeGlobalFilters = [filters.status, filters.category, filters.region, filters.isMyRegion].filter(
    (filter) => {
      if (Array.isArray(filter)) {
        return filter && filter.length > 0;
      }

      return filter !== undefined;
    },
  );

  const filtersAndAggDialog = useDialogRoute("/filters-and-aggs", ({ onClose }) => (
    <FiltersAndAggDialog
      aggs={aggs}
      filters={filters}
      handleAddFiltersClick={handleAddFiltersClick}
      handlers={handlers}
      onClose={onClose}
    />
  ));

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
      }}
    >
      <MdOutlineFilterAlt size={20} css={{ color: theme.colors.primary, flexShrink: 0 }} />
      {isMobile ? (
        <button
          css={{
            fontSize: "0.875rem",
            fontWeight: 700,
            color: theme.colors.primary,
            border: "none",
            background: "none",
            padding: "0.125rem 0.25rem",
          }}
          onClick={filtersAndAggDialog.open}
        >
          Filtry ({activeGlobalFilters.length})
        </button>
      ) : (
        <FiltersAndAggList
          filters={filters}
          handlers={handlers}
          aggs={aggs}
          handleAddFiltersClick={handleAddFiltersClick}
        />
      )}
      {filtersAndAggDialog.element}
    </div>
  );
};
