import { SwitchField } from "@dash/form";
import { useEffect, useState } from "react";

export const isReadAutomaticUpdateEnabledKey = "isReadAutomaticUpdateEnabled";

export const IsReadSettings = () => {
  const [isEnabled, setIsEnabled] = useState(true);

  useEffect(() => {
    const isReadAutomaticUpdateEnabled = getIsReadAutomaticUpdateEnabled();

    if (isReadAutomaticUpdateEnabled === null) {
      return;
    }

    setIsEnabled(isReadAutomaticUpdateEnabled);
  }, []);

  const handleChange = (value: boolean) => {
    localStorage.setItem(isReadAutomaticUpdateEnabledKey, String(value));
    setIsEnabled(value);
  };

  return (
    <SwitchField
      label="Automatyczne oznaczanie wątku jako przeczytany:"
      trueLabel="Włączone"
      falseLabel="Wyłączone"
      fullWidth
      onChange={handleChange}
      selectedValue={isEnabled}
    />
  );
};

export const getIsReadAutomaticUpdateEnabled = () => {
  const isReadAutomaticUpdateEnabled = localStorage.getItem(isReadAutomaticUpdateEnabledKey);

  if (!isReadAutomaticUpdateEnabled) {
    return null;
  }

  return isReadAutomaticUpdateEnabled === "true";
};
