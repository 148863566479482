import { DescriptionField } from "@dash/description-list";
import { Button } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { SectionHeader } from "@dash/page";
import { QuerySkeleton } from "@dash/skeleton";
import { useNavigate } from "react-router-dom-v5-compat";

import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

type Props = {
  userId: Uuid;
};

export const CustomerAccountDetails: React.FC<Props> = ({ userId }) => {
  const { isMobile } = useDeviceType();

  const navigate = useNavigate();

  const accountsAccountQuery = useClientManager("auth")
    .account()
    .useQuery({ uuid: userId }, { enabled: userId !== undefined });

  const pointsAccountQuery = useClientManager("loyaltyPoints")
    .account()
    .useQuery({ uuid: userId }, { enabled: userId !== undefined });

  const customerOrdersUrl = createUrl("/specjalista/orders", {
    userUuid: userId,
    page: 0,
    rewardId: [],
    isFulfilled: undefined,
  });

  const accountsUnauthorized = accountsAccountQuery.error === "Unauthorized";
  const pointsUnauthorized = pointsAccountQuery.error === "Unauthorized";

  if (accountsUnauthorized && pointsUnauthorized) {
    return null;
  }

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <SectionHeader
        isHr
        actions={
          <Button variant="outline" size="small" onClick={() => navigate(customerOrdersUrl)}>
            Realizacja nagród
          </Button>
        }
      >
        Dane konta
      </SectionHeader>
      {!accountsUnauthorized && (
        <QuerySkeleton query={accountsAccountQuery} height="90px">
          {(account) => (
            <div css={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr", gap: "0.625rem" }}>
              <DescriptionField label="Data dołączenia">{account.joinedAt?.toLocaleString()}</DescriptionField>
              <DescriptionField label="Data rejestracji">{account.registeredAt?.toLocaleString()}</DescriptionField>
              {account.email && <DescriptionField label="Zatwierdzony email">{account.email}</DescriptionField>}
              {account.unverifiedEmail && (
                <DescriptionField label="Email do zatwierdzenia">{account.unverifiedEmail}</DescriptionField>
              )}
            </div>
          )}
        </QuerySkeleton>
      )}
      {!pointsUnauthorized && (
        <QuerySkeleton query={pointsAccountQuery} height="90px">
          {(account) => (
            <div css={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr", gap: "0.625rem" }}>
              <DescriptionField label="Stan konta">{account.balance.toFixed(1)} pkt</DescriptionField>
              <DescriptionField label="Status">{getStatusText(account)}</DescriptionField>
              <DescriptionField label="Nr konta PRO">{account.proNumber}</DescriptionField>
              <DescriptionField label="Liczba skanów">{account.lifetimeCodeCount}</DescriptionField>
            </div>
          )}
        </QuerySkeleton>
      )}
    </div>
  );
};

const getStatusText = (acc: { isAffiliate: boolean; isPro: boolean }) => {
  if (acc.isAffiliate && acc.isPro) return "Partner, PRO";
  if (acc.isAffiliate) return "Partner";
  if (acc.isPro) return "PRO";

  return null;
};

function createUrl(pathname: string, params: Record<string, any>) {
  const searchText = JSON.stringify(params);

  return `${pathname}?filters=${encodeURIComponent(searchText)}`;
}
