import { BaseResource, ResourceActionV2, UuidResourceParam } from "@legacy-megarax/rest-resource";
import { Serializers } from "@legacy-megarax/serializers";

export const shippingOrderResource = BaseResource(["shipping", "orders"], {
  fastestDeliveryDate: ResourceActionV2({
    name: "fastestDeliveryDate",
    method: "get",
    responseValueSerializer: Serializers.dateOnly,
  }),
});
