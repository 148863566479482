import { legacyMegaraxHttpService } from "@legacy-megarax/crm-contracts";
import { MegaraxConnectionProvider } from "@legacy-megarax/react-client";
import { useConfig } from "@megarax/dev-config";
import posthog from "posthog-js";
import { PropsWithChildren } from "react";

import { createTestUserAuthHeader, TestUserPayload } from "@megaron/access-control";
import { authHttpService } from "@megaron/auth-contracts";
import { IamAuthContext } from "@megaron/auth-react";
import { crmHttpService } from "@megaron/crm-contracts";
import { docsHttpService } from "@megaron/docs-contracts";
import { HttpServiceClient } from "@megaron/http-service-client";
import { invoicesHttpService } from "@megaron/invoices-contracts";
import { ordersHttpService } from "@megaron/loyalty-orders-contracts";
import { pointsHttpService } from "@megaron/loyalty-points-contracts";
import { qrSyncService } from "@megaron/loyalty-qr-sync-contracts";
import { notificationsHttpService } from "@megaron/notifications-contracts";
import { ClientContext } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

type Props = {
  auth: IamAuthContext;
} & PropsWithChildren;

export const ApiServicesProvider: React.FC<Props> = ({ children, auth }) => {
  const { config } = useConfig();

  const trackUserSignup = (userId: string | undefined | null) => {
    if (posthog && userId) {
      posthog.identify(userId);
    }
  };

  const getAuthHeader = (auth: IamAuthContext) => async () => {
    const { email, groups, phoneNumber, roles, type, uuid } = config.testUser;

    const testUserPayload: TestUserPayload | undefined = config.testUser.enabled
      ? {
          email: email || undefined,
          groups,
          phoneNumber: phoneNumber || undefined,
          roles,
          userType: type || undefined,
          uuid: uuid ? Uuid(uuid) : undefined,
        }
      : undefined;

    if (testUserPayload) {
      return createTestUserAuthHeader(testUserPayload);
    }
    trackUserSignup(auth.iamUser?.userType === "megarax" ? auth.iamUser?.email : auth.iamUser?.loyaltyId);
    return auth.getAuthHeader();
  };

  return (
    <MegaraxConnectionProvider tokenProvider={getAuthHeader(auth)}>
      <ClientContext.Provider
        value={{
          loyaltyOrders: HttpServiceClient(config.ordersUrl, ordersHttpService, getAuthHeader(auth)),
          loyaltyPoints: HttpServiceClient(config.pointsUrl, pointsHttpService, getAuthHeader(auth)),
          crm: HttpServiceClient(config.crmUrl, crmHttpService, getAuthHeader(auth)),
          qrSync: HttpServiceClient(config.qrSyncUrl, qrSyncService, getAuthHeader(auth)),
          auth: HttpServiceClient(config.accountsUrl, authHttpService, getAuthHeader(auth)),
          notifications: HttpServiceClient(config.accountsUrl, notificationsHttpService, getAuthHeader(auth)),
          docs: HttpServiceClient(config.docsUrl, docsHttpService, getAuthHeader(auth)),
          invoices: HttpServiceClient(config.invoicesUrl, invoicesHttpService, getAuthHeader(auth)),
          legacyMegarax: HttpServiceClient(config.apiUrl, legacyMegaraxHttpService, getAuthHeader(auth)),
        }}
      >
        {children}
      </ClientContext.Provider>
    </MegaraxConnectionProvider>
  );
};
