import { useIndependentAsyncLoad } from "@legacy-megarax/react-client";
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Dialog,
  FormControl,
  InputBase,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";

export interface MultiSelectInputProps<T> {
  value: T[];
  onChange: (newValue: T[]) => void;
  error?: string | boolean;
  helperText?: string;
  getOptions: (searchString: string) => Promise<T[]>;
  label?: string;
  getKey: GetOptionKey<T>;
  getTitle: GetOptionLabel<T>;
  initialValueIds?: string[];
}
export type GetOptionKey<T> = (option: T) => string | number;
export type GetOptionLabel<T> = (option: T) => string;

export const MultiSelectInput = <T,>({
  getOptions,
  label,
  value,
  onChange,
  getKey = (op) => String(op),
  getTitle = (op) => String(op),
  helperText,
  error,
  initialValueIds,
}: MultiSelectInputProps<T>): React.ReactElement<any> => {
  const classes = useStyles();

  const isInitialized = useRef(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>("");
  const onSearchChange = debounce((e) => setSearchValue(e.target.value), 300);

  const [options, setOptions] = useState<T[]>();

  const { loading } = useIndependentAsyncLoad(() => getOptions(searchValue), setOptions, [searchValue]);

  const onSelect = (selected: T, check: boolean) => {
    if (check) return onChange([...value, selected]);
    return onChange(value.filter((item) => getKey(item) !== getKey(selected)));
  };

  useEffect(() => {
    setOpen(false);
  }, [isMobile]);

  useEffect(() => {
    setSearchValue("");
  }, [open]);

  useEffect(() => {
    if (isInitialized.current || !options || !initialValueIds || value.length !== 0) return;
    onChange(options.filter((option) => initialValueIds.includes(getKey(option).toString())));
    isInitialized.current = true;
  }, [options]);

  return (
    <>
      <Dialog fullScreen open={isMobile && open}>
        <Paper className={classes.paper} square={true} elevation={1}>
          <InputBase
            autoFocus
            fullWidth
            onChange={onSearchChange}
            placeholder="Szukaj..."
            startAdornment={<FaSearch style={{ marginRight: "1rem" }} />}
          />
        </Paper>
        <List style={{ overflow: "auto" }}>
          {options?.map((option) => {
            const isChecked = Boolean(value?.find((value) => getKey(value) === getKey(option)));
            return (
              <ListItem key={getKey(option)} dense button onClick={() => onSelect(option, !isChecked)}>
                <ListItemIcon>
                  <Checkbox edge="start" checked={isChecked} tabIndex={-1} disableRipple />
                </ListItemIcon>
                <ListItemText>{getTitle(option)}</ListItemText>
              </ListItem>
            );
          })}
        </List>
        <Button color="primary" variant="contained" onClick={() => setOpen(false)} className={classes.closeButton}>
          Gotowe
        </Button>
      </Dialog>
      <FormControl fullWidth variant="outlined">
        {isMobile ? (
          <>
            <InputLabel>{label}</InputLabel>
            <Select
              label={label}
              open={false}
              onClick={() => setOpen(true)}
              classes={{ select: classes.select }}
              multiple
              value={value}
              variant="outlined"
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {(selected as T[]).map((value) => (
                    <Chip key={getKey(value)} label={getTitle(value)} className={classes.chip} />
                  ))}
                </div>
              )}
            />
          </>
        ) : (
          <Autocomplete
            multiple
            disableCloseOnSelect
            options={options ? [...options, ...value] : []}
            loading={loading}
            value={value}
            autoComplete={false}
            isOptionEqualToValue={(option, value) => {
              return getKey(option) === getKey(value);
            }}
            onChange={(e, newValue) => {
              setSearchValue("");
              onChange(newValue);
            }}
            clearOnBlur={false}
            filterOptions={() => (options ? options.filter((x) => x) : [])}
            getOptionLabel={(option) => getTitle(option)}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label={label}
                onChange={onSearchChange}
                error={Boolean(error)}
                variant="outlined"
                helperText={helperText ?? error}
              />
            )}
          />
        )}
      </FormControl>
    </>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      padding: "0.5rem 1rem",
      boxSizing: "border-box",
    },
    closeButton: {
      width: "100%",
      position: "absolute",
      bottom: "0",
      borderRadius: "0",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: theme.spacing(1),
      marginLeft: "0",
      marginBottom: "0",
    },
    select: {
      minHeight: "50px",
      padding: theme.spacing(1),
    },
  };
});
