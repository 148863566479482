import { LoyaltyAdminLogo } from "@megarax/links";
import {
  MdOutlineCorporateFare,
  MdOutlineHandshake,
  MdOutlineLocalAtm,
  MdOutlineStorefront,
  MdOutlineWarehouse,
} from "react-icons/md";

import { CustomerCategory as CustomerCategoryType } from "@megaron/crm-contracts";

import { CustomerCategory, SectionHeader } from "./AddCustomerPage";
import { CustomerTypeTile } from "./CustomerTypeTile";

import type { JSX } from "react";

type Props = {
  selectedCategories: CustomerCategory[];
  onCategoryChange: (value: CustomerCategory) => void;
  isDisabled?: boolean;
};

export const CustomerCategorySection: React.FC<Props> = ({ selectedCategories, onCategoryChange, isDisabled }) => {
  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem", opacity: isDisabled ? 0.5 : 1, width: "100%" }}>
      <SectionHeader header="Wybierz kategorie klienta" />
      <div css={{ display: "flex", flexDirection: "column", width: "100%", gap: "0.375rem" }}>
        {customerCategories
          .filter((category) => category.userType === "firm")
          .map(({ label, value, Icon }) => (
            <CustomerTypeTile
              key={value}
              label={label}
              value={value}
              isSelected={selectedCategories.includes(value as CustomerCategory)}
              icon={Icon}
              onClick={isDisabled ? undefined : (value) => onCategoryChange(value as CustomerCategory)}
              isDisabled={isDisabled}
            />
          ))}
      </div>
    </div>
  );
};

export type CustomerType = "firm" | "customer";

export type CustomerCategoryDetails = {
  label: string;
  value: CustomerCategoryType;
  Icon: JSX.Element;
  userType: CustomerType;
};

export const customerCategories: CustomerCategoryDetails[] = [
  {
    label: "Użytkownik Specjalista",
    value: "loyaltyUser",
    Icon: <LoyaltyAdminLogo css={{ width: "16px", height: "16px" }} />,
    userType: "customer",
  },
  // {
  //   label: "Partner Specjalista",
  //   value: "partner-specjalista",
  //   Icon: <MdOutlineHandshake size={16} />,
  //   userType: "customer",
  // },
  { label: "Punkt sprzedaży", value: "pos", Icon: <MdOutlineStorefront size={16} />, userType: "firm" },
  { label: "Płatnik", value: "payer", Icon: <MdOutlineLocalAtm size={16} />, userType: "firm" },
  { label: "Sieć", value: "chain", Icon: <MdOutlineCorporateFare size={16} />, userType: "firm" },
  { label: "Dystrybutor", value: "distributor", Icon: <MdOutlineWarehouse size={16} />, userType: "firm" },
];
