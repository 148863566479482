import { QuerySkeleton } from "@dash/skeleton";
import { useToast } from "@dash/toast";
import { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useQueryClient } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom-v5-compat";

import { IamAuthContext } from "@megaron/auth-react";
import { SavedViewDoc, SavedViewType, SaveViewError } from "@megaron/docs-contracts";
import { CommonErrors } from "@megaron/http-service-client";
import { useClientManager } from "@megaron/react-clients";
import { newUuid } from "@megaron/uuid";

import { AnalyticsPreset, analyticsPresetSerializer, newAnalyticsPreset } from "../customerAnalytics/analyticsPreset";
import { CustomerAnalyticsView } from "../customerAnalytics/CustomerAnalyticsView";
import { ListPreset, listPresetSerializer, newListPreset } from "../customerListPreset";
import { MapPreset, mapPresetSerializer, newMapPreset } from "../customerMapPreset";
import { CustomerMapView } from "../CustomerMapView";
import { CustomersHome } from "../CustomersHome";
import { checkArePresetsEqual } from "./checkArePresetsEqual";
import { ViewsBar } from "./ViewsBar";

const templateIds = ["new-map", "new-customer-analytics", "new-list"] as const;

type TemplateId = (typeof templateIds)[number];

type ViewIdOrTemplate = TemplateId | string;

export const CustomersViewPage = () => {
  const { viewId } = useParams<{
    viewId: ViewIdOrTemplate;
  }>();

  const [_, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const auth = useContext(IamAuthContext);

  const toast = useToast();

  const userEmail = auth.iamUser?.userType === "megarax" ? auth.iamUser?.email : undefined;

  const savedViews = useClientManager("docs").searchSavedViews().useQuery({ limit: 10000 });

  const queryClient = useQueryClient();

  const [analyticsPresetsMap, setAnalyticsPresetsMap] = useState<
    Record<string | "new-customer-analytics", AnalyticsPreset | undefined>
  >({
    "new-customer-analytics": newAnalyticsPreset(),
  });

  const [listPresetsMap, setListPresetsMap] = useState<Record<string | "new-list", ListPreset | undefined>>({
    "new-list": newListPreset(),
  });

  const [mapPresetsMap, setMapPresetsMap] = useState<Record<string | "new-map", MapPreset | undefined>>({
    "new-map": newMapPreset(),
  });

  const updateAnalyticsPreset = (viewId: ViewIdOrTemplate, newPreset: AnalyticsPreset) => {
    setAnalyticsPresetsMap((prev) => ({ ...prev, [viewId]: newPreset }));
  };

  const updateListPreset = (viewId: ViewIdOrTemplate, newPreset: ListPreset) => {
    setListPresetsMap((prev) => ({ ...prev, [viewId]: newPreset }));
  };

  const updateMapPreset = (viewId: ViewIdOrTemplate, newPreset: MapPreset) => {
    setMapPresetsMap((prev) => ({ ...prev, [viewId]: newPreset }));
  };

  const getViewsDetails = (viewId?: ViewIdOrTemplate, views?: SavedViewDoc[]) => {
    if (!viewId || !views) {
      return;
    }

    const isValidViewId =
      views.map((item) => item.id as string).includes(viewId) || templateIds.includes(viewId as TemplateId);

    if (!isValidViewId) {
      return;
    }

    const activeView = views.find((view) => view.id === viewId);

    const viewType = activeView?.viewType ?? getTemplateViewType(viewId);

    const analyticsActiveViewPreset =
      viewType === "customerAnalytics" && activeView?.options
        ? analyticsPresetSerializer.deserialize(activeView.options)
        : undefined;

    const listActiveViewPreset =
      viewType === "customerList" && activeView?.options
        ? listPresetSerializer.deserialize(activeView.options)
        : undefined;

    const mapActiveViewPreset =
      viewType === "customerMap" && activeView?.options
        ? mapPresetSerializer.deserialize(activeView.options)
        : undefined;

    const analyticsPreset = analyticsPresetsMap[viewId] ?? analyticsActiveViewPreset?.value;
    const mapPreset = mapPresetsMap[viewId] ?? mapActiveViewPreset?.value;
    const listPreset = listPresetsMap[viewId] ?? listActiveViewPreset?.value;

    return { viewType, activeView, analyticsPreset, mapPreset, listPreset };
  };

  const saveViewMutation = useClientManager("docs").saveView().useMutation();

  const handleSave = (options: SaveViewOptions) => {
    if (!savedViews.data) {
      return;
    }

    const currentViewDetails = getViewsDetails(viewId, savedViews.data.items);

    if (!currentViewDetails) {
      return;
    }

    const { viewType, activeView, analyticsPreset, listPreset, mapPreset } = currentViewDetails;

    const preset =
      currentViewDetails.viewType === "customerAnalytics"
        ? analyticsPreset
        : currentViewDetails.viewType === "customerList"
        ? listPreset
        : mapPreset;

    const viewUuid = options.createNew ? newUuid() : activeView?.id ?? newUuid();

    saveViewMutation.mutate(
      {
        id: viewUuid,
        title: options.title,
        description: options.description,
        viewType: viewType,
        options: preset,
        isArchived: false,
        isPrivate: options.isPrivate,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(savedViews.key);
          if (viewId === "new-customer-analytics") {
            updateAnalyticsPreset(viewId, newAnalyticsPreset());
          }
          if (viewId === "new-list") {
            updateListPreset(viewId, newListPreset());
          }
          if (viewId === "new-map") {
            updateMapPreset(viewId, newMapPreset());
          }
          navigate(`/crm/customers/${viewUuid}`);
        },
        onError: (e) => {
          if (options.onError) {
            options.onError(e);
            return;
          }

          toast.error("Nie udało się zapisać widoku");
        },
      },
    );
  };

  const handleArchive = (viewId: string) => {
    const view = savedViews.data?.items.find((view) => view.id === viewId);

    if (!view) {
      toast.error("Wystąpił nieznany błąd");
      return;
    }

    saveViewMutation.mutate(
      {
        id: view.id,
        title: view.title,
        description: view.description,
        viewType: view.viewType,
        options: view.options,
        isArchived: !view.isArchived,
        isPrivate: view.isPrivate,
      },
      {
        onSuccess: () => {
          toast.info(view.isArchived ? "Widok został przywrócony" : "Widok został zarchiwizowany");
          queryClient.invalidateQueries(savedViews.key);
        },
        onError: () => {
          toast.error("Nie udało się zarchiwizować widoku");
        },
      },
    );
  };

  return (
    <div css={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <QuerySkeleton query={savedViews}>
        {(views) => {
          const details = getViewsDetails(viewId, views.items);

          if (!details || !viewId) {
            if (savedViews.isFetching) {
              return;
            }

            navigate("/crm/customers/new-list");
            return;
          }

          const { activeView, analyticsPreset, listPreset, mapPreset, viewType } = details;

          const clearPresetChanges = () => {
            if (viewType === "customerList") {
              updateListPreset(
                viewId,
                activeView?.options
                  ? listPresetSerializer?.deserialize(activeView.options).value ?? newListPreset()
                  : newListPreset(),
              );
              setSearchParams("", { replace: true });
            }

            if (viewType === "customerMap") {
              updateMapPreset(
                viewId,
                activeView?.options
                  ? mapPresetSerializer.deserialize(activeView.options).value ?? newMapPreset()
                  : newMapPreset(),
              );
              setSearchParams("", { replace: true });
            }

            if (viewType === "customerAnalytics") {
              updateAnalyticsPreset(
                viewId,
                activeView?.options
                  ? analyticsPresetSerializer.deserialize(activeView.options).value ?? newAnalyticsPreset()
                  : newAnalyticsPreset(),
              );
              setSearchParams("", { replace: true });
            }
          };

          const checkIsPresetChanged = () => {
            if (viewType === "customerList") {
              return listPreset
                ? !checkArePresetsEqual(
                    listPreset,
                    activeView?.options
                      ? listPresetSerializer.deserialize(activeView.options).value ?? newListPreset()
                      : newListPreset(),
                  )
                : false;
            }

            if (viewType === "customerMap") {
              return mapPreset
                ? !checkArePresetsEqual(
                    mapPreset,
                    activeView?.options
                      ? mapPresetSerializer.deserialize(activeView.options).value ?? newMapPreset()
                      : newMapPreset(),
                  )
                : false;
            }

            if (viewType === "customerAnalytics") {
              return analyticsPreset
                ? !checkArePresetsEqual(
                    analyticsPreset,
                    activeView?.options
                      ? analyticsPresetSerializer.deserialize(activeView.options).value ?? newAnalyticsPreset()
                      : newAnalyticsPreset(),
                  )
                : false;
            }

            return false;
          };

          return (
            <div css={{ height: viewType !== "customerList" ? "calc(100dvh - 52px)" : "auto", paddingTop: "62px" }}>
              <Helmet title={activeView ? activeView.name : "Klienci"} />
              <ViewsBar
                isViewEdited={checkIsPresetChanged()}
                bookmarkedViews={
                  userEmail
                    ? views.items.filter((view) => view.bookmarkedBy.includes(userEmail) || view.id === viewId)
                    : []
                }
                activeView={activeView}
                handleSave={handleSave}
                queryKey={savedViews.key}
                isUserTheOwner={activeView?.owner === userEmail}
                isSaving={saveViewMutation.isLoading}
                clearPresetChanges={clearPresetChanges}
                onArchive={() => handleArchive(viewId)}
              />
              {viewType === "customerList" && listPreset && (
                <CustomersHome
                  preset={listPreset}
                  updatePreset={(newPreset) => {
                    updateListPreset(viewId, newPreset);
                  }}
                />
              )}
              {viewType === "customerMap" && mapPreset && (
                <CustomerMapView
                  preset={mapPreset}
                  updatePreset={(newPreset) => {
                    updateMapPreset(viewId, newPreset);
                  }}
                />
              )}
              {viewType === "customerAnalytics" && analyticsPreset && (
                <CustomerAnalyticsView
                  preset={analyticsPreset}
                  updatePreset={(newPreset) => {
                    updateAnalyticsPreset(viewId, newPreset);
                  }}
                />
              )}
            </div>
          );
        }}
      </QuerySkeleton>
    </div>
  );
};

const getTemplateViewType = (viewId?: ViewIdOrTemplate): SavedViewType => {
  switch (viewId) {
    case "new-list":
      return "customerList";
    case "new-map":
      return "customerMap";
    case "new-customer-analytics":
      return "customerAnalytics";
    default:
      return "customerList";
  }
};

export type SaveViewOptions = {
  title: string;
  description: string;
  isPrivate: boolean;
  createNew?: boolean;
  onError?: (e: SaveViewError | CommonErrors) => void;
};
