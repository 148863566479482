import { Card, Header4 } from "@dash/page";
import { useTheme } from "@emotion/react";
import React from "react";

export const BaseFulfillmentCard: React.FunctionComponent<{
  header: React.ReactNode;
  children: React.ReactNode;
  actions?: React.ReactNode;
  status?: React.ReactNode;
}> = (props) => {
  const theme = useTheme();

  return (
    <Card
      variant="secondary"
      css={{
        minWidth: "280px",
        maxWidth: "400px",
        flexGrow: 1,
      }}
    >
      <div css={{ display: "flex" }}>
        <Header4>{props.header}</Header4>
        <div css={{ marginLeft: "auto" }}>{props.status}</div>
      </div>
      {props.children}
      {props.actions && <div>{props.actions}</div>}
    </Card>
  );
};
