import { Dialog } from "@dash/dialog";
import { useTheme } from "@emotion/react";
import { useState } from "react";

import { ChangeRangeButton } from "./ChangeRangeButton";

type Props = {
  header: string;
  onClose: () => void;
  year: number;
  month: number;
  onDateChange: (year: number, month: number) => void;
  checkIsDisabled: (year: number, month: number) => boolean;
};

export const MonthSelectDialog: React.FC<Props> = ({ header, onClose, month, year, onDateChange, checkIsDisabled }) => {
  const theme = useTheme();

  const [selectedYear, setSelectedYear] = useState(year);

  const isButtonForwardDisabled = selectedYear === new Date().getFullYear();

  return (
    <Dialog header={header} onClose={onClose}>
      <div css={{ display: "flex", flexDirection: "column", gap: "1rem", alignItems: "center" }}>
        <div css={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <ChangeRangeButton onClick={() => setSelectedYear(selectedYear - 1)} />
          <span css={{ color: theme.colors.primary, fontWeight: 700 }}>{selectedYear}</span>
          <ChangeRangeButton
            onClick={() => setSelectedYear(selectedYear + 1)}
            isForward
            isDisabled={isButtonForwardDisabled}
          />
        </div>
        <div css={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "0.5rem" }}>
          {Array.from({ length: 12 }, (_, i) => i + 1).map((monthNumber) => {
            const isButtonDisabled = checkIsDisabled(selectedYear, monthNumber);

            const isActive = monthNumber === month && selectedYear === year;

            return (
              <button
                key={`${monthNumber}-${selectedYear}`}
                css={{
                  padding: "0.5rem",
                  borderRadius: theme.smallBorderRadius,
                  border: "none",
                  background: isActive ? theme.colors.primary : "none",
                  cursor: isButtonDisabled ? "auto" : "pointer",
                  textAlign: "center",
                  color: isActive ? "white" : theme.colors.primary,
                  opacity: isButtonDisabled ? 0.5 : 1,
                  transition: "background 0.2s",
                  "&:hover": {
                    background: isButtonDisabled ? "none" : isActive ? theme.colors.primary : theme.colors.primaryLight,
                  },
                }}
                disabled={isButtonDisabled}
                onClick={() => onDateChange(selectedYear, monthNumber)}
              >
                {months[monthNumber - 1]}
              </button>
            );
          })}
        </div>
      </div>
    </Dialog>
  );
};

const months = [
  "Styczeń",
  "Luty",
  "Marzec",
  "Kwiecień",
  "Maj",
  "Czerwiec",
  "Lipiec",
  "Sierpień",
  "Wrzesień",
  "Październik",
  "Listopad",
  "Grudzień",
];
