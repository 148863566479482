import { useDeviceType } from "@dash/mq";
import { RowCellContent, Table, TableBody, TableHeadColumn } from "@dash/table";
import { useTheme } from "@emotion/react";

import { RegionTarget } from "@megaron/crm-contracts";
import { Month } from "@megaron/serializers";

type Props = {
  regionTargets: RegionTarget[];
  isLoading: boolean;
};

export const ProfitTargetsTable: React.FC<Props> = ({ regionTargets, isLoading }) => {
  const { isMobile } = useDeviceType();

  const theme = useTheme();

  const months = Object.keys(regionTargets[0]?.targets);

  const tableColumns: TableHeadColumn<string>[] = [
    { label: "Nazwa regionu", isSortable: false },
    ...months.map(
      (month) =>
        ({
          isSortable: false,
          label: month,
        } as const),
    ),
  ];

  const getTableRowCellsContent = (targetRow: RegionTarget): RowCellContent[] => {
    return [
      {
        cellCss: {
          color: theme.colors.primary,
        },
        element: targetRow.regionName,
      },
      ...months.map((month) => ({
        cellCss: {
          color: "black",
        },
        element: targetRow.targets[month as Month]?.toFixed(2) ?? "-",
      })),
    ];
  };

  return (
    <div css={{ overflow: "auto", margin: "0 -2rem 1rem" }}>
      <Table
        columns={tableColumns}
        css={{ maxWidth: "60rem", minWidth: isMobile ? "100%" : "unset", padding: "0 2rem" }}
      >
        <TableBody
          isLoading={isLoading}
          rows={regionTargets.map((regionTarget) => ({
            uuid: regionTarget.regionName,
            cellsContent: getTableRowCellsContent(regionTarget),
            css: {
              "td:first-child": {
                padding: "0.75rem",
              },
            },
          }))}
        />
      </Table>
    </div>
  );
};
