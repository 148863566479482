import { Uuid, UuidResourceParam, uuidSerializer } from "@legacy-megarax/common";
import { BaseResource, ResourceActionV2 } from "@legacy-megarax/rest-resource";
import { MSerializers } from "@legacy-megarax/serializers";

import { saveTemplateInputSerializer } from "./inputs";
import list from "./list";

export interface ChecklistTemplateDetail {
  uuid: Uuid;
  name: string;
  tags: string[];
  tasks: {
    uuid: Uuid;
    label: string;
    priority: number;
  }[];
}

export const checklistTemplateDetailSerializer = MSerializers.object<ChecklistTemplateDetail>({
  uuid: uuidSerializer,
  name: MSerializers.string,
  tags: MSerializers.array(MSerializers.string),
  tasks: MSerializers.array(
    MSerializers.object<{ label: string; uuid: Uuid; priority: number }>({
      label: MSerializers.string,
      uuid: uuidSerializer,
      priority: MSerializers.integer,
    }),
  ),
});

export const checklistTemplateResource = BaseResource(["crm", "checklistTemplate"], {
  list,
  byUuid: UuidResourceParam("uuid", "uuid", {
    save: ResourceActionV2({
      name: "save",
      method: "post",
      requestBodySerializer: saveTemplateInputSerializer,
      responseErrorSerializer: MSerializers.stringOneOf("TagAlreadyExists"),
    }),
    retrieve: ResourceActionV2({
      name: "retrieve",
      method: "get",
      responseValueSerializer: checklistTemplateDetailSerializer,
      responseErrorSerializer: MSerializers.stringOneOf("ChecklistTemplateNotFound"),
    }),
  }),
});
