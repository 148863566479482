import { Chip, InputBase, MenuItem, Select } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

type Props<TValue> = {
  className?: string;
  label: string;
  value: TValue | null;
  options: {
    label: string;
    value: TValue;
  }[];
  onChange: (value: TValue | null) => void;
  style?: {
    opaque?: boolean;
  };
};

export const SelectChip = <TValue extends string | number>({
  children,
  value,
  className,
  label,
  options,
  onChange,
  style,
}: React.PropsWithChildren<Props<TValue>>): React.ReactElement<any> => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);

  const isSelected = value !== null;

  const selectedOption = options.find((o) => o.value === value) ?? null;

  const onDelete = () => {
    onChange(null);
  };

  return (
    <div className={[classes.container, className].join(" ")}>
      <Select
        open={isOpen}
        className={classes.select}
        value={value ?? ""}
        inputProps={{ hidden: true }}
        input={<InputBase />}
        onClose={() => setIsOpen(false)}
        onChange={(e) => onChange(e.target.value as TValue)}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <Chip
        className={[classes.chip, style?.opaque && !isSelected ? classes.opaque : ""].join(" ")}
        onClick={() => setIsOpen(!isOpen)}
        clickable
        label={selectedOption?.label ?? label}
        color={isSelected ? "primary" : "default"}
        onDelete={isSelected ? onDelete : undefined}
      ></Chip>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  chip: {},
  opaque: {
    backgroundColor: "#ededed",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  container: {
    display: "inline-block",
    verticalAlign: "middle",
  },
  select: {
    visibility: "hidden",
    width: 0,
    height: 0,
    // opacity: 0,
  },
}));
