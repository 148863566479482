import { useDeviceType } from "@dash/mq";
import { useTheme } from "@emotion/react";
import React, { useContext, useState } from "react";
import { MdCheck, MdClose, MdOutlineDrafts, MdOutlineMarkEmailUnread } from "react-icons/md";
import tinycolor from "tinycolor2";

import { InvoiceThreadDoc, Participant } from "@megaron/invoices-contracts";
import { useClientManager } from "@megaron/react-clients";

import { ThreadStateContext } from "../threadStateContext";
import { warningColor } from "./AllThreadsTableBody";

type Props = {
  remainingDays: number;
  thread: InvoiceThreadDoc;
  userData: Participant | undefined;
};

export const ThreadStatusTile: React.FC<Props> = ({ remainingDays, thread, userData }) => {
  const theme = useTheme();

  const isParticipant = !!userData;
  const isSeen = !!userData?.isRead;
  const isThreadApproved = thread.status === "approved";
  const isThreadClosed = thread.status === "closed";

  const { isMobile } = useDeviceType();

  const [isHovered, setIsHovered] = useState(false);

  const { storeThread } = useContext(ThreadStateContext);

  const readMutation = useClientManager("invoices").setIsRead().useMutation();

  const isReadChangeable = !isThreadClosed && !isThreadApproved && isParticipant;

  const onMutate = () => {
    if (!isReadChangeable) {
      return;
    }

    const updateThread = (isSeen: boolean) => {
      const updatedThread = {
        ...thread,
        uuid: thread.threadUuid,
        version: thread.version + 1,
        participants: thread.participants.map((participant) => {
          if (participant.user === userData.user) {
            return { ...participant, isRead: isSeen };
          }

          return participant;
        }),
      };
      storeThread(updatedThread);
    };

    updateThread(!isSeen);

    readMutation.mutate(
      { isRead: !isSeen, threadUuid: thread.threadUuid },
      {
        onError: () => {
          updateThread(isSeen);
        },
      },
    );
  };

  const getTileStyles = () => {
    if (isThreadClosed) {
      return { background: theme.colors.border, color: "black" };
    }

    if (isThreadApproved) {
      return { background: theme.colors.success, color: "white" };
    }

    if (remainingDays <= 0) {
      return {
        background: !isSeen && isParticipant ? theme.colors.danger : theme.colors.dangerBackground,
        color: !isSeen && isParticipant ? "white" : theme.colors.danger,
        "&:hover": !isMobile
          ? {
              background: isParticipant
                ? isSeen
                  ? theme.colors.danger
                  : theme.colors.dangerBackground
                : theme.colors.dangerBackground,
              color: isParticipant ? (isSeen ? "white" : theme.colors.danger) : theme.colors.danger,
            }
          : undefined,
      };
    }

    if (remainingDays <= 3) {
      return {
        background: !isSeen && isParticipant ? warningColor : `${tinycolor(warningColor).setAlpha(0.1)}`,
        color: !isSeen && isParticipant ? "white" : warningColor,
        "&:hover": !isMobile
          ? {
              background: isParticipant
                ? isSeen
                  ? warningColor
                  : `${tinycolor(warningColor).setAlpha(0.1)}`
                : `${tinycolor(warningColor).setAlpha(0.1)}`,
              color: isParticipant ? (isSeen ? "white" : warningColor) : warningColor,
            }
          : undefined,
      };
    }

    if (!isSeen && isParticipant) {
      return {
        background: theme.colors.primary,
        color: "white",
        "&:hover": !isMobile ? { background: theme.colors.primaryLight, color: theme.colors.primary } : undefined,
      };
    }

    if (isSeen && isParticipant) {
      return {
        background: theme.colors.primaryLight,
        color: theme.colors.primary,
        "&:hover": !isMobile ? { background: theme.colors.primary, color: "white" } : undefined,
      };
    }

    return { background: theme.colors.primaryLight, color: theme.colors.primary };
  };

  const tileStyles = getTileStyles();

  return (
    <div css={{ height: "100%", width: "2rem" }}>
      <button
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onMutate}
        css={{
          width: "2rem",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          border: "none",
          cursor: isReadChangeable ? "pointer" : "auto",
          inset: 0,
          transition: "background-color 0.2s, color 0.2s",
          ...tileStyles,
        }}
      >
        {isThreadClosed && <MdClose size={14} />}
        {isThreadApproved && <MdCheck size={14} />}
        {!isSeen &&
          isParticipant &&
          !isThreadApproved &&
          !isThreadClosed &&
          (isHovered && !isMobile ? <MdOutlineDrafts size={14} /> : <MdOutlineMarkEmailUnread size={14} />)}
        {isSeen &&
          isParticipant &&
          !isThreadApproved &&
          !isThreadClosed &&
          (isHovered && !isMobile ? <MdOutlineMarkEmailUnread size={14} /> : <MdOutlineDrafts size={14} />)}
      </button>
    </div>
  );
};
