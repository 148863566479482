import {
  ExtractValidatorError,
  identityValidator,
  objectValidator,
  ObjectValidatorSchema,
  requiredValidator,
} from "@legacy-megarax/validators";
import Decimal from "decimal.js";

import { AttachmentUpload, Invoice, NewThreadInput } from "@megaron/invoices-contracts";
import { newUuid, Uuid } from "@megaron/uuid";

export interface InvoiceFormValue {
  invoiceNumber: string | undefined;
  total: Decimal | undefined;
  currency: Invoice["currency"];
  issuer: string | undefined;
  issuerTaxId: string;
  issueDate: Date;
  dueDate: Date | null;
  receivedDate: Date | null;
  isDigital: boolean;
}

export interface FormValue {
  uuid: Uuid;
  title: string;
  invoice: InvoiceFormValue;
  attachments: AttachmentUpload[];
}

export const initialFormState = (): FormValue => {
  return {
    uuid: newUuid(),
    title: "",
    invoice: {
      invoiceNumber: undefined,
      issuer: undefined,
      issuerTaxId: "",
      issueDate: new Date(),
      dueDate: null,
      receivedDate: null,
      total: undefined,
      currency: "PLN",
      isDigital: false,
    },
    attachments: [],
  };
};

export const invoiceValidatorSchema = ObjectValidatorSchema<InvoiceFormValue, NewThreadInput["invoice"]>()({
  invoiceNumber: requiredValidator(),
  issuer: requiredValidator(),
  issueDate: requiredValidator(),
  dueDate: identityValidator(),
  total: requiredValidator(),
  currency: requiredValidator(),
  isDigital: identityValidator(),
  receivedDate: requiredValidator(),
  issuerTaxId: identityValidator(),
});

export const validatorSchema = ObjectValidatorSchema<FormValue, NewThreadInput>()({
  title: requiredValidator(),
  uuid: identityValidator(),
  invoice: objectValidator(invoiceValidatorSchema),
  attachments: identityValidator(),
});

export const errorMessages = {
  required: "To pole jest wymagane",
  gtError: "Kwota musi być wyższa od zera.",
  ltError: "",
};

export const getErrorMessage = (failure: "required" | "gtError" | "ltError" | undefined) =>
  failure ? errorMessages[failure] : undefined;

export const formValidator = objectValidator(validatorSchema);
export type FormErrors = ExtractValidatorError<typeof formValidator>;
