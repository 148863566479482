import { Uuid } from "@legacy-megarax/common";
import { RegionListQueryFilters, regionResource } from "@legacy-megarax/crm-contracts";
import { useResourceProviderV2 } from "@legacy-megarax/react-client";
import { commonErrorsMap, useSnackbarErrorHandler } from "@legacy-megarax/ui-components";
import _ from "lodash";

import { Failure, Ok, Result } from "@megaron/result";

type OnSuccessFunction = (() => void) | (() => Promise<void>);

export const voidifyResult = <TValue, TError>(result: Result<TValue, TError>): Result<void, void> =>
  result.flatMapError(() => Failure()).flatMap(() => Ok());

export const promisifyResult = <TValue, TError>(result: Result<TValue, TError>): Promise<TValue> =>
  new Promise((resolve, reject) => {
    if (result.isFailure) return reject(result.error);
    resolve(result.value);
  });

export const useRegionsResource = () => {
  const regionProvider = useResourceProviderV2(regionResource);

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
  });

  const nameRegion =
    ({ onSuccess }: { onSuccess: OnSuccessFunction }) =>
    (uuid: Uuid, name: string) =>
      regionProvider
        .byUuid(uuid)
        .nameRegion(undefined, { name })
        .mapError(handleError)
        .map(onSuccess)
        .then(voidifyResult);

  const appendMacroregion =
    ({ onSuccess }: { onSuccess: OnSuccessFunction }) =>
    (uuid: Uuid, macroregionUuid: Uuid) =>
      regionProvider
        .byUuid(uuid)
        .appendMacroregion(undefined, { macroregionUuid })
        .mapError(handleError)
        .map(onSuccess)
        .then(voidifyResult);

  const getRegions = (filters: RegionListQueryFilters) => regionProvider.list(filters).mapError(handleError);

  const getRegionsItems = (filters: RegionListQueryFilters) =>
    getRegions(filters)
      .map((result) => result.items)
      .then(promisifyResult);

  const getRegionsDictionary = () =>
    getRegions({ limit: 1000 })
      .map((result) => _.keyBy(result.items, "uuid"))
      .then(promisifyResult);

  return {
    nameRegion,
    appendMacroregion,
    getRegions,
    getRegionsItems,
    getRegionsDictionary,
  };
};
