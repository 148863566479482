import { QuerySkeleton } from "@dash/skeleton";
import { useTheme } from "@emotion/react";
import { addDays } from "date-fns";

import { toDateString } from "@megaron/date-string";
import { useClientManager } from "@megaron/react-clients";

import { DayUpcomingVisits } from "./DayUpcomingVisits";

type Props = {
  userId: number;
};

export const UpcomingVisits: React.FC<Props> = ({ userId }) => {
  const theme = useTheme();

  const todayVisitsQuery = useClientManager("legacyMegarax")
    .visitScheduleQuery()
    .useQuery({ date: toDateString(new Date()), userId });

  const nextDayVisitsQuery = useClientManager("legacyMegarax")
    .visitScheduleQuery()
    .useQuery({ date: toDateString(addDays(new Date(), 1)), userId });

  const hasNoVisits =
    todayVisitsQuery.isSuccess &&
    !todayVisitsQuery.data?.visits?.length &&
    nextDayVisitsQuery.isSuccess &&
    !nextDayVisitsQuery.data?.visits?.length;

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {hasNoVisits && (
        <div css={{ fontSize: "0.875rem", color: theme.colors.secondaryText }}>Brak zaplanowanych wizyt</div>
      )}
      <QuerySkeleton query={todayVisitsQuery} height="100px">
        {(today) => <DayUpcomingVisits label="dzisiaj" visits={today?.visits ?? []} />}
      </QuerySkeleton>

      <QuerySkeleton query={nextDayVisitsQuery} height="100px">
        {(tomorrow) => <DayUpcomingVisits label="jutro" visits={tomorrow?.visits ?? []} />}
      </QuerySkeleton>
    </div>
  );
};
