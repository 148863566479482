import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

type GetOptionKey<T, K extends string | number> = (option: T) => K;
type GetOptionLabel<T> = (option: T) => string | React.ReactNode;

interface Props<T, K extends string | number> {
  values: T[];
  getKey: GetOptionKey<T, K>;
  getLabel: GetOptionLabel<T>;
  actions: { action: (key: K) => void; icon: IconDefinition }[];
}

export const CrudList = <T, K extends string | number>({
  actions,
  values,
  getKey,
  getLabel,
}: Props<T, K>): React.ReactElement<any> => {
  const classes = useStyles();

  return (
    <List className={classes.list} dense>
      {values.map((value) => {
        return (
          <Box key={getKey(value)}>
            <ListItem>
              <ListItemText>{getLabel(value)}</ListItemText>
              <ListItemSecondaryAction>
                {actions.map(({ action, icon }) => {
                  return (
                    <IconButton
                      edge="end"
                      className={classes.detailsButton}
                      onClick={() => action(getKey(value))}
                      size="large"
                    >
                      <FontAwesomeIcon icon={icon} className={classes.icon} fixedWidth />
                    </IconButton>
                  );
                })}
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </Box>
        );
      })}
    </List>
  );
};

const useStyles = makeStyles({
  list: {
    paddingTop: "0",
  },
  detailsButton: {
    padding: "4px 2px",
    marginRight: "8px",
  },
  icon: {
    fontSize: "1rem",
  },
});
