import {
  BaseResource,
  idObjectReferenceSerializer,
  ResourceAction,
  ResourceActionV2,
} from "@legacy-megarax/rest-resource";
import { Serializers } from "@legacy-megarax/serializers";

import { permissions } from "./PermissionList";

const authorizedUsersAction = ResourceActionV2({
  name: "authorizedUsers",
  method: "get",
  querySerializer: Serializers.object({
    permission: Serializers.stringOneOf(...permissions),
  }),
  responseValueSerializer: Serializers.array(idObjectReferenceSerializer),
});

export const permissionResource = BaseResource(["iam", "permissions"], {
  authorizedUsers: authorizedUsersAction,
});
