import { useDeviceType } from "@dash/mq";
import { useTheme } from "@emotion/react";
import React from "react";

type Props = {
  children?: React.ReactNode;
  className?: string;
  isVertical?: boolean;
};

export const ButtonGroup: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const { mq } = useDeviceType();

  return (
    <div
      className={props.className}
      css={{
        display: "flex",
        gap: "0.5rem",
        flexDirection: props.isVertical ?? true ? "column" : "row",
        [mq("tablet")]: {
          flexDirection: props.isVertical ? "column" : "row",
        },
      }}
    >
      {props.children}
    </div>
  );
};
