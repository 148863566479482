import { Dialog, useDialogRoute } from "@dash/dialog";
import { Button } from "@dash/form";
import { css, useTheme } from "@emotion/react";
import React from "react";
import { MdCheck } from "react-icons/md";

type Props<T> = {
  label: string;

  children: React.ReactNode;
  items: T[];
  getName: (item: T) => React.ReactNode;
  dialogPath: string;
};

export const EntitySelect = <T,>(props: Props<T>) => {
  const theme = useTheme();

  const dialog = useDialogRoute(props.dialogPath, ({ onClose }) => (
    <Dialog
      header={props.label}
      onClose={onClose}
      footer={
        <Button icon={<MdCheck />} css={{ marginLeft: "auto" }} onClick={onClose}>
          Ok
        </Button>
      }
    >
      {props.children}
    </Dialog>
  ));

  const styles = css({
    maxWidth: "500px",
    border: "none",
    padding: "0.5rem 0.75rem",
    fontSize: "1rem",
    borderRadius: theme.smallBorderRadius,
    backgroundColor: theme.colors.primaryLight,
    minHeight: "2rem",
    outline: dialog.isOpen ? `1px solid ${theme.colors.primary}` : undefined,
  });

  const text = props.items.map((item) => props.getName(item) ?? item).join(", ");

  return (
    <>
      {dialog.element}
      <div css={{ display: "flex", flexDirection: "column", padding: "0.5rem 0" }} onClick={dialog.open}>
        <label
          css={{
            color: theme.colors.primary,
            fontSize: "0.875rem",
            fontFamily: theme.displayFontFamily,
            marginBottom: "0.25rem",
          }}
        >
          {props.label}
        </label>

        <div css={styles}>{props.items.length ? text : <span css={{ opacity: 0.5 }}>Wybierz...</span>}</div>
      </div>
    </>
  );
};
