import { Uuid, UuidResourceParam, uuidSerializer } from "@legacy-megarax/common";
import { BaseResource, ResourceActionV2 } from "@legacy-megarax/rest-resource";
import { MSerializers } from "@legacy-megarax/serializers";

import { customerBasicSerializer } from "./customerBasic";
import { customerDetailSerializer } from "./customerDetail";
import {
  assignToChainInputSerializer,
  changeStatusInputSerializer,
  designateVisitLocationInputSerializer,
  reassignRegionInputSerializer,
  registerCustomerInputSerializer,
  renameCustomerInputSerialized,
  tagCustomerInputSerializer,
} from "./inputs";

const retrieveByUuidsQueryFiltersSerializer = MSerializers.object<{ uuids: Uuid[] }>({
  uuids: MSerializers.array(uuidSerializer),
});

const listAll = ResourceActionV2({
  name: "listAll",
  method: "get",
  path: "",
  responseValueSerializer: MSerializers.array(customerDetailSerializer),
});

const listByUuids = ResourceActionV2({
  name: "listByUuids",
  method: "get",
  path: "",
  querySerializer: retrieveByUuidsQueryFiltersSerializer,
  responseValueSerializer: MSerializers.array(customerBasicSerializer),
});

export const customerResource = BaseResource(["crm", "customers"], {
  allCustomers: ResourceActionV2({
    name: "allCustomers",
    method: "get",
    responseValueSerializer: MSerializers.array(customerDetailSerializer),
  }),

  bulkRetrieve: ResourceActionV2({
    name: "bulkRetrieve",
    method: "post",
    requestBodySerializer: MSerializers.array(uuidSerializer),
    responseValueSerializer: MSerializers.array(customerDetailSerializer.nullable()),
  }),

  bulkRegisterFromCsv: ResourceActionV2({
    name: "bulkRegisterFromCsv",
    method: "post",
    requestBodySerializer: MSerializers.object<{ csv: string }>({ csv: MSerializers.string }),
  }),

  byUuid: UuidResourceParam("uuid", "uuid", {
    registerCustomer: ResourceActionV2({
      name: "registerCustomer",
      method: "post",
      path: "registerCustomer",
      requestBodySerializer: registerCustomerInputSerializer,
      responseErrorSerializer: MSerializers.identity<"CustomerAlreadyRegistered" | "CustomerWithThatNameExists">(),
    }),
    reassignRegion: ResourceActionV2({
      name: "reassignRegion",
      method: "put",
      path: "reassignRegion",
      requestBodySerializer: reassignRegionInputSerializer,
      responseErrorSerializer: MSerializers.identity<"CustomerNotFound">(),
    }),
    designateVisitLocation: ResourceActionV2({
      name: "designateVisitLocation",
      method: "post",
      path: "designateVisitLocation",
      requestBodySerializer: designateVisitLocationInputSerializer,
      responseErrorSerializer: MSerializers.identity<"CustomerNotFound">(),
    }),
    assignToChain: ResourceActionV2({
      name: "assignToChain",
      method: "post",
      path: "assignToChain",
      requestBodySerializer: assignToChainInputSerializer,
      responseErrorSerializer: MSerializers.identity<"CustomerNotFound">(),
    }),
    renameCustomer: ResourceActionV2({
      name: "renameCustomer",
      method: "put",
      path: "renameCustomer",
      requestBodySerializer: renameCustomerInputSerialized,
      responseErrorSerializer: MSerializers.identity<"CustomerNotFound" | "CustomerWithThatNameExists">(),
    }),
    tagCustomer: ResourceActionV2({
      name: "tagCustomer",
      method: "put",
      path: "tagCustomer",
      requestBodySerializer: tagCustomerInputSerializer,
      responseErrorSerializer: MSerializers.identity<"CustomerNotFound" | "TagAlreadyAssigned">(),
    }),
    untagCustomer: ResourceActionV2({
      name: "untagCustomer",
      method: "put",
      path: "untagCustomer",
      requestBodySerializer: tagCustomerInputSerializer,
      responseErrorSerializer: MSerializers.identity<"CustomerNotFound" | "TagNotAssigned">(),
    }),
    changeStatus: ResourceActionV2({
      name: "changeStatus",
      method: "put",
      requestBodySerializer: changeStatusInputSerializer,
      responseErrorSerializer: MSerializers.stringOneOf("CustomerNotFound"),
    }),
    retrieve: ResourceActionV2({
      name: "retrieve",
      method: "get",
      path: "",
      responseValueSerializer: customerDetailSerializer,
      responseErrorSerializer: MSerializers.identity<any>(),
    }),
  }),
});
