import { css, keyframes, useTheme } from "@emotion/react";

import type { JSX } from "react";

type Props = {
  width?: string;
  height?: string;
  className?: string;
  variant?: "avatarWithLabel" | "avatarGroup";
};

export const Skeleton = (props: Props): JSX.Element => {
  const theme = useTheme();

  const pulse = keyframes`
    0% {
      opacity: 0.6;
    }

    100% {
      opacity: 1;
    }
  }
  `;

  const skeletonCSS = css({
    display: "inline-block",
    backgroundColor: theme.colors.skeleton,
    backgroundRepeat: "no-repeat",
    borderRadius: theme.largeBorderRadius,
    animation: `${pulse} 1s infinite alternate`,
  });

  if (props.variant === "avatarWithLabel")
    return (
      <div css={{ display: "flex", gap: "4px", padding: "0 8px", alignItems: "center" }}>
        <div
          className={props.className}
          css={[skeletonCSS, { borderRadius: "50%", width: props.width ?? "24px", height: props.height ?? "24px" }]}
        />
        <div className={props.className} css={[skeletonCSS, { borderRadius: "3px", width: "100px", height: "16px" }]} />
      </div>
    );

  if (props.variant === "avatarGroup") {
    return (
      <div css={{ display: "flex", alignItems: "center" }}>
        {[0, 1, 2].map((i) => (
          <div
            key={i}
            className={props.className}
            css={[
              skeletonCSS,
              { borderRadius: "50%", width: props.width ?? "30px", height: props.height ?? "30px", marginLeft: "-8px" },
            ]}
          />
        ))}
      </div>
    );
  }

  return (
    <div
      className={props.className}
      css={[skeletonCSS, { width: props.width ?? "100%", height: props.height ?? "300px" }]}
    />
  );
};
