import { toDateString } from "@legacy-megarax/common";
import { workDayResource } from "@legacy-megarax/crm-contracts";
import { useResourceProviderV2 } from "@legacy-megarax/react-client";
import { useQuery } from "react-query";

export const useWorkDay = (date: Date, userId: number) => {
  const workDayProvider = useResourceProviderV2(workDayResource);

  const {
    data: workDay,
    isFetching,
    refetch,
  } = useQuery(["userWorkDay", date, userId], () =>
    workDayProvider
      .byUserId(userId!)
      .byDate(toDateString(date))
      .retrieve()
      .then((r) => r.assertOk().value),
  );

  return {
    workDay,
    isLoading: isFetching,
    refetch,
  };
};
