import { BaseResource, NestedResourceNode, ResourceActionV2, UuidResourceParam } from "@legacy-megarax/rest-resource";
import { MSerializers } from "@legacy-megarax/serializers";

import {
  acknowledgeMessagesErrors,
  postMessageCommandErrors,
  subscribeToThreadErrors,
  unsubscribeFromThreadErrors,
} from "./errors";
import { postMessageInputSerializer } from "./inputs";
import { mySubscriptionSerializer } from "./mySubscription";
import { threadDtoSerializer } from "./threadDto";
import { ThreadIdentifierResourceParam } from "./threadIdentifier";

const myFeedAction = ResourceActionV2({
  name: "myFeed",
  method: "get",
  path: "myFeed",
  responseValueSerializer: MSerializers.array(mySubscriptionSerializer),
  querySerializer: MSerializers.object({
    limit: MSerializers.integer,
    offset: MSerializers.integer,
  }),
});

export const chatThreadResource = BaseResource(["chat", "threads"], {
  byThreadIdentifier: ThreadIdentifierResourceParam("identifier", "identifier", {
    retrieve: ResourceActionV2({
      name: "retrieve",
      method: "get",
      path: "",
      responseValueSerializer: threadDtoSerializer,
    }),
    subscribe: ResourceActionV2({
      name: "subscribe",
      method: "post",
      path: "subscribe",
      responseErrorSerializer: MSerializers.stringOneOf(...subscribeToThreadErrors),
    }),
    unsubscribe: ResourceActionV2({
      name: "unsubscribe",
      method: "post",
      path: "unsubscribe",
      responseErrorSerializer: MSerializers.stringOneOf(...unsubscribeFromThreadErrors),
    }),
    acknowledgeMessages: ResourceActionV2({
      name: "acknowledgeMessages",
      method: "post",
      path: "acknowledgeMessages",
      requestBodySerializer: MSerializers.object({
        readCount: MSerializers.integer,
      }),
      responseErrorSerializer: MSerializers.stringOneOf(...acknowledgeMessagesErrors),
    }),

    messages: NestedResourceNode("messages", {
      post: ResourceActionV2({
        name: "post",
        method: "post",
        path: "",
        requestBodySerializer: postMessageInputSerializer,
        responseErrorSerializer: MSerializers.stringOneOf(...postMessageCommandErrors),
      }),
    }),
  }),

  myFeed: myFeedAction,
});
