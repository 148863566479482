import { AmqpTopic } from "@legacy-megarax/amqp";
import { Uuid, uuidSerializer as mUuidSerializer } from "@legacy-megarax/common";
import { MSerializers } from "@legacy-megarax/serializers";

import { PubsubTopic } from "@megaron/pubsub-contracts";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { Gtin, gtinSerializer } from "../common";

export type TradeItemEventMessage = {
  uuid: Uuid;
  gtin: Gtin;
  name: string;
};

export const tradeItemEventMessageSerializer = MSerializers.object<TradeItemEventMessage>({
  uuid: mUuidSerializer,
  gtin: gtinSerializer,
  name: MSerializers.string,
});

export const tradeItemEventAmqpTopic: AmqpTopic<TradeItemEventMessage> = {
  key: "bc.tradeitem.event",
  serializer: tradeItemEventMessageSerializer,
};

export type OrderPlacedMessage = {
  uuid: Uuid;
};

export const orderPlacedMessageSerializer = MSerializers.object<OrderPlacedMessage>({
  uuid: mUuidSerializer,
});

export const orderPlacedAmqpTopic: AmqpTopic<OrderPlacedMessage> = {
  key: "bc.order.orderPlaced",
  serializer: orderPlacedMessageSerializer,
};

export type OrdersChangedMessage = {
  uuids: Uuid[];
  customerUuids: Uuid[];
};

export const ordersChangedMessageSerializer = MSerializers.object<OrdersChangedMessage>({
  uuids: MSerializers.array(mUuidSerializer),
  customerUuids: MSerializers.array(mUuidSerializer),
});

export const ordersChangedAmqpTopic: AmqpTopic<OrdersChangedMessage> = {
  key: "bc.order.ordersChanged",
  serializer: ordersChangedMessageSerializer,
};

export const ordersChangedTopic = PubsubTopic({
  name: "legacy-bc-orders-changed",
  serializer: Serializers.object<OrdersChangedMessage>({
    uuids: uuidSerializer.array(),
    customerUuids: uuidSerializer.array(),
  }),
});
