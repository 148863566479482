import { MSerializers } from "@legacy-megarax/serializers";

export type CommentDto = {
  uuid: string;
  commenterId: number;
  content: string;
  commentedAt: Date;
};

export const commentDtoSerializer = MSerializers.object<CommentDto>({
  uuid: MSerializers.uuid(),
  commentedAt: MSerializers.datetime,
  commenterId: MSerializers.integer,
  content: MSerializers.string,
});
