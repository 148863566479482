import {
  PendingPriceListProposalTaskIdentifier,
  PriceListReference,
  priceListResource,
} from "@legacy-megarax/crm-contracts";
import { useResourceProviderV2 } from "@legacy-megarax/react-client";
import { useAsyncLoad } from "@legacy-megarax/react-utils";
import { commonErrorsMap, ResultSkeletonLoader, useSnackbarErrorHandler } from "@legacy-megarax/ui-components";
import { Button, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { FaArrowRight, FaDollarSign } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom-v5-compat";

import { BaseTaskGroupCard } from "./BaseTaskGroupCard";

interface Props {
  identifiers: PendingPriceListProposalTaskIdentifier[];
  time: Date;
}

type ProposalStatus = {
  priceList: PriceListReference;
  count: number;
};

const identifiersToStatuses = (identifiers: PendingPriceListProposalTaskIdentifier[]): ProposalStatus[] =>
  _(identifiers)
    .groupBy((t) => t.priceListUuid)
    .mapValues((tasks) => tasks.length)
    .entries()
    .map<ProposalStatus>(([uuid, count]) => ({
      priceList: { uuid: uuid },
      count,
    }))
    .value();

export const PendingPriceListProposalTasksCard: React.FunctionComponent<Props> = ({ identifiers, time }) => {
  const statuses = identifiersToStatuses(identifiers);

  const navigate = useNavigate();

  const goToPriceLists = () => {
    navigate(`/crm/pos/cenniki`);
  };

  return (
    <BaseTaskGroupCard
      icon={<FaDollarSign />}
      time={time}
      title="Propozycje zmian cen"
      actions={
        <Button size="small" color="primary" variant="text" onClick={goToPriceLists}>
          Przejdź do cenników
        </Button>
      }
    >
      <List dense>
        {statuses.map((status) => (
          <PriceListProposalStatusItem
            pendingProposalCount={status.count}
            uuid={status.priceList.uuid}
            key={status.priceList.uuid}
          />
        ))}
      </List>
    </BaseTaskGroupCard>
  );
};

const PriceListProposalStatusItem: React.FC<{
  pendingProposalCount: number;
  uuid: string;
}> = ({ uuid, pendingProposalCount }) => {
  const priceListProvider = useResourceProviderV2(priceListResource);

  const handleError = useSnackbarErrorHandler({ ...commonErrorsMap });

  const { value: priceListNameResult } = useAsyncLoad(
    () =>
      priceListProvider
        .byUuid(uuid)
        .retrieve()
        .map((res) => res.name)
        .mapError(handleError),
    [],
  );

  return (
    <ListItem button component={Link} to={`/crm/pos/cenniki/${uuid}/propozycje`}>
      <ListItemIcon>
        <FaArrowRight />
      </ListItemIcon>
      <ListItemText
        primary={
          <ResultSkeletonLoader
            result={priceListNameResult}
            allowErrors={true}
            component={(res) => <Typography variant="inherit">{res.isOk ? res.value : uuid}</Typography>}
          />
        }
        secondary={`Oczekujące propozycje (${pendingProposalCount})`}
      />
    </ListItem>
  );
};
