import { Gtin } from "@legacy-megarax/crm-contracts";
import {
  decimalValidator,
  gtinValidator,
  identityValidator,
  objectValidator,
  ObjectValidatorSchema,
} from "@legacy-megarax/validators";
import Decimal from "decimal.js";

export interface InitialProposalInput {
  activeFrom: Date | null;
  netPrice: Decimal | undefined;
  gtin: Gtin;
  comment: string;
}

export interface ProposalInput {
  activeFrom: Date | null;
  netPrice: Decimal;
  gtin: Gtin;
  comment: string;
}

const activeFrom = new Date();
activeFrom.setDate(activeFrom.getDate() + 1);

export const INITIAL_VALUE: InitialProposalInput = {
  activeFrom: activeFrom,
  netPrice: undefined,
  gtin: "" as Gtin,
  comment: "",
};

export const validatorSchema = ObjectValidatorSchema<InitialProposalInput, ProposalInput>()({
  activeFrom: identityValidator(),
  netPrice: decimalValidator({ greaterThan: 0 }),
  gtin: gtinValidator(),
  comment: identityValidator(),
});

const errorMessages = {
  required: "To pole jest wymagane.",
  gtError: "Cena musi być wyższa od zera.",
  invalidGtin: "Gtin jest nieprawidłowy",
};

export const getErrorMessage = (failure: "required" | "gtError" | "invalidGtin" | undefined) =>
  failure ? errorMessages[failure] : undefined;

export const formValidator = objectValidator(validatorSchema);
