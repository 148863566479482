import { newUuid, Uuid } from "@legacy-megarax/common";
import { WorkDayDto } from "@legacy-megarax/crm-contracts";
import { LoadingBar } from "@legacy-megarax/ui-components";
import { AccessTime } from "@mui/icons-material";
import { Box, Button, Card, CardActions, CardHeader, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {
  className?: string;
  onEditStartDialogOpen: () => void;
  onEditEndDialogOpen: () => void;
  isLoading: boolean;
  myWorkDay: WorkDayDto;
  openBreakDialog: (uuid: Uuid) => void;
  isDisabled: boolean;
}

export const WorkTimeCard: React.FunctionComponent<Props> = ({
  className,
  onEditStartDialogOpen,
  onEditEndDialogOpen,
  isLoading,
  myWorkDay,
  openBreakDialog,
  isDisabled,
}) => {
  const classes = useStyles();

  return (
    <Card className={className}>
      <LoadingBar loading={isLoading} />
      <CardHeader avatar={<AccessTime />} title={<Typography variant="h6">Czas pracy</Typography>}></CardHeader>

      <Box px={2} py={1} display="flex">
        <Box>
          <Typography variant="caption" color="textSecondary">
            Początek
          </Typography>
          <Typography variant="body1">
            <span className={classes.time}>{myWorkDay.startTime?.toLocaleTimeString() ?? "-"}</span>
            {myWorkDay.startOdometer && `${myWorkDay.startOdometer} km`}
          </Typography>
        </Box>
        <Box className={classes.editButton}>
          <Button disabled={isDisabled} onClick={onEditStartDialogOpen}>
            Edytuj
          </Button>
        </Box>
      </Box>
      <Divider />

      {myWorkDay.breaks.map((breakDto) => (
        <>
          <Box px={2} py={1} display="flex">
            <Box>
              <Typography variant="caption" color="textSecondary">
                Przerwa
              </Typography>
              <Typography variant="body1">
                <span className={classes.time}>od {breakDto.startTime.toLocaleTimeString()}</span>
                {breakDto.startOdometer && `${breakDto.startOdometer} km`}
              </Typography>
              <Typography variant="body1">
                <span className={classes.time}>do {breakDto.endTime?.toLocaleTimeString() ?? "-"}</span>
                {breakDto.endOdometer && `${breakDto.endOdometer} km`}
              </Typography>
            </Box>
            <Box className={classes.editButton}>
              <Button disabled={isDisabled} onClick={() => openBreakDialog(breakDto.uuid)}>
                Edytuj
              </Button>
            </Box>
          </Box>
          <Divider />
        </>
      ))}

      <Box px={2} py={1} display="flex">
        <Box>
          <Typography variant="caption" color="textSecondary">
            Koniec
          </Typography>
          <Typography variant="body1">
            <span className={classes.time}>{myWorkDay.endTime?.toLocaleTimeString() ?? "-"}</span>
            {myWorkDay.endOdometer && `${myWorkDay.endOdometer} km`}
          </Typography>
        </Box>
        <Box className={classes.editButton}>
          <Button disabled={isDisabled} onClick={onEditEndDialogOpen}>
            Edytuj
          </Button>
        </Box>
      </Box>
      <CardActions>
        <Button disabled={isDisabled} onClick={() => openBreakDialog(newUuid())}>
          Przerwa
        </Button>
      </CardActions>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  time: {
    fontWeight: "bold",
    fontSize: "1.3rem",
    marginRight: "0.5rem",
  },
  editButton: {
    marginLeft: "auto",
  },
}));
