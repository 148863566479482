import { Uuid, uuidSerializer } from "@legacy-megarax/common";
import { MSerializers } from "@legacy-megarax/serializers";

export interface RegionSimple {
  uuid: Uuid;
  name: string;
  macroregionUuid: Uuid | null;
  macroregionName: string | null;
}

export const regionSimpleSerializer = MSerializers.object<RegionSimple>({
  uuid: uuidSerializer,
  name: MSerializers.string,
  macroregionUuid: uuidSerializer.nullable(),
  macroregionName: MSerializers.string.nullable(),
});
