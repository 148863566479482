import { dateStringSerializer } from "@megaron/date-string";
import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";

export const userWorkTimeReportDaySerializer = Serializers.object({
  email: Serializers.string,
  breakDurationMinutes: Serializers.integer,
  date: dateStringSerializer,
  endTime: Serializers.datetime.nullable(),
  startTime: Serializers.datetime.nullable(),
  workTimeMinutes: Serializers.integer.nullable(),
  visitCount: Serializers.integer,
  posVisitCount: Serializers.integer,
  interactionCount: Serializers.integer,
});

export type UserWorkTimeReportDay = SerializerValue<typeof userWorkTimeReportDaySerializer>;

export const userWorkTimeReportSerializer = Serializers.object({
  days: Serializers.array(userWorkTimeReportDaySerializer),
  totalWorkTimeMinutes: Serializers.integer,
});

export type UserWorkTimeReport = SerializerValue<typeof userWorkTimeReportSerializer>;

export const legacyActions = {
  workTimeReportQuery: HttpAction({
    path: "/workTimeReportQuery",
    qsSerializer: Serializers.object({
      legacyUserId: Serializers.integer.optional(),
      since: dateStringSerializer,
      until: dateStringSerializer,
    }),
    requiresAuth: true as const,
    valueSerializer: userWorkTimeReportSerializer,
  }),
};
