import { Uuid } from "@legacy-megarax/common";
import React from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom-v5-compat";

export const useVisitNavigation = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const safeToGoBack = React.useRef(false);

  const openVisit = (uuid: Uuid, customerUuid: Uuid | null, isEnded: boolean) => {
    if (!customerUuid) return;

    const path = isEnded
      ? `/crm/customers/id/${customerUuid}?tab=historia`
      : `/crm/customers/id/${customerUuid}/add-interaction?tab=historia&interactionUuid=${uuid}`;
    navigate(path, { state: isEnded ? { scrollTo: `interaction-${uuid}` } : undefined });
    safeToGoBack.current = true;
  };

  const closeVisit = () => {
    if (safeToGoBack.current) {
      navigate(-1);
      safeToGoBack.current = false;
    } else {
      navigate(pathname, { replace: true });
    }
  };

  return {
    openVisit,
    closeVisit,
  };
};

export const useOnVisitClose = (onClose: () => void) => {
  const { pathname } = useLocation();
  const isOpen =
    matchPath(
      {
        path: `${pathname}/wizyta/:uuid`,
      },
      pathname,
    ) !== null;
  const wasOpen = React.useRef(isOpen);

  React.useEffect(() => {
    if (!isOpen && wasOpen.current) onClose();

    wasOpen.current = isOpen;
  }, [pathname]);
};
