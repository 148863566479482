import { ClassNames } from "@emotion/react";
import { Uuid } from "@legacy-megarax/common";
import { Edit, Event } from "@mui/icons-material";
import { Card, CardContent, CardHeader, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React from "react";

export type ActivityListEvent = {
  type: "event";

  uuid: Uuid;
  name: string;
  description: string;

  startTime: Date;
  endTime: Date;
};

interface Props {
  entry: ActivityListEvent;
  onEdit: () => void;
}

const formatTime = (date: Date) =>
  date.toLocaleTimeString(undefined, {
    hour: "numeric",
    minute: "2-digit",
  });

export const EventCard: React.FunctionComponent<Props> = ({ entry, onEdit }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(ClassNames, classes.paper)}>
      <CardHeader
        action={
          <IconButton size="small" onClick={onEdit}>
            <Edit />
          </IconButton>
        }
        avatar={<Event />}
        title={entry.name}
        subheader={`${formatTime(entry.startTime)} - ${formatTime(entry.endTime)}`}
      ></CardHeader>
      {entry.description && (
        <CardContent className={classes.content}>{entry.description}</CardContent>
      )}
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(0, 0, 2),
    background: theme.palette.mode,
  },
  content: {
    whiteSpace: "pre-wrap",
  },
}));
