import { createTheme, DefaultGlobalStyles } from "@dash/default-theme";
import { ToastContextProvider } from "@dash/toast";
import { Theme, ThemeProvider } from "@emotion/react";
import { ConfigContextProvider } from "@megarax/dev-config";
import { NotificationsProvider, ProfilesProvider, WebPushContextProvider } from "@megarax/features";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { QueryClient, QueryClientProvider } from "react-query";

import { IamAuthContextProvider } from "@megaron/auth-react";
import { RegionContextProvider } from "@megaron/region-context";

import { ApiServicesProvider } from "./ApiServicesProvider";
import { AppRouter } from "./AppRouter";
import { useLegacyMegaraxRedirects } from "./useLegacyMegaraxRedirects";

const theme: Theme = createTheme("273");
const defaultTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    primary: `#2F2F2F`,
    primaryLight: `#E7E4EA`,
    background: `#F7F4FA`,
    hover: `#AEABAB`,
    selectedBackground: `#AEABAB`,
  },
};

export const App = () => {
  const posthogKey = process.env.NX_PUBLIC_POSTHOG_KEY;
  useLegacyMegaraxRedirects();

  if (typeof window !== "undefined" && posthogKey) {
    posthog.init(posthogKey, {
      api_host: process.env.NX_PUBLIC_POSTHOG_URL,
      persistence: "memory",
      person_profiles: "always",
      loaded: (posthog) => {
        if (process.env.NODE_ENV === "development") posthog.debug();
      },
    });
  }

  return (
    <PostHogProvider client={posthog}>
      <QueryClientProvider client={new QueryClient()}>
        <ConfigContextProvider>
          <DefaultGlobalStyles />
          <ThemeProvider theme={defaultTheme}>
            <ToastContextProvider>
              <IamAuthContextProvider>
                {(auth) => (
                  <ApiServicesProvider auth={auth}>
                    <RegionContextProvider auth={auth}>
                      <WebPushContextProvider isAuthLoaded={auth.isLoaded}>
                        <ProfilesProvider isAuthLoaded={auth.isLoaded}>
                          <NotificationsProvider isAuthLoaded={auth.isLoaded}>
                            <AppRouter isAuthLoaded={auth.isLoaded} />
                          </NotificationsProvider>
                        </ProfilesProvider>
                      </WebPushContextProvider>
                    </RegionContextProvider>
                  </ApiServicesProvider>
                )}
              </IamAuthContextProvider>
            </ToastContextProvider>
          </ThemeProvider>
        </ConfigContextProvider>
      </QueryClientProvider>
    </PostHogProvider>
  );
};
