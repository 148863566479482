/* eslint-disable @typescript-eslint/no-empty-function */
import { Uuid } from "@legacy-megarax/common";
import {
  SaveAndScheduleRouteInput,
  SaveRouteAsPermanentInput,
  ScheduleRouteInput,
  TradeRouteListQueryFilters,
  tradeRoutesResource,
} from "@legacy-megarax/crm-contracts";
import { useProfilesDictionary } from "@legacy-megarax/iam-webapp";
import { useResourceProviderV2 } from "@legacy-megarax/react-client";
import {
  BreadcrumbMarker,
  commonErrorsMap,
  LoadingBar,
  PageTitleManager,
  qsIdSchema,
  QsInput,
  qsPageSchema,
  useNewRouterQsV2,
  useSnackbarErrorHandler,
} from "@legacy-megarax/ui-components";
import { useSalespeopleResource } from "libs/@megarax/crm/sales-people/src";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom-v5-compat";
import { v4 } from "uuid";
import { z } from "zod";

import { TradeRoutesHome } from "./TradeRoutesHome";

const qsSchema: QsInput<QueryFilters> = {
  searchText: {
    validator: z.string(),
    fallback: "",
  },
  salespersonId: qsIdSchema,
  page: qsPageSchema,
};
export interface QueryFilters {
  searchText: string;
  salespersonId: number | undefined;
  page: number;
}
export type SetQueryFilters = (key: keyof QueryFilters, value: QueryFilters[typeof key]) => void;
export const perPage = 25;

export const TradeRoutesHomeContainer = () => {
  const navigate = useNavigate();

  const { qs, setQs } = useNewRouterQsV2(qsSchema);
  const { listAll } = useSalespeopleResource();
  const { list, archive, rename } = useTradeRoutesResource();

  const salespeopleQuery = useQuery(["salespeople"], () => listAll());
  const tradeRoutesQuery = useQuery(
    ["tradeRoutes", qs],
    () =>
      list({
        searchText: qs.searchText,
        salespersonId: qs.salespersonId,
        limit: perPage,
        offset: qs.page * perPage,
      }),
    { keepPreviousData: true },
  );
  const salespeople = salespeopleQuery.data?.isOk ? salespeopleQuery.data.value.items : [];
  const profiles = useProfilesDictionary(salespeople.map((salesperson) => ({ id: salesperson.userId }))) ?? {};

  return (
    <>
      <PageTitleManager title="Trasy" />
      <BreadcrumbMarker title="Trasy" id="trasy" />
      <LoadingBar loading={tradeRoutesQuery.isRefetching || tradeRoutesQuery.isLoading} />
      <TradeRoutesHome
        tradeRoutes={tradeRoutesQuery.data}
        qs={qs}
        setQs={setQs}
        profiles={profiles}
        salespeople={salespeople}
        archiveTradeRoute={(uuid) =>
          archive(uuid).then(() => {
            tradeRoutesQuery.refetch();
          })
        }
        renameTradeRoute={(uuid, name) =>
          rename(uuid, name).then(() => {
            tradeRoutesQuery.refetch();
          })
        }
        navigation={{
          navigateToNew: () => navigate(`/crm/pos/trasy/${v4()}?status[0]=vip`),
          navigateToMapView: (tradeRouteUuid) => navigate(`/crm/pos/trasy/${tradeRouteUuid}?status[0]=vip`),
        }}
      />
    </>
  );
};

export const useTradeRoutesResource = () => {
  const resource = useResourceProviderV2(tradeRoutesResource);
  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
    TradeRouteAlreadyExists: "Taka trasa już istnieje",
    TradeRouteDoesNotExist: "Taka trasa nie istnieje",
  });

  const retrieve = (uuid: Uuid) => resource.byUuid(uuid).retrieve().mapError(handleError);
  const list = (filters: TradeRouteListQueryFilters) => resource.listNamed(filters).mapError(handleError);
  const saveAsPermanent = (input: SaveRouteAsPermanentInput) =>
    resource
      .byUuid(v4() as Uuid)
      .saveAsPermanent(undefined, input)
      .mapError((err) => {
        handleError(err);
        return Promise.reject(err);
      })
      .then(() => {});
  const schedule = (uuid: Uuid, input: ScheduleRouteInput) =>
    resource
      .byUuid(uuid)
      .schedule(undefined, input)
      .mapError((err) => {
        handleError(err);
        return Promise.reject(err);
      })
      .then(() => {});
  const saveAndSchedule = (uuid: Uuid, input: SaveAndScheduleRouteInput) =>
    resource
      .byUuid(uuid)
      .saveAndSchedule(undefined, input)
      .mapError((err) => {
        handleError(err);
        return Promise.reject(err);
      })
      .then(() => {});
  const archive = (uuid: Uuid) =>
    resource
      .byUuid(uuid)
      .archive()
      .mapError((err) => {
        handleError(err);
        return Promise.reject(err);
      })
      .then(() => {});

  return {
    list,
    schedule,
    saveAndSchedule,
    saveAsPermanent,
    retrieve,
    archive,
    rename: (uuid: Uuid, name: string) =>
      resource
        .byUuid(uuid)
        .rename(undefined, { name })
        .mapError((err) => {
          handleError(err);
          return Promise.reject(err);
        })
        .then(() => {}),
  };
};
