import { profileResource } from "@legacy-megarax/iam-contracts";
import { useResourceProvider } from "@legacy-megarax/react-client";
import { useAsyncLoad } from "@legacy-megarax/react-utils";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";

import { createUserProfileStore, UserProfile, UserProfileStore } from "./profileStore";

export const ProfileStoreContext = React.createContext<UserProfileStore | undefined>(undefined);

interface Props {
  children?: React.ReactNode;
}

export const ProfileStoreProvider: React.FunctionComponent<Props> = ({ children }) => {
  const profileProvider = useResourceProvider(profileResource);
  return (
    <ProfileStoreContext.Provider value={createUserProfileStore(profileProvider)}>
      {children}
    </ProfileStoreContext.Provider>
  );
};

export interface ProfilesDictionary {
  [id: number]: UserProfile | undefined;
}

export const useProfilesDictionary = (users: { id: number }[] | undefined) => {
  const profileStore = useContext(ProfileStoreContext);
  if (!profileStore) throw new Error("No ProfileStoreContext found.");

  const [profiles, setProfiles] = useState<ProfilesDictionary | undefined>(undefined);

  const fetchUsersToDictionary = async (users: { id: number }[]) => {
    const userProfiles = await Promise.all(users.map(profileStore));
    setProfiles(_.keyBy(userProfiles, (profile) => profile.user.id));
  };

  useEffect(() => {
    if (!users) return;
    fetchUsersToDictionary(users);
  }, [JSON.stringify(users)]);

  return profiles;
};

export const useProfile = (user: { id: number } | undefined): UserProfile | undefined => {
  const profileStore = useContext(ProfileStoreContext);
  if (!profileStore) throw new Error("No ProfileStoreContext found.");
  return useAsyncLoad(() => (user ? profileStore(user) : Promise.resolve(undefined)), [user?.id]).value;
};
