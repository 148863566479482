import React from "react";
import { Route, Routes } from "react-router-dom-v5-compat";

import { GroupPage, NewGroupPage } from "./GroupPage";
import { GroupsHome } from "./GroupsHome";

export const Groups: React.FC = () => {
  return (
    <Routes>
      <Route path="/id/:id/*" element={<GroupPage />} />
      <Route path="/nowe" element={<NewGroupPage />} />
      <Route path="/*" element={<GroupsHome />} />
    </Routes>
  );
};
