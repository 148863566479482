import { SelectDialog } from "@dash/select";
import { TileList } from "@dash/tile";
import { useTheme } from "@emotion/react";
import tinycolor from "tinycolor2";

import { customerCategories, CustomerCategoryDetails } from "./addCustomer/CustomerCategorySection";

type Props = {
  selectedCategories: CustomerCategoryDetails[];
  onCategoryChange?: (value: CustomerCategoryDetails[]) => void;
  isChangeDisabled?: boolean;
  userType: "firm" | "customer";
};

export const CustomerCategories: React.FC<Props> = ({
  selectedCategories,
  onCategoryChange,
  isChangeDisabled,
  userType,
}) => {
  const theme = useTheme();

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "0.25rem", width: "100%" }}>
      <label css={{ fontSize: "0.875rem", color: theme.colors.primary, fontFamily: theme.displayFontFamily }}>
        Kategorie
      </label>
      <SelectDialog
        variant="multi-select"
        initiallySelectedValues={selectedCategories.map((category) => category.value)}
        onSelectedChange={onCategoryChange}
        options={customerCategories.filter((category) => category.userType === userType)}
        isDisabled={isChangeDisabled}
        renderTrigger={(_, __, ___, onOpen) => (
          <button
            onClick={onOpen}
            css={{
              padding: 0,
              margin: 0,
              background: "none",
              border: "none",
              cursor: isChangeDisabled ? "auto" : "pointer",
            }}
          >
            {selectedCategories.length > 0 ? (
              <TileList
                elementsList={selectedCategories}
                renderElement={(props) => <CategoryTile {...props} key={props.value} />}
              />
            ) : (
              <div
                css={{
                  borderRadius: theme.smallBorderRadius,
                  border: `1px solid ${tinycolor("rgb(0, 0, 0)").setAlpha(0.1)}`,
                  background: "white",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "1.5rem",
                  color: theme.colors.secondaryText,
                  fontSize: "0.875rem",
                }}
              >
                Brak kategorii
              </div>
            )}
          </button>
        )}
      />
    </div>
  );
};

const CategoryTile = ({ label, Icon }: CustomerCategoryDetails) => {
  const theme = useTheme();

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: "0.75rem 1rem",
        gap: "0.75rem",
        color: theme.colors.primary,
        fontSize: "0.875rem",
      }}
    >
      {Icon}
      {label}
    </div>
  );
};
