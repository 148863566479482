import { Dialog } from "@dash/dialog";
import { useDeviceType } from "@dash/mq";
import { useTheme } from "@emotion/react";
import { formatCurrency } from "@legacy-megarax/react-utils";
import { useContext, useState } from "react";
import { MdOutlineEdit, MdOutlineInsertDriveFile } from "react-icons/md";
import tinycolor from "tinycolor2";

import { IamAuthContext } from "@megaron/auth-react";
import { ThreadDto } from "@megaron/invoices-contracts";

import { EditInvoiceBody } from "./EditInvoiceBody";

type Props = {
  thread: ThreadDto;
  queryKey: string | string[];
  isPrint?: boolean;
};

export const ThreadDetailsCard: React.FC<Props> = ({ thread, queryKey, isPrint }) => {
  const theme = useTheme();
  const { isMobile } = useDeviceType();
  const auth = useContext(IamAuthContext);

  const user = auth.iamUser?.userType === "megarax" ? auth.iamUser : undefined;
  const hasAdminOrCoordinatorRole =
    user?.roles?.includes("invoices.admin") || user?.roles?.includes("invoices.coordinator");

  const [isOpen, setIsOpen] = useState(false);
  const isEditable =
    (thread.status === "open" &&
      !thread.budgetSignatures.filter((s) => !s.isSuggestion).length &&
      !thread.validitySignatures.length) ||
    hasAdminOrCoordinatorRole;

  return (
    <>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          borderRadius: theme.smallBorderRadius,
          border: `1px solid ${tinycolor(theme.colors.primary).setAlpha(0.1)}`,
          background: "white",
          marginTop: "16px",
        }}
      >
        <div
          css={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: `linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.08) 100%), ${theme.colors.primary}`,
            borderTopLeftRadius: theme.smallBorderRadius,
            borderTopRightRadius: theme.smallBorderRadius,
            padding: "16px",
            color: "white",
          }}
        >
          <div css={{ display: "flex", alignContent: "center", gap: "16px", alignItems: "center" }}>
            <MdOutlineInsertDriveFile size={"24px"} />

            <div css={{ display: "flex", flexDirection: "column" }}>
              <span css={{ fontSize: "16px", fontWeight: 700 }}>{thread.invoice.invoiceNumber}</span>
              <span css={{ fontSize: "14px", fontWeight: 400 }}>{thread.invoice.issuer}</span>
            </div>
          </div>
          {!isPrint && (
            <button
              css={{
                border: "none",
                background: "rgba(255, 255, 255, 0.15)",
                padding: "6px",
                borderRadius: "50px",
                cursor: isEditable ? "pointer" : "not-allowed",
                width: "30px",
                height: "30px",
                alignItems: "center",
              }}
              onClick={() => isEditable && setIsOpen(true)}
            >
              <MdOutlineEdit size={16} css={{ color: "white" }} />
            </button>
          )}
        </div>
        {isMobile ? (
          <div css={{ display: "flex", flexDirection: "column", padding: "16px", gap: "8px" }}>
            <ThreadDetailsElement
              bold
              label="Kwota netto"
              value={formatCurrency(thread.invoice.total.toNumber(), thread.invoice.currency)}
            />
            <ThreadDetailsElement label="Data wystawienia" value={thread.invoice.issueDate.toLocaleDateString()} />
            <ThreadDetailsElement
              label="Termin płatności"
              value={thread.invoice.dueDate?.toLocaleDateString() ?? "-"}
            />
            <ThreadDetailsElement
              label="Data otrzymania faktury"
              value={thread.invoice.receivedDate.toLocaleDateString()}
            />
          </div>
        ) : (
          <div css={{ display: "flex" }}>
            <div
              css={{
                display: "flex",
                flexDirection: "column",
                padding: "16px",
                gap: "8px",
                alignContent: "center",
                justifyContent: "center",
                flex: 1,
              }}
            >
              <ThreadDetailsElement
                bold
                label="Kwota netto"
                value={formatCurrency(thread.invoice.total.toNumber(), thread.invoice.currency)}
              />
              <ThreadDetailsElement label="Data wystawienia" value={thread.invoice.issueDate.toLocaleDateString()} />
            </div>

            <div
              css={{
                width: "1px",
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                margin: "0 16px",
              }}
            />

            <div
              css={{
                display: "flex",
                flexDirection: "column",
                padding: "16px",
                gap: "8px",
                alignContent: "center",
                justifyContent: "center",
                flex: 1,
              }}
            >
              <ThreadDetailsElement
                label="Termin płatności"
                value={thread.invoice.dueDate?.toLocaleDateString() ?? "-"}
              />
              <ThreadDetailsElement
                label="Data otrzymania faktury"
                value={thread.invoice.receivedDate.toLocaleDateString()}
              />
            </div>
          </div>
        )}
      </div>
      {isOpen && (
        <Dialog
          onClose={() => setIsOpen(false)}
          placement={isMobile ? "top" : "center"}
          header={"Edycja faktury"}
          css={{ width: "400px" }}
        >
          <EditInvoiceBody
            invoice={thread.invoice}
            onClose={() => setIsOpen(false)}
            queryKey={queryKey}
            threadUuid={thread.uuid}
          />
        </Dialog>
      )}
    </>
  );
};

const ThreadDetailsElement: React.FC<{ label: string; value: string; bold?: boolean }> = ({ label, value, bold }) => {
  const theme = useTheme();

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        gap: "0.25rem",
        justifyContent: "space-between",
        fontSize: "0.875rem",
      }}
    >
      <span css={{ color: theme.colors.primary }}>{label}</span>
      <span css={{ color: "black", fontWeight: bold ? 700 : 400 }}>{value}</span>
    </div>
  );
};
