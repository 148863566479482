import { AuthAttribute, pickRoleAttrs } from "@megaron/access-control";
import { NotificationType } from "@megaron/notifications-contracts";

export type NotificationPermission = {
  app: string;
  permissionName: string;
  targetAudienceAttributes: AuthAttribute[];
};

export const notificationPermissionMap: { [K in NotificationType]: NotificationPermission } = {
  crmInteractionMention: {
    app: "crm",
    permissionName: "Oznaczenie w interakcji w CRM",
    targetAudienceAttributes: pickRoleAttrs("crm.admin", "crm.supervisor", "crm.user"),
  },
  crmRegionAssigned: {
    app: "crm",
    permissionName: "Klient przypisany do Twojego regionu",
    targetAudienceAttributes: pickRoleAttrs("crm.admin", "crm.supervisor", "crm.user"),
  },
  invoiceThreadMention: {
    app: "invoices",
    permissionName: "Oznaczenie w komentarzu wątku faktury",
    targetAudienceAttributes: ["userType:megarax"],
  },
  testWebPush: {
    app: "external",
    permissionName: "Testowe powiadomienie",
    targetAudienceAttributes: ["userType:megarax"],
  },
  taxReimbursementIssued: {
    app: "external",
    permissionName: "Zwrot podatku został wydany",
    targetAudienceAttributes: ["userType:loyalty"],
  },
  referralBonusEarned: {
    app: "loyalty",
    permissionName: "Otrzymano bonus za polecenie",
    targetAudienceAttributes: ["userType:loyalty"],
  },
  affiliateCodeRedeemed: {
    app: "loyalty",
    permissionName: "Kod afiliacyjny został wykorzystany",
    targetAudienceAttributes: ["userType:loyalty"],
  },
  test: {
    app: "external",
    permissionName: "Testowe powiadomienie",
    targetAudienceAttributes: [],
  },
};
