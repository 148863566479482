import { AppointmentModel, ViewState } from "@devexpress/dx-react-scheduler";
import {
  AllDayPanel,
  Appointments,
  CurrentTimeIndicator,
  Scheduler,
  WeekView,
} from "@devexpress/dx-react-scheduler-material-ui";
import { DateString, toDateString } from "@legacy-megarax/common";
import { TableView } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
import React from "react";

import { CalendarCell } from "./CalendarCell";
import { CalendarEvent } from "./CalendarEvent";
import { EventTile } from "./EventTile";

interface Props {
  date: Date;
  events: CalendarEvent[];
  renderDaySummary: (date: DateString) => React.ReactNode;
  onOpenReport: () => void;
}

export const WeekCalendar: React.FunctionComponent<Props> = ({ date, events, renderDaySummary, onOpenReport }) => {
  const classes = useStyles();

  const appointments: AppointmentModel[] = events.map(eventToAppointment);

  const showAllDayPanel = appointments.some((e) => e.allDay);

  const startHour = getStartHour(events);
  const endHour = getEndHour(events);

  return (
    <Box>
      <Scheduler locale="pl-PL" data={appointments} firstDayOfWeek={1}>
        <ViewState currentDate={date} />
        <WeekView
          cellDuration={30}
          startDayHour={startHour}
          endDayHour={endHour}
          layoutComponent={(props) => (
            <WeekView.Layout
              {...props}
              groupingPanelComponent={(props: any) => <>{props.children}</>}
              className={classes.layoutComponent}
            />
          )}
          dayScaleEmptyCellComponent={(props) => (
            <WeekView.DayScaleEmptyCell {...props} style={{ minWidth: "81px" }}>
              <IconButton size="large" onClick={onOpenReport}>
                <TableView fontSize="large" />
              </IconButton>
            </WeekView.DayScaleEmptyCell>
          )}
          timeTableCellComponent={(props) => (
            <CalendarCell dayStartTime={new Date()} dayEndTime={new Date()} {...props} />
          )}
          dayScaleCellComponent={(props) => <td>{renderDaySummary(toDateString(props.startDate))}</td>}
        />

        <Appointments
          appointmentComponent={(props) => (
            <EventTile appointmentProps={props} event={props.data.event as unknown as CalendarEvent} />
          )}
        />
        {showAllDayPanel && (
          <AllDayPanel
            messages={{ allDay: "?" }}
            layoutComponent={(props) => <AllDayPanel.Layout {...props} className={classes.allDayRowLayout} />}
          />
        )}

        <CurrentTimeIndicator />
      </Scheduler>
    </Box>
  );
};

const getStartHour = (events: CalendarEvent[]) => {
  const earliestEventHour = _.min(events.map((a) => a.startTime.getHours()));
  if (earliestEventHour === undefined) return 8;

  return _.clamp(earliestEventHour, 0, 8);
};

const getEndHour = (events: CalendarEvent[]) => {
  return 24;
};

const useStyles = makeStyles((theme) => ({
  layoutComponent: {
    "& > div": {
      minWidth: "900px",
    },
  },
  allDayRowLayout: {
    height: "100px",
  },
}));

const eventToAppointment = (event: CalendarEvent): AppointmentModel => ({
  event,
  startDate: event.startTime,
  endDate: event.endTime ?? undefined,
  allDay: false,
  id: event.key,
  title: event.title,
});
