import { GeoPoint, geoPointSerialzier, Uuid, uuidSerializer } from "@legacy-megarax/common";
import { Serializers } from "@legacy-megarax/serializers";

import { Travel, travelSerializer } from "./travel";

export type DayDto = {
  date: Date;
  userId: number;

  startTime: Date;
  homeLocation: GeoPoint;

  visits: ScheduleVisitDto[];
  travelHomeStart: Date;
  travelHome: Travel;
  totalTravel: Travel;
  totalDurationSeconds: number;

  removedVisits: RemovedVisitDto[];
};

export type RemovedVisitDto = {
  uuid: Uuid;
  customerUuid: Uuid;
};

export type ScheduleVisitDto = {
  uuid: Uuid;
  customerUuid: Uuid;
  startTime: Date;
  travelToVisit: Travel;
  durationMinutes: number;
  location: GeoPoint;
};

export const scheduleVisitDtoSerializer = Serializers.object<ScheduleVisitDto>({
  uuid: uuidSerializer,
  customerUuid: uuidSerializer,
  durationMinutes: Serializers.integer,
  location: geoPointSerialzier,
  startTime: Serializers.datetime,
  travelToVisit: travelSerializer,
});

export const dayDtoSerializer = Serializers.object<DayDto>({
  date: Serializers.dateOnly,
  homeLocation: geoPointSerialzier,
  startTime: Serializers.datetime,
  userId: Serializers.integer,
  visits: Serializers.array(scheduleVisitDtoSerializer),
  travelHome: travelSerializer,
  totalTravel: travelSerializer,
  travelHomeStart: Serializers.datetime,
  totalDurationSeconds: Serializers.integer,
  removedVisits: Serializers.array(
    Serializers.object<RemovedVisitDto>({
      uuid: uuidSerializer,
      customerUuid: uuidSerializer,
    }),
  ),
});
