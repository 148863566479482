import { Button } from "@dash/form";
import { Page, PageHeader } from "@dash/page";
import React from "react";
import { MdAdd } from "react-icons/md";
import { Link } from "react-router-dom-v5-compat";

import { GroupList } from "./GroupList";
import { getNewGroupPath } from "./paths";

export const GroupsHome: React.FC = () => {
  return (
    <Page>
      <PageHeader
        actions={
          <Link to={getNewGroupPath()}>
            <Button icon={<MdAdd />}>Nowe</Button>
          </Link>
        }
      >
        Stanowiska
      </PageHeader>

      <GroupList />
    </Page>
  );
};
