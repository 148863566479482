import { Dialog } from "@dash/dialog";
import { Card, HeaderBar } from "@dash/page";
import { useTheme } from "@emotion/react";
import React, { useEffect } from "react";
import { MdCheckCircle } from "react-icons/md";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom-v5-compat";

import { mutationToResult, useClientManager } from "@megaron/react-clients";
import { isDefined } from "@megaron/utils";

import { useBeep } from "./beep";
import { QrScanner } from "./QrScanner";
import { ScanStatusCard } from "./ScanStatusCard";

export const LabelBatchView: React.FC = () => {
  const theme = useTheme();
  const beep = useBeep();
  const maxSizeRaw = new URLSearchParams(window.location.search).get("maxSize");
  const maxSize: number | undefined = maxSizeRaw ? parseInt(maxSizeRaw) : undefined;
  const { groupName } = useParams<{ groupName: string }>();
  if (!groupName) throw new Error("No group name in path");

  const [scannedCodeString, setScannedCodeString] = React.useState<string>();
  const groupQuery = useClientManager("loyaltyPoints").codeGroupQuery().useQuery({ groupName });
  const queryClient = useQueryClient();

  const appendMutation = useClientManager("loyaltyPoints")
    .appendCodesToGroup()
    .useMutation({
      onSuccess: () => {
        beep(1000);
        queryClient.invalidateQueries(["loyaltyPoints", "codeGroupQuery"]);
      },
      onError: () => {
        beep(400);
      },
    });

  useEffect(() => {
    if (scannedCodeString) {
      (async () => {
        await beep(800);
        appendMutation.mutate({ codeIds: [scannedCodeString], groupName });
      })();
    }
  }, [scannedCodeString]);

  const isMaxSizeReached = maxSize && isDefined(groupQuery.data?.total) && groupQuery.data.total >= maxSize;

  return (
    <div>
      <HeaderBar header={groupName} actions={<></>} />
      {!isMaxSizeReached ? (
        <QrScanner onScan={setScannedCodeString}>
          {scannedCodeString && (
            <div css={{ position: "absolute", top: "50px", width: "100%", padding: "1rem" }}>
              <ScanStatusCard
                code={scannedCodeString}
                error={appendMutation.error ?? undefined}
                isSuccess={appendMutation.isSuccess}
              />
            </div>
          )}

          <div css={{ position: "absolute", bottom: "48px", width: "100%", padding: "1rem", display: "flex" }}>
            <Card
              variant="secondary"
              css={{
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                padding: "0.5rem 1.5rem",
                flexDirection: "row",
                margin: "auto",
                minWidth: 0,
                flexShrink: "1",
              }}
            >
              <div css={{ display: "flex", fontSize: "1.25rem", minWidth: 0 }}>
                {groupQuery?.data?.total.toString()}
                {maxSize ? `/${maxSize}` : ""}
              </div>
            </Card>
          </div>
        </QrScanner>
      ) : (
        <div
          css={{
            padding: "5rem 1rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            color: theme.colors.primary,
            fontWeight: "bold",
            fontSize: "1rem",
            gap: "0.5rem",
          }}
        >
          <MdCheckCircle fontSize={"1.5rem"} />
          {groupQuery?.data?.total.toString()}
          {maxSize ? `/${maxSize}` : ""}
        </div>
      )}
    </div>
  );
};
