import { BaseResource, ResourceActionV2 } from "@legacy-megarax/rest-resource";
import { MSerializers } from "@legacy-megarax/serializers";

import { createTagInputSerializer } from "./inputs";
import list from "./list";

export const tagResource = BaseResource(["crm", "customerTags"], {
  list,
  create: ResourceActionV2({
    name: "create",
    method: "post",
    requestBodySerializer: createTagInputSerializer,
    responseErrorSerializer: MSerializers.stringOneOf("TagAlreadyExists"),
  }),
});
