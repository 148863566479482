import { Serializer, serializerExtensions, SerializerExtensions, ValidationError } from "@legacy-megarax/serializers";
import { formatISO, parseISO } from "date-fns";

import { Failure, Ok } from "@megaron/result";

export type TimeString = string & { __brand: "TimeString" };

export const validateTimeString = (str: string) => {
  const date = parseISO(`1970-01-01T${str}`);
  const formattedTime = formatISO(date, { representation: "time", format: "extended" });
  return Ok<TimeString>(formattedTime as TimeString);
};

export const timeStringSerializer: Serializer<TimeString> & SerializerExtensions<TimeString> = {
  serialize: (dateString) => dateString,
  forceDeserialize: (raw: unknown) => {
    if (typeof raw !== "string") throw new ValidationError("Not a string");
    const result = validateTimeString(raw);
    if (result.isFailure) throw new ValidationError("Invalid time string");
    return result.value;
  },
  ...serializerExtensions(),
};

export function toTimeString(date: Date): TimeString;
export function toTimeString(date: Date | null): TimeString | null;
export function toTimeString(date: Date | null) {
  if (date === null) return null;
  return formatISO(date, { representation: "time" }) as TimeString;
}

export function timeStringToDate(str: TimeString, date?: Date): Date;
export function timeStringToDate(str: TimeString | null, date?: Date): Date | null;
export function timeStringToDate(str: TimeString | null, date?: Date) {
  if (str === null) return null;
  const dateStr = formatISO(date ?? new Date(), { representation: "date" });
  return parseISO(`${dateStr}T${str}`);
}

export const timeStringToLocale = (str: TimeString) =>
  timeStringToDate(str).toLocaleTimeString(undefined, { hour: "2-digit", minute: "2-digit" });
