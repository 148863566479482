import { RowCellContent, Table, TableBody, TableHeadColumn } from "@dash/table";
import { useTheme } from "@emotion/react";
import { formatDistanceToNowStrict } from "date-fns";
import { pl } from "date-fns/locale";
import { Link } from "react-router-dom-v5-compat";

import { MyOrderDraftDto } from "@megaron/crm-contracts";

type Props = {
  orders: MyOrderDraftDto[];
  isLoading: boolean;
};

export const OrderDraftsTable: React.FC<Props> = ({ orders, isLoading }) => {
  const theme = useTheme();

  const tableColumns: TableHeadColumn<string>[] = [
    {},
    ...tableHeadingList.map(
      ({ heading }) =>
        ({
          isSortable: false,
          label: heading,
        } as const),
    ),
  ];

  const getTableRowCellsContent = (order: MyOrderDraftDto): RowCellContent[] => {
    return [
      {
        isLink: true,
        element: (
          <div
            css={{
              position: "absolute",
              inset: 0,
              background: theme.colors.primaryLight,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        ),
      },
      {
        isLink: true,
        element: (
          <span css={{ color: theme.colors.primary, textWrap: "nowrap" }}>
            {formatDistanceToNowStrict(new Date(order.draftStartedAt), { addSuffix: true, locale: pl })}
          </span>
        ),
      },
      {
        cellCss: { textWrap: "nowrap" },
        element: (
          <Link css={{ color: theme.colors.primary }} to={`/crm/customers/id/${order.sellToUuid}`}>
            {order.sellToName}
          </Link>
        ),
      },
      {
        cellCss: { textWrap: "nowrap" },
        element: <Link to={`/crm/customers/id/${order.billToUuid}`}>{order.billToName}</Link>,
      },
    ];
  };

  return (
    <div css={{ overflow: "auto", margin: "0 -2rem 1rem" }}>
      <Table columns={tableColumns} css={{ padding: "0 2rem", maxWidth: "1000px" }}>
        <TableBody
          isLoading={isLoading}
          rows={orders.map((order) => ({
            uuid: order.uuid,
            linkTo: `/crm/order-drafts/${order.uuid}`,
            cellsContent: getTableRowCellsContent(order),
            css: {
              "&:hover": {
                cursor: "pointer",
                "td:nth-child(1)": {
                  textDecoration: "underline",
                },
              },
            },
          }))}
        />
      </Table>
    </div>
  );
};

const tableHeadingList: { heading: string }[] = [
  { heading: "Data utw." },
  { heading: "Nabywca" },
  { heading: "Odbiorca faktury" },
];
