import { AvatarGroup } from "@dash/avatar";
import { Dialog, useDialogRoute } from "@dash/dialog";
import { Chip } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { Card, Header2 } from "@dash/page";
import { useQueryClient } from "react-query";

import { Profile } from "@megaron/auth-contracts";
import { Macroregion } from "@megaron/crm-contracts";

import { SaveMacroregion } from "./SaveMacroregion";

type Props = {
  macroregion: Macroregion;
  profiles?: Profile[];
  queryKey: string | string[];
};

export const MacroregionTile: React.FC<Props> = (props) => {
  const queryClient = useQueryClient();
  const { isMobile } = useDeviceType();

  const updateRegionDialog = useDialogRoute(`/${props.macroregion.name}`, ({ onClose }) => (
    <Dialog
      onClose={onClose}
      header={props.macroregion.name}
      placement={isMobile ? "bottom" : "center"}
      css={{ width: isMobile ? "100%" : "500px" }}
    >
      <SaveMacroregion
        macroregion={props.macroregion}
        onSuccess={() => {
          queryClient.invalidateQueries(props.queryKey);
          onClose();
        }}
      />
    </Dialog>
  ));

  return (
    <>
      <Card variant="secondary" css={{ cursor: "pointer" }} onClick={updateRegionDialog.open}>
        <div css={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>
            <Header2 css={{ fontSize: "16px", fontWeight: "bold" }}>{props.macroregion.name}</Header2>
            <span>
              {props.macroregion.customerCount} {props.macroregion.customerCount === 1 ? "klient" : "klientów"}
            </span>
          </div>
          <AvatarGroup
            profiles={
              props.profiles?.map((profile) => ({
                ...profile,
                firstName: profile.firstName ?? null,
                lastName: profile.lastName ?? null,
              })) ?? []
            }
            fullName
          />
        </div>
        <div css={{ display: "flex", gap: "4px" }}>
          {props.macroregion.regions.slice(0, 3).map((region, index) => (
            <Chip key={index} variant="secondary">
              {region}
            </Chip>
          ))}
          {props.macroregion.regions.length > 3 && (
            <Chip variant="secondary">{`+${props.macroregion.regions.length - 3}`}</Chip>
          )}
        </div>
      </Card>
      {updateRegionDialog.element}
    </>
  );
};
