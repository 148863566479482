import Decimal from "decimal.js";

import { authAttributeSerializer } from "@megaron/access-control";
import { HttpAction } from "@megaron/http-service";
import { ResourceId, resourceIdSerializer } from "@megaron/resource";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { newUuid, uuidSerializer } from "@megaron/uuid";

import { customerCategories } from "./categories";
import { customerAddressSerializer, customerStatuses } from "./customers";
import { customerProductSerializer } from "./purchases";
import { relationTypes } from "./relation";

const customerPricingRulesSerializer = Serializers.object({
  priceListUuid: uuidSerializer.nullable(),
  baseDiscount: Serializers.decimal,
  otherTerms: Serializers.string,
  awaitingProposalCount: Serializers.integer,
}).nullable();

export type CustomerPricingRules = SerializerValue<typeof customerPricingRulesSerializer>;

export const customerDocSerializer = Serializers.object({
  docType: Serializers.stringOneOf("customer"),
  docId: resourceIdSerializer("customer"),
  authorizedAttributes: authAttributeSerializer.array(),
  boostedFor: authAttributeSerializer.array(),
  isArchived: Serializers.boolean,
  name: Serializers.string,

  uuid: uuidSerializer,
  phoneNumber: Serializers.string,
  phoneNumberWithoutCountryCode: Serializers.string,
  firstName: Serializers.string.nullable(),
  lastName: Serializers.string.nullable(),
  postalCode: Serializers.string,
  interactionCount: Serializers.integer,
  lastInteraction: Serializers.object({
    uuid: uuidSerializer,
    createdAt: Serializers.datetime,
    ownerEmail: Serializers.string,
    message: Serializers.string,
  }).nullable(),
  visitCount: Serializers.integer,
  lastVisit: Serializers.object({
    uuid: uuidSerializer,
    createdAt: Serializers.datetime,
    ownerEmail: Serializers.string,
    message: Serializers.string,
  }).nullable(),
  email: Serializers.email.nullable(),
  lastPurchaseTime: Serializers.datetime.nullable(),
  totalMassKg: Serializers.decimal,
  products: customerProductSerializer.array(),
  region: Serializers.string.nullable(),
  regionOwnerEmail: Serializers.string.nullable(),
  isPro: Serializers.boolean,
  isAffiliate: Serializers.boolean,
  tags: Serializers.array(Serializers.string),
  tagsNested: Serializers.array(Serializers.object({ tag: Serializers.string })),
  categories: Serializers.stringOneOf(...customerCategories).array(),
  status: Serializers.stringOneOf(...customerStatuses),
  address: customerAddressSerializer,
  location: Serializers.object({ lat: Serializers.float, lon: Serializers.float }).nullable(),

  pricingRules: customerPricingRulesSerializer,

  fixedTradeRouteCount: Serializers.integer,

  parents: Serializers.object({
    uuid: Serializers.string,
    name: Serializers.string,
    alert: Serializers.string.nullable(),
    relationType: Serializers.stringOneOf(...relationTypes),
  }).array(),
  invitedChildrenCount: Serializers.integer,
  supplyPointChildrenCount: Serializers.integer,
  payerChildrenCount: Serializers.integer,
  distributorChildrenCount: Serializers.integer,
  chainChildrenCount: Serializers.integer,
  postSaleDiscount: Serializers.decimal.nullable(),
  unmodifiedSince: Serializers.datetime,
  alert: Serializers.string.nullable(),
});

export type CustomerDoc = SerializerValue<typeof customerDocSerializer>;

export const newCustomerDoc = (props: Partial<CustomerDoc> = {}): CustomerDoc => {
  const uuid = newUuid();
  return {
    uuid,
    docId: ResourceId("customer", uuid),
    firstName: "John",
    lastName: "Doe",
    interactionCount: 0,
    lastInteraction: null,
    lastPurchaseTime: null,
    phoneNumber: "+48111222333",
    phoneNumberWithoutCountryCode: "111222333",
    products: [],
    email: null,
    totalMassKg: new Decimal(0),
    authorizedAttributes: ["userType:megarax", "user:jake.doe@example.com"],
    region: "region-1",
    regionOwnerEmail: "john.doe@example.com",
    isArchived: false,
    tags: [],
    tagsNested: props.tagsNested ?? props.tags?.map((t) => ({ tag: t })) ?? [],
    postalCode: "",
    isAffiliate: false,
    isPro: false,
    status: "verified",
    categories: [],
    address: {
      city: "",
      country: "",
      latitude: null,
      longitude: null,
      postalCode: "",
      street: "",
      googlePlaceId: null,
    },
    name: "",
    parents: [],
    invitedChildrenCount: 0,
    supplyPointChildrenCount: 0,
    payerChildrenCount: 0,
    distributorChildrenCount: 0,
    chainChildrenCount: 0,
    postSaleDiscount: null,
    lastVisit: null,
    visitCount: 0,
    boostedFor: [],
    docType: "customer",
    fixedTradeRouteCount: 0,
    location: null,
    pricingRules: null,
    unmodifiedSince: new Date(),
    alert: null,
    ...props,
  };
};

export const customerDocActions = {
  customerDocBulkQuery: HttpAction({
    path: "/customers/bulk",
    method: "get",
    qsSerializer: Serializers.object({
      customerUuids: uuidSerializer.array().asJsonString(),
      regions: Serializers.string.array().asJsonString(),
      modifiedAfter: Serializers.datetime,
    }).partial(),
    valueSerializer: customerDocSerializer.array(),
    requiresAuth: true as const,
  }),
};
