import { Uuid } from "@legacy-megarax/common";
import { ReportSubject } from "@legacy-megarax/crm-contracts";
import { DecimalInput, NewRouterResponsiveSelect } from "@legacy-megarax/ui-components";
import { Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Decimal from "decimal.js";
import React from "react";

export type GetPriceReportSubjects = (search: string) => Promise<ReportSubject[]>;

export type OnSubmit = (value: { subjectUuid: Uuid; netPrice: Decimal }) => void;

interface Props {
  getPriceReportSubjects: GetPriceReportSubjects;
  onSubmit: OnSubmit;
  taxRate?: Decimal;
}

export const ReportForm: React.FunctionComponent<Props> = ({
  getPriceReportSubjects,
  onSubmit,
  taxRate = new Decimal("1.23"),
}) => {
  const classes = useStyles();
  const [subject, setSubject] = React.useState<ReportSubject | null>(null);
  const [netPrice, setNetPrice] = React.useState<Decimal | undefined>();
  const [grossPrice, setGrossPrice] = React.useState<Decimal | undefined>();

  const onNetPriceSet = (newValue: Decimal | undefined) => {
    setNetPrice(newValue);
    setGrossPrice(newValue ? newValue.mul(taxRate).toDecimalPlaces(2) : undefined);
  };

  const onGrossPriceSet = (newValue: Decimal | undefined) => {
    setGrossPrice(newValue);
    setNetPrice(newValue ? newValue.div(taxRate).toDecimalPlaces(2) : undefined);
  };

  const submit = () => {
    if (!netPrice || !subject) return;

    onSubmit({
      netPrice,
      subjectUuid: subject?.uuid,
    });
  };

  return (
    <div className={classes.container}>
      <Typography variant="h6">Dodaj cenę</Typography>
      <NewRouterResponsiveSelect
        label={"Produkt"}
        value={subject}
        getOptions={getPriceReportSubjects}
        getValue={(i) => i}
        getKey={(i) => i.uuid}
        getLabel={(i) => i.varietyName}
        onChange={setSubject}
      />
      <DecimalInput fullWidth value={netPrice} onChange={onNetPriceSet} label="Cena netto [PLN]" variant="outlined" />
      <DecimalInput
        fullWidth
        value={grossPrice}
        onChange={onGrossPriceSet}
        label="Cena brutto [PLN]"
        variant="outlined"
      />
      <Button onClick={submit} color="primary" variant="contained" className={classes.button}>
        Dodaj
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    formContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      "& > .MuiFormControl-root": {
        margin: theme.spacing(1, 0),
      },
      maxWidth: "300px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        maxWidth: "500px",
      },
    },
    container: {
      marginBottom: theme.spacing(2),
      "& > .MuiFormControl-root": {
        margin: theme.spacing(1, 0),
      },
    },
    button: {
      marginTop: theme.spacing(1),
      display: "block",
      marginLeft: "auto",
    },
  };
});
