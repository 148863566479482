import { decodeResourceId, ResourceId } from "./ResourceId";

const resourceLinkMap: Record<string, (id: string) => string> = {
  customer: (id) => `/crm/customers/id/${id}`,
  order: (id) => `/orders/${id}`,
  invoice: (id) => `/faktury/watki/${id}`,
  product: (id) => `/items/${id}`,
};

export const getResourceLink = (resourceId: ResourceId): string => {
  const { type, id } = decodeResourceId(resourceId);
  return resourceLinkMap[type]?.(id) ?? `/unknown/${id}`;
};
