import { Button } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { QuerySkeleton } from "@dash/skeleton";
import { TileList } from "@dash/tile";
import { useToast } from "@dash/toast";
import { MdQrCode2 } from "react-icons/md";
import { useQueryClient } from "react-query";

import { useClientManager } from "@megaron/react-clients";

import { MyAffiliateCodeTile } from "./MyAffiliateCodeTile";

type Props = {
  region: string;
};

export const MyAffiliateCodes: React.FC<Props> = ({ region }) => {
  const { isMobile } = useDeviceType();

  const toast = useToast();

  const queryClient = useQueryClient();

  const myAffiliateCodesQuery = useClientManager("loyaltyPoints").myAffiliateCodesQuery().useQuery({});

  const generateAffiliateCodeMuatation = useClientManager("loyaltyPoints")
    .generateSalespersonAffiliateCode()
    .useMutation();

  const handleGenerateCode = () => {
    generateAffiliateCodeMuatation.mutate(
      { region },
      {
        onSuccess: () => {
          toast.info("Pomyślnie wygenerowano kod zaproszeniowy");
          queryClient.invalidateQueries(myAffiliateCodesQuery.key);
        },
        onError: (error) => {
          if (error === "AlreadyHasCode") {
            return toast.error("Już posiadasz kod zaproszeniowy");
          }

          return toast.error("Wystąpił błąd podczas generowania kodu zaproszeniowego");
        },
      },
    );
  };

  return (
    <QuerySkeleton query={myAffiliateCodesQuery}>
      {(myAffiliateCodes) => (
        <div css={{ width: isMobile ? "100%" : "400px" }}>
          {myAffiliateCodes.length > 0 ? (
            <TileList
              elementsList={myAffiliateCodes}
              renderElement={(element) => <MyAffiliateCodeTile affiliateCode={element} key={element.id} />}
            />
          ) : (
            <Button variant="outline" icon={<MdQrCode2 />} onClick={handleGenerateCode}>
              Wygeneruj kod zaproszeniowy
            </Button>
          )}
        </div>
      )}
    </QuerySkeleton>
  );
};
