import { DateString } from "@legacy-megarax/common";
import { MilageReport } from "@legacy-megarax/crm-contracts";
import { stringify } from "csv/browser/esm/sync";
import _ from "lodash";

type CsvRow = {
  handlowiec: string;
  razem: number;
  [date: DateString]: number | undefined;
};

export const reportToCsv = (
  report: MilageReport,
  userNameDictionary: {
    [id: number]: string | undefined;
  },
  allDates: DateString[],
): string => {
  const userRows = report.users.map<CsvRow>((user) => ({
    handlowiec: userNameDictionary[user.userId] ?? "?",
    razem: user.totalMilageKm,
    ..._(allDates)
      .keyBy((date) => date)
      .mapValues((date) => user.days.find((d) => d.date === date)?.milageKm)
      .value(),
  }));

  return stringify([...userRows], { header: true });
};
