import React from "react";
import { Route, Routes } from "react-router-dom-v5-compat";

import { AccountPage } from "./AccountPage";
import { AccountsHome } from "./AccountsHome";

export const Accounts: React.FC = () => {
  return (
    <Routes>
      <Route path="/*" element={<AccountsHome />} />
      <Route path="/email/:email/*" element={<AccountPage />} />
    </Routes>
  );
};
