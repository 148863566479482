import { customerListResource } from "@legacy-megarax/crm-contracts";
import { useResourceProviderV2 } from "@legacy-megarax/react-client";
import { commonErrorsMap, useSnackbarErrorHandler } from "@legacy-megarax/ui-components";
import React from "react";

import { AddUnplannedVisitDialog, GetCustomerOptions } from "./AddUnplannedVisitDialog";

interface Props {
  date: Date;
  onClose: () => void;
}

export const AddUnplannedVisitDialogContainer: React.FunctionComponent<Props> = ({ date, onClose }) => {
  const customerListProvider = useResourceProviderV2(customerListResource);

  const errorHandler = useSnackbarErrorHandler({
    ...commonErrorsMap,
    InvalidVisitTime: "Czas wizyty niezgodny z godzinami pracy.",
  });

  const getCustomerOptions: GetCustomerOptions = (searchText) =>
    customerListProvider
      .list({ searchText })
      .map((v) => v.items)
      .mapError(errorHandler)
      .then((r) => r.assertOk().value);

  return (
    <AddUnplannedVisitDialog
      onClose={onClose}
      isOpen={true}
      key={date.toISOString()}
      getCustomerOptions={getCustomerOptions}
    />
  );
};
