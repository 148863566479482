import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

interface Props<T> {
  isBeingEdited: boolean;
  disableEdit: () => void;
  enableEdit: () => void;
  onSave: () => void;
  currentValue: string;
  editQueryLoading: boolean;
  newValue: T;
  label: string;
  InputNode: React.ReactNode;
  options?: {
    nullable?: boolean;
  };
  disabled?: boolean;
}

export const InlineEditableRow = <T,>({
  isBeingEdited,
  disableEdit,
  enableEdit,
  onSave,
  currentValue,
  newValue,
  editQueryLoading,
  label,
  InputNode,
  options,
  disabled,
}: Props<T>) => {
  const classes = useStyles();
  const isDisabled = editQueryLoading || (!options?.nullable && !newValue) || disabled;

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" minHeight="64px">
      {isBeingEdited ? (
        <>
          <Box width="100%" maxWidth="300px">
            {InputNode}
          </Box>
          <Box display="flex" flexWrap="nowrap" ml={4}>
            <Button onClick={disableEdit} disabled={editQueryLoading} className={classes.button} color="inherit">
              Anuluj
            </Button>
            <Button onClick={onSave} disabled={isDisabled} color="primary" className={classes.button}>
              Zapisz
            </Button>
          </Box>
        </>
      ) : (
        <>
          <div>
            <Typography variant="caption" color="textSecondary">
              {label}
            </Typography>
            <Typography variant="subtitle2" className={classes.value}>
              {currentValue}
            </Typography>
          </div>
          <Button onClick={enableEdit} className={classes.button} disabled={disabled} color="inherit">
            Edytuj
          </Button>
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    value: {
      fontSize: "1rem",
    },
    button: {
      fontSize: "0.8rem",
    },
  };
});
