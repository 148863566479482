import { Uuid, uuidSerializer } from "@legacy-megarax/common";
import { MSerializers } from "@legacy-megarax/serializers";
import Decimal from "decimal.js";

export type PriceReportDto = {
  uuid: Uuid;
  createdById: number;
  time: Date;
  netPrice: Decimal;
  customerUuid: Uuid;
  subjectUuid: Uuid;
  subjectName: string;
};

export const priceReportDtoSerializer = MSerializers.object<PriceReportDto>({
  uuid: uuidSerializer,
  createdById: MSerializers.integer,
  customerUuid: uuidSerializer,
  netPrice: MSerializers.decimal,
  subjectName: MSerializers.string,
  subjectUuid: uuidSerializer,
  time: MSerializers.datetime,
});
