import { DescriptionField } from "@dash/description-list";
import { AutocompleteTextField, Checkbox, DecimalField, SwitchField } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { SectionHeader } from "@dash/page";

import { Item } from "@megaron/crm-contracts";
import { useServiceClient } from "@megaron/react-clients";

type Props = {
  item: Item;
  values: {
    index: string;
    group: string;
    isArchived: boolean;
    cost: string;
    isLoyalty: boolean;
    defaultProfit: string;
    isInAvailability: boolean;
  };
  handlers: {
    onIndexChange: (index: string) => void;
    onGroupChange: (group: string) => void;
    onArchivedChange: (isArchived: boolean) => void;
    onCostChange: (cost: string) => void;
    onIsLoyaltyChange: (isLoyalty: boolean) => void;
    onDefaultProfitChange: (defaultProfit: string) => void;
    onIsInAvailabilityChange: (isInAvailability: boolean) => void;
  };
};

export const TradeItemDetails: React.FC<Props> = ({ item, values, handlers }) => {
  const { isMobile } = useDeviceType();
  const docs = useServiceClient("docs");

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>
      <SwitchField
        label="Aktywny?"
        falseLabel="Zarchiwizowany"
        trueLabel="Aktywny"
        selectedValue={!values.isArchived}
        onChange={(v) => handlers.onArchivedChange(!v)}
      />
      <SectionHeader isHr>Dane podstawowe</SectionHeader>
      <div css={{ display: "grid", gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr", gap: "0.625rem", width: "100%" }}>
        <DescriptionField label="Jednostka handlowa" css={{ width: "100%" }}>
          {item.itemName}
        </DescriptionField>
        <DescriptionField label="Id" css={{ width: "100%" }}>
          {item.itemId}
        </DescriptionField>
        <DescriptionField label="EAN" css={{ width: "100%" }}>
          {item.gtin}
        </DescriptionField>
        <DescriptionField label="Numer BC" css={{ width: "100%" }}>
          {item.bcNumber}
        </DescriptionField>
        <AutocompleteTextField
          label="Indeks"
          value={values.index}
          onChange={handlers.onIndexChange}
          css={{ width: "100%" }}
          keyName="item-index"
          getOptions={async (searchText) => {
            const results = await docs.autocomplete({ text: searchText, doctype: "item", field: "productId" });
            return results.value || [];
          }}
        />
        <AutocompleteTextField
          label="Grupa"
          value={values.group}
          onChange={handlers.onGroupChange}
          css={{ width: "100%" }}
          keyName="item-group"
          getOptions={async (searchText) => {
            const results = await docs.autocomplete({ text: searchText, doctype: "item", field: "productGroup" });
            return results.value || [];
          }}
        />
        <DecimalField value={values.cost} onChange={handlers.onCostChange} label="Koszty standardowe" />
        <div css={{ paddingTop: "30px" }}>
          <Checkbox value={values.isLoyalty} onChange={handlers.onIsLoyaltyChange} label="W programie lojalnościowym" />
        </div>
        <div css={{ paddingTop: "30px" }}>
          <Checkbox
            value={values.isInAvailability}
            onChange={handlers.onIsInAvailabilityChange}
            label="W dostępności produktów"
          />
        </div>
        {values.isLoyalty && (
          <DecimalField label="Domyślna cena" value={values.defaultProfit} onChange={handlers.onDefaultProfitChange} />
        )}
      </div>
    </div>
  );
};
