import { useDeviceType } from "@dash/mq";
import { SelectDialog } from "@dash/select";
import { useTheme } from "@emotion/react";
import { MdOutlineShoppingCart } from "react-icons/md";

import { useClientManager } from "@megaron/react-clients";

type Props = {
  selectedProductGroups: string[];
  onProductGroupsChange: (productGroups: string[]) => void;
};

export const ProductGroupsButton: React.FC<Props> = ({ selectedProductGroups, onProductGroupsChange }) => {
  const theme = useTheme();

  const { isMobile } = useDeviceType();

  const productGroupsQuery = useClientManager("docs").searchProductGroups().useQuery({});

  return (
    <SelectDialog
      variant="multi-select"
      options={productGroupsQuery.data?.map((group) => ({ label: group, value: group })) ?? []}
      initiallySelectedValues={selectedProductGroups}
      onSelectedChange={(options) => onProductGroupsChange(options?.map((option) => option.value))}
      renderTrigger={(selectedOptions, _, __, onOpen) => (
        <button
          css={{
            position: "relative",
            border: "none",
            background: "none",
            color: theme.colors.primary,
            fontSize: "0.875rem",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            gap: "0.75rem",
            padding: "0.25rem 0.75rem",
            alignSelf: "stretch",
            textWrap: "nowrap",
            flexShrink: 0,
            borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
            fontWeight: 700,
          }}
          onClick={onOpen}
        >
          <MdOutlineShoppingCart size={16} />
          {!isMobile && `Grupy produktowe ${selectedOptions.length ? `(${selectedOptions.length})` : ""}`}
        </button>
      )}
    />
  );
};
