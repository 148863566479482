import { useTheme } from "@emotion/react";
import axios from "axios";
import heic2any from "heic2any";
import { AnimatePresence, motion } from "motion/react";
import { useEffect, useRef, useState } from "react";
import { MdClose } from "react-icons/md";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface FileViewProps {
  url: string;
  fileType: string;
  onClose: () => void;
}

const SUPPORTED_TYPES = ["application/pdf", "image/jpeg", "image/png", "image/heic"];

export const FileView: React.FC<FileViewProps> = ({ url, fileType, onClose }) => {
  const [file, setFile] = useState<Blob | string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [scale, setScale] = useState(1);
  const [containerDimensions, setContainerDimensions] = useState({ width: "100%", height: "100vh" });
  const containerRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  const updateContainerDimensions = (page: any) => {
    if (containerRef.current) {
      const { clientWidth, clientHeight } = containerRef.current;
      const viewport = page.getViewport({ scale: 1 });

      let newScale = clientWidth / viewport.width;
      let newHeight = viewport.height * newScale;
      let newWidth = clientWidth;

      if (newHeight > clientHeight) {
        newScale = clientHeight / viewport.height;
        newHeight = clientHeight;
        newWidth = viewport.width * newScale;
      }

      setScale(newScale);
      setContainerDimensions({
        width: `${newWidth}px`,
        height: `${newHeight}px`,
      });
    }
  };

  const onDocumentLoadSuccess = (pdf: any) => {
    pdf.getPage(1).then((page: any) => {
      updateContainerDimensions(page);
    });
  };

  useEffect(() => {
    const fetchFile = async () => {
      try {
        if (fileType === "application/pdf" || fileType === "image/heic") {
          const response = await axios({ url, responseType: "blob", method: "GET" });
          const blob = response.data;

          if (fileType === "image/heic") {
            try {
              const convertedBlob = await heic2any({ blob, toType: "image/jpeg" });
              const reader = new FileReader();
              reader.readAsDataURL(convertedBlob as Blob);
              reader.onloadend = () => {
                setFile(reader.result as string);
              };
            } catch (conversionError) {
              // eslint-disable-next-line no-console
              console.error("HEIC conversion error:", conversionError);
              setError("Nie udało się załadować podglądu HEIC.");
            }
          } else {
            setFile(blob);
          }
        } else {
          setFile(url);
        }
        setError(null);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("Failed to fetch the file:", err);
        setError("Nie udało się załadować pliku.");
      }
    };

    fetchFile();
  }, [url, fileType]);

  useEffect(() => {
    const handleResize = () => {
      if (file && fileType === "application/pdf") {
        const loadingTask = pdfjs.getDocument(URL.createObjectURL(file as Blob));
        loadingTask.promise.then((pdf) => {
          pdf.getPage(1).then((page) => {
            updateContainerDimensions(page);
          });
        });
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [file, fileType]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const containerVariants = {
    hidden: { opacity: 0, y: -20, scale: 0.9 },
    visible: { opacity: 1, y: 0, scale: 1 },
  };

  const containerTransition = {
    duration: 0.3,
    ease: "easeOut",
  };

  if (!SUPPORTED_TYPES.includes(fileType)) {
    return <div style={{ padding: "16px", color: theme.colors.primary || "#000" }}>Podgląd niedostępny.</div>;
  }

  return (
    <AnimatePresence>
      <motion.div
        ref={containerRef}
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={containerVariants}
        transition={containerTransition}
        style={{
          display: "flex",
          flexDirection: "column",
          width: containerDimensions.width,
          height: containerDimensions.height,
          position: "relative",
          background: "white",
          borderRadius: theme.smallBorderRadius,
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
          overflow: "hidden",
          justifyContent: "center",
          alignItems: "bottom",
        }}
      >
        {fileType === "application/pdf" ? (
          <>
            {error ? (
              <div style={{ padding: "16px", color: "red" }}>{error}</div>
            ) : file ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "bottom",
                  overflow: "hidden",
                }}
              >
                <Document
                  file={file as Blob}
                  loading={
                    <div
                      css={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "180px",
                        width: "100%",
                        color: theme.colors.primary,
                        fontSize: "16px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      <p>Ładowanie...</p>
                    </div>
                  }
                  error={"Nie udało się załadować podglądu."}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page
                    pageNumber={1}
                    scale={scale}
                    css={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Document>
                <button
                  onClick={onClose}
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    background: "white",
                    border: "none",
                    borderRadius: "50%",
                    padding: "8px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <MdClose size={24} color="#000" />
                </button>
              </div>
            ) : null}
          </>
        ) : (
          <>
            <img
              src={file as string}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                objectPosition: "top",
              }}
              alt="File preview"
            />
            <button
              onClick={onClose}
              style={{
                position: "absolute",
                top: "8px",
                right: "8px",
                background: "white",
                border: "none",
                borderRadius: "50%",
                padding: "8px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              <MdClose size={24} color="#000" />
            </button>
          </>
        )}
      </motion.div>
    </AnimatePresence>
  );
};
