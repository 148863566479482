import { SelectDialog } from "@dash/select";
import { css, useTheme } from "@emotion/react";
import { useProfiles } from "@megarax/features";
import { MdClose, MdOutlineContactPage } from "react-icons/md";
import { useNavigate } from "react-router-dom-v5-compat";

import { Profile } from "@megaron/auth-contracts";
import { Region } from "@megaron/crm-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Email } from "@megaron/serializers";

type Props = {
  profile?: Profile;
  region: Region;
  onSuccess: () => void;
};

export const EditRegion: React.FC<Props> = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { allProfiles } = useProfiles();
  const saveRegion = useClientManager("crm").saveRegion().useMutation();
  const avatar = css({ borderRadius: "50%", width: "30px", height: "30px" });

  const saveCustomerMutation = async (ownerEmail: Email) => {
    saveRegion.mutate(
      {
        name: props.region.name,
        ownerEmail,
      },
      {
        onSuccess: (r) => {
          props.onSuccess();
        },
      },
    );
  };

  const removeOwner = () => {
    saveRegion.mutate(
      {
        name: props.region.name,
        ownerEmail: null,
      },
      {
        onSuccess: (r) => {
          props.onSuccess();
        },
      },
    );
  };

  const options = allProfiles?.map((profile) => ({
    label: profile.firstName ? `${profile.firstName} ${profile.lastName}` : `${profile.email}`,
    value: profile.email,
  }));
  return (
    <div css={{ display: "flex", flexDirection: "column", width: "100%", gap: "1rem" }}>
      {!props.region.ownerEmail ? (
        <div css={{ display: "flex", flexDirection: "column", gap: "1em" }}>
          <SelectDialog
            label={"Handlowiec"}
            options={options ?? []}
            onSelect={(option) => saveCustomerMutation(option?.value as Email)}
            initiallySelectedValues={props.region.ownerEmail ? [props.region.ownerEmail] : []}
            variant="single-select"
          />
        </div>
      ) : (
        <div
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: theme.colors.primaryLight,
            padding: "8px 12px",
            borderRadius: theme.smallBorderRadius,
            marginBottom: "20px",
          }}
        >
          <div css={{ display: "flex", alignItems: "center", gap: "1em" }}>
            <img src={props.profile?.profilePictureUrl ?? undefined} alt="Profile picture" css={css(avatar)} />
            {props.profile?.firstName} {props.profile?.lastName}
          </div>
          <MdClose cursor="pointer" color={theme.colors.primary} onClick={removeOwner} />
        </div>
      )}

      <div
        css={{
          display: "flex",
          height: "46px",
          alignItems: "center",
          borderRadius: theme.smallBorderRadius,
          overflow: "hidden",
          backgroundColor: "white",
          cursor: "pointer",
        }}
        onClick={() =>
          navigate(
            `/crm/customers?filters=%7B%22page%22%3A0%2C%22searchText%22%3A%22%22%2C%22regions%22%3A%5B%22${encodeURIComponent(
              props.region.name,
            )}%22%5D%7D`,
          )
        }
      >
        <div
          css={{
            display: "flex",
            gap: "6px",
            background: theme.colors.primary,
            color: "white",
            alignItems: "center",
            height: "100%",
            padding: "6px 12px",
          }}
        >
          <MdOutlineContactPage />
          Klienci
        </div>
        <span css={{ color: theme.colors.primary, paddingLeft: "15px" }}>{props.region.customerCount}</span>
      </div>
    </div>
  );
};
