import { Uuid, UuidResourceParam } from "@legacy-megarax/common";
import { BaseResource, ResourceActionV2 } from "@legacy-megarax/rest-resource";
import { MSerializers } from "@legacy-megarax/serializers";

import list from "./list";

export interface NameChainInput {
  name: string;
}

const nameChainInputSerialized = MSerializers.object<NameChainInput>({
  name: MSerializers.string,
});

export interface ChainSimple {
  uuid: Uuid;
  name: string;
}

export const chainResource = BaseResource(["crm", "chains"], {
  byUuid: UuidResourceParam("uuid", "uuid", {
    nameChain: ResourceActionV2({
      name: "nameChain",
      method: "post",
      path: "nameChain",
      requestBodySerializer: nameChainInputSerialized,
    }),
  }),
  list: list,
});
