import { Dialog } from "@dash/dialog";
import { useDeviceType } from "@dash/mq";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { MdOutlineSettings } from "react-icons/md";

import { getIsReadAutomaticUpdateEnabled, IsReadSettings } from "./IsReadSettings";

export const isReadAutomaticUpdateEnabledKey = "isReadAutomaticUpdateEnabled";

export const Settings = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const theme = useTheme();

  const { isMobile } = useDeviceType();

  useEffect(() => {
    const isReadAutomaticUpdateEnabled = getIsReadAutomaticUpdateEnabled();

    if (isReadAutomaticUpdateEnabled === null) {
      localStorage.setItem(isReadAutomaticUpdateEnabledKey, "true");
      return;
    }
  }, []);

  if (isMobile) {
    return null;
  }

  return (
    <>
      <button
        onClick={() => setIsDialogOpen(true)}
        css={{
          background: "none",
          border: "none",
          display: "flex",
          gap: "0.375rem",
          alignItems: "center",
          fontSize: "0.875rem",
          color: theme.colors.primary,
          padding: "0.375rem 0.625rem",
          cursor: "pointer",
        }}
      >
        <MdOutlineSettings size={20} />
        Ustawienia
      </button>
      {isDialogOpen && (
        <Dialog css={{ width: "400px" }} header="Ustawienia" onClose={() => setIsDialogOpen(false)}>
          <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem", width: "100%" }}>
            <IsReadSettings />
          </div>
        </Dialog>
      )}
    </>
  );
};
