import { DescriptionField, DescriptionList } from "@dash/description-list";
import { Button, ButtonGroup } from "@dash/form";
import { ToastContext } from "@dash/toast";
import React, { useContext } from "react";
import { MdClose } from "react-icons/md";

import { BlikCheckFulfillmentDto, FulfillmentDto } from "@megaron/loyalty-orders-contracts";
import { useClientManager } from "@megaron/react-clients";

import { BaseFulfillmentCard } from "./BaseFulfillmentCard";
import { BlikCheckStatusChip } from "./BlikCheckStatusChip";
import { ShoperFulfillmentCard } from "./ShoperFulfillmentCard";

export const FulfillmentCard: React.FunctionComponent<{
  fulfillment: FulfillmentDto;
  isPro: boolean;
}> = (props) => {
  if (props.fulfillment.type === "blikCheck") return <BlikCheckFulfillmentCard fulfillment={props.fulfillment} />;
  if (props.fulfillment.type === "shoper")
    return <ShoperFulfillmentCard fulfillment={props.fulfillment} isPro={props.isPro} />;

  return null;
};

export const BlikCheckFulfillmentCard: React.FunctionComponent<{
  fulfillment: BlikCheckFulfillmentDto;
}> = (props) => {
  const toast = useContext(ToastContext);
  const cancelCheckMutation = useClientManager("loyaltyOrders")
    .cancelBlikCheck()
    .useMutation({
      onError: (error) => toast.error("Nie udało się anulować czeku BLIK", error),
      onSuccess: () => toast.info("Czek BLIK został anulowany", "Status czeku zostanie zaktualizowany wkrótce"),
    });

  return (
    <BaseFulfillmentCard
      header="Czek BLIK"
      status={<BlikCheckStatusChip status={props.fulfillment.status} />}
      actions={
        <ButtonGroup>
          <Button
            isLoading={cancelCheckMutation.isLoading}
            size="small"
            variant="outline"
            icon={<MdClose />}
            color="danger"
            onClick={() => cancelCheckMutation.mutate(props.fulfillment)}
          >
            Anuluj czek
          </Button>
        </ButtonGroup>
      }
    >
      <DescriptionList>
        <DescriptionField label="Nr zlecenia">{props.fulfillment.referenceNumber}</DescriptionField>
        <DescriptionField label="Kwota">{props.fulfillment.amount.toFixed(2)} PLN</DescriptionField>
      </DescriptionList>
    </BaseFulfillmentCard>
  );
};
