import { Uuid } from "@legacy-megarax/common";
import { Skeleton } from "@mui/material";
import React from "react";

import { ActivityCard, ActivityListEntry } from "./ActivityCard";

interface Props {
  entries: ActivityListEntry[] | undefined;
  onEventEdit: (uuid: Uuid) => void;
  onVisitSeeMore: (uuid: Uuid, customerUuid: Uuid | null, isEnded: boolean) => void;
  className?: string;
}

export const ActivityList: React.FunctionComponent<Props> = ({ className, entries, onEventEdit, onVisitSeeMore }) => {
  return (
    <div className={className}>
      {entries === undefined ? (
        <Skeleton variant="rectangular" height={120} />
      ) : (
        entries.map((entry) => (
          <ActivityCard entry={entry} key={entry.uuid} onEventEdit={onEventEdit} onVisitSeeMore={onVisitSeeMore} />
        ))
      )}
    </div>
  );
};
