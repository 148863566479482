import { useTheme } from "@emotion/react";
import { ScheduleVisitDto } from "@legacy-megarax/crm-contracts";

import { UpcomingVisitTile } from "./UpcomingVisitTile";

type Props = {
  label: string;
  visits: ScheduleVisitDto[];
};

export const DayUpcomingVisits: React.FC<Props> = ({ label, visits }) => {
  const theme = useTheme();

  if (visits.length === 0) return null;

  return (
    <div css={{ display: "flex", flexDirection: "column", width: "100%", gap: "1rem" }}>
      <div
        css={{
          position: "relative",
          padding: "4px 0",
          display: "flex",
          alignItems: "center",
          gap: "0.625rem",
          fontSize: "12px",
          color: "rgba(0, 0, 0, 0.3)",
        }}
      >
        <hr
          css={{
            width: "100%",
            height: 0,
            border: "none",
            borderBottom: `1px dashed rgba(0, 0, 0, 0.3)`,
            position: "absolute",
            left: "0",
            top: "50%",
            margin: 0,
            transform: "translate(0, -50%)",
          }}
        />
        <div css={{ margin: "0 auto", background: theme.colors.background, zIndex: 1, padding: "0 8px" }}>{label}</div>
      </div>

      <div css={{ display: "flex", flexDirection: "column", width: "100%", gap: "0.625rem" }}>
        {visits.map((visit) => (
          <UpcomingVisitTile key={visit.uuid} customerId={visit.customerUuid} scheduleTime={visit.startTime} />
        ))}
      </div>
    </div>
  );
};
