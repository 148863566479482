import { DescriptionField, DescriptionList } from "@dash/description-list";
import { QuerySkeleton } from "@dash/skeleton";
import { ProductTile } from "@megarax/crm-purchases";
import { formatDistanceToNow } from "date-fns";
import { pl } from "date-fns/locale";
import React from "react";
import { useParams as useParamsCompat } from "react-router-dom-v5-compat";

import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

type Props = {
  loyaltyUuid: Uuid | null;
  isCustomerArchived: boolean;
};

export const LoyaltyDetails: React.FC<Props> = (props) => {
  const { customerId }: { customerId?: Uuid } = useParamsCompat();

  if (!customerId) {
    return <div>Error: Customer ID not provided.</div>;
  }

  return <LoyaltyDetailsContent customerId={customerId} {...props} />;
};

const LoyaltyDetailsContent: React.FC<Props & { customerId: Uuid }> = (props) => {
  const purchaseFunnels = useClientManager("crm")
    .getCustomerPurchaseFunnels()
    .useQuery({ customerId: props.customerId });
  const handleRefetch = () => {
    purchaseFunnels.refetch();
  };

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <DescriptionList itemMinWidth="110px">
        <DescriptionField label="Ostatnie 3 miesiące">
          {purchaseFunnels?.data?.lastThreeMonthsTotalMassKg?.toString()} kg
        </DescriptionField>
        <DescriptionField label="Ostatni zakup">
          {purchaseFunnels.data && purchaseFunnels.data.lastPurchaseTime
            ? formatDistanceToNow(purchaseFunnels.data.lastPurchaseTime, {
                locale: pl,
              }) + " temu"
            : "-"}
        </DescriptionField>
        <DescriptionField label="Całość">{purchaseFunnels?.data?.totalMassKg?.toString()} kg</DescriptionField>
      </DescriptionList>
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))",
          gap: "0.75em",
        }}
      >
        <QuerySkeleton query={purchaseFunnels} height="78px">
          {(funnels) =>
            funnels.funnels.map((productData, i) => (
              <ProductTile
                productData={productData}
                customerId={props.customerId}
                onRefetch={handleRefetch}
                key={i}
                isCustomerArchived={props.isCustomerArchived}
              />
            ))
          }
        </QuerySkeleton>
      </div>
    </div>
  );
};
