import { DateString, dateStringSerializer, Uuid, uuidSerializer } from "@legacy-megarax/common";
import { Serializers } from "@legacy-megarax/serializers";
import Decimal from "decimal.js";

import { currencies, Currency, Gtin, gtinSerializer } from "../common";

export type OrderDto = {
  uuid: Uuid;
  referenceNumber: string;
  sellToCustomerUuid: Uuid | null;
  billToCustomerUuid: Uuid | null;
  lines: OrderLineDto[];
  currency: Currency;
  orderDate: DateString;
  netTotal: Decimal;
  updatedAt: Date;
  createdAt: Date;

  placedByUserId: number | null;
};

export type OrderLineDto = {
  uuid: Uuid;
  tradeItemUuid: Uuid;
  tradeItemGtin: Gtin | null;
  tradeItemName: string | null;
  quantity: Decimal;
  price: Decimal;
};

export const orderLineDtoSerializer = Serializers.object<OrderLineDto>({
  price: Serializers.decimal,
  quantity: Serializers.decimal,
  tradeItemUuid: uuidSerializer,
  uuid: uuidSerializer,
  tradeItemGtin: gtinSerializer.nullable(),
  tradeItemName: Serializers.string.nullable(),
});

export const orderDtoSerializer = Serializers.object<OrderDto>({
  uuid: uuidSerializer,
  referenceNumber: Serializers.string,
  sellToCustomerUuid: uuidSerializer.nullable(),
  billToCustomerUuid: uuidSerializer.nullable(),
  lines: Serializers.array(orderLineDtoSerializer),
  currency: Serializers.stringOneOf(...currencies),
  netTotal: Serializers.decimal,
  orderDate: dateStringSerializer,
  updatedAt: Serializers.datetime,
  createdAt: Serializers.datetime,
  placedByUserId: Serializers.integer.nullable(),
});
