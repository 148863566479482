import { Uuid } from "@legacy-megarax/common";
import {
  customerListResource,
  PriceReportInput,
  priceReportResource,
  priceReportSubjectResource,
} from "@legacy-megarax/crm-contracts";
import { useResourceProviderV2 } from "@legacy-megarax/react-client";
import { useAsyncLoad } from "@legacy-megarax/react-utils";
import {
  BreadcrumbMarker,
  commonErrorsMap,
  FeedbackSnackbarContext,
  useSnackbarErrorHandler,
} from "@legacy-megarax/ui-components";
import { useCrmGroupsResource } from "libs/@megarax/crm/resource-hooks/src";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom-v5-compat";

import {
  GetCustomers,
  GetPreviousReports,
  GetPriceReportSubjects,
  PriceReportForm,
  SubmitPriceReport,
} from "./PriceReportForm";

export const PriceReportFormContainer: React.FC = () => {
  const feedbackSnackbar = useContext(FeedbackSnackbarContext);
  const customerProvider = useResourceProviderV2(customerListResource);
  const priceReportProvider = useResourceProviderV2(priceReportResource);
  const priceReportSubjectProvider = useResourceProviderV2(priceReportSubjectResource);
  const { getMyGroups } = useCrmGroupsResource();

  const { pathname } = useLocation();

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
  });

  const { value: myGroups } = useAsyncLoad(getMyGroups, []);

  const getCustomers: GetCustomers = (search: string) =>
    customerProvider.list({ searchText: search }).then((result) => result.assertOk().value.items);

  const getPriceReportSubjects: GetPriceReportSubjects = (search: string) =>
    priceReportSubjectProvider
      .search({ searchText: search, limit: 20, offset: 0 })
      .mapError((error) => {
        handleError(error);
        return Promise.reject(error);
      })
      .map((result) => result.items)
      .then((items) => items.value);

  const submitPriceReport: SubmitPriceReport = (uuid: Uuid, input: PriceReportInput) =>
    priceReportProvider
      .byUuid(uuid)
      .add(undefined, input)
      .mapError(handleError)
      .map((result) => {
        feedbackSnackbar.pushMessage({
          severity: "success",
          content: "Pomyślnie wysłano ankietę cenową.",
        });
        return result;
      })
      .then();

  const getPreviousReports: GetPreviousReports = (customerUuid) =>
    priceReportProvider
      .myPastReports({ customerUuid })
      .mapError((error) => {
        handleError(error);
        return Promise.reject(error);
      })
      .then((items) => items.value);

  return (
    <>
      <BreadcrumbMarker title="Moje ankiety cenowe" to={pathname} id="myPriceReports" />
      <PriceReportForm
        myRegions={myGroups?.isOk ? myGroups.value.map((item) => item.regionUuid) : []}
        getCustomers={getCustomers}
        getPriceReportSubjects={getPriceReportSubjects}
        submitPriceReport={submitPriceReport}
        getPreviousReports={getPreviousReports}
      />
    </>
  );
};
