import { Button } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { Page, PageHeader } from "@dash/page";
import { QuerySkeleton } from "@dash/skeleton";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom-v5-compat";

import { useClientManager } from "@megaron/react-clients";

import { AllDraftsMobileList } from "./AllDraftsMobileList";
import { AllDraftsTable } from "./AllDraftsTable";

export const AllDraftsPage = () => {
  const navigate = useNavigate();

  const { isMobile } = useDeviceType();

  const draftsQuery = useClientManager("invoices").draftsQuery().useQuery({});

  const onNewClick = () => navigate("/faktury/wersje-robocze/nowa");

  return (
    <Page
      css={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        gap: "1rem",
      }}
    >
      <PageHeader
        actions={
          <div css={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            <Button icon={<MdAdd />} onClick={onNewClick}>
              Dodaj
            </Button>
          </div>
        }
      >
        Wersje robocze
      </PageHeader>

      <QuerySkeleton query={draftsQuery}>
        {(drafts) =>
          isMobile ? (
            <AllDraftsMobileList drafts={drafts} queryKey={draftsQuery.key} />
          ) : (
            <AllDraftsTable drafts={drafts} isLoading={!draftsQuery.data} queryKey={draftsQuery.key} />
          )
        }
      </QuerySkeleton>
    </Page>
  );
};
