import { useDeviceType } from "@dash/mq";
import { useTheme } from "@emotion/react";
import { PropsWithChildren } from "react";
import tinycolor from "tinycolor2";

import { Pagination } from "./Pagination";
import { SearchBox } from "./SearchBox";

type Props = {
  onSearchChange: (value: string) => void;
  searchValue: string;
  autofocus?: boolean;
  className?: string;
  isSticky?: boolean;
  top?: number;
} & (
  | {
      itemCount: number | undefined;
      pageSize: number;
      page: number;
      onPageChange: (page: number) => void;
      hidePagination?: false;
    }
  | {
      itemCount?: undefined;
      pageSize?: undefined;
      page?: undefined;
      onPageChange?: undefined;
      hidePagination: true;
    }
) &
  PropsWithChildren;

export const SearchWithPagination: React.FC<Props> = ({
  onSearchChange,
  searchValue,
  autofocus,
  pageSize,
  itemCount,
  page,
  onPageChange,
  children,
  className,
  isSticky,
  top,
  hidePagination,
}) => {
  const theme = useTheme();

  const { isMobile, isTablet } = useDeviceType();

  return (
    <div
      id="search-with-pagination"
      className={className}
      css={{
        display: "flex",
        width: isSticky ? "auto" : "100%",
        boxShadow: isSticky ? "0px 2px 4px 0px rgba(0, 0, 0, 0.15)" : "none",
        alignItems: "stretch",
        borderRadius: theme.smallBorderRadius,
        backgroundColor: "white",
        overflow: "hidden",
        flexDirection: isMobile ? "column" : "row",
        position: isSticky ? "fixed" : "static",
        top: isSticky ? `${top}px` : 0,
        zIndex: 1000,
        right: isSticky ? (isTablet || isMobile ? "1rem" : "2rem") : 0,
        left: isSticky ? (isTablet || isMobile ? "1rem" : "292px") : 0,
        border: isSticky ? "none" : `1px solid ${tinycolor("#000000").setAlpha(0.1)}`,
      }}
    >
      <div
        css={{
          display: "flex",
          width: "100%",
          alignItems: "stretch",
        }}
      >
        <SearchBox
          onChange={onSearchChange}
          value={searchValue}
          css={{ width: "100%", flex: "1" }}
          autoFocus={autofocus}
        />
        {!isMobile && <Divider />}
        {!hidePagination && (
          <Pagination
            page={page}
            onPageChange={onPageChange}
            pageSize={pageSize}
            itemCount={itemCount}
            css={{ display: isMobile ? "none" : "flex" }}
          />
        )}
        {children && (
          <>
            <Divider />
            {children}
          </>
        )}
      </div>
      {isMobile && <div css={{ width: "100%", height: "1px", backgroundColor: theme.colors.primaryLight }} />}
      {!hidePagination && (
        <Pagination
          page={page}
          onPageChange={onPageChange}
          pageSize={pageSize}
          itemCount={itemCount}
          css={{ display: isMobile ? "flex" : "none" }}
        />
      )}
    </div>
  );
};

const Divider = () => {
  const theme = useTheme();

  return <div css={{ width: "1px", alignSelf: "stretch", backgroundColor: theme.colors.primaryLight }} />;
};
