import { SalespersonSimple } from "@legacy-megarax/crm-contracts";
import { ProfilesDictionary } from "@legacy-megarax/iam-webapp";
import { NewItemFab, QsOutput, SearchBar, SelectChip } from "@legacy-megarax/ui-components";
import { FilterList as FilterIcon } from "@mui/icons-material";
import { Box, useMediaQuery } from "@mui/material";
import React from "react";

import { QueryFilters } from "./TradeRoutesHomeContainer";

interface Props {
  qs: QueryFilters;
  setQs: (key: keyof QueryFilters, value: QueryFilters[keyof QueryFilters]) => void;
  profiles: ProfilesDictionary;
  salespeople: SalespersonSimple[];
  onClickNew: () => void;
}

export const TopBar: React.FC<Props> = ({ qs, setQs, profiles, salespeople, onClickNew }) => {
  const isDesktop = useMediaQuery("(min-width:640px)");

  const Filters = () => (
    <Box px={2} py={1} display="flex" sx={{ gap: 1 }} alignItems="center" justifyContent="flexStart" flexWrap="nowrap">
      <FilterIcon />
      <SelectChip
        label={"Handlowiec"}
        value={qs.salespersonId ?? null}
        options={salespeople.map((salesperson) => ({
          label: profiles[salesperson.userId]?.displayedName ?? "",
          value: salesperson.userId,
        }))}
        onChange={(value) => setQs("salespersonId", value ? value.toString() : undefined)}
      />
    </Box>
  );

  const NewItemButton = () => <NewItemFab onClick={() => onClickNew()} text="Nowa" />;

  if (!isDesktop)
    return (
      <Box py={2}>
        <Box display="flex" px={2} sx={{ gap: "8px" }} justifyContent="space-between" alignItems="center">
          <SearchBar onSearchChange={(value) => setQs("searchText", value)} initialValue={qs.searchText} />
          <NewItemButton />
        </Box>
        <Filters />
      </Box>
    );
  return (
    <Box p={2} display="flex" sx={{ gap: 1 }} alignItems="center" justifyContent="flexStart">
      <SearchBar onSearchChange={(value) => setQs("searchText", value)} initialValue={qs.searchText} />
      <Filters />
      <Box ml="auto">
        <Box>
          <NewItemButton />
        </Box>
      </Box>
    </Box>
  );
};
