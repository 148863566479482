import { useDeviceType } from "@dash/mq";
import { RowCellContent, Table, TableBody, TableHeadColumn } from "@dash/table";
import { Tile } from "@dash/tile";
import { useTheme } from "@emotion/react";
import { renderUserUuid } from "@megarax/loyalty-users-admin";
import { MdOutlineFilterAlt } from "react-icons/md";
import { Link } from "react-router-dom-v5-compat";

import { OrderDto } from "@megaron/loyalty-orders-contracts";
import { Uuid } from "@megaron/uuid";

import { FulfillmentChip } from "./OrderList";

type Props = {
  orders: OrderDto[];
  onUserClick: (value: Uuid | undefined) => void;
  onRewardClick: (value: string) => void;
};

export const OrdersTable: React.FC<Props> = ({ orders, onRewardClick, onUserClick }) => {
  const theme = useTheme();

  const { isMobile } = useDeviceType();

  const getRowLink = (order: OrderDto) => `/specjalista/orders/id/${order.purchaseUuid}`;

  const getRowsContent = (item: OrderDto): RowCellContent[] => [
    {
      isLink: false,
      element: (
        <div css={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <FilterButton onClick={() => onRewardClick(item.rewardId)} />
          <Link
            to={getRowLink(item)}
            css={{ textDecoration: item.isCancelled ? "line-through" : "none", color: theme.colors.primary }}
          >
            {item.rewardId}
          </Link>
        </div>
      ),
    },
    {
      isLink: true,
      element: item.orderedAt.toLocaleString(),
    },
    {
      isLink: true,
      element: (
        <div css={{ display: "flex", gap: "0.25rem" }}>
          {item.fulfillments.map((f) => (
            <FulfillmentChip fulfillment={f} />
          ))}
        </div>
      ),
    },
    {
      cellCss: { color: theme.colors.primary },
      element: (
        <div css={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <Link to={`/crm/customers/id/${item.userUuid}`}>{renderUserUuid(item.userUuid)}</Link>
          <FilterButton onClick={() => onUserClick(item.userUuid)} />
        </div>
      ),
    },
  ];

  if (isMobile) {
    return (
      <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem", width: "100%" }}>
        {orders.map((order) => (
          <Tile>
            <div css={{ display: "flex", flexDirection: "column", gap: "0.5rem", overflow: "hidden" }}>
              <div css={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
                <Link
                  to={getRowLink(order)}
                  css={{
                    textDecoration: order.isCancelled ? "line-through" : "none",
                    color: theme.colors.primary,
                    fontWeight: 600,
                  }}
                >
                  {order.rewardId}
                </Link>
                <FilterButton onClick={() => onRewardClick(order.rewardId)} />
              </div>
              {order.fulfillments.length > 0 && (
                <div css={{ display: "flex", gap: "0.25rem" }}>
                  {order.fulfillments.map((f) => (
                    <FulfillmentChip fulfillment={f} />
                  ))}
                </div>
              )}
              {order.orderedAt.toLocaleString()}
              <div css={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
                <Link
                  to={`/crm/customers/id/${order.userUuid}`}
                  css={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                >
                  {order.userUuid}
                </Link>
                <FilterButton onClick={() => onUserClick(order.userUuid)} />
              </div>
            </div>
          </Tile>
        ))}
      </div>
    );
  }

  return (
    <Table columns={columns}>
      <TableBody
        rows={orders.map((order) => ({
          cellsContent: getRowsContent(order),
          linkTo: getRowLink(order),
          uuid: order.purchaseUuid,
          css: {
            "&:hover": {
              "td:nth-child(1)": {
                textDecoration: "underline",
              },
            },
            "td:first-child": {
              padding: "0.75rem",
            },
          },
        }))}
      />
    </Table>
  );
};

const columns: TableHeadColumn<string>[] = [
  { label: "Nagroda" },
  { label: "Data" },
  { label: "Realizacja" },
  { label: "Użytkownik" },
];

const FilterButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const theme = useTheme();

  return (
    <button
      css={{
        border: "none",
        background: "none",
        padding: "0.125rem",
        display: "flex",
        cursor: "pointer",
        opacity: 0.7,
        ":hover": { opacity: 1 },
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
    >
      <MdOutlineFilterAlt color={theme.colors.primary} size={18} />
    </button>
  );
};
