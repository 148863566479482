import { Dialog, useDialogRoute } from "@dash/dialog";
import { Button, Slider } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { DottedProgress } from "@dash/progress";
import { useTheme } from "@emotion/react";
import Decimal from "decimal.js";
import { useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { useQueryClient } from "react-query";

import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

type Props = {
  label: string;
  goalValue: number | null;
  currentValue: number;
  customerId: Uuid;
  queryKey: string | string[];
};

export const ProductGroupSaleTile: React.FC<Props> = ({ label, goalValue, currentValue, customerId, queryKey }) => {
  const theme = useTheme();

  const editProductGroupDialog = useDialogRoute(`/grupa/${label}`, ({ onClose }) => (
    <EditProductGroupDialog
      onClose={onClose}
      label={label}
      currentValue={currentValue}
      goalValue={goalValue}
      customerId={customerId}
      queryKey={queryKey}
    />
  ));

  return (
    <>
      <button
        onClick={editProductGroupDialog.open}
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          padding: "0.75rem",
          background: "none",
          border: "none",
          width: "100%",
          cursor: "pointer",
        }}
      >
        <div
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: "0.875rem",
            width: "100%",
          }}
        >
          <span css={{ color: theme.colors.primary, fontWeight: 700 }}>{label}</span>
          <span css={{ color: theme.colors.secondaryText }}>
            {currentValue} {goalValue !== null && `/ ${goalValue} zł`}
          </span>
        </div>

        {goalValue === null ? (
          <span
            css={{
              fontSize: "12px",
              color: theme.colors.secondaryText,
              fontWeight: 400,
              opacity: 0.5,
              lineHeight: "12px",
            }}
          >
            Nieznany potencjał
          </span>
        ) : goalValue > 0 || currentValue > 500 ? (
          <DottedProgress
            singleDotValue={1000}
            goalValue={goalValue > 0 ? goalValue : 1000}
            currentValue={currentValue}
            allowWrap
          />
        ) : (
          <span
            css={{
              fontSize: "12px",
              color: theme.colors.secondaryText,
              fontWeight: 400,
              opacity: 0.5,
              lineHeight: "12px",
            }}
          >
            Brak potencjału
          </span>
        )}
      </button>
      {editProductGroupDialog.element}
    </>
  );
};

const EditProductGroupDialog: React.FC<{ onClose: () => void } & Props> = ({
  onClose,
  label,
  goalValue,
  currentValue,
  customerId,
  queryKey,
}) => {
  const { isMobile } = useDeviceType();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const [newGoalValue, setNewGoalValue] = useState<number | null>(goalValue ?? 0);

  const savePotentialMutation = useClientManager("crm").saveCustomerPotential().useMutation();

  const savePotential = (potential: number | null) => {
    savePotentialMutation.mutate(
      {
        customerId: customerId,
        productGroup: label,
        potential: potential !== null ? new Decimal(potential) : null,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
        },
      },
    );
  };

  const handleChange = (value: number) => {
    setNewGoalValue(value);
    savePotential(value);
  };

  const handleRemoveGoalValue = () => {
    setNewGoalValue(null);
    savePotential(null);
  };

  return (
    <Dialog
      hideHeader
      placement={isMobile ? "top" : "center"}
      onClose={onClose}
      css={{ width: isMobile ? "100%" : "380px" }}
    >
      <div css={{ display: "flex", flexDirection: "column", width: "100%", gap: "0.75rem" }}>
        <div css={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
          <div css={{ display: "flex", alignItems: "center", justifyContent: "space-between", fontSize: "0.875rem" }}>
            <span css={{ color: theme.colors.primary, fontWeight: 700 }}>{label}</span>
            <span css={{ color: theme.colors.secondaryText }}>
              {currentValue} {goalValue !== null && `/ ${goalValue}`}
            </span>
          </div>

          {goalValue === null ? (
            <span
              css={{
                fontSize: "12px",
                color: theme.colors.secondaryText,
                fontWeight: 400,
                opacity: 0.5,
                lineHeight: "12px",
              }}
            >
              Nieznany potencjał
            </span>
          ) : goalValue > 0 ? (
            <DottedProgress singleDotValue={1000} goalValue={goalValue} currentValue={currentValue} allowWrap />
          ) : (
            <span
              css={{
                fontSize: "12px",
                color: theme.colors.secondaryText,
                fontWeight: 400,
                opacity: 0.5,
                lineHeight: "12px",
              }}
            >
              Brak potencjału
            </span>
          )}
        </div>
        <hr
          css={{
            border: "none",
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            width: "100%",
            height: 0,
            margin: 0,
            "&:last-child": {
              display: "none",
            },
          }}
        />
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "0.625rem",
            width: "100%",
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: theme.colors.primary,
              width: "100%",
              gap: "0.5rem",
            }}
          >
            <span css={{ fontSize: "0.75rem" }}>Potencjał</span>

            <Slider
              value={newGoalValue ?? 0}
              min={0}
              max={30000}
              step={5000}
              onChange={handleChange}
              showSteps
              showTooltip
            />

            {goalValue !== null && (
              <div
                css={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  marginTop: "12px",
                  position: "relative",
                }}
              >
                <Button
                  onClick={handleRemoveGoalValue}
                  icon={<MdDeleteOutline />}
                  variant="outline"
                  css={{
                    fontSize: "0.75rem",
                    bottom: "0.5rem",
                    right: "0.5rem",
                    padding: "0.25rem 0.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.25rem",
                  }}
                >
                  Usuń potencjał
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
