import { Button } from "@dash/form";
import { useState } from "react";
import { MdOutlinePrint } from "react-icons/md";

import { Uuid } from "@megaron/uuid";

import { PrintViewContainer } from "../thread/PrintView/PrintViewContainer";

type Props = {
  threadUuid: Uuid;
};

export const PrintButton: React.FC<Props> = ({ threadUuid }) => {
  const [print, setPrint] = useState<{
    uuid: string | undefined;
    active: boolean;
  }>({ uuid: undefined, active: false });

  const onPrint = () => {
    setPrint({ active: true, uuid: threadUuid });
  };

  const onPrintEnd = () => setPrint({ active: false, uuid: undefined });

  return (
    <>
      <Button
        onClick={onPrint}
        variant="outline"
        css={{ padding: "0.25rem", height: "1.5rem" }}
        icon={<MdOutlinePrint size={14} />}
      ></Button>
      {print.active && print.uuid && <PrintViewContainer threadUuid={threadUuid} onPrintEnd={onPrintEnd} />}
    </>
  );
};
