import { AutocompleteTextField, Button } from "@dash/form";
import { Spinner } from "@dash/spinner";
import { useToast } from "@dash/toast";
import { useTheme } from "@emotion/react";
import { useCallback } from "react";
import { MdCheck } from "react-icons/md";
import { useNavigate } from "react-router-dom-v5-compat";
import tinycolor from "tinycolor2";

import { DraftDto } from "@megaron/invoices-contracts";
import { useClientManager, useServiceClient } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { DraftData, useDraftForm } from "./useDraftForm";

type Props = {
  draft: DraftDto;
  isFetching: boolean;
  setDraft: ReturnType<typeof useDraftForm>["setDraft"];
  draftData: DraftData;
  isLoading: boolean;
};

export const CreateDraftForm: React.FC<Props> = ({ draft, isFetching, draftData, setDraft, isLoading }) => {
  const theme = useTheme();

  const toast = useToast();

  const docs = useServiceClient("docs");

  const navigate = useNavigate();

  const createThreadMutate = useClientManager("invoices").startNewThread().useMutation();

  const handleCreateThread = useCallback(() => {
    const { title, invoiceNumber, issuer, receivedDate, total, issueDate, dueDate, isPayed } = draftData;

    if (!title || !invoiceNumber || !issueDate || !issuer || !receivedDate || !total || (!isPayed && !dueDate)) {
      toast.error("Uzupełnij brakujące pola w formularzu");
      return;
    }

    createThreadMutate.mutate(
      {
        draftUuid: Uuid(draft.uuid),
      },
      {
        onError: () => {
          toast.error("Nie udało się utworzyć wątku");
        },
        onSuccess: () => {
          navigate(`/faktury/watki/${draft.uuid}`, { replace: true });
        },
      },
    );
  }, [draftData, createThreadMutate, draft.uuid, toast, navigate]);

  const isCreateDisabled = isLoading || isFetching || createThreadMutate.isLoading;

  return (
    <form
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "0.625rem",
        padding: "1rem",
        borderRadius: theme.smallBorderRadius,
        border: `1px solid ${tinycolor("#000000").setAlpha(0.1)}`,
        background: "white",
      }}
    >
      <AutocompleteTextField
        label="Tutuł wątku"
        value={draftData.title ?? ""}
        onChange={setDraft("title")}
        getOptions={async (searchText) => {
          const results = await docs.autocomplete({ text: searchText, doctype: "invoice-thread", field: "title" });

          return results.value || [];
        }}
        keyName="title"
      />
      <Button
        isDisabled={isCreateDisabled}
        icon={isCreateDisabled ? <Spinner size="16px" color="white" /> : <MdCheck size={20} />}
        css={{ marginTop: "1rem" }}
        onClick={(e) => {
          e.preventDefault();
          if (isCreateDisabled) {
            return;
          }

          handleCreateThread();
        }}
      >
        Utwórz wątek
      </Button>
    </form>
  );
};
