import { EntityList } from "@dash/entity-list";
import { Button, TextField } from "@dash/form";
import { Page, PageHeader, SectionHeader } from "@dash/page";
import { QuerySkeleton, ResultSkeleton } from "@dash/skeleton";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom-v5-compat";
import { Observable } from "rxjs";

import { useClientManager, useServiceClient } from "@megaron/react-clients";

import { CameraCard } from "./CameraCard";
import { IoModuleCard } from "./IoModuleCard";

export const LinePage = () => {
  const lineId = useParams<{ lineId: string }>().lineId;
  if (!lineId) throw new Error("No lineId");

  const client = useServiceClient("qrSync");
  type LineQueryResult = ReturnType<typeof client.lineQuery>["observable"] extends Observable<infer T> ? T : never;
  const [lineResult, setLineResult] = React.useState<LineQueryResult>();
  const [sseStatus, setSseStatus] = React.useState<boolean>(false);

  const groupsQuery = useClientManager("qrSync").groupsQuery().useQuery(
    {},
    {
      refetchInterval: 3000,
    },
  );

  useEffect(() => {
    const sse = client.lineQuery({ lineId });

    sse.observable.subscribe(setLineResult);
    sse.statusObservable.subscribe(setSseStatus);

    return () => {
      sse.stop();
    };
  }, []);

  const suffixSuggestions = ["czyściwo", "S-8_20", "C-50s_5", "Dv-21_17", "Gv-15_20", "inne"];
  const selectedSuffix = lineResult?.value?.groupNameSuffix;

  const changeSuffixMutation = useClientManager("qrSync").changeGroupNameSuffix().useMutation();

  return (
    <Page css={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
      <PageHeader>{lineResult?.value?.name ?? ""}</PageHeader>
      <ResultSkeleton result={sseStatus ? lineResult : undefined}>
        {(line) => (
          <div css={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            <IoModuleCard isConnected={line.isIoConnected} />
            <TextField label="Nazwa grupy" value={line.groupName} onChange={() => null} />
            <div css={{ display: "flex", flexDirection: "row" }}>
              {suffixSuggestions.map((suffix) => (
                <Button
                  onClick={() => changeSuffixMutation.mutate({ lineId, groupNameSuffix: suffix })}
                  variant={suffix === selectedSuffix ? "outline" : "text"}
                >
                  {suffix}
                </Button>
              ))}
            </div>
          </div>
        )}
      </ResultSkeleton>
      <SectionHeader isHr>Kamery</SectionHeader>
      <ResultSkeleton result={sseStatus ? lineResult : undefined}>
        {(line) => (
          <div css={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            {line.cameras.map((camera) => (
              <CameraCard
                key={camera.id}
                cameraId={camera.id}
                lineId={lineId}
                name={camera.id}
                scanStatus={camera.status}
                isConnected={camera.isConnected}
              />
            ))}
          </div>
        )}
      </ResultSkeleton>
      <QuerySkeleton query={groupsQuery}>
        {(groups) => (
          <>
            <SectionHeader isHr>Grupy</SectionHeader>
            <EntityList
              items={groups}
              getKey={(group) => group.groupName}
              columns={(group) => [
                { content: group.groupName, header: "Grupa" },
                { content: group.totalCount, header: "Wielkość" },
              ]}
            />
          </>
        )}
      </QuerySkeleton>
    </Page>
  );
};
