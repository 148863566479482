import { EntityList } from "@dash/entity-list";
import { SearchBox } from "@dash/search";
import { QuerySkeleton } from "@dash/skeleton";
import React, { useState } from "react";
import { Link } from "react-router-dom-v5-compat";

import { GroupSimpleDto } from "@megaron/auth-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { getGroupPath } from "./paths";

type Props<TSelected> =
  | {
      isSelectable: true;
      onSelect: (groups: (GroupSimpleDto | TSelected)[]) => void;
      selected: TSelected[];
    }
  | { isSelectable?: false; onSelect?: never; selected?: never };

export const GroupList = <TSelected extends { id: Uuid }>(props: Props<TSelected>) => {
  const [search, setSearch] = useState("");
  const groupsQuery = useClientManager("auth").groupsQuery().useQuery({ searchText: search });

  return (
    <>
      <SearchBox onChange={setSearch} value={search} css={{ marginBottom: "1rem" }} />
      <QuerySkeleton query={groupsQuery}>
        {(groups) => (
          <EntityList
            items={groups}
            getKey={(g) => g.id}
            {...props}
            columns={(item) => [
              {
                header: "Stanowisko",
                isPrimary: true,
                content: <Link to={getGroupPath(item.id)}>{item.name}</Link>,
              },
            ]}
          />
        )}
      </QuerySkeleton>
    </>
  );
};
