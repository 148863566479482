import { Uuid } from "@legacy-megarax/common";
import { NamedTradeRoute, SalespersonSimple } from "@legacy-megarax/crm-contracts";
import { HttpRequestError } from "@legacy-megarax/http-client";
import { ProfilesDictionary } from "@legacy-megarax/iam-webapp";
import {
  AreUSureWrapper,
  FeedbackSnackbarContext,
  NewRouterResponsiveSelect,
  PaginationV2,
  TextInput,
} from "@legacy-megarax/ui-components";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";

import { Result } from "@megaron/result";

import { formatGoogleDuration } from "../utils";
import { TopBar } from "./TopBar";
import { perPage, QueryFilters, SetQueryFilters } from "./TradeRoutesHomeContainer";

interface Props {
  tradeRoutes:
    | Result<
        {
          count: number;
          items: NamedTradeRoute[];
        },
        HttpRequestError
      >
    | undefined;
  qs: QueryFilters;
  renameTradeRoute: (uuid: Uuid, name: string) => Promise<void>;
  archiveTradeRoute: (uuid: Uuid) => Promise<void>;
  setQs: SetQueryFilters;
  profiles: ProfilesDictionary;
  salespeople: SalespersonSimple[];
  navigation: {
    navigateToNew: () => void;
    navigateToMapView: (tradeRouteUuid: Uuid) => void;
  };
}

export const TradeRoutesHome: React.FC<Props> = ({
  tradeRoutes,
  qs,
  setQs,
  profiles,
  salespeople,
  navigation,
  archiveTradeRoute,
  renameTradeRoute,
}) => {
  return (
    <Container maxWidth="lg" disableGutters>
      <TopBar
        salespeople={salespeople}
        profiles={profiles}
        qs={qs}
        setQs={setQs}
        onClickNew={navigation.navigateToNew}
      />
      <Box p={2} display="flex" flexDirection="column" sx={{ gap: 1 }}>
        {tradeRoutes?.error && (
          <Typography textAlign="center" sx={{ py: 2, fontSize: "0.8rem" }} color="textSecondary">
            {`Wystąpił błąd podczas ładowania tras :( \n(${tradeRoutes.error})`}
          </Typography>
        )}
        {tradeRoutes?.value?.items.length === 0 && (
          <Typography textAlign="center" sx={{ py: 2, fontSize: "0.8rem" }} color="textSecondary">
            {"Brak rekordów."}
          </Typography>
        )}
        {tradeRoutes?.value?.items.map((route) => {
          return (
            <Paper key={route.uuid} sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Box display="flex" sx={{ gap: 0.5 }} alignItems="center">
                  <Typography>{`${route.name}`}</Typography>
                  <PersonIcon sx={{ color: "darkgrey", ml: 1 }} fontSize="small" />
                  <Typography color="textSecondary" variant="body2">
                    {profiles[route.salespersonId]?.displayedName ?? ""}
                  </Typography>
                </Box>
                <Box display="flex" sx={{ gap: 2 }}>
                  <Typography variant="caption">{formatGoogleDuration(route.fullDurationMinutes * 60, 0)}</Typography>
                  <Typography variant="caption">{route.distanceMeters / 1000} km</Typography>
                </Box>
              </Box>
              <Box display="flex" sx={{ gap: 1 }}>
                <IconButton onClick={() => navigation.navigateToMapView(route.uuid)}>
                  <SearchIcon />
                </IconButton>
                <NameSelectDialog
                  action={(name) => renameTradeRoute(route.uuid, name)}
                  disabled={false}
                  initialName={route.name}
                >
                  <IconButton>
                    <DriveFileRenameOutlineIcon />
                  </IconButton>
                </NameSelectDialog>
                <AreUSureWrapper action={() => archiveTradeRoute(route.uuid)}>
                  <IconButton>
                    <DeleteOutlineIcon />
                  </IconButton>
                </AreUSureWrapper>
              </Box>
            </Paper>
          );
        })}
      </Box>
      <Box>
        <PaginationV2
          perPage={perPage}
          allItemsCount={tradeRoutes?.value?.count}
          onChange={(page) => setQs("page", page)}
          page={qs.page}
        />
      </Box>
    </Container>
  );
};

export const SalespersonSelectDialog: React.FC<{
  initialSalesperson?: number;
  action: (salespersonId: number) => void;
  salespeople: SalespersonSimple[];
  profiles: ProfilesDictionary;
  children?: React.ReactNode;
}> = (props) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const [selectedSalesperson, setSelectedSalesperson] = React.useState<number | null>(props.initialSalesperson ?? null);

  const onAccept = () => {
    if (!selectedSalesperson) return;
    props.action(selectedSalesperson);
    setIsOpen(false);
  };

  React.useEffect(() => {
    if (isOpen) setSelectedSalesperson(props.initialSalesperson ?? null);
  }, [isOpen]);

  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} fullWidth maxWidth="xs">
        <DialogTitle>Wybierz handlowca</DialogTitle>
        <DialogContent>
          <NewRouterResponsiveSelect
            initialValue={props.initialSalesperson}
            getOptions={() => Promise.resolve(props.salespeople.map((item) => item.userId))}
            value={selectedSalesperson}
            onChange={setSelectedSalesperson}
            getKey={(i) => i}
            getValue={(i) => i}
            getLabel={(i) => props.profiles[i]?.displayedName ?? "Nieznany"}
          />
        </DialogContent>
        <DialogActions>
          <Box mr={"auto"}>
            <Button onClick={() => setIsOpen(false)}>Anuluj</Button>
          </Box>
          <Button onClick={onAccept} color={"primary"}>
            Przejdź do tras
          </Button>
        </DialogActions>
      </Dialog>
      <Box onClick={() => setIsOpen(true)}>{props.children}</Box>
    </>
  );
};

const NameSelectDialog: React.FC<{
  action: (name: string) => Promise<void>;
  disabled: boolean;
  initialName: string;
  children?: React.ReactNode;
}> = (props) => {
  const snackbar = React.useContext(FeedbackSnackbarContext);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>(props.initialName);

  const onAccept = () => {
    if (!name || props.disabled) return;
    props.action(name).then(() => {
      setIsOpen(false);
      snackbar.pushMessage({ content: "Pomyślnie zaktualizowano nazwę trasy", severity: "success" });
    });
  };

  React.useEffect(() => {
    setName(props.initialName);
  }, [props.initialName]);

  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Nazwij trasę</DialogTitle>
        <DialogContent>
          <TextInput value={name} onChange={setName} label="Nazwa" />
        </DialogContent>
        <DialogActions>
          <Box mr={"auto"}>
            <Button onClick={() => setIsOpen(false)}>Anuluj</Button>
          </Box>
          <Button onClick={onAccept} color={"primary"} disabled={props.disabled}>
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
      <Box display="flex" justifyContent="center" onClick={() => !props.disabled && setIsOpen(true)}>
        {props.children}
      </Box>
    </>
  );
};
