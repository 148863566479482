import { Dialog } from "@dash/dialog";
import { Button, ButtonGroup, IntegerField, TextField } from "@dash/form";
import { useToast } from "@dash/toast";
import React from "react";
import { useQueryClient } from "react-query";

import { DeliveryAddress, ShoperFulfillmentDto } from "@megaron/loyalty-orders-contracts";
import { useClientManager } from "@megaron/react-clients";

export type Props = {
  fulfillment: ShoperFulfillmentDto;
  onClose: () => void;
  isPro: boolean;
};

export const EditShoperFulfillmentDialog: React.FunctionComponent<Props> = (props) => {
  const [email, setEmail] = React.useState(props.fulfillment.email ?? "");
  const [deliveryAddress, setDeliveryAddress] = React.useState<DeliveryAddress>(
    props.fulfillment.deliveryAddress ?? {
      street: "",
      postalCode: "",
      firstName: "",
      lastName: "",
      locality: "",
      phoneNumber: "",
    },
  );
  const [productOption, setProductOption] = React.useState(props.fulfillment.productOption ?? "");
  const [shoperOrderId, setShoperOrderId] = React.useState(props.fulfillment.shoperOrderId ?? null);
  const [customizationText, setCustomizationText] = React.useState<string | null>(
    props.fulfillment.customizationText || null,
  );

  const setAddressField =
    <TKey extends keyof DeliveryAddress>(field: TKey) =>
    (value: DeliveryAddress[TKey]) =>
      setDeliveryAddress({ ...deliveryAddress, [field]: value });

  const queryClient = useQueryClient();
  const toast = useToast();

  const mutation = useClientManager("loyaltyOrders")
    .editShoperFulfillment()
    .useMutation({
      onSuccess: () => {
        queryClient.invalidateQueries(["loyaltyOrders", "order"]);
        props.onClose();
      },
      onError: (error) => {
        toast.error("Nie udało się edytować zamówienia", error);
      },
    });

  const save = () => {
    mutation.mutate({
      fulfillmentUuid: props.fulfillment.uuid,
      email,
      deliveryAddress,
      productOption: productOption || undefined,
      shoperOrderId: shoperOrderId ?? undefined,
      customizationText: customizationText || null,
    });
  };

  return (
    <Dialog header="Edytuj zamówienie" css={{ minWidth: "300px" }} onClose={props.onClose}>
      <div css={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        <TextField label="Email" value={email} onChange={setEmail} />
        <TextField label="Wybrana opcja" value={productOption} onChange={setProductOption} />
        <IntegerField isNullable label="Nr zamówienia shopera" value={shoperOrderId} onChange={setShoperOrderId} />

        <TextField label="Imię" value={deliveryAddress.firstName} onChange={setAddressField("firstName")} />
        <TextField label="Nazwisko" value={deliveryAddress.lastName} onChange={setAddressField("lastName")} />
        <TextField label="Ulica" value={deliveryAddress.street} onChange={setAddressField("street")} />
        <TextField label="Miejscowość" value={deliveryAddress.locality} onChange={setAddressField("locality")} />
        <TextField label="Kod pocztowy" value={deliveryAddress.postalCode} onChange={setAddressField("postalCode")} />
        <TextField label="Nr telefonu" value={deliveryAddress.phoneNumber} onChange={setAddressField("phoneNumber")} />

        {props.isPro && (
          <TextField
            label="Napis do wygrawerowania"
            value={customizationText ?? ""}
            onChange={(value) => setCustomizationText(value || null)}
          />
        )}

        <ButtonGroup css={{ marginTop: "0.5rem" }}>
          <Button variant="outline" onClick={props.onClose}>
            Anuluj
          </Button>
          <Button onClick={save}>Zapisz</Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};
