import { Uuid } from "@legacy-megarax/common";
import { CustomerReference, customerResource, PendingPayerProposalTaskIdentifier } from "@legacy-megarax/crm-contracts";
import { useResourceProviderV2 } from "@legacy-megarax/react-client";
import { useAsyncLoad } from "@legacy-megarax/react-utils";
import { commonErrorsMap, ResultSkeletonLoader, useSnackbarErrorHandler } from "@legacy-megarax/ui-components";
import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { FaArrowRight, FaHandHoldingUsd } from "react-icons/fa";
import { Link } from "react-router-dom-v5-compat";

import { BaseTaskGroupCard } from "./BaseTaskGroupCard";

interface Props {
  identifiers: PendingPayerProposalTaskIdentifier[];
  time: Date;
}

type ProposalStatus = {
  customer: CustomerReference;
  count: number;
};

const identifiersToStatuses = (identifiers: PendingPayerProposalTaskIdentifier[]): ProposalStatus[] =>
  _(identifiers)
    .groupBy((t) => t.customerUuid)
    .mapValues((tasks) => tasks.length)
    .entries()
    .map<ProposalStatus>(([uuid, count]) => ({
      customer: { uuid: uuid },
      count,
    }))
    .value();

export const PendingPayerProposalTasksCard: React.FunctionComponent<Props> = ({ identifiers, time }) => {
  const statuses = identifiersToStatuses(identifiers);

  return (
    <BaseTaskGroupCard icon={<FaHandHoldingUsd />} time={time} title="Propozycje zmian warunków handlowych">
      <List dense>
        {statuses.map((status) => (
          <PayerProposalStatusItem
            pendingProposalCount={status.count}
            uuid={status.customer.uuid}
            key={status.customer.uuid}
          />
        ))}
      </List>
    </BaseTaskGroupCard>
  );
};

const PayerProposalStatusItem: React.FC<{
  pendingProposalCount: number;
  uuid: string;
}> = ({ uuid, pendingProposalCount }) => {
  const customerProvider = useResourceProviderV2(customerResource);

  const handleError = useSnackbarErrorHandler({ ...commonErrorsMap });

  const { value: customerResult } = useAsyncLoad(
    () =>
      customerProvider
        .byUuid(uuid as Uuid)
        .retrieve()
        .map((res) => res.name)
        .mapError(handleError),
    [],
  );

  return (
    <ListItem key={uuid} button component={Link} to={`/crm/customers/id/${uuid}`}>
      <ListItemIcon>
        <FaArrowRight />
      </ListItemIcon>
      <ListItemText
        primary={
          <ResultSkeletonLoader
            result={customerResult}
            allowErrors={true}
            component={(res) => <Typography variant="inherit">{res.isOk ? res.value : uuid}</Typography>}
          />
        }
        secondary={`Oczekujące propozycje (${pendingProposalCount})`}
      />
    </ListItem>
  );
};
