import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

const pricedItemsFiltersSerializer = Serializers.object({
  discountRate: Serializers.decimal,
  postSaleDiscountRate: Serializers.decimal,
  limit: Serializers.integer.optional(),
  offset: Serializers.integer.optional(),
  priceListUuid: uuidSerializer,
  searchText: Serializers.string.optional(),
  time: Serializers.datetime.optional(),
}).partial();

export type PricedItemsFilters = SerializerValue<typeof pricedItemsFiltersSerializer>;

const pricedItemDtoSerializer = Serializers.object({
  basePrice: Serializers.decimal.nullable(),
  cost: Serializers.decimal.nullable(),
  finalPrice: Serializers.decimal.nullable(),
  gtin: Serializers.gtin,
  name: Serializers.string.nullable(),
  absoluteProfitMargin: Serializers.decimal.nullable(),
  relativeProfitMargin: Serializers.decimal.nullable(),
});

export type PricedItemDto = SerializerValue<typeof pricedItemDtoSerializer>;

export const pricedItemErrors = ["InvalidDiscountRate", "InvalidPostSaleDiscountRate"] as const;

export type PricedItemQueryError = (typeof pricedItemErrors)[number];

export const pricedItemActions = {
  getPricedItems: HttpAction({
    method: "get",
    path: "/pricing/pricedItems",
    qsSerializer: pricedItemsFiltersSerializer,
    valueSerializer: Serializers.paginatedList(pricedItemDtoSerializer),
    errorSerializer: Serializers.stringOneOf(...pricedItemErrors),
  }),
};
