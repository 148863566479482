import { UuidResourceParam } from "@legacy-megarax/common";
import { BaseResource, paginatedListSerializer, ResourceActionV2 } from "@legacy-megarax/rest-resource";
import { MSerializers } from "@legacy-megarax/serializers";

import { GtinResourceParam } from "../common";
import { tradeItemListQueryFiltersSerializer } from "./listFilters";
import { tradeItemDtoSerializer } from "./tradeItemDto";

const listAction = ResourceActionV2({
  name: "list",
  method: "get",
  path: "",
  querySerializer: tradeItemListQueryFiltersSerializer,
  responseValueSerializer: paginatedListSerializer(tradeItemDtoSerializer),
});

export const tradeItemResource = BaseResource(["bc", "tradeItems"], {
  list: listAction,
  refresh: ResourceActionV2({
    name: "refresh",
    method: "post",
    path: "refresh",
  }),
  byGtin: GtinResourceParam("gtin", "gtin", {
    retrieve: ResourceActionV2({
      name: "retrieveByGtin",
      method: "get",
      path: "",
      responseValueSerializer: tradeItemDtoSerializer.nullable(),
    }),
  }),
});
