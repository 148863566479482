import { useValidatorForm } from "@legacy-megarax/react-client";
import { AsyncButton, DateTimeInput, SwitchInput, TextInput } from "@legacy-megarax/ui-components";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { formValidator, getErrorMessage, ProposeDeletionInput } from "./proposeDeletionForm";

interface Props {
  onProposeDeletion: (input: ProposeDeletionInput) => Promise<void>;
}

export const ProposeDeletionDialog: React.FC<Props> = ({ onProposeDeletion }) => {
  const navigate = useNavigate();

  const classes = useStyles();
  const [activeImmediately, setActiveImmidiately] = useState<boolean>(true);
  const { formValue, onFormInput, onSubmit, formErrors } = useValidatorForm(
    { comment: "", activeFrom: null } as ProposeDeletionInput,
    formValidator,
    onProposeDeletion,
  );
  const lastDate = useRef(formValue.activeFrom);

  const onClose = () => navigate(-1);

  useEffect(() => {
    if (activeImmediately) return onFormInput("activeFrom")(null);
    return onFormInput("activeFrom")(lastDate.current);
  }, [activeImmediately]);

  useEffect(() => {
    if (formValue.activeFrom) lastDate.current = formValue.activeFrom;
  }, [formValue.activeFrom]);

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Propozycja usunięcia</DialogTitle>
      <DialogContent className={classes.container}>
        <SwitchInput setValue={setActiveImmidiately} value={activeImmediately} label="Aktywna od razu?" />
        <DateTimeInput
          label={`Aktywna od ${activeImmediately ? "razu" : ""}`}
          inputVariant="outlined"
          value={formValue.activeFrom}
          onChange={onFormInput("activeFrom")}
          error={getErrorMessage(formErrors.activeFrom)}
          minDate={new Date()}
          ampm={false}
          disabled={activeImmediately}
        />
        <TextInput
          label={"Komentarz"}
          variant="outlined"
          value={formValue.comment}
          onChange={onFormInput("comment")}
          multiline
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Anuluj</Button>
        <AsyncButton color="primary" asyncAction={onSubmit}>
          Utwórz
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    actions: {
      justifyContent: "space-between",
    },
    container: {
      marginTop: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      "& > .MuiFormControl-root": {
        margin: theme.spacing(1, 0),
      },
    },
  };
});
