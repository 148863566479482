import { Avatar } from "@dash/avatar";
import { IconButton } from "@dash/form";
import { useTheme } from "@emotion/react";
import React from "react";
import { MdLogout } from "react-icons/md";

type Props = {
  name: string;
  groups?: string[];
  profilePictureUrl: string | null;
  onSignOut: () => void;
  className?: string;
  color?: "white" | "black";
};

export const AccountHeader: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  return (
    <div
      className={props.className}
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "0.75rem",
      }}
    >
      <Avatar src={props.profilePictureUrl} size="35px" />
      <div css={{ minWidth: "0" }}>
        <div
          css={{
            fontFamily: theme.displayFontFamily,
            lineHeight: 1.2,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            fontWeight: "500",
            fontSize: "0.875rem",
            color: props.color ?? "white",
          }}
        >
          {props.name}
        </div>
        <div
          css={{
            fontSize: "0.75rem",
            lineHeight: 1.2,
            color: props.color ?? "white",
            opacity: 0.75,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {props.groups?.join(", ")}
        </div>
      </div>
      <IconButton
        color="inverse"
        css={{ fontSize: "1rem", marginLeft: "auto", color: props.color ?? "white" }}
        onClick={props.onSignOut}
      >
        <MdLogout />
      </IconButton>
    </div>
  );
};
