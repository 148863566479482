import { priceListProposalCommentResource } from "@legacy-megarax/crm-contracts";
import { useResourceProviderV2 } from "@legacy-megarax/react-client";
import { Chat, commonErrorsMap, useSnackbarErrorHandler } from "@legacy-megarax/ui-components";
import React from "react";
import { v4 } from "uuid";

interface Props {
  priceListUuid: string;
  proposalUuid: string;
}

export const ProposalChat: React.FC<Props> = ({ priceListUuid, proposalUuid }) => {
  const commentProvider = useResourceProviderV2(priceListProposalCommentResource)
    .byPriceListUuid(priceListUuid)
    .proposalComments.byProposalUuid(proposalUuid);

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
    CommentAlreadyExists: "Komentarz już istnieje.",
  });

  const getComments = () =>
    commentProvider
      .commentList()
      .mapError((error) => {
        handleError(error);
        return Promise.reject(error);
      })
      .then((result) => result.value);

  const postComment = (content: string) =>
    commentProvider
      .post(undefined, {
        content,
        uuid: v4(),
      })
      .mapError(handleError)
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .then(() => {});

  return <Chat getComments={getComments} postComment={postComment} />;
};
