import { Theme } from "@emotion/react";
import { AppLinkTree, appLinkTrees } from "@megarax/links";
import { MdLink } from "react-icons/md";

import { LinkDoc } from "@megaron/docs-contracts";

export const getLoyaltyUrl = () => "https://specjalista.megarax.net";
export const getMegaraxUrl = () => "https://megarax.net";

export const getLinkDocIcon = (linkDoc: LinkDoc) => {
  const appLink = appLinkTrees.find((tree) => tree.app === linkDoc.app);

  if (linkDoc.type === "app") return appLink?.Icon ?? MdLink;

  return appLink?.pageLinks.find((pageLink) => pageLink.name === linkDoc.name)?.Icon ?? MdLink;
};

export const getAppColor = (app: string, theme: Theme) => {
  switch (app) {
    case "crm":
      return theme.colors.crm;
    case "iam":
      return theme.colors.iam;
    case "invoices":
      return theme.colors.invoices;
    case "loyaltyAdmin":
      return theme.colors.loyalty;
    case "plm":
      return theme.colors.plm;
    case "dev":
      return theme.colors.dev;
    default:
      return theme.colors.default;
  }
};

export const filterLinkTree = (docs: LinkDoc[]): AppLinkTree[] =>
  appLinkTrees
    .filter((appLink) => docs.some((doc) => doc.type === "app" && doc.app === appLink.app))
    .map((appLink) => ({
      ...appLink,
      pageLinks: appLink.pageLinks.filter((pageLink) =>
        docs.some((doc) => doc.type === "page" && doc.app === appLink.app && doc.name === pageLink.name),
      ),
    }));
