import { TextField } from "@dash/form";
import { Loader } from "@googlemaps/js-api-loader";
import Decimal from "decimal.js";

import { CustomerCategory, CustomerDetails as Customer } from "@megaron/crm-contracts";

import { customerCategories } from "./addCustomer/CustomerCategorySection";
import { CustomerAddress } from "./CustomerAddress";
import { CustomerCategories } from "./CustomerCategories";
import { UpdateStatus } from "./CustomerPage";
import { CustomerPersonalDetails } from "./CustomerPersonalDetails";
import { RegionField } from "./RegionField";
import { StatusField } from "./StatusField";
import { useCustomerDetailsForm } from "./useCustomerDetailsForm";

const googleMapsLoader = new Loader({
  apiKey: process.env["NX_PUBLIC_GOOGLE_MAPS_API_KEY"]!,
  version: "weekly",
  libraries: ["places"],
  language: "pl",
});

type Props = {
  customer: Customer;
  queryKey: string | string[];
  onStatusChange: (status: UpdateStatus) => void;
};

export const CustomerDetails: React.FC<Props> = ({ customer, queryKey, onStatusChange }) => {
  const isCustomerDetailsEditDisabled =
    customer.loyaltyRegistrationStatus === "deleted" || customer.loyaltyRegistrationStatus === "registered";

  const { customerData, isLoading, setCustomer, emailError, nameError } = useCustomerDetailsForm({
    customer,
    customerQueryKey: queryKey,
    onStatusChange,
  });

  const isLoyalty = customerData.categories?.includes("loyaltyUser");

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {!isLoyalty && (
        <TextField label="Akronim" value={customerData.name} onChange={setCustomer("name")} error={nameError} />
      )}
      <div css={{ display: "flex", gap: "0.625rem" }}>
        <RegionField region={customerData.region} onRegionChange={setCustomer("region")} />
        <StatusField currentStatus={customerData.status} onStatusChange={setCustomer("status")} />
      </div>
      <CustomerCategories
        key={customer.uuid}
        selectedCategories={customerCategories.filter((category) => customerData.categories.includes(category.value))}
        userType={customerData.categories.includes("loyaltyUser") ? "customer" : "firm"}
        isChangeDisabled={customerData.categories.includes("loyaltyUser")}
        onCategoryChange={(values) => {
          if (customerData.categories.includes("loyaltyUser")) {
            return;
          }

          setCustomer("categories")(values.map((value) => value.value as CustomerCategory));
        }}
      />
      {isLoyalty && (
        <CustomerPersonalDetails
          values={{
            email: (customerData.email as string | null) ?? undefined,
            firstName: customerData.firstName,
            lastName: customerData.lastName,
            phoneNumber: customerData.phoneNumber,
          }}
          handlers={{
            onEmailChange: setCustomer("email"),
            onFirstNameChange: setCustomer("firstName"),
            onLastNameChange: setCustomer("lastName"),
            onPhoneNumberChange: setCustomer("phoneNumber"),
          }}
          isCustomerDetailsEditDisabled={isCustomerDetailsEditDisabled}
          emailError={emailError}
        />
      )}
      <CustomerAddress
        customerId={customer.uuid}
        address={customerData.address}
        isLoyalty={isLoyalty}
        onAddressChange={setCustomer("address")}
        isLoading={isLoading}
      />
    </div>
  );
};

export const getLatLng = async (street: string, postalCode: string): Promise<[Decimal, Decimal] | [null, null]> => {
  const address = `${street}, ${postalCode}`;

  try {
    await googleMapsLoader.load();

    const geocoder = new google.maps.Geocoder();
    return new Promise((resolve) => {
      geocoder.geocode({ address }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results && results.length > 0) {
          const location = results[0].geometry.location;
          resolve([new Decimal(location.lat()), new Decimal(location.lng())]);
        } else {
          resolve([null, null]);
        }
      });
    });
  } catch (error) {
    return [null, null];
  }
};
