import { useToast } from "@dash/toast";
import { useTheme } from "@emotion/react";
import { useContext, useState } from "react";
import { MdOutlineSubdirectoryArrowLeft } from "react-icons/md";

import { IamAuthContext } from "@megaron/auth-react";
import { ThreadDto } from "@megaron/invoices-contracts";
import { useClientManager } from "@megaron/react-clients";

type Props = {
  thread: ThreadDto;
};

export const ChangeDecretNumberTile: React.FC<Props> = (props) => {
  const theme = useTheme();
  const toast = useToast();
  const auth = useContext(IamAuthContext);

  const [inputValue, setInputValue] = useState<string>(props.thread.decreeNumber ?? "");

  const decreeNumberMutation = useClientManager("invoices").setDecreeNumber().useMutation();

  const userRoles = auth.iamUser?.userType === "megarax" ? auth.iamUser?.roles : undefined;

  const allowAccountingActions =
    (userRoles?.includes("invoices.coordinator") || userRoles?.includes("invoices.admin")) &&
    props.thread.status === "approved";

  const handleSetDecreeNumber = () =>
    decreeNumberMutation.mutate(
      { threadUuid: props.thread.uuid, decreeNumber: inputValue },
      {
        onSuccess: () => {
          toast.info("Pomyślnie zapisano numer dekretu.");
        },
        onError: (error) => {
          toast.error("Nie udało się zapisać numeru dekretu.");
        },
      },
    );

  const inputProps = {
    value: inputValue,
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setInputValue(e.target.value),
    css: {
      width: "100%",
      border: "none",
      padding: "0.25rem 0.5rem",
      fontSize: "0.875rem",
      borderRadius: "6px",
      height: "46px",
      backgroundColor: theme.colors.primaryLight,
      paddingRight: "2rem",
      ":focus": {
        outline: `1px solid ${theme.colors.primary}`,
      },
      disabled: {
        cursor: "text",
      },
    },
  };

  if (!allowAccountingActions) return;

  return (
    <div css={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <label
        css={{
          color: theme.colors.primary,
          fontSize: "0.875rem",
          fontFamily: theme.displayFontFamily,
          marginBottom: "0.25rem",
        }}
      >
        Nr dekretu
      </label>
      <div css={{ position: "relative", width: "100%" }}>
        <input {...inputProps} type="text" placeholder={"Nr dekretu..."} />
        <MdOutlineSubdirectoryArrowLeft
          css={{
            position: "absolute",
            right: "0.75rem",
            top: "50%",
            transform: "translateY(-50%)",
            color: theme.colors.primary,
            cursor: "pointer",
          }}
          onClick={handleSetDecreeNumber}
        />
      </div>
    </div>
  );
};
