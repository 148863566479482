import { Dropdown } from "@dash/dropdown";
import { Spinner } from "@dash/spinner";
import { useTheme } from "@emotion/react";
import { IconType } from "react-icons/lib";
import { MdMoreVert, MdOutlineArchive, MdOutlineEdit, MdOutlineUnarchive } from "react-icons/md";

type Props = {
  onArchive: () => void;
  onEdit: () => void;
  isArchived: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
};

export const ViewsMenu: React.FC<Props> = ({ onArchive, isArchived, isDisabled, isLoading, onEdit }) => {
  const theme = useTheme();

  return (
    <Dropdown
      openButton={<MdMoreVert color={theme.colors.primary} css={{ fontSize: "24px" }} />}
      isDisabled={isDisabled}
    >
      <MenuButton
        Icon={isArchived ? MdOutlineUnarchive : MdOutlineArchive}
        label={isArchived ? "Przywróć widok" : "Archiwizuj widok"}
        onClick={onArchive}
        isLoading={isLoading}
      />
      <MenuButton Icon={MdOutlineEdit} label="Edytuj" onClick={onEdit} />
    </Dropdown>
  );
};

const MenuButton: React.FC<{ onClick: () => void; Icon: IconType; label: string; isLoading?: boolean }> = ({
  Icon,
  label,
  onClick,
  isLoading,
}) => {
  const theme = useTheme();

  return (
    <button
      css={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        padding: "6px 8px",
        cursor: "pointer",
        background: "none",
        border: "none",
        color: theme.colors.primary,
      }}
      onClick={onClick}
    >
      {isLoading ? <Spinner size="16px" /> : <Icon size={16} css={{ flexShrink: 0 }} />}
      {label}
    </button>
  );
};
