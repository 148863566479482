import { Serializer, serializerExtensions, SerializerExtensions, ValidationError } from "@legacy-megarax/serializers";
import _ from "lodash";

/**
 * @todo Move this type to `@legacy-megarax/common`
 */
export interface SortFilter<TSortField extends string> {
  field: TSortField;
  order?: "ASC" | "DESC";
}

const validateSortFilter =
  <TFields extends string>(allowedFields: TFields[]) =>
  (field: string, order: "ASC" | "DESC"): SortFilter<TFields> => {
    if (!(allowedFields as string[]).includes(field)) throw new ValidationError("Incorrect sort field " + field);

    return {
      field: field as TFields,
      order,
    };
  };

const deserializeSortFilter =
  <TFields extends string>(allowedFields: TFields[]) =>
  (raw: string | string[] | undefined): SortFilter<TFields>[] => {
    if (!raw) return [];
    if (raw instanceof Array) {
      const predicates = raw.flatMap(deserializeSortFilter(allowedFields));
      return _.uniqBy(predicates, (predicate) => predicate.field);
    } else {
      // base case
      if (raw.startsWith("-")) return [validateSortFilter<TFields>(allowedFields)(raw.substring(1), "DESC")];
      else return [validateSortFilter<TFields>(allowedFields)(raw, "ASC")];
    }
  };

export const sortFilterSerializer = <TSortFields extends string>(
  ...fields: TSortFields[]
): Serializer<SortFilter<TSortFields>[]> & SerializerExtensions<SortFilter<TSortFields>[]> => ({
  // serialized format: ['fieldA', '-fieldB', 'fieldC']
  forceDeserialize: deserializeSortFilter(fields),
  serialize: (filter) => filter.map((node) => (node.order === "DESC" ? "-" + node.field : node.field)),
  ...serializerExtensions(),
});
