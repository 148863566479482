import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";

export const jwksSerialzier = Serializers.object({
  keys: Serializers.array(
    Serializers.object({
      /** Key type ("RSA", "EC", "oct", etc.) */
      kty: Serializers.string,
      /** Key ID */
      kid: Serializers.string,
      /** Algorithm */
      alg: Serializers.string,
      /** Public key use (sig/enc) */
      use: Serializers.string,
      /** Curve name */
      crv: Serializers.string,
      /** X coordinate (base64url) */
      x: Serializers.string,
      /** y coordinate (base64url) */
      y: Serializers.string,
      /** y coordinate */
    }).partial(),
  ),
});

export type Jwks = SerializerValue<typeof jwksSerialzier>;

export const jwksActions = {
  jwksQuery: HttpAction({
    path: "/.well-known/jwks.json",
    valueSerializer: jwksSerialzier,
    resultWrapper: false,
  }),
};
