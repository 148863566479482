import { MegaraxHttpClient } from "@legacy-megarax/http-client";
import { useConfig } from "@megarax/dev-config";
import React from "react";

import { MegaraxClientContext } from "./megaraxClientContext";
import { TokenProviderContext } from "./tokenProviderContext";

export type TokenProvider = () => Promise<string | undefined>;

interface Props {
  tokenProvider: TokenProvider;
  children?: React.ReactNode;
}

export const MegaraxConnectionProvider: React.FC<Props> = ({ children, tokenProvider }) => {
  const { config } = useConfig();

  const megaraxClient = MegaraxHttpClient(config.apiUrl, tokenProvider);

  return (
    <TokenProviderContext.Provider value={tokenProvider}>
      <MegaraxClientContext.Provider value={megaraxClient}>{children}</MegaraxClientContext.Provider>
    </TokenProviderContext.Provider>
  );
};
