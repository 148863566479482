import { DescriptionField } from "@dash/description-list";
import { Card } from "@dash/page";
import { useTheme } from "@emotion/react";
import React from "react";
import { MdCable } from "react-icons/md";

type Props = {
  isConnected: boolean;
};

export const IoModuleCard: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  const color = props.isConnected ? theme.colors.primary : theme.colors.danger;

  const statusText = props.isConnected ? "Połączono" : "Brak połączenia";

  return (
    <Card
      color={color}
      variant="secondary"
      css={{ display: "flex", flexDirection: "row", gap: "1rem", alignItems: "center" }}
    >
      <MdCable size="1.5rem" color={color} />
      <DescriptionField label="Moduł I/O" color={color}>
        <span css={{ fontWeight: "700", color }}>{statusText}</span>
      </DescriptionField>
    </Card>
  );
};
