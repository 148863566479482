import { BaseResource, HttpResourceProviderV2, ResourceNodes } from "@legacy-megarax/rest-resource";
import { useContext } from "react";

import { MegaraxClientContext } from "./megaraxClientContext";

export const useResourceProviderV2 = <T extends ResourceNodes>(resource: BaseResource<T>) => {
  const megaraxClient = useContext(MegaraxClientContext);
  if (!megaraxClient) throw Error("Resource provider must be used within Megarax Client context.");

  return HttpResourceProviderV2(megaraxClient)(resource);
};
