import { Dropdown } from "@dash/dropdown";
import { useToast } from "@dash/toast";
import { MdMoreVert, MdOutlineContentCopy } from "react-icons/md";
import { useNavigate } from "react-router-dom-v5-compat";

import { useClientManager } from "@megaron/react-clients";
import { newUuid, Uuid } from "@megaron/uuid";

type Props = {
  orderUuid: Uuid;
};

export const OrderMenu: React.FC<Props> = ({ orderUuid }) => {
  const navigate = useNavigate();

  const toast = useToast();

  const createCopyMutation = useClientManager("crm").createDraftCopy().useMutation();

  const createDraft = () => {
    const draftUuid = newUuid();

    createCopyMutation.mutate(
      { uuid: draftUuid, templateUuid: orderUuid },
      {
        onSuccess: () => {
          navigate(`/crm/order-drafts/${draftUuid}`);
        },
        onError: (error) => {
          if (error === "DraftNotFound") {
            toast.error("Nie znaleziono szablonu zamówienia");
          } else {
            toast.error("Wystąpił błąd podczas tworzenia kopii zamówienia");
          }
        },
      },
    );
  };

  return (
    <Dropdown openButton={<MdMoreVert color="white" css={{ cursor: "pointer", fontSize: "24px" }} />}>
      <button
        css={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          padding: "6px 8px",
          cursor: "pointer",
          background: "none",
          border: "none",
        }}
        onClick={createDraft}
      >
        <MdOutlineContentCopy />
        Utwórz kopię roboczą
      </button>
    </Dropdown>
  );
};
