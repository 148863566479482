/* eslint-disable no-console */
import { Card, HeaderBar } from "@dash/page";
import React, { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom-v5-compat";

import { queryToResult, useClientManager } from "@megaron/react-clients";
import { Failure, Ok } from "@megaron/result";

import { useBeep } from "./beep";
import { QrScanner } from "./QrScanner";
import { ScanStatusCard } from "./ScanStatusCard";

export const ProductionBatchView: React.FC = () => {
  const beep = useBeep();

  const groupName = useParams<{ groupName: string }>().groupName;
  if (!groupName) throw new Error("No group name in path");

  const [scannedCodeString, setScannedCodeString] = React.useState<string>();
  const groupQuery = useClientManager("loyaltyPoints").codeGroupQuery().useQuery({ groupName });
  const queryClient = useQueryClient();

  const scannedGroupQuery = useClientManager("loyaltyPoints")
    .codeGroupsQuery()
    .useQuery({ codeId: scannedCodeString!, type: ["pokrywki"] }, { enabled: !!scannedCodeString });

  const groupNameResult = queryToResult(scannedGroupQuery)
    ?.mapError((e) => "GroupNotFound")
    .flatMap((groups) => {
      if (groups.length === 0) return Failure("GroupNotFound");
      if (groups.length > 1) return Failure("MultipleGroupsFound");
      return Ok(groups[0].name);
    });

  const appendMutation = useClientManager("loyaltyPoints")
    .appendCodesToGroup()
    .useMutation({
      onSuccess: () => {
        beep(1000);
        console.log("append on success");
        queryClient.invalidateQueries(groupQuery.key);
      },
      onError: () => {
        beep(400);
      },
    });

  useEffect(() => {
    if (scannedCodeString) {
      beep(800);
      console.log("resetting");
      appendMutation.reset();
    }
  }, [scannedCodeString]);

  useEffect(() => {
    if (groupNameResult?.value) {
      appendMutation.mutate({ sourceGroupName: groupNameResult.value, groupName });
    }
  }, [groupNameResult?.value]);

  return (
    <div>
      <HeaderBar header={groupName} actions={<></>} />
      <QrScanner onScan={setScannedCodeString}>
        {scannedCodeString && (
          <div css={{ position: "absolute", top: "50px", width: "100%", padding: "1rem" }}>
            <ScanStatusCard
              code={scannedCodeString}
              lastGroupName={groupNameResult?.value}
              error={appendMutation?.error ?? groupNameResult?.error ?? undefined}
              isSuccess={appendMutation.isSuccess}
            />
          </div>
        )}

        <div css={{ position: "absolute", bottom: "48px", width: "100%", padding: "1rem", display: "flex" }}>
          <Card
            variant="secondary"
            css={{
              alignContent: "center",
              justifyContent: "center",
              display: "flex",
              padding: "0.5rem 1.5rem",
              flexDirection: "row",
              margin: "auto",
              minWidth: 0,
              flexShrink: "1",
            }}
          >
            <div css={{ display: "flex", fontSize: "1.25rem", minWidth: 0 }}>
              {groupQuery?.data?.total.toString()} szt.
            </div>
          </Card>
        </div>
      </QrScanner>
    </div>
  );
};
