import { useDeviceType } from "@dash/mq";
import { useTheme } from "@emotion/react";
import { useOs } from "@mantine/hooks";
import React, { useEffect, useRef, useState } from "react";
import { MdClear, MdOutlineSearch } from "react-icons/md";

import { SearchShortcut } from "./SearchButton";

type Props = {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  autoFocus?: boolean;
  variant?: "grayscale";
  useShortcut?: boolean;
  showBorder?: boolean;
};

export const SearchBox: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState<string>(props.value);
  const inputRef = useRef<HTMLInputElement>(null);

  const { isDesktop } = useDeviceType();

  const os = useOs();
  const isMacOs = os === "macos";

  useEffect(() => {
    if (!props.useShortcut) {
      return;
    }

    const callback = (e: KeyboardEvent) => {
      if (e.key === "k" && (isMacOs ? e.metaKey : e.ctrlKey)) {
        e.preventDefault();
        inputRef.current?.focus();
      }
    };

    document.addEventListener("keydown", callback);

    return () => document.removeEventListener("keydown", callback);
  }, [isMacOs, inputRef, props.useShortcut]);

  useEffect(() => {
    if (props.autoFocus && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [props.autoFocus]);

  const handleInputChange = (value: string) => {
    setInputValue(value);
    props.onChange(value);
  };

  const clearInput = () => {
    setInputValue("");
    props.onChange("");
  };

  return (
    <div className={props.className}>
      <div
        css={{
          alignItems: "center",
          position: "relative",
          flex: "1",
        }}
      >
        <MdOutlineSearch
          css={{
            position: "absolute",
            left: "0.75rem",
            top: "50%",
            transform: "translateY(-50%)",
            color: props.variant === "grayscale" ? "black" : theme.colors.primary,
          }}
        />
        <input
          ref={inputRef}
          css={{
            padding: "0.5rem 3rem 0.5rem 2.5rem",
            border: props.showBorder ? "1px solid rgba(0,0,0,0.1)" : "none",
            background: "white",
            borderRadius: theme.smallBorderRadius,
            fontSize: "1rem",
            color: props.variant === "grayscale" ? "black" : theme.colors.primary,
            minWidth: "180px",
            width: "calc(100% - 2px)",
            margin: "1px",
            ":focus": {
              outlineStyle: "solid",
              outlineColor: theme.colors.primary,
              outlineWidth: "1px",
            },
          }}
          placeholder="Szukaj..."
          value={inputValue}
          onChange={(e) => handleInputChange(e.target.value)}
        />
        {inputValue && (
          <MdClear
            css={{
              position: "absolute",
              right: "0.75rem",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
              color: theme.colors.primary,
            }}
            onClick={clearInput}
          />
        )}
        {!inputValue && isDesktop && props.useShortcut && <SearchShortcut />}
      </div>
    </div>
  );
};
