import { AmqpTopic } from "@legacy-megarax/amqp";
import { MSerializers } from "@legacy-megarax/serializers";

import { PubsubTopic } from "@megaron/pubsub-contracts";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export type PayerProposalStatusDto = {
  customers: {
    uuid: string;
    awaitingProposalCount: number;
  }[];
};

export type PayerProposalStatusEventMessage = {
  changeType: "proposalCountIncreased" | "proposalCountDecreased";
  status: PayerProposalStatusDto;
};

export const payerProposalStatusSerializer = MSerializers.object<PayerProposalStatusDto>({
  customers: MSerializers.array(
    MSerializers.object({
      awaitingProposalCount: MSerializers.integer,
      uuid: MSerializers.uuid(),
    }),
  ),
});
export const payerProposalStatusEventAmqpTopic: AmqpTopic<PayerProposalStatusEventMessage> = {
  key: "pricing.payer.proposalStatusEvent",
  serializer: MSerializers.object<PayerProposalStatusEventMessage>({
    changeType: MSerializers.identity(),
    status: payerProposalStatusSerializer,
  }),
};

export type PayerProposalApprovedEventMessage = {
  type: "proposalApproved";
  customerUuid: string;
  proposalUuid: string;
};

export type RuleChangeProposedEventMessage = {
  type: "ruleChangeProposed";
  customerUuid: string;
  proposalUuid: string;
};

export type PayerProposalRetractedEventMessage = {
  type: "proposalRetracted";
  customerUuid: string;
  proposalUuid: string;
};

export type PayerProposalRejectedEventMessage = {
  type: "proposalRejected";
  customerUuid: string;
  proposalUuid: string;
};

export type PayerEventMessage =
  | PayerProposalApprovedEventMessage
  | RuleChangeProposedEventMessage
  | PayerProposalRetractedEventMessage
  | PayerProposalRejectedEventMessage;

export const payerEventMessageSerializer = MSerializers.object<PayerEventMessage>({
  customerUuid: MSerializers.uuid(),
  proposalUuid: MSerializers.uuid(),
  type: MSerializers.stringOneOf(
    "proposalApproved",
    "ruleChangeProposed",
    "proposalRetracted",
    "proposalRejected",
  ) as any,
});

export const payerEventAmqpTopic: AmqpTopic<PayerEventMessage> = {
  key: "pricing.payer.payerEvent",
  serializer: payerEventMessageSerializer,
};

export const payerChangedTopic = PubsubTopic({
  name: "legacy-crm-payer-changed",
  serializer: Serializers.object({
    customerUuid: uuidSerializer,
  }),
});
