import { Dialog } from "@dash/dialog";
import { useDeviceType } from "@dash/mq";
import { useTheme } from "@emotion/react";

import { IndexModuleDto } from "@megaron/docs-contracts";

import { RefreshButton } from "./RefreshButton";

type Props = {
  onClose: () => void;
  docsIndex: IndexModuleDto;
  onRefresh: () => void;
  isRefreshLoading: boolean;
};

export const DocsIndexDialog: React.FC<Props> = ({ onClose, docsIndex, isRefreshLoading, onRefresh }) => {
  const { isMobile } = useDeviceType();

  const theme = useTheme();

  return (
    <Dialog
      header={`Docs index: ${docsIndex.indexAlias}`}
      onClose={onClose}
      placement={isMobile ? "top" : "center"}
      css={{ width: "400px" }}
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem", width: "100%" }}>
        <DocsIndexDataElement header="Doctype" data={docsIndex.docType} />
        <DocsIndexDataElement header="Last refreshed at" data={docsIndex.lastRefreshAt?.toLocaleDateString() ?? ""} />
        <DocsIndexDataElement header="Index alias" data={docsIndex.indexAlias} />
        <DocsIndexDataElement header="Index version name" data={docsIndex.indexVersionName} />
        <DocsIndexDataElement
          header="Error"
          data={JSON.stringify(docsIndex.setupError) || "-"}
          textColor={theme.colors.danger}
        />
        <RefreshButton onClick={onRefresh} isLoading={isRefreshLoading} css={{ alignSelf: "flex-end" }} />
      </div>
    </Dialog>
  );
};

const DocsIndexDataElement: React.FC<{ header: string; data: string; textColor?: string }> = ({
  header,
  data,
  textColor,
}) => {
  const theme = useTheme();

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "0.125rem", width: "100%", fontSize: "0.875rem" }}>
      <span css={{ color: theme.colors.primary, fontWeight: 700 }}>{header}</span>
      <p css={{ margin: 0, color: textColor ?? "black" }}>{data}</p>
    </div>
  );
};
