import { DescriptionField, DescriptionList } from "@dash/description-list";
import { Dialog } from "@dash/dialog";
import { EntitySelect } from "@dash/entity-list";
import { Button, CheckboxField, DateField, TextField } from "@dash/form";
import { addDays, differenceInDays } from "date-fns";
import Decimal from "decimal.js";
import React from "react";
import { MdPlayArrow } from "react-icons/md";

import { useClientManager } from "@megaron/react-clients";
import { Serializers } from "@megaron/serializers";

type Props = {
  groups: string[];
  onClose: () => void;
};

const today = () => {
  const s = Serializers.dateOnly;
  return s.deserialize(s.serialize(new Date())).value!;
};

export const ActivateGroupsDialog: React.FunctionComponent<Props> = (props) => {
  const [productId, setProductId] = React.useState<string>();

  const [isSure, setIsSure] = React.useState(false);
  const [productionDate, setProductionDate] = React.useState(today());

  const mutation = useClientManager("loyaltyPoints")
    .activateProductCodeGroups()
    .useMutation({
      onSuccess: (data, vars, ctx) => {
        ctx.queryClient.invalidateQueries(["loyaltyPoints", "codeGroupsQuery"]);
        props.onClose();
      },
    });

  const expirationDate = addDays(productionDate, 366);
  const expiresAfterDays = differenceInDays(expirationDate, new Date());

  const options = productId
    ? {
        smigS8_20: {
          expiresAfterDays,

          groupNames: props.groups,
          pointValue: new Decimal(8),
          productId: "smigS8",
          massKg: new Decimal(20),
        },
        smigC50s_5: {
          expiresAfterDays,

          groupNames: props.groups,
          pointValue: new Decimal(4),
          productId: "smigC50s",
          massKg: new Decimal(5),
        },
        smigA11_17: {
          expiresAfterDays,

          groupNames: props.groups,
          pointValue: new Decimal(8),
          productId: "smigA11",
          massKg: new Decimal(17),
        },
        megaronDv21_17: {
          expiresAfterDays,

          groupNames: props.groups,
          pointValue: new Decimal(8),
          productId: "megaronDv21",
          massKg: new Decimal(17),
        },
        megaronDv20_20: {
          expiresAfterDays,

          groupNames: props.groups,
          pointValue: new Decimal(5),
          productId: "megaronDv20",
          massKg: new Decimal(20),
        },
        megaronGv15_20: {
          expiresAfterDays,

          groupNames: props.groups,
          pointValue: new Decimal(8),
          productId: "megaronGv15",
          massKg: new Decimal(20),
        },
      }[productId]
    : undefined;

  const go = () => {
    if (options) mutation.mutate(options);
  };

  return (
    <Dialog
      onClose={props.onClose}
      header="Aktywuj grupy"
      footer={
        <Button css={{ width: "100%" }} icon={<MdPlayArrow />} isDisabled={!options || !isSure} onClick={go}>
          Aktywuj
        </Button>
      }
    >
      <div css={{ minWidth: "250px", display: "flex", flexDirection: "column" }}>
        <select onChange={(e) => setProductId(e.target.value)} value={productId} css={{ marginBottom: "1rem" }}>
          <option value=""></option>
          <option value="smigS8_20">Śmig S-8 20kg</option>
          <option value="smigC50s_5">Śmig C-50s 5kg</option>
          <option value="smigA11_17">Śmig A-11 17kg</option>
          <option value="megaronDv21_17">Megaron Dv-21 17kg</option>
          <option value="megaronDv20_20">Megaron Dv-20 20kg</option>
          <option value="megaronGv15_20">Megaron Gv-15 20kg</option>
        </select>
        <DescriptionField label="Grupy">
          {props.groups.map((g) => (
            <div>{g}</div>
          ))}
        </DescriptionField>
        <DescriptionList itemMinWidth="100px" css={{ margin: "0.5rem 0" }}>
          <DescriptionField label="Produkt">{options?.productId}</DescriptionField>
          <DescriptionField label="Masa">{options?.massKg.toFixed(1)} kg</DescriptionField>
          <DescriptionField label="Wartość">{options?.pointValue.toFixed(1)} punktów</DescriptionField>
        </DescriptionList>

        <DateField label="Data produkcji" value={productionDate} onChange={setProductionDate} />

        <DescriptionList itemMinWidth="100px" css={{ margin: "0.5rem 0" }}>
          <DescriptionField label="Okres ważności">{options?.expiresAfterDays} dni</DescriptionField>
          <DescriptionField label="Termin ważności">{expirationDate.toLocaleDateString()}</DescriptionField>
        </DescriptionList>
        <CheckboxField label="Potwierdzam" value={isSure} onChange={setIsSure}></CheckboxField>
      </div>
    </Dialog>
  );
};
