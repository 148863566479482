import { ResourceAction } from "@legacy-megarax/rest-resource";
import { MSerializers } from "@legacy-megarax/serializers";

export type PayerNotification = {
  type: "proposalRetracted" | "ruleChangeProposed" | "proposalApproved" | "proposalRejected";
  time: Date;
  customerUuid: string;
  version: number;
};

export type NotificationsQueryValue = {
  eventLogIndex: number;
  notifications: PayerNotification[];
};

const payerNotificationSerializer = MSerializers.object<PayerNotification>({
  customerUuid: MSerializers.uuid(),
  time: MSerializers.datetime,
  type: MSerializers.identity(),
  version: MSerializers.integer,
});

export const eventNotificationAction = ResourceAction({
  name: "getEventNotifications",
  method: "get",
  querySerializer: MSerializers.object({
    sinceIndex: MSerializers.integer,
  }),
  responseSerializer: MSerializers.result<NotificationsQueryValue>(
    MSerializers.object({
      notifications: MSerializers.array(payerNotificationSerializer),
      eventLogIndex: MSerializers.integer,
    }),
  ),
});
