import { Dialog, useDialogRoute } from "@dash/dialog";
import { Button, TextField } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { SectionHeader } from "@dash/page";
import { QuerySkeleton } from "@dash/skeleton";
import { useToast } from "@dash/toast";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import { MdAdd, MdEdit } from "react-icons/md";
import { useQueryClient } from "react-query";

import { useClientManager } from "@megaron/react-clients";
import { newUuid, Uuid } from "@megaron/uuid";

import { ContactTile } from "./ContactTile";

type Props = {
  customerUuid: Uuid;
};

export const ContactList: React.FC<Props> = ({ customerUuid }) => {
  const theme = useTheme();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { isMobile } = useDeviceType();

  const [fullName, setFullName] = useState<string>("");
  const [jobTitle, setJobTitle] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const contactsQuery = useClientManager("crm").customerContactsQuery().useQuery({
    customerId: customerUuid,
  });

  const addContactMutation = useClientManager("crm").saveCustomerContact().useMutation();

  const addContact = (onSuccess: () => void) => {
    addContactMutation.mutate(
      {
        uuid: newUuid(),
        customerId: customerUuid,
        name: fullName,
        email,
        phoneNumber,
        jobTitle,
      },
      {
        onSuccess: () => {
          toast.info("Dodano kontakt");
          setFullName("");
          setJobTitle("");
          setEmail("");
          setPhoneNumber("");
          queryClient.invalidateQueries(contactsQuery.key);
          onSuccess();
        },
        onError: () => toast.error(`Nie udało się dodać kontaktu`),
      },
    );
  };

  const dialog = useDialogRoute("/add-contact", ({ onClose }) => (
    <Dialog onClose={onClose} header="Nowy kontakt" placement={isMobile ? "top" : "center"} css={{ width: "400px" }}>
      <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <TextField
          css={{ width: "100%", input: { height: "46px" }, padding: 0 }}
          label={"Imie i Nazwisko"}
          value={fullName}
          onChange={setFullName}
          autoFocus
        />
        <TextField
          css={{ width: "100%", input: { height: "46px" }, padding: 0 }}
          label={"Stanowisko"}
          value={jobTitle}
          onChange={setJobTitle}
        />
        <TextField
          css={{ width: "100%", input: { height: "46px" }, padding: 0 }}
          label={"Email"}
          value={email}
          onChange={setEmail}
        />
        <TextField
          css={{ width: "100%", input: { height: "46px" }, padding: 0 }}
          label={"Nr telefonu"}
          value={phoneNumber}
          onChange={setPhoneNumber}
        />
      </div>
      <div css={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem" }}>
        <Button
          css={{ width: "auto", padding: "0.5rem 1rem" }}
          onClick={() => {
            addContact(onClose);
          }}
        >
          Dodaj
        </Button>
      </div>
    </Dialog>
  ));

  return (
    <>
      <SectionHeader
        isHr
        css={{ marginTop: "0" }}
        actions={
          contactsQuery.data &&
          contactsQuery.data.length > 0 && (
            <Button variant="outline" css={{ fontSize: "12px", padding: "4px 8px" }} onClick={dialog.open}>
              Dodaj
            </Button>
          )
        }
      >
        Kontakty
      </SectionHeader>
      <QuerySkeleton height="41px" query={contactsQuery}>
        {(contacts) => (
          <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            {contacts.length > 0 ? (
              <>
                {contacts.map((contact) => (
                  <ContactTile key={contact.uuid} contact={contact} queryKey={contactsQuery.key} />
                ))}
              </>
            ) : (
              <div
                onClick={dialog.open}
                css={{
                  border: `1px dashed ${theme.colors.border}`,
                  color: theme.colors.primary,
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  height: "45px",
                  cursor: "pointer",
                  borderRadius: theme.smallBorderRadius,
                }}
              >
                <MdAdd />
                Dodaj
              </div>
            )}
          </div>
        )}
      </QuerySkeleton>

      {dialog.element}
    </>
  );
};
