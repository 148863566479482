import { CardLink } from "@dash/page";
import { AppLinkTree } from "@megarax/links";

import { LinkGroupHeader } from "./LinkGroupHeader";

type Props = {
  linkTree: AppLinkTree;
};

export const LinkGroup: React.FC<Props> = ({ linkTree }) => {
  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem", minWidth: "16rem", flexGrow: 1 }}>
      <LinkGroupHeader linkTree={linkTree} />

      <div css={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        {linkTree.pageLinks.map((pageLink) => (
          <CardLink
            isNarrow
            icon={<pageLink.Icon css={{ height: "1rem", width: "1rem" }} />}
            linkTo={`${linkTree.baseUrl || ""}${pageLink.url}`}
            color={linkTree.color}
            isExternal={!linkTree.baseUrl?.startsWith("/")}
            key={`link-${pageLink.name}`}
            linkName={pageLink.name}
          >
            <span css={{ fontSize: "0.875rem", color: linkTree.color }}>{pageLink.name}</span>
          </CardLink>
        ))}
      </div>
    </div>
  );
};
