import { useProfilesDictionary } from "@legacy-megarax/iam-webapp";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  Typography,
} from "@mui/material";
import { useSalespeopleResource } from "libs/@megarax/crm/sales-people/src";
import React from "react";
import { useQuery } from "react-query";

import { QueryFilters, SetQueryFilters } from "../TradeRouteDetails";

export const SelectSalespersonDialog: React.FC<{
  qs: QueryFilters;
  setQs: SetQueryFilters;
}> = ({ qs, setQs }) => {
  const [open, setOpen] = React.useState(false);
  const { listAll } = useSalespeopleResource();

  const salespeopleQuery = useQuery(["salespeople"], () => listAll());
  const salespeople = salespeopleQuery.data?.isOk ? salespeopleQuery.data.value.items : [];
  const profiles = useProfilesDictionary(salespeople.map((salesperson) => ({ id: salesperson.userId })));

  return (
    <>
      <Button onClick={() => setOpen(true)} sx={{ width: "100%", px: 4 }}>
        {profiles && qs.salespersonId
          ? profiles[qs.salespersonId]?.displayedName ?? "Nieznany Handlowiec"
          : "Brak Handlowca"}
      </Button>

      <Dialog
        open={open || qs.salespersonId === undefined}
        fullWidth
        maxWidth="xs"
        onClose={() => qs.salespersonId && setOpen(false)}
      >
        <Box sx={{ overflowY: "auto" }}>
          <Box p={2} pb={1}>
            <Typography variant="subtitle2">Wybierz Handlowca:</Typography>
          </Box>
          <List>
            {profiles &&
              salespeople.map((salesperson, idx) => {
                const selected = qs.salespersonId === salesperson.userId;
                return (
                  <ListItem
                    button
                    key={salesperson.userId}
                    sx={{ backgroundColor: selected ? "#d1e6d6" : "transparent" }}
                    secondaryAction={<Radio checked={selected} sx={{ pointerEvents: "none" }} />}
                    onClick={() => {
                      setQs("salespersonId", salesperson.userId);
                      setOpen(false);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={profiles[salesperson.userId]?.displayedName ?? idx.toString()}
                        src={profiles[salesperson.userId]?.pictureUrl ?? ""}
                      />
                    </ListItemAvatar>
                    <ListItemText>{profiles[salesperson.userId]?.displayedName ?? "Nieznany Handlowiec"}</ListItemText>
                  </ListItem>
                );
              })}
          </List>
        </Box>
      </Dialog>
    </>
  );
};
