import { Page, PageHeader } from "@dash/page";
import { useLayoutEffect } from "react";

import { OrderList } from "./OrderList";

export const OrdersHome = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page>
      <PageHeader>Realizacja nagród</PageHeader>
      <OrderList />
    </Page>
  );
};
