import { AmqpTopic } from "@legacy-megarax/amqp";
import { Uuid, uuidSerializer } from "@legacy-megarax/common";
import { MSerializers } from "@legacy-megarax/serializers";

export type CustomerEventMessage = {
  uuid: Uuid;
};

export const customerEventMessageSerializer = MSerializers.object<CustomerEventMessage>({
  uuid: uuidSerializer,
});

export const customerEventMessageAmqpTopic: AmqpTopic<CustomerEventMessage> = {
  key: "crm-customer-event",
  serializer: customerEventMessageSerializer,
};
