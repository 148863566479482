import { Uuid } from "@legacy-megarax/common";
import { BreakDto, ChangeBreakInput } from "@legacy-megarax/crm-contracts";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TimePicker } from "@mui/x-date-pickers";
import React from "react";

interface Props {
  onClose: () => void;
  allBreaks: BreakDto[];
  breakUuid: Uuid;
  onSave: (uuid: Uuid, data: ChangeBreakInput) => Promise<void>;
  onDelete: (uuid: Uuid) => Promise<void>;
}

export const EditBreakDialog: React.FunctionComponent<Props> = ({
  onClose,
  allBreaks,
  breakUuid,
  onDelete,
  onSave,
}) => {
  const classes = useStyles();
  const existingBreak = allBreaks.find((b) => b.uuid === breakUuid);

  const [startTime, setStartTime] = React.useState<Date | null>(existingBreak?.startTime ?? new Date());
  const [endTime, setEndTime] = React.useState<Date | null>(existingBreak?.endTime ?? null);
  const [startOdometer, setStartOdometer] = React.useState(existingBreak?.startOdometer ?? null);
  const [endOdometer, setEndOdometer] = React.useState(existingBreak?.endOdometer ?? null);

  const onStartOdometerInputChange = (input: string) => {
    const parsed = parseInt(input);
    if (isNaN(parsed)) setStartOdometer(null);
    else setStartOdometer(parsed);
  };
  const onEndOdometerInputChange = (input: string) => {
    const parsed = parseInt(input);
    if (isNaN(parsed)) setEndOdometer(null);
    else setEndOdometer(parsed);
  };

  const isComplete = startTime !== null;

  const onConfirm = async () => {
    await onSave(breakUuid, {
      endOdometer: endOdometer,
      endTime: endTime,
      startOdometer: startOdometer,
      startTime: startTime!,
    });
    onClose();
  };

  const onDeleteClick = async () => {
    await onDelete(breakUuid);
    onClose();
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Przerwa</DialogTitle>
      <DialogContent>
        <Typography variant="h6" marginBottom={2}>
          Początek przerwy
        </Typography>
        <TimePicker ampm={false} onChange={setStartTime} label="Godzina" />
        <TextField
          fullWidth
          label="Stan licznika [km]"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          value={startOdometer ?? ""}
          onChange={(e) => onStartOdometerInputChange(e.target.value)}
          className={classes.field}
        />
        <Divider className={classes.divider} />
        <Typography variant="h6" marginBottom={2}>
          Koniec przerwy
        </Typography>
        <TimePicker ampm={false} onChange={setEndTime} value={endTime ?? null} label="Godzina" />
        <TextField
          fullWidth
          label="Stan licznika [km]"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          value={endOdometer ?? ""}
          onChange={(e) => onEndOdometerInputChange(e.target.value)}
          className={classes.field}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onDeleteClick} color="error" sx={{ marginLeft: 0, marginRight: "auto" }}>
          Usuń
        </Button>
        <Button onClick={onClose}>Anuluj</Button>
        <Button onClick={onConfirm} variant="contained" color="primary" disabled={!isComplete}>
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));
