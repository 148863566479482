import { PubsubTopic } from "@megaron/pubsub-contracts";
import { Serializers, SerializerValue } from "@megaron/serializers";

import { notificationContentSerializer } from "./notifications";

const notificationTopicSerializer = Serializers.object({
  targetUserAttributes: Serializers.string.array(),
  notification: notificationContentSerializer,
});

export const notificationRequestedTopic = PubsubTopic({
  name: "notification-requested",
  serializer: notificationTopicSerializer,
});

export type NotificationRequesteddMessage = SerializerValue<typeof notificationRequestedTopic.serializer>;
