import { Dialog } from "@dash/dialog";
import { Button, DateField, TextField } from "@dash/form";
import { Select } from "@dash/select";
import { useToast } from "@dash/toast";
import { customAlphabet } from "nanoid";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { IamAuthContext } from "@megaron/auth-react";
import { toDateString } from "@megaron/date-string";
import { useClientManager } from "@megaron/react-clients";

type Props = {
  onClose: () => void;
};

export const NewProductionBatchDialog: React.FunctionComponent<Props> = (props) => {
  const auth = useContext(IamAuthContext);
  const toast = useToast();
  const navigate = useNavigate();

  const [productId, setProductId] = useState<string | null>(null);
  const [date, setDate] = useState<Date>(new Date());
  const [notes, setNotes] = useState<string>("");

  const productOptions = [
    { value: "Dv-21_17", label: "Dv-21_17" },
    { value: "Dv-20_20", label: "Dv-20_20" },
    { value: "A-11_17", label: "A-11_17" },
  ];

  const saveGroupProperties = useClientManager("loyaltyPoints")
    .saveGroupProperties()
    .useMutation({
      onSuccess: (data, vars, ctx) => {
        navigate(`../production-batch/${vars.groupName}`);
      },
      onError: (e) => toast.error("Error", e as any),
    });

  const createGroup = async () => {
    const date = toDateString(new Date());
    const randomSuffix = customAlphabet("0123456789abcdefghijklmnopqrstuvwxyz")(8);
    const groupName = ["produkcja_pokrywki", date, productId, randomSuffix].join("_");
    const userEmail = auth.iamUser?.userType === "megarax" ? auth.iamUser?.email ?? "" : "";

    await saveGroupProperties.mutateAsync({
      groupName,
      createdAt: new Date(),
      date: new Date(),
      type: "produkcja_pokrywki",
      productId,
      createdBy: userEmail,
      notes: "",
    });
  };

  return (
    <Dialog
      header="Nowa grupa produkcyjna"
      onClose={props.onClose}
      footer={
        <Button isDisabled={!productId} onClick={createGroup} css={{ width: "100%" }}>
          Dalej
        </Button>
      }
    >
      <Select label="Produkt" options={productOptions} onChange={setProductId} value={productId} />
      <DateField label="Data produkcji" value={date} onChange={setDate} />
      <TextField label="Komentarz" value={notes} onChange={setNotes} />
    </Dialog>
  );
};
