import { Tile } from "@dash/tile";
import { useTheme } from "@emotion/react";
import { MdList } from "react-icons/md";
import { Link } from "react-router-dom-v5-compat";

import { RelationType } from "@megaron/crm-contracts";
import { Uuid } from "@megaron/uuid";

type Props = {
  count: number;
  category:
    | "invitedChildrenCount"
    | "supplyPointChildrenCount"
    | "payerChildrenCount"
    | "distributorChildrenCount"
    | "chainChildrenCount";
  customerUuid: Uuid;
};

export const RelatedCustomerTile: React.FC<Props> = ({ count, category, customerUuid }) => {
  const theme = useTheme();

  const categoryDetails: Record<string, { tileLabel: string; relationType: RelationType }> = {
    invitedChildrenCount: { tileLabel: "Zaproszeni wykonawcy", relationType: "invitedBy" },
    supplyPointChildrenCount: { tileLabel: "Jako punkt sprzedaży", relationType: "supplyPoint" },
    payerChildrenCount: { tileLabel: "Jako płatnik", relationType: "payer" },
    distributorChildrenCount: { tileLabel: "Jako dystrybutor", relationType: "distributor" },
    chainChildrenCount: { tileLabel: "Jako sieć", relationType: "chain" },
  };

  const relation = {
    relationType: categoryDetails[category].relationType,
    parents: [customerUuid],
  };

  const filters = {
    page: 0,
    searchText: "",
    sort: undefined,
    isPro: false,
    isAffiliate: false,
    relation: relation,
  };

  const encodedFilters = encodeURIComponent(JSON.stringify(filters));

  return (
    <Link to={`/crm/customers/new-list?filters=${encodedFilters}`} css={{ "&:hover": { cursor: "pointer" } }}>
      <Tile
        key={category}
        stripe={
          <div
            css={{
              color: "white",
              background: theme.colors.primary,
              opacity: 1,
              padding: "0.5rem 0.875rem",
              minHeight: 0,
              alignSelf: "stretch",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRight: `1px solid ${theme.colors.primary}`,
            }}
          >
            <MdList size={18} />
          </div>
        }
      >
        <div
          css={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            justifyContent: "space-between",
          }}
        >
          <div css={{ display: "flex", flexDirection: "column" }}>
            <span css={{ fontSize: "0.75rem", color: theme.colors.secondaryText }}>
              {categoryDetails[category].tileLabel}
            </span>
            <span
              css={{
                fontSize: "0.875rem",
                color: count ? theme.colors.primary : theme.colors.secondaryText,
                fontWeight: count ? 700 : 400,
                opacity: count ? 1 : 0.5,
              }}
            >
              {`${count} ${count > 1 ? "klientów" : "klient"}` || "Brak"}
            </span>
          </div>
        </div>
      </Tile>
    </Link>
  );
};
