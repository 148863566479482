import {
  BaseResource,
  paginatedListSerializer,
  ResourceAction,
  UuidResourceParam,
} from "@legacy-megarax/rest-resource";
import { Serializers } from "@legacy-megarax/serializers";

export interface RoleReference {
  uuid: string;
}

export interface UpdateGroupInput {
  name: string;
  roles: RoleReference[];
}

export interface GroupListFilters {
  limit?: number;
  offset?: number;
  searchText?: string;
}
export interface GroupSimple {
  uuid: string;
  name: string;
}

export interface NewGroupInput {
  uuid: string;
  name: string;
  roles: RoleReference[];
}

export interface GroupDto {
  uuid: string;
  name: string;

  roles: {
    uuid: string;
    name: string;
  }[];
}

const list = ResourceAction({
  name: "list",
  method: "get",
  path: "",
  querySerializer: Serializers.object<GroupListFilters>({
    searchText: Serializers.string.optional(),
    limit: Serializers.integer.optional(),
    offset: Serializers.integer.optional(),
  }),
  responseSerializer: paginatedListSerializer(
    Serializers.object<GroupSimple>({
      name: Serializers.string,
      uuid: Serializers.uuid(),
    }),
  ),
});

export const groupResource = BaseResource(["iam", "groups"], {
  list,
  byUuid: UuidResourceParam("groupUuid", {
    retrieve: ResourceAction({
      name: "retrieve",
      method: "get",
      path: "",
      responseSerializer: Serializers.object<GroupDto>({
        name: Serializers.string,
        uuid: Serializers.uuid(),
        roles: Serializers.array(
          Serializers.object({
            name: Serializers.string,
            uuid: Serializers.uuid(),
          }),
        ),
      }),
    }),
  }),
});
