import { Dialog } from "@dash/dialog";
import { useDeviceType } from "@dash/mq";
import { SelectDialog } from "@dash/select";
import { useToast } from "@dash/toast";
import { PricedItemDto } from "@legacy-megarax/crm-contracts";
import Decimal from "decimal.js";
import { useState } from "react";
import { useQueryClient } from "react-query";

import { AddDraftLineInput, Currency, Gtin } from "@megaron/crm-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { ItemDialogContent } from "./ItemDialogContent";

type Props = {
  onClose: () => void;
  orderUuid?: Uuid;
  queryKey?: string | string[];
  currency: Currency;
  pricedItems?: PricedItemDto[];
  baseDiscount?: Decimal;
};

export const AddItemDialog: React.FC<Props> = ({
  onClose,
  orderUuid,
  queryKey,
  currency,
  pricedItems,
  baseDiscount,
}) => {
  const { isMobile } = useDeviceType();

  const toast = useToast();

  const queryClient = useQueryClient();

  const [itemGtin, setItemGtin] = useState<string | undefined>(undefined);

  const itemQuery = useClientManager("crm")
    .itemQuery()
    .useQuery({ gtin: itemGtin as Gtin }, { enabled: !!itemGtin });

  const addLineMutation = useClientManager("crm").addDraftLine().useMutation();

  const getPricedItem = (itemGtin?: string) => {
    if (!pricedItems || !itemGtin) {
      return undefined;
    }

    return pricedItems.find((item) => item.gtin === itemGtin);
  };

  const handleAddLine = (line: AddDraftLineInput) => {
    if (!orderUuid) {
      return;
    }

    const input = {
      ...line,
      uuid: orderUuid,
    };

    addLineMutation.mutate(input, {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        onClose();
      },
      onError: (error) => {
        if (error === "DraftNotOpen") {
          toast.error("Wersja robocza nie posiada statusu: otwarta.");
        }

        if (error === "InvalidLineQuantity") {
          toast.error("Nieprawidłowa ilość przedmiotu.");
        }

        if (error === "LineAlreadyExists") {
          toast.error("Przedmiot już istnieje w zamówieniu.");
        }

        if (error === "OrderNotFound") {
          toast.error("Nie znaleziono zamówienia.");
        }

        if (error === "UserNotOrderOwner") {
          toast.error("Nie jesteś właścicielem zamówienia.");
        }

        if (error === "InvalidLineDiscountRate") {
          toast.error("Nieprawidłowy rabat na przedmiocie. Suma rabatów nie może przekraczać 100%.");
        }
      },
    });
  };

  return (
    <Dialog
      onClose={onClose}
      placement={isMobile ? "top" : "center"}
      css={{ width: isMobile ? "100%" : "400px" }}
      header="Dodaj przedmiot"
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "1rem", maxHeight: "70dvh", overflow: "auto" }}>
        <SelectDialog
          variant="single-select"
          label="Jednostka handlowa"
          onSelect={(item) => setItemGtin(item?.value)}
          initiallySelectedValues={itemGtin ? [itemGtin] : []}
          search={async (search) => {
            const items =
              pricedItems?.map((item) => ({
                label: item.name ?? item.gtin,
                value: item.gtin,
              })) ?? [];

            const filteredItems = items.filter((item) =>
              item.label.replace(/[-_]/g, "").toLowerCase().includes(search.replace(/[-_]/g, "").toLowerCase()),
            );

            return filteredItems;
          }}
        />

        <hr
          css={{
            margin: 0,
            width: "100%",
            height: "1px",
            background: "rgba(0, 0, 0, 0.1)",
            border: "none",
          }}
        />

        <ItemDialogContent
          item={itemQuery.data}
          onSave={handleAddLine}
          currency={currency}
          pricedItem={getPricedItem(itemGtin)}
          baseDiscount={baseDiscount}
        />
      </div>
    </Dialog>
  );
};
