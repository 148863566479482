import { userTypes } from "@megaron/access-control";
import { HttpAction } from "@megaron/http-service";
import { PostalCode, Serializers, SerializerValue } from "@megaron/serializers";
import { decodeBase64 } from "@megaron/utils";
import { Uuid, uuidSerializer } from "@megaron/uuid";

export const idTokenPayloadSerializer = Serializers.object({
  id: Serializers.string,
  userType: Serializers.stringOneOf(...userTypes),
  loyaltyId: uuidSerializer.nullable(),
  firstName: Serializers.string.nullable(),
  lastName: Serializers.string.nullable(),
  profilePictureUrl: Serializers.string.nullable(),
  email: Serializers.string.nullable(),
  phoneNumber: Serializers.string.nullable(),

  groups: Serializers.array(Serializers.object({ id: Serializers.string, name: Serializers.string })),
  roles: Serializers.string.array(),
  attributes: Serializers.string.array(),
  exp: Serializers.integer,
  iat: Serializers.integer,
  refreshTokenId: uuidSerializer.nullable().optional(),
});

export type IdTokenPayload = SerializerValue<typeof idTokenPayloadSerializer>;

export type MyAccountDto = {
  uuid: Uuid;
  phoneNumber: string;
  scheduledDeletionTime: Date | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  unverifiedEmail: string | null;
  isRegistered: boolean;
  isVerified: boolean;
  emailConsent: boolean;
  phoneCallConsent: boolean;
  smsConsent: boolean;
  postalCode: PostalCode | null;
};

export const myAccountDtoSerializer = Serializers.object<MyAccountDto>({
  uuid: uuidSerializer,
  phoneNumber: Serializers.string,
  scheduledDeletionTime: Serializers.datetime.nullable(),
  firstName: Serializers.string.nullable(),
  lastName: Serializers.string.nullable(),
  email: Serializers.string.nullable(),
  unverifiedEmail: Serializers.string.nullable(),
  isRegistered: Serializers.boolean,
  isVerified: Serializers.boolean,
  emailConsent: Serializers.boolean,
  phoneCallConsent: Serializers.boolean,
  smsConsent: Serializers.boolean,
  postalCode: Serializers.postalCode.nullable(),
});

export const accountDtoSerializer = Serializers.object({
  uuid: uuidSerializer,
  phoneNumber: Serializers.string,
  email: Serializers.email.nullable(),
  firstName: Serializers.string.nullable(),
  lastName: Serializers.string.nullable(),
  unverifiedEmail: Serializers.string.nullable(),
  deletedAt: Serializers.datetime.nullable(),
  scheduledDeletionTime: Serializers.datetime.nullable(),
  joinedAt: Serializers.datetime.nullable(),
  registeredAt: Serializers.datetime.nullable(),
  postalCode: Serializers.postalCode.nullable(),
});

export type AccountDto = SerializerValue<typeof accountDtoSerializer>;

export const testUserSignInOptionsSerializer = Serializers.object({
  userId: Serializers.string,
  userType: Serializers.stringOneOf(...userTypes),
  phoneNumber: Serializers.string,
}).partial();

export type TestUserSignInOptions = SerializerValue<typeof testUserSignInOptionsSerializer>;

const signInErrorSerializer = Serializers.stringOneOf(
  "InvalidToken",
  "NoCredentialsProvided",
  "InvalidRefreshToken",
  "AccountInactive",
  "AccountNotFound",
  "InvalidApiKey",
  "InvalidOtp",
  "RequestExpired",
  "TooManyAttempts",
  "NotAllowedToIssueRefreshToken",
);

export type SignInErorr = SerializerValue<typeof signInErrorSerializer>;

const otpSignInOptionsSerializer = Serializers.object({
  uuid: uuidSerializer,
  password: Serializers.string,
});

export type OtpSignInOptions = SerializerValue<typeof otpSignInOptionsSerializer>;

const signInResponseSerializer = Serializers.object({
  idTokenPayload: idTokenPayloadSerializer,
  idTokenExpiresAt: Serializers.datetime,
  idToken: Serializers.string,
  refreshToken: Serializers.object({
    id: uuidSerializer,
    expiresAt: Serializers.datetime,
    legacyTokenValue: Serializers.string.optional(),
  }).nullable(),
});

export type SignInResponse = SerializerValue<typeof signInResponseSerializer>;

export const authActions = {
  signIn: HttpAction({
    path: "/signIn",
    method: "post",
    bodySerializer: Serializers.object({
      googleIdToken: Serializers.string,
      apiKey: Serializers.string,
      otp: otpSignInOptionsSerializer,
      testUser: testUserSignInOptionsSerializer.partial(),
      legacyRefreshToken: Serializers.string,
      issueRefreshToken: Serializers.stringOneOf("cookie", "legacy").optional(),
    }).partial(),
    valueSerializer: signInResponseSerializer,
    errorSerializer: signInErrorSerializer,
  }),

  /**
   * @deprecated Legacy endpoint for compatibility with old frontend versions.
   */
  exchangeToken: HttpAction({
    path: "/exchangeToken",
    method: "post",
    bodySerializer: Serializers.object({ refreshToken: Serializers.string }),
    valueSerializer: Serializers.object({ idToken: Serializers.string, expiresAt: Serializers.datetime }),
    errorSerializer: Serializers.stringOneOf("InvalidRefreshToken"),
  }),

  requestOtp: HttpAction({
    path: "/requestOtp",
    method: "post",
    bodySerializer: Serializers.first(
      Serializers.object({ phoneNumber: Serializers.string, recaptchaToken: Serializers.string }),
      Serializers.object({ accountUuid: uuidSerializer, recaptchaToken: Serializers.string }),
    ),
    valueSerializer: Serializers.object({ uuid: uuidSerializer }),
    errorSerializer: Serializers.stringOneOf("InvalidPhoneNumber", "FailedRecaptcha", "AccountNotFound"),
  }),

  signOut: HttpAction({
    path: "/signOut",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({
      refreshTokenId: Serializers.string,
      allTokens: Serializers.boolean,
    }).partial(),
    errorSerializer: Serializers.stringOneOf("TokenNotFound"),
  }),

  myAccount: HttpAction({
    path: "/myAccount",
    method: "get",
    valueSerializer: myAccountDtoSerializer,
    errorSerializer: Serializers.stringOneOf("AccountNotFound", "AccountDeleted"),
    requiresAuth: true as const,
  }),

  account: HttpAction({
    path: "/account/uuid/:uuid",
    method: "get",
    requiresAuth: true as const,
    valueSerializer: accountDtoSerializer,
    errorSerializer: Serializers.stringOneOf("AccountNotFound"),
    paramsSerializer: Serializers.object({ uuid: uuidSerializer }),
  }),

  publicProfileQuery: HttpAction({
    method: "get",
    path: "/publicProfiles/uuid/:uuid",
    paramsSerializer: Serializers.object({
      uuid: uuidSerializer,
    }),
    valueSerializer: Serializers.object({
      uuid: uuidSerializer,
      firstName: Serializers.string.nullable(),
      lastName: Serializers.string.nullable(),
    }),
    errorSerializer: Serializers.stringOneOf("AccountNotFound"),
  }),

  requestAccountDeletion: HttpAction({
    path: "/requestAccountDeletion",
    method: "post",
    errorSerializer: Serializers.stringOneOf("DeletionAlreadyScheduled", "AccountNotFound", "AccountDeleted"),
    requiresAuth: true as const,
  }),

  cancelAccountDeletion: HttpAction({
    path: "/cancelAccountDeletion",
    method: "post",
    errorSerializer: Serializers.stringOneOf("DeletionNotScheduled", "AccountNotFound", "AccountDeleted"),
    requiresAuth: true as const,
  }),

  executeScheduledDeletions: HttpAction({
    path: "/executeScheduledDeletions",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({ cutoff: Serializers.datetime.optional() }),
  }),
};

export const decodeIdToken = (idToken: string): IdTokenPayload => {
  const payloadB64 = idToken.split(".")[1];
  const payloadJsonStr = decodeBase64(payloadB64);
  const payloadJson = JSON.parse(payloadJsonStr);

  return idTokenPayloadSerializer.deserialize(payloadJson).assertOk().value;
};
