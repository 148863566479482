import { Button } from "@dash/form";
import { useDeviceType } from "@dash/mq";
import { useToast } from "@dash/toast";
import { parse } from "csv/browser/esm/sync";
import { MdOutlineUploadFile } from "react-icons/md";

import { useClientManager } from "@megaron/react-clients";

import { rawToInput } from "./input";

type Props = {
  onImportSuccess: () => void;
  className?: string;
};

export const ImportCostsButton: React.FC<Props> = ({ onImportSuccess, className }) => {
  const toast = useToast();

  const { isMobile } = useDeviceType();

  const saveItemCostsMutation = useClientManager("crm").saveItemCosts().useMutation();

  const onFileUpload: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;
    const parsed: any[] = parse(await file.text(), {
      columns: true,
    });

    const inputRowsResult = rawToInput(parsed);
    if (inputRowsResult.isFailure) {
      if (inputRowsResult.error === "InvalidItemId") {
        toast.error("Nieprawidłowe ID produktu");
      }

      if (inputRowsResult.error === "InvalidItemCosts") {
        toast.error("Nieprawidłowy koszt standardowy");
      }

      if (inputRowsResult.error === "InvalidInput") {
        toast.error("Nieprawidłowy format danych");
      }

      if (inputRowsResult.error === "MissingItemId") {
        toast.error("Plik musi zawierać kolumnę 'ItemId'");
      }

      if (inputRowsResult.error === "MissingItemCosts") {
        toast.error("Plik musi zawierać kolumnę 'Koszty standardowe'");
      }

      toast.error(inputRowsResult.error);
      return;
    }

    saveItemCostsMutation.mutate(inputRowsResult.value, {
      onSuccess: () => {
        onImportSuccess();
      },
      onError: () => {
        toast.error("Błąd podczas importowania rabatów posprzedażowych");
      },
    });
  };

  return (
    <div className={className}>
      <input accept=".csv" type="file" id="contained-button-file" style={{ display: "none" }} onChange={onFileUpload} />
      <label htmlFor="contained-button-file">
        <Button variant="outline" size={isMobile ? "small" : "medium"} icon={<MdOutlineUploadFile />} as="div">
          Importuj koszty standardowe
        </Button>
      </label>
    </div>
  );
};
