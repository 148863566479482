import { TaskIdentifier } from "@legacy-megarax/crm-contracts";
import React from "react";

import { PendingPayerProposalTasksCard } from "./PendingPayerProposalTasksCard";
import { PendingPriceListProposalTasksCard } from "./PendingPriceListProposalTasksCard";

interface Props<TIdentifier extends TaskIdentifier> {
  identifiers: TIdentifier[];
  time: Date;
}

const AnyTaskGroupCard: React.FunctionComponent<Props<TaskIdentifier>> = ({ identifiers, time }) => {
  if (identifiers[0].type === "pendingPriceListProposal") {
    return <PendingPriceListProposalTasksCard identifiers={identifiers as any} time={time} />;
  }
  if (identifiers[0].type === "pendingPayerProposal")
    return <PendingPayerProposalTasksCard identifiers={identifiers as any} time={time} />;

  return null;
};

export default AnyTaskGroupCard;
