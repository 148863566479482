import { uuidSerializer } from "@legacy-megarax/common";
import { paginatedListSerializer, ResourceActionV2 } from "@legacy-megarax/rest-resource";
import { MSerializers } from "@legacy-megarax/serializers";

import { MacroregionSimple } from "./";

export interface MacroregionListQueryFilters {
  limit?: number;
  offset?: number;
  searchText?: string;
}

const macroregionListQueryFiltersSerializer = MSerializers.object<MacroregionListQueryFilters>({
  limit: MSerializers.integer.optional(),
  offset: MSerializers.integer.optional(),
  searchText: MSerializers.string.optional(),
});

const macroregionSimpleSerializer = MSerializers.object<MacroregionSimple>({
  uuid: uuidSerializer,
  name: MSerializers.string,
});

export default ResourceActionV2({
  name: "list",
  method: "get",
  path: "",
  querySerializer: macroregionListQueryFiltersSerializer,
  responseValueSerializer: paginatedListSerializer(macroregionSimpleSerializer),
});
