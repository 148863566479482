import { Button } from "@dash/form";
import { Spinner } from "@dash/spinner";
import { stringify } from "csv/browser/esm/sync";
import fileDownload from "js-file-download";
import { useState } from "react";
import { MdOutlineFileDownload } from "react-icons/md";

import { ContactEntry } from "@megaron/crm-contracts";
import { useServiceClient } from "@megaron/react-clients";

export const DownloadContactsCsvButton: React.FC = () => {
  const crm = useServiceClient("crm");

  const [isFetching, setIsFetching] = useState(false);

  const fetchCsvThreads = async () => {
    const contacts = await crm.allContactsQuery({});

    return contacts.value || [];
  };

  const handleDownload = async () => {
    setIsFetching(true);

    const contacts = await fetchCsvThreads();
    setIsFetching(false);

    downloadCsv(contacts);
  };

  return (
    <Button
      onClick={handleDownload}
      variant="outline"
      size="small"
      icon={
        isFetching ? (
          <div css={{ width: "20px" }}>
            <Spinner size="16px" />
          </div>
        ) : (
          <MdOutlineFileDownload size={20} />
        )
      }
    >
      Kontakty CSV
    </Button>
  );
};

type ContactFlat = {
  nazwa_klienta: string;
  imie_nazwisko: string;
  stanowisko: string;
  telefon: string;
  email: string;
  region: string;
  makroregion: string;
};

const contactToCsvEntry = (contact: ContactEntry): ContactFlat => ({
  nazwa_klienta: contact.customerName,
  imie_nazwisko: contact.name,
  stanowisko: contact.jobTitle,
  telefon: contact.phoneNumber,
  email: contact.email,
  region: contact.region,
  makroregion: contact.macroregion,
});

const downloadCsv = (contacts: ContactEntry[]) => {
  const entries = contacts.slice(0, 10000).map(contactToCsvEntry);

  const columns: (keyof ContactFlat)[] = [
    "nazwa_klienta",
    "imie_nazwisko",
    "stanowisko",
    "telefon",
    "email",
    "region",
    "makroregion",
  ];

  const csv = stringify(entries, {
    header: true,
    columns: columns,
  });
  fileDownload(csv, `kontakty_${new Date().toISOString()}.csv`);
};
