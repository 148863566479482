import { useTheme } from "@emotion/react";
import { useEffect, useRef } from "react";

type Props = {
  value: number;
  onInputChange: (value: number) => void;
  onIncrement: () => void;
  onDecrement: () => void;
  label?: string;
  className?: string;
  isDisabled?: boolean;
  isRemoveButtonDisabled?: boolean;
  onInputFocusChange?: (isFocused: boolean) => void;
};

export const IntegerFieldWithButtons: React.FunctionComponent<Props> = ({ onInputFocusChange, ...props }) => {
  const theme = useTheme();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!onInputFocusChange) {
      return;
    }

    const handleFocus = () => onInputFocusChange(true);
    const handleBlur = () => onInputFocusChange(false);

    const input = inputRef.current;

    if (!input) {
      return;
    }

    input.addEventListener("focus", handleFocus);
    input.addEventListener("blur", handleBlur);

    return () => {
      input.removeEventListener("focus", handleFocus);
      input.removeEventListener("blur", handleBlur);
    };
  }, [onInputFocusChange]);

  const handleChange = (value: string) => {
    const int = parseInt(value);
    if (isNaN(int)) {
      props.onInputChange(0);
      return;
    }
    props.onInputChange(parseInt(value));
  };

  const buttonStyles = {
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.875rem",
    color: theme.colors.primary,
    backgroundColor: "white",
    flexShrink: 0,
    width: "2rem",
    height: "2rem",
    borderRadius: "100%",
    cursor: "pointer",
    ":disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
  };

  return (
    <div css={{ display: "flex", flexDirection: "column", width: "100%" }} className={props.className}>
      <label
        css={{
          color: theme.colors.primary,
          fontSize: "0.875rem",
          fontFamily: theme.displayFontFamily,
          marginBottom: "0.25rem",
        }}
      >
        {props.label}
      </label>
      <div
        css={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          justifyContent: "space-between",
          width: "100%",
          backgroundColor: theme.colors.primaryLight,
          padding: "0.5rem 0.75rem",
          borderRadius: "6px",
          opacity: props.isDisabled ? 0.7 : 1,
        }}
      >
        <button
          disabled={
            props.isDisabled ||
            (props.isRemoveButtonDisabled === undefined ? props.value < 1 : props.isRemoveButtonDisabled)
          }
          css={buttonStyles}
          onClick={props.onDecrement}
        >
          -1
        </button>
        <input
          ref={inputRef}
          value={props.value?.toString() ?? ""}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange(e.target.value)}
          css={{
            width: "100%",
            textAlign: "center",
            border: "none",
            fontSize: "1rem",
            backgroundColor: "transparent",
            ":focus": {
              outline: `none`,
            },
            disabled: {
              cursor: "text",
            },
          }}
          disabled={props.isDisabled ? true : false}
          type="text"
          inputMode="numeric"
        />
        <button css={buttonStyles} onClick={props.onIncrement} disabled={props.isDisabled}>
          +1
        </button>
      </div>
    </div>
  );
};
